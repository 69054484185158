import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";

import routing from "stores/routing";

import "./index.scss";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

import Modal from "components/Modal";

import useDocumentTitle from "hooks/useDocumentTitle";

const Searches = inject(
  "PaginationStore",
  "ItemsStore",
  "SearchStore",
  "ModalStore",
  "ApplicationStore"
)(
  observer(
    ({
      PaginationStore,
      ItemsStore,
      SearchStore,
      ModalStore,
      ApplicationStore,
    }) => {
      const { fetchWithParameters } = ItemsStore;
      const {
        currentPage,
        perPage,
        changePerPage,
        paginate,
        resetPagination,
        resetPerPage,
        startIndex,
        resetAll,
      } = PaginationStore;
      const { isOpen, toggle, isSubmit, resetModal } = ModalStore;
      const {
        delSavedSearch,
        setSubmitted,
        setRedirected,
        createDisplayedParams,
        displayedSearches,
        fetchSavedSearches,
        count,
        resetSearchStore,
      } = SearchStore;
      const { isLoading } = ApplicationStore;
      const [deleteId, setDeleteId] = useState("");

      useDocumentTitle("Сохранённые поиски");

      useEffect(() => {
        fetchSavedSearches(startIndex, perPage);
        return () => {
          resetSearchStore();
        };
      }, [startIndex, perPage, resetSearchStore, fetchSavedSearches]);

      useEffect(() => {
        if (isSubmit) {
          delSavedSearch(deleteId, startIndex, perPage);
        }
      }, [isSubmit, delSavedSearch, deleteId, startIndex, perPage]);

      useEffect(() => {
        resetPagination();
      }, [resetPagination, perPage]);

      useEffect(() => () => resetModal(), [resetModal]);
      useEffect(() => () => resetPerPage(), [resetPerPage]);

      const classnames = {
        header: "searches-header",
        itemWrapper: "searches-item",
      };

      const header = (
        <>
          <div>Параметры поиска</div>
          <div>Действие</div>
        </>
      );

      const mappedItems = displayedSearches?.map((item, index) => (
        <>
          <div className="searches-item page-link">
            <span
              onClick={() => {
                resetAll();
                setSubmitted(true);
                setRedirected(true);
                createDisplayedParams(index, "saved");
                fetchWithParameters("all", startIndex, perPage);
                routing.push("/people");
              }}
            >
              {item.string}
            </span>
          </div>
          <div className="searches-item page-link">
            <span
              onClick={() => {
                toggle(true);
                setDeleteId(item.id);
              }}
            >
              Удалить
            </span>
          </div>
        </>
      ));

      const modalDanger = <Modal type="danger" />;

      return (
        <div className="wrapper__content">
          {isOpen && modalDanger}
          <section className="section">
            <div className="container">
              <h1 className="page-title">Сохранённые поиски</h1>
              <div className="searches-wrapper">
                {displayedSearches && displayedSearches.length !== 0 ? (
                  <>
                    <Table
                      header={header}
                      items={mappedItems}
                      classnames={classnames}
                    />
                    <Pagination
                      perPage={perPage}
                      total={count}
                      currentPage={currentPage + 1}
                      handlePaginate={(page) => paginate(page - 1)}
                      isShowPerPage
                      handleChangePerPage={(count) => changePerPage(count)}
                    />
                  </>
                ) : (
                  <>
                    {isLoading ? <LoaderSpinner /> : <Empty isSearch={false} />}
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      );
    }
  )
);

export default Searches;
