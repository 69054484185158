import React, { useEffect } from "react";
import { observer } from "mobx-react";

import UnderlinedTabs from "components/UnderlinedTabs";
import RecentlySearchBlock from "components/RecentlySearchBlock";

import TabbedSwitchersStore from "stores/common/tabbedSwitchersStore";
import SearchStore from "stores/components/searchStore";

const underlinedTabsData = [
  { title: "Недавно искали", id: "recentlySearches" },
  { title: "Сохранённые поиски", id: "savedSearches" },
];

const MemoizedSearchBlock = observer(() => {
  const { selectTabById, selectedTab } = TabbedSwitchersStore;
  const {
    displayedSearches,
    fetchTemporarySearches,
    fetchSavedSearches,
  } = SearchStore;

  useEffect(() => {
    selectTabById("recentlySearches");
  }, [selectTabById]);

  useEffect(() => {
    const tab = selectedTab;

    if (tab && tab === "recentlySearches") {
      fetchTemporarySearches();
    }

    if (tab && tab === "savedSearches") {
      fetchSavedSearches();
    }
  }, [selectedTab, fetchTemporarySearches, fetchSavedSearches]);

  return (
    <div className="memoized-search-block">
      <UnderlinedTabs
        data={underlinedTabsData}
        selectedTab={selectedTab}
        onSelectTab={selectTabById}
      />
      <RecentlySearchBlock
        data={displayedSearches}
        category={selectedTab === "recentlySearches" ? "recent" : "saved"}
      />
    </div>
  );
});

export default MemoizedSearchBlock;
