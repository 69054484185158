import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import DeleteButton from "common/components/Buttons/DeleteButton";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";

import PeopleForm from "./PeopleForm";

import useDocumentTitle from "hooks/useDocumentTitle";
import { toJS } from "mobx";

const AddPeople = inject(
  "ModalStore",
  "PeopleFormStore"
)(
  observer(({ ModalStore, PeopleFormStore, id, isEdit }) => {
    const { deletePerson, person } = PeopleFormStore;
    const { toggle, isSubmit, resetModal, isOpen } = ModalStore;

    console.log("person", toJS(person));

    useEffect(() => {
      if (isSubmit) {
        deletePerson(id);
      }
    }, [isSubmit, deletePerson, id]);

    useEffect(() => () => resetModal(), [resetModal]);

    useDocumentTitle(
      () => (isEdit ? "Редактировать человека" : "Добавить человека"),
      [isEdit]
    );

    const modalDanger = <Modal type="danger" />;
    const modalWarning = (
      <Modal
        type="warning"
        message="Вы действительно хотите деактивировать данного человека?"
      />
    );

    const breadcrumbs = [
      { path: "/people", label: "Люди" },
      {
        path: "/people/addperson",
        label: isEdit ? "Редактировать человека" : "Добавить человека",
      },
    ];

    return (
      <>
        {isEdit ? (
          <>
            {isOpen && modalWarning}
            {person && person.isActive && (
              <DeleteButton onClick={() => toggle(true)} className="page-link">
                Деактивировать человека
              </DeleteButton>
            )}
            <PeopleForm isEdit={isEdit} id={id} />
          </>
        ) : (
          <div className="wrapper__content">
            {isOpen && modalDanger}
            <Breadcrumbs routes={breadcrumbs} />
            <section className="section">
              <div className="container">
                <h1 className="page-title">Добавить человека</h1>
                <PeopleForm isEdit={isEdit} id={id} />
              </div>
            </section>
          </div>
        )}
      </>
    );
  })
);

export default AddPeople;
