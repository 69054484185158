import React from "react";

const Loader = () => (
  <>
    <style>
      {`
      .throbber-container {
        height:35vh;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      .throbber-svg svg {
        padding: 2px;
        -webkit-animation: throbberRotateX 1.5s infinite cubic-bezier(0.8, 0, 0, 0.8);
        animation: throbberRotateX 1.5s infinite cubic-bezier(0.8, 0, 0, 0.8);
        will-change: transform;
        box-sizing: content-box;
      }
      .throbber-svg .throbber-path {
        -webkit-transform-origin: 46px 50px;
        transform-origin: 46px 50px;
        -webkit-animation: throbberRotateZ 1.5s infinite linear,
          throbberColor 6s infinite cubic-bezier(0.8, 0, 0, 0.8);
        animation: throbberRotateZ 1.5s infinite linear,
          throbberColor 6s infinite cubic-bezier(0.8, 0, 0, 0.8);
        will-change: transform;
      }

      .throbber-svg .throbber-big {
        width: 104px;
        height: 104px;
        margin: auto;
      }

      .throbber-big svg {
        width: 104px;
        height: 104px;
        -webkit-transform-origin: 0 52px;
        transform-origin: 0 54px;
      }

      @-webkit-keyframes throbberRotateX {
        0% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
        }
        100% {
          -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
        }
      }
      @keyframes throbberRotateX {
        0% {
          -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
        }
        100% {
          -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
        }
      }

      @-webkit-keyframes throbberRotateZ {
        0% {
          -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
        }
        25% {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }
        50% {
          -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
        }
        75% {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }
        100% {
          -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
        }
      }

      @keyframes throbberRotateZ {
        0% {
          -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
        }
        25% {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }
        50% {
          -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
        }
        75% {
          -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
        }
        100% {
          -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
        }
      }

      @-webkit-keyframes throbberColor {
        0%,
        100% {
          fill: #00aadd;
        }
        25% {
          fill: #00cb3c;
        }
        50% {
          fill: #d71956;
        }
        75% {
          fill: #ff9700;
        }
      }
      @keyframes throbberColor {
        0%,
        100% {
          fill: #00aadd;
        }
        25% {
          fill: #00cb3c;
        }
        50% {
          fill: #d71956;
        }
        75% {
          fill: #ff9700;
        }
      }

      .line-1{
        font-weight: normal;
        font-size: 18px;
        color: #000000;
        margin-bottom: 14px;
        text-align: center;
      }
      .line-2{
        font-size: 15px;
        margin-bottom: 40px;
        color: #535B63;
      }
    `}
    </style>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="92px"
      height="100px"
      viewBox="0 0 92 100"
      enableBackground="new 0 0 92 100"
    >
      <path
        fill="#00aadd"
        className="throbber-path"
        d="M81.765,82.649L56.607,97.19c-6.479,3.744-14.462,3.746-20.942,0.01L10.493,82.682
        C4.012,78.944,0.017,72.032,0.014,64.549L0,35.493c-0.003-7.482,3.985-14.398,10.463-18.141L35.621,2.811
        c6.478-3.744,14.46-3.748,20.943-0.011l25.171,14.519c6.481,3.738,10.476,10.648,10.479,18.131l0.014,29.059
        C92.231,71.989,88.244,78.905,81.765,82.649"
      ></path>
    </svg>
  </>
);

const LoaderSpinner = () => {
  return (
    <div className="throbber-container">
      <div className="throbber-svg throbber-window">
        <div>
          <h3 className="line-1">Идёт загрузка</h3>
          <div className="line-2">Это может занять некоторое время</div>
        </div>
        <div className="throbber-big">
          <Loader />
        </div>
      </div>
    </div>
  );
};
export default LoaderSpinner;
