import { observable, decorate, action, runInAction, set, computed } from "mobx";
import { EMPTY_ERR, INVALID_SYMBOLS_ERR } from "helpers/errors";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import ModalStore from "stores/components/modalStore";
import PeopleParamsStore from "./peopleParamsStore";
import AlertStore from "./alertsStore";
class HomeParamsStore {
  homeParam = null;
  newParam = { title: "", possibleValues: [] };
  titleCache = "";
  serverErr = "";

  get errors() {
    let error = {};
    if (!ApplicationStore.isSubmitted) {
      return error;
    }
    switch (ApplicationStore.mode) {
      case "ADD_HOME_PARAM":
        if (!this.newParam.title) {
          error["title"] = EMPTY_ERR;
        } else if (this.serverErr) {
          error["title"] = this.serverErr;
        } else if (!PeopleParamsStore.isValidParam(this.newParam.title)) {
          error["title"] = INVALID_SYMBOLS_ERR;
        }
        break;
      case "EDIT_HOME_PARAM":
        if (!this.homeParam.title) {
          error["title"] = EMPTY_ERR;
        } else if (this.serverErr) {
          error["title"] = this.serverErr;
        } else if (!PeopleParamsStore.isValidParam(this.homeParam.title)) {
          error["title"] = INVALID_SYMBOLS_ERR;
        }
        break;
      default:
        break;
    }
    return error;
  }

  resetServerErrors = () => {
    this.serverErr = "";
  };

  addValue = (isEdit) => {
    isEdit
      ? this.homeParam.possibleValues.push("")
      : this.newParam.possibleValues.push("");
  };

  changeValue = (isEdit, index, value) => {
    isEdit
      ? (this.homeParam.possibleValues[index] = value)
      : (this.newParam.possibleValues[index] = value);
  };

  deleteValue = (isEdit, index) => {
    isEdit
      ? this.homeParam.possibleValues.splice(index, 1)
      : this.newParam.possibleValues.splice(index, 1);
  };

  changeParameter = (obj, key, value) => {
    set(obj, key, value);
  };

  reset = () => {
    runInAction(() => {
      this.homeParam = null;
      this.newParam.title = "";
      this.newParam.possibleValues = [];
      this.titleCache = "";
      this.serverErr = "";
    });
  };

  fetchParameter = async (title) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/buildingparameters/${title}`)
      .then((res) => {
        this.homeParam = res.data;
        this.titleCache = this.homeParam.title;
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          routing.push(`/404`);
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  saveParam = () => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (Object.keys(this.errors).length !== 0) {
      return;
    }

    const editorModeIsOn = ApplicationStore.mode === "EDIT_HOME_PARAM";
    const method = editorModeIsOn ? "put" : "post";
    const param = editorModeIsOn ? this.homeParam : this.newParam;
    const b = "/buildingparameters/";
    const url = editorModeIsOn ? b + this.titleCache : b;

    const filteredValues = param.possibleValues
      .map((value) => value.trim())
      .filter((value, index, self) => value && self.indexOf(value) === index);

    ApplicationStore.setUploading(true);
    app[method](url, {
      title: param.title.trim(),
      possibleValues: filteredValues,
    })
      .then((res) => {
        if (editorModeIsOn) {
          runInAction(() => {
            this.titleCache = param.title;
            AlertStore.toggleHandbookEditBuildingParamSuccessAlertOpen(true);
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
          });
        } else {
          runInAction(() => {
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
            routing.push(`/handbooks/edithomeparam/${res.data}`);
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          this.serverErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setUploading(false);
      });
  };

  deleteParam = () => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      app
        .delete(`/buildingparameters/${this.titleCache}`)
        .then(() => {
          runInAction(() => {
            routing.push("/handbooks/homeparams");
          });
        })
        .catch((err) => {
          if (err.response) {
            this.serverErr = err.response.data;
          }
          ApplicationStore.setError(err);
          ModalStore.resetModal();
        });
    }
  };
}

decorate(HomeParamsStore, {
  homeParam: observable,
  newParam: observable,
  titleCache: observable,
  serverErr: observable,

  errors: computed,

  addValue: action,
  changeValue: action,
  deleteValue: action,
  fetchParameter: action,
  changeParameter: action,
  reset: action,
  addParam: action,
  saveParam: action,
  updateParam: action,
  deleteParam: action,
  resetServerErrors: action,
});

export default new HomeParamsStore();
