import React from "react";

import { ReactComponent as Danger } from "assets/svg/danger.svg";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

const ModalDanger = ({ message, onSubmit, onCancel }) => {
  return (
    <div className="modal">
      <p className="modal__title static">Удалить</p>
      <Danger className="modal__icon" />
      <p className="modal__helper-text static">
        {message
          ? message
          : "Вы уверены? Отменить это действие будет невозможно"}
      </p>
      <div className="modal__button-group static">
        <SubmitButton
          className={"modal__button"}
          onClick={() => onSubmit(true)}
        >
          Удалить
        </SubmitButton>
        <CancelButton
          className={"modal__button"}
          onClick={() => onCancel(false)}
        >
          Отмена
        </CancelButton>
      </div>
    </div>
  );
};

export default ModalDanger;
