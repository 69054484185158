import React from "react";

import "./index.scss";

import { ReactComponent as Trash } from "assets/svg/trash.svg";

const DeleteButton = (props) => {
	return (
		<button
			type="button"
			className={`button__delete ${props.className ? props.className : ""}`}
			onClick={props.onClick}
			disabled={props.disabled || false}>
			<Trash />
			{props.children}
		</button>
	);
};

export default DeleteButton;
