import { observable, decorate, action } from "mobx";

class ModalStore {
  constructor() {
    this.resetModal();
  }

  resetModal = () => {
    this.isOpen = false;
    this.isContentModalOpen = false;
    this.isErrModalOpen = false;
    this.isHelperModalOpen = false;
    this.isInvitePeopleModalOpen = false;
    this.isAssignParameterModalOpen = false;
    this.isAddParameterModalOpen = false;
    this.isFileUploadModalOpen = false;
    this.isAddJuryListModalOpen = false;
    this.isEditJuryListModalOpen = false;
    this.isExportJuryModalOpen = false;
    this.isProgressJuryListModalOpen = false;
    this.isJuryIntersectModalOpen = false;
    this.isManageJuryPersonOpen = false;
    this.isEditExclusionJuryPersonOpen = false;
    this.isSubmit = false;
    this.isFilterModalOpen = false;
    this.isImportResultsOpen = false;
    this.isExcludesModalIsOpen = false;
    this.message = "";
    this.type = "warning";
  };

  toggle = (open) => {
    this.isOpen = open;
  };

  toggleErrModal = (isOpen) => {
    this.isErrModalOpen = isOpen;
  };

  toggleHelperModal = (isOpen) => {
    this.isHelperModalOpen = isOpen;
  };

  toggleAddJuryListModal = (isOpen) => {
    this.isAddJuryListModalOpen = isOpen;
  };

  toggleEditJuryListModal = (isOpen) => {
    this.isEditJuryListModalOpen = isOpen;
  };

  toggleExportJuryListModal = (isOpen) => {
    this.isExportJuryModalOpen = isOpen;
  };

  toggleProgressJuryListModal = (isOpen) => {
    this.isProgressJuryListModalOpen = isOpen;
  };

  toggleJuryIntersectModal = (isOpen) => {
    this.isJuryIntersectModalOpen = isOpen;
  };

  toggleManageJuryPersonModal = (isOpen) => {
    this.isManageJuryPersonOpen = isOpen;
  };

  toggleEditExclusionJuryPersonModal = (isOpen) => {
    this.isEditExclusionJuryPersonOpen = isOpen;
  };

  toggleInvitePeopleModal = (isOpen) => {
    this.isInvitePeopleModalOpen = isOpen;
  };

  toggleAssignParameterModal = (isOpen) => {
    this.isAssignParameterModalOpen = isOpen;
  };

  toggleAddParameterModalOpen = (isOpen) =>
    (this.isAddParameterModalOpen = isOpen);

  toggleContentModal = (isOpen) => (this.isContentModalOpen = isOpen);

  toggleFileUploadModal = (isOpen) => {
    this.isFileUploadModalOpen = isOpen;
  };

  toggleIsImportResultsModal = (isOpen) => {
    this.isImportResultsOpen = isOpen;
  };

  toggleFilterModalOpen = (isOpen) => {
    this.isFilterModalOpen = isOpen;
  };

  toggleModalExcludesOpen = (isOpen) => {
    this.isExcludesModalIsOpen = isOpen;
  };

  submit = (submit) => {
    this.isSubmit = submit;
  };

  setModal = ({ message = "", type = "warning" }) => {
    this.message = message;
    this.type = type;
  };

  wrapCallback = (e, callback, action) => {
    e.preventDefault();
    callback();
    action && action();
  };
}

decorate(ModalStore, {
  isOpen: observable,
  isSubmit: observable,
  message: observable,
  isErrModalOpen: observable,
  isHelperModalOpen: observable,
  isInvitePeopleModalOpen: observable,
  isAssignParameterModalOpen: observable,
  isAddParameterModalOpen: observable,
  isContentModalOpen: observable,
  isFileUploadModalOpen: observable,
  isImportResultsOpen: observable,
  isAddJuryListModalOpen: observable,
  isEditJuryListModalOpen: observable,
  isProgressJuryListModalOpen: observable,
  isJuryIntersectModalOpen: observable,
  isExportJuryModalOpen: observable,
  isManageJuryPersonOpen: observable,
  isEditExclusionJuryPersonOpen: observable,
  isFilterModalOpen: observable,
  isExcludesModalIsOpen: observable,

  toggleFilterModalOpen: action,
  toggleErrModal: action,
  toggleHelperModal: action,
  toggleInvitePeopleModal: action,
  resetModal: action,
  toggle: action,
  submit: action,
  setModal: action,
  toggleFileUploadModal: action,
  toggleAssignParameterModal: action,
  toggleAddParameterModalOpen: action,
  toggleContentModal: action,
  toggleAddJuryListModal: action,
  toggleEditJuryListModal: action,
  toggleProgressJuryListModal: action,
  toggleJuryIntersectModal: action,
  toggleExportJuryListModal: action,
  toggleManageJuryPersonModal: action,
  toggleEditExclusionJuryPersonModal: action,
  toggleIsImportResultsModal: action,
  toggleModalExcludesOpen: action,
});

export default new ModalStore();
