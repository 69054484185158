import JuryPerson from "pages/Jury/JuryPerson";
import Profile from "pages/Profile";
import Jury from "pages/Jury/JuryCommon";

import NotFound from "components/NotFound";

const mkuRoutes = [
  {
    path: ["/jury/common", "/", "/jury/common/:juryListId?"],
    exact: true,
    component: Jury,
  },

  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: [
      //   "/jury/:juryListId/person/:personId",
      "/jury/:juryListId/person/history/:personId",
      "/jury/:juryListId/person/information/:personId",
    ],
    exact: true,
    component: JuryPerson,
  },

  {
    path: ["/500", "/404"],
    component: NotFound,
  },
];

export default mkuRoutes;
