import { observer } from "mobx-react";
import React, { useRef } from "react";
import Expand from "components/Expand";
import FileInput from "common/components/Inputs/FileInput";
import excludeStore from "../../../../stores/components/excludeStore";

const FileLoader = () => {
  const inputFile = useRef(null);

  const handleStreetOpened = () => {
    excludeStore.loaderFileStore.selectLoaderOpened(true);
  };

  const handleStreetClosed = () => {
    excludeStore.loaderFileStore.selectLoaderOpened(false);
  };

  const prepareToXlsImport = () => {
    const file = inputFile.current.files[0];
    excludeStore.loaderFileStore.setFile(file);
  };

  const handleRemoveFromFilestList = (item) => {
    excludeStore.loaderFileStore.removeFromList(item);
  };

  const fileInputs = [
    {
      file: excludeStore.loaderFileStore.file,
    },
  ];

  return (
    <>
      <Expand
        onClick={
          !excludeStore.loaderFileStore.loaderOpenAccordeon
            ? handleStreetOpened
            : handleStreetClosed
        }
        opened={excludeStore.loaderFileStore.loaderOpenAccordeon}
        selectedArray={excludeStore.loaderFileStore.fileNames}
        handleRemoveFromList={handleRemoveFromFilestList}
        withCloserList={true}
        noBorder
      >
        <ul label={"Импортировать из файла"}>
          {fileInputs.map((item, idx) => {
            return (
              <FileInput
                currentUploadingFilename={"Загрузить файл"}
                ref={inputFile}
                file={item.file}
                accept=".xlsx, .txt"
                onChange={(e) => prepareToXlsImport(e)}
                disabled={excludeStore.loaderFileStore.fileNames.length === 1}
                key={idx}
              />
            );
          })}
        </ul>
      </Expand>
      {excludeStore.data &&
        (excludeStore.data?.success > 0 || excludeStore.data?.error > 0) && (
          <div className="result-import">
            <span className="result-import__success">
              Успешно импортировано - {excludeStore.data?.success}
            </span>
            <span className="result-import__error">
              Не удалось импортировать - {excludeStore.data?.error}.{" "}
              {excludeStore.data?.errorLines.length !== 0 && (
                <span>
                  Номер строки {excludeStore.data?.errorLines.toString()}
                </span>
              )}
            </span>
          </div>
        )}
    </>
  );
};

export default observer(FileLoader);
