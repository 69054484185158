import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import Chip from "components/Chip";

const ParametersList = inject(
  "SearchStore",
  "ItemsStore",
  "PaginationStore"
)(
  observer(({ SearchStore, ItemsStore, PaginationStore, searchType }) => {
    const {
      delDisplayedParameter,
      isSubmitted,
      buildingsDisplayedParams,
      peopleDisplayedParams,
      globalConditionAND,
    } = SearchStore;
    const { fetchWithParameters } = ItemsStore;
    const { startIndex, perPage, resetPagination } = PaginationStore;

    const BuildingParams = () =>
      buildingsDisplayedParams.length !== 0 && (
        <>
          {buildingsDisplayedParams.map((array, outerIndex, outerArray) => {
            return (
              <Fragment key={outerIndex}>
                {array.map((item, index, thisArray) => (
                  <li key={index} className="parameters__item">
                    {!item.included && <span className="not">НЕ</span>}
                    <Chip
                      handleClick={() => {
                        delDisplayedParameter("value", item.value);
                        resetPagination();
                        isSubmitted &&
                          fetchWithParameters(searchType, startIndex, perPage);
                      }}
                    >
                      {item.title + (item.value ? `: ${item.value}` : "")}
                    </Chip>
                    {thisArray.length - 1 !== index ? (
                      <span>ИЛИ</span>
                    ) : outerArray.length - 1 !== outerIndex ? (
                      <span>И</span>
                    ) : null}
                  </li>
                ))}
              </Fragment>
            );
          })}
        </>
      );

    const PeopleParams = () =>
      peopleDisplayedParams.length !== 0 && (
        <>
          {peopleDisplayedParams.map((array, outerIndex, outerArray) => {
            return (
              <Fragment key={outerIndex}>
                {array.map((item, index, thisArray) => (
                  <li key={index} className="parameters__item">
                    {!item.value && <span className="not">НЕ</span>}
                    <Chip
                      handleClick={() => {
                        delDisplayedParameter("title", item.title);
                        resetPagination();
                        isSubmitted &&
                          fetchWithParameters(searchType, startIndex, perPage);
                      }}
                    >
                      {item.title}
                    </Chip>
                    {thisArray.length - 1 !== index ? (
                      <span>ИЛИ</span>
                    ) : outerArray.length - 1 !== outerIndex ? (
                      <span>И</span>
                    ) : null}
                  </li>
                ))}
              </Fragment>
            );
          })}
        </>
      );

    return (
      <ul className="parameters__wrapper">
        {peopleDisplayedParams.length !== 0 && <PeopleParams />}

        {peopleDisplayedParams.length !== 0 &&
          buildingsDisplayedParams.length !== 0 &&
          (globalConditionAND ? (
            <span className="not">И</span>
          ) : (
            <span className="not">ИЛИ</span>
          ))}

        {buildingsDisplayedParams.length !== 0 && <BuildingParams />}
      </ul>
    );
  })
);

export default ParametersList;
