import React from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import Checkbox from "common/components/Inputs/Checkbox";

import "./index.scss";

const ModalManageJuryPerson = inject("JuryPersonStore")(
  observer(({ JuryPersonStore, onCancel }) => {
    const {
      juryPerson,
      excludeJuryPerson,
      handleAdditionalReasonsChangeJuryPerson,
    } = JuryPersonStore;

    const { additionalReasons } = juryPerson;

    const disabledCondition = juryPerson.additionalReasons
      ? juryPerson.additionalReasons.every((item) => !item.selected)
      : true;

    return (
      <div className="modal">
        <h3 className="modal__title">Редактировать исключения</h3>
        <div className="modal-manage-jury-person__body">
          <ul>
            {additionalReasons &&
              additionalReasons.map((item) => (
                <div
                  key={item.id}
                  className="modal-manage-jury-person__list-field"
                >
                  <Checkbox
                    id={item.id}
                    onChange={() => {
                      handleAdditionalReasonsChangeJuryPerson(item.id);
                    }}
                    checked={item.selected || false}
                  >
                    {item.title}
                  </Checkbox>
                </div>
              ))}
          </ul>
        </div>
        <div className="modal__button-group static">
          <SubmitButton
            disabled={disabledCondition}
            onClick={() => excludeJuryPerson(true, true)}
            className="modal__button"
          >
            Редактировать
          </SubmitButton>
          <CancelButton
            className="modal__button"
            onClick={() => onCancel(false)}
          >
            Отмена
          </CancelButton>
        </div>
      </div>
    );
  })
);

export default ModalManageJuryPerson;
