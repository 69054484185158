import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

const BottomDottedButton = ({ onClick, className, children, path }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button__bottom-dotted ${className ? className : ""}`}
  >
    {path ? <Link to={`${path}`}>{children}</Link> : children}
  </button>
);

export default BottomDottedButton;