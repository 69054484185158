export const roles = {
  Admin: "Администратор",
  AdminKOKR: "Администратор КОКР",
  AdminRegion: "Администратор района",
  MainSpecMKU: 'Ведущий специалист МКУ "Центр муниципальных услуг"',
  ChiefSpecMKU: 'Главный специалист МКУ "Центр муниципальных услуг"',
  CoChiefMKU: 'Заместитель начальника отдела МКУ "Центр муниципальных услуг"',
  ChiefDepartmentMKU: 'Начальник отдела МКУ "Центр муниципальных услуг"',
  PassportMKU: 'Паспортист МКУ "Центр муниципальных услуг"',
  SpecialistMKU: 'Специалист 1 категории МКУ "Центр муниципальных услуг"',
};
