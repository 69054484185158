import React, { useState, useRef } from "react";
import { observer, inject } from "mobx-react";
import { NavLink } from "react-router-dom";

import { ReactComponent as Logo } from "assets/svg/navbarlogo.svg";
import { ReactComponent as Burger } from "assets/svg/burger.svg";

import { getSurnameWithInitials } from "../../helpers/commonMethods";

import "./index.scss";

import useOnClickOutside from "hooks/useOnClickOutside";
import { toJS } from "mobx";

const Navbar = inject(
  "TabbedSwitchersStore",
  "ApplicationStore",
  "ItemsStore"
)(
  observer(({ ApplicationStore, ItemsStore }) => {
    const { loggedInUser } = ApplicationStore;
    const { itemsAreLoading } = ItemsStore;
    const [expandedNav, setExpandedNav] = useState(false);
    const ref = useRef();

    const links = [
      {
        title: "Люди",
        to: "/people",
        active: ["/people"],
      },
      { title: "Дома", to: "/homes", active: ["/homes"] },
      {
        title: "Мероприятия",
        to: "/activities",
        active: ["/activities", "/activity"],
      },
      { title: "Аналитика", to: "/analytics", active: ["/analytics"] },
      {
        title: "Справочники",
        to: "/handbooks",
        active: ["/handbooks"],
      },
      {
        title: "Присяжные",
        to: "/jury",
        active: ["/jury"],
      },
      {
        title: getSurnameWithInitials(loggedInUser),
        to: "/profile",
        active: ["/profile"],
      },
    ];

    const onPaths = (paths) => {
      return (_, location) => {
        return paths.some((path) => location.pathname.startsWith(path));
      };
    };

    useOnClickOutside(ref, () => setExpandedNav(false));

    const accessableLinks = links.filter((link) =>
      loggedInUser?.modules?.includes(link.title)
    );

    return (
      <header className="wrapper__navbar" ref={ref}>
        <div className="container__navbar">
          <div className="logo">
            <NavLink
              className="logo__link"
              to="/"
              onClick={() => setExpandedNav(false)}
            >
              <Logo />
              <span className="logo__text">АИС Присяжные</span>
            </NavLink>
          </div>
          <nav>
            <button
              className="navbar__burger"
              onClick={() => setExpandedNav(!expandedNav)}
            >
              <Burger />
            </button>
            <ul
              className={
                expandedNav ? "navbar__list expand" : "navbar__list none"
              }
            >
              {accessableLinks.map((link, index) => (
                <li
                  key={index}
                  className={
                    expandedNav
                      ? "navbar__item padding no-margin"
                      : "navbar__item"
                  }
                >
                  <NavLink
                    to={link.to}
                    onClick={() => setExpandedNav(!expandedNav)}
                    className={`page-link navbar__link ${
                      itemsAreLoading ? "navbar__link--disabled" : ""
                    }`}
                    activeClassName="navbar__active"
                    isActive={onPaths(link.active)}
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    );
  })
);

export default Navbar;
