import React from "react";
import { inject, observer } from "mobx-react";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";

import "../ChekboxWrapper";
import CheckboxWrapper from "../ChekboxWrapper";

const AddressForm = inject(
  "PeopleFormStore",
  "ApplicationStore"
)(
  observer(({ PeopleFormStore }) => {
    const {
      noHouse,
      noFlat,
      region,
      city,
      locality,
      innerDistrict,
      street,
      houseNum,
      housePart,
      houseBuilding,
      flat,
      index,
      onFieldChange,
      handleChange,
    } = PeopleFormStore;

    const cityInputs = [
      {
        key: "city",
        value: city,
        placeholder: "Город",
        disabled: true,
        type: "text",
      },
      {
        key: "locality",
        value: locality,
        placeholder: "Населенный пункт",
        disabled: true,
        type: "text",
      },
      {
        key: "innerDistrict",
        value: innerDistrict,
        placeholder: "Внутригородской район",
        disabled: true,
        type: "text",
      },
      {
        key: "street",
        value: street,
        placeholder: "Улица",
        disabled: true,
        type: "text",
      },
    ];

    const houseInputs = [
      {
        key: "houseNum",
        value: noHouse ? null : houseNum,
        placeholder: "Дом",
        disabled: true,
        type: "text",
      },
      {
        key: "housePart",
        value: housePart,
        placeholder: "Корпус",
        disabled: true,
        type: "text",
      },
      {
        key: "houseBuilding",
        value: houseBuilding,
        placeholder: "Строение",
        disabled: true,
      },
      {
        key: "flat",
        value: noFlat ? null : flat,
        placeholder: "Квартира",
        disabled: true,
        type: "number",
      },
    ];

    return (
      <div className="people-form__address-block margin-top-25">
        <FloatLabel label="Регион" value={region}>
          <TextInput
            type="text"
            name="region"
            value={region}
            autoComplete="off"
            onChange={(e) => {
              onFieldChange("region", e.target.value, e.target.type);
            }}
            className="margin-bottom-25"
            disabled
          />
        </FloatLabel>

        <div className="address-block__city-wrapper">
          {cityInputs.map((item) => (
            <FloatLabel
              key={item.key}
              label={item.placeholder}
              value={item.value}
            >
              <TextInput
                type={item.type}
                name={item.key}
                value={item.value}
                autoComplete="off"
                onChange={(e) => {
                  onFieldChange(item.key, e.target.value, e.target.type);
                }}
                className="address-block__medium-field margin-bottom-25"
                disabled={item.disabled ? item.disabled : false}
              />
            </FloatLabel>
          ))}
        </div>

        <div className="address-block__house-wrapper margin-bottom-25">
          {houseInputs.map((item) => (
            <FloatLabel
              key={item.key}
              label={item.placeholder}
              value={item.value || ""}
            >
              <TextInput
                type={item.type}
                name={item.name}
                value={item.value || ""}
                autoComplete="off"
                onChange={(e) => {
                  onFieldChange(item.key, e.target.value, e.target.type);
                }}
                className="address-block__small-field"
                disabled={item.disabled ? item.disabled : false}
              />
            </FloatLabel>
          ))}
        </div>

        <CheckboxWrapper
          noHouse={noHouse}
          noFlat={noFlat}
          handleChange={handleChange}
        />

        <FloatLabel label="Индекс" value={index || ""}>
          <TextInput
            type="text"
            name={index}
            value={index || ""}
            autoComplete="off"
            onChange={(e) => {
              onFieldChange("index", e.target.value, e.target.type);
            }}
            className="address-block__small-field"
            disabled
          />
        </FloatLabel>
      </div>
    );
  })
);

export default AddressForm;
