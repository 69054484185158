import SignIn from "pages/Auth/SignIn";

const authRoutes = [
  {
    path: "/login",
    component: SignIn,
  },
];

export default authRoutes;
