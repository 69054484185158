import { observable, decorate, action, runInAction } from "mobx";
import app from "utils/axiosConfig";
import ApplicationStore from "../appStore";

import { downloadFile } from "helpers/filesExport";

import ModalStore from "stores/components/modalStore";

class JuryPersonStore {
  constructor() {
    this.reset();
  }

  reset = () => {
    runInAction(() => {
      this.juryPerson = {
        personId: "",
        name: "",
        surname: "",
        patronymic: "",
        birthDate: "",
        region: "",
        snils: "",
        address: "",
        reasons: [],
        history: [],
        edittedReasons: [],
        additionalReasons: [],
      };
      this.commonJuryPersonReasons = [];
    });
  };

  fetchJuryPerson = async (juryListId, personId) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/JuryLists/${juryListId}/people/${personId}`)
      .then((res) => {
        this.juryPerson = res.data.person;
        this.juryPerson.juryListId = res.data.juryListId;
        this.commonJuryPersonReasons = res.data.reasons;

        this.juryPerson.edittedReasons = this.getEdittedReasonsPersonJury();
        this.juryPerson.additionalReasons =
          this.getAdditionalReasonsPersonJury();
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  getEdittedReasonsPersonJury = () => {
    return this.commonJuryPersonReasons
      .filter((item) => item.forExclusion === this.juryPerson.isActive)
      .map((item) => {
        return {
          ...item,
          selected: Boolean(
            this.juryPerson.reasons
              ? this.juryPerson.reasons.find(
                  (reasonInPerson) => reasonInPerson.id === item.id
                )
              : false
          ),
        };
      });
  };

  getAdditionalReasonsPersonJury = () => {
    return this.commonJuryPersonReasons
      .filter((item) => Boolean(item.forExclusion))
      .map((item) => {
        return {
          ...item,
          selected: Boolean(
            this.juryPerson.reasons
              ? this.juryPerson.reasons.find(
                  (reasonInPerson) => reasonInPerson.id === item.id
                )
              : false
          ),
        };
      });
  };

  fetchJuryPersonHistory = async (juryListId, personId) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/JuryLists/${juryListId}/people/${personId}/history`)
      .then((res) => {
        this.juryPerson = { ...this.juryPerson, history: res.data };
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  excludeJuryPerson = async (isEdit = false, isExclude) => {
    let reasonsToSend = [];

    let currentReasons = isEdit
      ? this.juryPerson.additionalReasons
      : this.juryPerson.edittedReasons;

    currentReasons.forEach((item) => {
      if (item.selected) {
        reasonsToSend.push(item.id);
      }
    });

    const dataToSend = {
      Exclude: isExclude,
      JuryListId: this.juryPerson.juryListId,
      PersonId: this.juryPerson.personId,
      Reasons: reasonsToSend,
    };

    ApplicationStore.setLoading(true);

    await app
      .post(`/JuryLists/exclude`, dataToSend)
      .then(() => {
        this.fetchJuryPerson(
          this.juryPerson.juryListId,
          this.juryPerson.personId
        );
        ModalStore.toggleManageJuryPersonModal(false);
        ModalStore.toggleEditExclusionJuryPersonModal(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  formJuryExcerpt = (personId, juryListId) => {
    const options = { responseType: "blob" };
    app
      .get(`/JuryLists/${juryListId}/people/${personId}/export`, options)
      .then((res) => {
        downloadFile(res, "docx", "excerpt.docx");
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  onReject = async (personId, juryListId) => {
    const options = { responseType: "blob" };
    await app
      .get(
        `/JuryLists/${juryListId}/people/${personId}/printedForm?type=Exclusion`,
        options
      )
      .then((res) => {
        downloadFile(res, "docx", "Исключение.docx");
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  onCorrectInformation = async (personId, juryListId) => {
    const options = { responseType: "blob" };
    await app
      .get(
        `/JuryLists/${juryListId}/people/${personId}/printedForm?type=CorrectingInformation`,
        options
      )
      .then((res) => {
        downloadFile(res, "docx", "Исправление неточностей.docx");
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  onFreeForm = async (personId, juryListId) => {
    const options = { responseType: "blob" };
    await app
      .get(
        `/JuryLists/${juryListId}/people/${personId}/printedForm?type=FreeForm`,
        options
      )
      .then((res) => {
        downloadFile(res, "docx", "Свободная форма.docx");
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  handleManageJuryPerson = (id) => {
    let arr = this.juryPerson.edittedReasons;
    let newReasons = arr.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }

      return item;
    });
    this.juryPerson = { ...this.juryPerson, edittedReasons: newReasons };
  };

  handleAdditionalReasonsChangeJuryPerson = (id) => {
    let arr = this.juryPerson.additionalReasons;
    let newReasons = arr.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }

      return item;
    });
    this.juryPerson = { ...this.juryPerson, additionalReasons: newReasons };
  };

  resetServerErrors = () => {
    this.serverErr = "";
  };
}

decorate(JuryPersonStore, {
  juryPerson: observable,
  commonJuryPersonReasons: observable,

  reset: action,
  resetServerErrors: action,
  handleManageJuryPerson: action,
  handleAdditionalReasonsChangeJuryPerson: action,
  fetchJuryPerson: action,
  fetchJuryPersonHistory: action,
  formJuryExcerpt: action,
  onFreeForm: action,
  onCorrectInformation: action,
  onReject: action,
});

export default new JuryPersonStore();
