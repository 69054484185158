import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./index.scss";

import AddButton from "common/components/Buttons/AddButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

const HomeParams = inject(
	"ItemsStore",
	"PaginationStore",
	"ApplicationStore"
)(
	observer(({ ItemsStore, PaginationStore }) => {
		const { items, reset, fetchItems, count, itemsAreLoading } = ItemsStore;
		const {
			currentPage,
			perPage,
			changePerPage,
			paginate,
			resetPagination,
			startIndex,
			resetAll,
		} = PaginationStore;

		const [isCleared, setCleared] = useState(false);

		useEffect(() => {
			isCleared && fetchItems("/buildingparameters/bypages", "get", startIndex, perPage);
			return () => {
				reset();
			};
		}, [fetchItems, startIndex, perPage, reset, isCleared]);

		useEffect(() => {
			resetPagination();
		}, [resetPagination, perPage]);

		useEffect(() => {
			resetAll();
			setCleared(true);
		}, [resetAll, setCleared]);

		const classnames = {
			header: "params-header",
			itemWrapper: "params-item",
		};

		const header = (
			<>
				<div>Параметр</div>
				<div>Значения</div>
			</>
		);

		const mappedItems = items.map((item) => (
			<>
				<span className="params-item__title">
					<Link className="page-link" to={`/handbooks/edithomeparam/${item.title}`}>
						{item.title}
					</Link>
				</span>
				{item.possibleValues?.length ? (
					<p className="params-value__title table-value">{item.possibleValues.join(", ")}</p>
				) : (
					<p className="table-value">—</p>
				)}
			</>
		));

		const list =
			items.length !== 0 ? (
				<div className="params-wrapper">
					{<Table header={header} items={mappedItems} classnames={classnames} />}
					<Pagination
						perPage={perPage}
						total={count}
						currentPage={currentPage + 1}
						handlePaginate={(page) => paginate(page - 1)}
						isShowPerPage
						handleChangePerPage={(count) => changePerPage(count)}
					/>
				</div>
			) : (
				<Empty isSearch={false} />
			);

		return (
			<>
				<Link to="/handbooks/addhomeparam">
					<AddButton className="handbooks__button">Добавить параметр</AddButton>
				</Link>
				{itemsAreLoading ? <LoaderSpinner /> : isCleared && list}
			</>
		);
	})
);

export default HomeParams;
