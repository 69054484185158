import React from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import Progressbar from "common/components/Inputs/Progressbar";

const ModalFileUpload = inject(
  "ItemsStore",
  "ApplicationStore",
  "ModalStore"
)(
  observer(
    ({
      header,
      children,
      onUpload,
      onCancel,
      ItemsStore,
      ApplicationStore,
      ModalStore,
    }) => {
      const {
        selectedSource,
        file,
        peopleImportFileIsLoading,
        peopleImportFileProgress,
      } = ItemsStore;
      const { isLoading } = ApplicationStore;

      const handleShowResultClick = () => {
        ModalStore.toggleFileUploadModal(false);
        ModalStore.toggleIsImportResultsModal(true);
      };

      return (
        <div className="modal">
          <p className="modal__title">{header}</p>
          <div className="modal__content full no-mt">{children}</div>
          <div className={`modal__button-group static`}>
            {!ItemsStore.isFileUploaded ? (
              !peopleImportFileIsLoading ? (
                <SubmitButton
                  className="modal__button static-width"
                  onClick={onUpload}
                  disabled={!selectedSource || !file || isLoading}
                >
                  Импортировать данные
                </SubmitButton>
              ) : (
                <div className="modal__button static-width">
                  <Progressbar completed={peopleImportFileProgress} />
                </div>
              )
            ) : null}
            {!ItemsStore.isFileUploaded && (
              <CancelButton
                className="modal__button"
                onClick={() => {
                  onCancel(false);
                }}
              >
                Отмена
              </CancelButton>
            )}
            {ItemsStore.isFileUploaded && (
              <div
                onClick={() => {
                  handleShowResultClick();
                }}
              >
                <SubmitButton className="show-rez">
                  Просмотреть результаты {ItemsStore.fileInfo.name}
                </SubmitButton>
              </div>
            )}
          </div>
        </div>
      );
    }
  )
);

export default ModalFileUpload;
