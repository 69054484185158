import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import Coming from "./Coming";
import Archive from "./Archive";

import Tabs from "components/Tabs";

import AddButton from "common/components/Buttons/AddButton";

import useDocumentTitle from "hooks/useDocumentTitle";

const Activities = ({ match }) => {
  const tabs = [
    {
      title: "Предстоящие",
      path: "/activities/coming",
      content: <Coming />,
    },
    {
      title: "Архив",
      path: "/activities/archive",
      content: <Archive />,
    },
  ];

  useDocumentTitle("Мероприятия");

  return (
    <div className="wrapper__content">
      <section className="section">
        <div className="container">
          <h1 className="page-title">Мероприятия</h1>
          <Link to="/activities/add">
            <AddButton className="activities__button">
              Добавить мероприятие
            </AddButton>
          </Link>
          <Tabs items={tabs} withRouter match={match} />
        </div>
      </section>
    </div>
  );
};

export default Activities;
