import React from "react";

import "./index.scss";

import { ReactComponent as Plus } from "assets/svg/plus.svg";

const AddButton = (props) => {
  return (
    <button
      type="button"
      className={`button__add ${props.className ? props.className : ""}`}
      onClick={props.onClick}
      disabled={props.disabled ? props.disabled : false}
    >
      <Plus />
      {props.children}
    </button>
  );
};

export default AddButton;
