import React from "react";

import "./index.scss";

import Tabs from "components/Tabs";

import PeopleTab from "./PeopleTab";
import HomeTab from "./HomeTab";

const tabs = [
  {
    title: "Люди",
    content: <PeopleTab />,
  },
  {
    title: "Дома",
    content: <HomeTab />,
  },
];

const ParametersModalContent = ({ searchType, isInForm = false }) => {
  const defaultTabs = <Tabs items={tabs} withRouter={false} />;

  switch (searchType) {
    case "all":
      return defaultTabs;
    case "people":
      return <PeopleTab isInForm={isInForm} />;
    case "homes":
      return <HomeTab isInForm={isInForm} />;
    case "analytics":
      return <PeopleTab isInForm={isInForm} />;
    default:
      return defaultTabs;
  }
};

export default ParametersModalContent;
