import ApplicationStore from "./appStore";

import PaginationStore from "./common/paginationStore";

import AutocompleteStore from "./components/autocompleteStore";
import PeopleFormStore from "./components/peopleFormStore";
import SearchStore from "./components/searchStore";
import ActivitiesStore from "./components/activitiesStore";
import HomeParamsStore from "./components/homeParamsStore";
import BuildingsStore from "./components/buildingsStore";
import PeopleParamsStore from "./components/peopleParamsStore";
import CompaniesStore from "./components/companiesStore";
import UsersStore from "./components/usersStore";
import AreasStore from "./components/areasStore";
import AuthStore from "./components/authStore";
import ModalStore from "./components/modalStore";
import AlertStore from "./components/alertsStore";
import JuryStore from "./components/juryStore";
import JuryPersonStore from "./components/juryPersonStore";
import ItemsStore from "./common/itemsStore";
import TabbedSwitchersStore from "./common/tabbedSwitchersStore";

const stores = {
  ApplicationStore,
  AutocompleteStore,
  PeopleFormStore,
  HomeParamsStore,
  BuildingsStore,
  PeopleParamsStore,
  ActivitiesStore,
  AuthStore,
  PaginationStore,
  ItemsStore,
  TabbedSwitchersStore,
  CompaniesStore,
  SearchStore,
  UsersStore,
  AreasStore,
  ModalStore,
  JuryStore,
  JuryPersonStore,
  AlertStore,
};

export default stores;
