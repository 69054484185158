import React, { useEffect } from "react";
import { observer } from "mobx-react";

import "./index.scss";

import { ReactComponent as CloseButton } from "assets/svg/close.svg";

import Empty from "components/Empty";
import { SkeletonLine } from "components/Skeleton";
import ActivityPreview from "components/ActivityPreview";

import ApplicationStore from "stores/appStore";
import ActivitiesStore from "stores/components/activitiesStore";

import { dateStringToDDMonthYYYY } from "helpers/formattedDate";

const ModalInviteToActivity = observer(({ peopleCount, onInvite, onCloseInviteModal }) => {
	const { fetchComingActivitiesForModal, comingActivitiesForModal } = ActivitiesStore;
	const { isLoading } = ApplicationStore;

	useEffect(() => {
		fetchComingActivitiesForModal();
	}, [fetchComingActivitiesForModal]);

	const comingActivitiesCount = comingActivitiesForModal.length;

	const comingActivitiesElements = comingActivitiesForModal.map(({ id, title, location, date, dateOfEnd }) => {
		return (
			<ActivityPreview
				key={id}
				size="mini"
				title={title}
				location={location}
				date={dateStringToDDMonthYYYY(date)}
				dateOfEnd={dateStringToDDMonthYYYY(dateOfEnd)}
				handler={() => {
					onInvite(id);
					onCloseInviteModal(false);
				}}
			/>
		);
	});

	const activitiesList = <ul className="modal__activities-list">{comingActivitiesElements}</ul>;

	const displayingContent = isLoading ? (
		<div className="activities__loading">
			<SkeletonLine width={490} height={108} translucent />
			<SkeletonLine width={490} height={108} translucent />
			<SkeletonLine width={490} height={108} translucent />
			<SkeletonLine width={490} height={108} translucent />
			<SkeletonLine width={490} height={108} translucent />
		</div>
	) : comingActivitiesCount > 0 ? (
		activitiesList
	) : (
		<Empty isSearch={false} />
	);

	return (
		<div className="modal invite-people">
			<div className="modal__header">
				<p className="modal__title invite-people">Пригласить на мероприятие</p>
				<button onClick={() => onCloseInviteModal(false)} className="modal__close">
					<CloseButton />
				</button>
			</div>
			<p className="modal__pepople-count invite-people">{`${peopleCount}`}</p>
			{displayingContent}
		</div>
	);
});

export default ModalInviteToActivity;
