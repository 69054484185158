import React from "react";

import routing from "stores/routing";

import { ReactComponent as Alert } from "assets/svg/alert.svg";

import SubmitButton from "common/components/Buttons/SubmitButton";

const ModalContent = ({ header, children, toggle, wrapCallback, callback }) => {
  return (
    <div className="modal">
      <p className="modal__title static">{header}</p>
      <Alert className="modal__icon" />
      <div className="modal__content">{children}</div>
      <div className="modal__button-group static">
        <SubmitButton
          className={"modal__button"}
          onClick={(e) =>
            wrapCallback(e, callback, () => {
              toggle(false);
              routing.go(0);
            })
          }
        >
          ОК
        </SubmitButton>
      </div>
    </div>
  );
};

export default ModalContent;
