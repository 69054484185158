export const parseValue = (value, type) => {
  if (type === "number") {
    return parseInt(value);
  } else {
    return value;
  }
};

export const extractItemsIds = (selectedItems) => {
  return selectedItems.map((item) => item.id);
};

export const stripCharsInIE = (string) => {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  if (isIE11) {
    const encoder = new TextEncoder();
    const decoder = new TextDecoder();
    const charCodeToStrip = [226, 128, 142];

    const encodedString = encoder.encode(string);
    const toDecode = encodedString.filter(
      (charCode) => !charCodeToStrip.includes(charCode)
    );

    return decoder.decode(toDecode);
  } else {
    return string;
  }
};

export const getSurnameWithInitials = (user) => {
  if (!(user && user.name && user.surName)) return "Профиль";
  if (!user.patronymic) {
    let surName =
      user.surName.length > 10
        ? user?.surName[0]?.toUpperCase()
        : user?.surName[0]?.toUpperCase() + user?.surName?.slice(1);

    return `${surName}. ${user?.name[0].toUpperCase()}.`;
  }
  let surName =
    user.surName.length > 10
      ? user?.surName[0]?.toUpperCase()
      : user?.surName[0]?.toUpperCase() + user?.surName?.slice(1);
  return `${surName}. ${user?.name[0].toUpperCase()}. ${user?.patronymic[0].toUpperCase()}.`;
};
