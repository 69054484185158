import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import AddButton from "common/components/Buttons/AddButton";

import SearchHeader from "components/Header/Search";
import DefaultHeader from "components/Header/Default";
import HeaderWithCount from "components/HeaderWithCount";

import HomesList from "pages/Homes/HomesList";

import useDocumentTitle from "hooks/useDocumentTitle";

const Homes = inject(
  "SearchStore",
  "ItemsStore",
  "PaginationStore"
)(
  observer(({ SearchStore, ItemsStore, PaginationStore }) => {
    const { isSubmitted, setSubmitted, resetFiltration } = SearchStore;
    const {
      isSearchSubmitted,
      itemsAreLoading,
      resetSelected,
      fetchWithParameters,
      setSearchActivation,
      setLastSearchStringRequest,
    } = ItemsStore;
    const { startIndex, perPage, resetAll } = PaginationStore;

    useDocumentTitle(
      () => (isSubmitted ? `Результаты поиска` : "Дома"),
      [isSubmitted]
    );

    useEffect(() => {
      return () => {
        setSearchActivation(false);
        setLastSearchStringRequest();
      };
    }, [setLastSearchStringRequest, setSearchActivation]);

    return (
      <>
        <SearchHeader searchType="homes" />
        <DefaultHeader
          title="Расширенный поиск"
          buttonText="Указать параметры для поиска"
          onSearch={() => {
            resetAll();
            resetSelected();
            setSubmitted(true);
            startIndex === 0 &&
              fetchWithParameters("homes", startIndex, perPage);
          }}
          onClearSearch={() => {
            resetFiltration();
            resetSelected();
            resetAll();
            isSubmitted && fetchWithParameters("homes", startIndex, perPage);
          }}
          searchType="homes"
        />
        <div className="wrapper__content">
          <section className="section">
            <div className="container">
              <HeaderWithCount
                loading={itemsAreLoading}
                title={
                  !(isSubmitted || isSearchSubmitted)
                    ? "Дома"
                    : "Результаты поиска"
                }
              />
              {!(isSubmitted && isSearchSubmitted) && (
                <Link to="/homes/addbuilding">
                  <AddButton>Добавить здание</AddButton>
                </Link>
              )}
              <HomesList isSearch={isSubmitted || isSearchSubmitted} />
            </div>
          </section>
        </div>
      </>
    );
  })
);

export default Homes;
