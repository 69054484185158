import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";

import "./index.scss";

import { ReactComponent as Close } from "assets/svg/close.svg";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import DeleteButton from "common/components/Buttons/DeleteButton";
import AddButton from "common/components/Buttons/AddButton";
import Alert from "common/components/Alerts/Success";

import useDocumentTitle from "hooks/useDocumentTitle";

const AddEditHomeParam = inject(
  "ModalStore",
  "HomeParamsStore",
  "ApplicationStore",
  "AlertStore"
)(
  observer(
    ({ HomeParamsStore, AlertStore, ApplicationStore, ModalStore, match }) => {
      const isEdit = match.url.includes("/handbooks/edithomeparam");
      const title = match.params.title;

      const { isUploading, isLoading, setGlobalFieldValue } = ApplicationStore;
      const { toggle, isOpen, isSubmit, resetModal } = ModalStore;

      const {
        fetchParameter,
        changeParameter,
        homeParam,
        newParam,
        titleCache,
        saveParam,
        addValue,
        changeValue,
        deleteValue,
        deleteParam,
        errors,
        reset,
        resetServerErrors,
      } = HomeParamsStore;

      const {
        isHandbookEditBuildingParamSuccessAlertOpen,
        toggleHandbookEditBuildingParamSuccessAlertOpen,
      } = AlertStore;

      const [isAlertShow, setAlertShow] = useState(false);

      useEffect(() => {
        if (isHandbookEditBuildingParamSuccessAlertOpen) {
          setAlertShow(true);
        }
      }, [isHandbookEditBuildingParamSuccessAlertOpen]);

      useEffect(() => {
        if (isEdit) {
          setGlobalFieldValue("mode", "EDIT_HOME_PARAM");
          fetchParameter(title);
        } else {
          setGlobalFieldValue("mode", "ADD_HOME_PARAM");
        }
        return () => {
          setGlobalFieldValue("mode", "");
          setGlobalFieldValue("isSubmitted", false);
          toggleHandbookEditBuildingParamSuccessAlertOpen(false);
          reset();
        };
      }, [fetchParameter, isEdit, title, reset, setGlobalFieldValue]);

      useEffect(() => {
        if (isSubmit) {
          deleteParam();
        }
      }, [isSubmit, deleteParam]);

      useEffect(() => () => resetModal(), [resetModal]);

      useDocumentTitle(
        () => (isEdit ? "Редактировать параметр" : "Добавить параметр"),
        [isEdit]
      );

      const breadcrumbs = [
        { path: "/handbooks/homeparams", label: "Параметры домов" },
        {
          path: "/handbooks/addhomeparam",
          label: isLoading
            ? "Загрузка..."
            : isEdit
            ? `${titleCache}`
            : "Добавить параметр",
        },
      ];

      const handleSubmit = (e) => {
        e.preventDefault();
        saveParam();
      };

      const modalDanger = <Modal type="danger" />;
      const pageTitleInEditorMode = titleCache || "Загрузка...";

      return (
        <div className="wrapper__content">
          {isEdit && isOpen && modalDanger}
          <Breadcrumbs routes={breadcrumbs} />
          <section className="section">
            <div className="container">
              <h1 className="page-title">
                {isEdit ? `${pageTitleInEditorMode}` : "Добавить параметр"}
              </h1>
              {isEdit && (
                <DeleteButton
                  onClick={() => {
                    toggle(true);
                  }}
                  disabled={isLoading || isUploading}
                  className={"page-link"}
                >
                  Удалить параметр и все его значения
                </DeleteButton>
              )}

              <div className="handbooks-add-edit-building-param__success-alert">
                {isAlertShow && (
                  <Alert
                    message={"Данные успешно обновлены"}
                    toggle={(open) => {
                      toggleHandbookEditBuildingParamSuccessAlertOpen(open);
                      setAlertShow(open);
                    }}
                  />
                )}
              </div>

              <form
                className="form"
                onSubmit={(e) => handleSubmit(e)}
                noValidate
              >
                <FloatLabel
                  label="Параметр"
                  value={isEdit && homeParam ? homeParam.title : newParam.title}
                >
                  <TextInput
                    type="text"
                    name="title"
                    value={
                      isEdit && homeParam ? homeParam.title : newParam.title
                    }
                    placeholder=""
                    onChange={(e) => {
                      resetServerErrors();
                      isEdit
                        ? changeParameter(homeParam, "title", e.target.value)
                        : changeParameter(newParam, "title", e.target.value);
                    }}
                    className={`form__field ${errors.title ? "warning" : ""}`}
                    autoFocus
                  />
                </FloatLabel>
                {errors.title && (
                  <p className="warning-text margin-bottom-12">
                    {errors.title}
                  </p>
                )}
                <label className="label margin-bottom-32 margin-top-20">
                  Значения
                </label>
                {isEdit
                  ? homeParam?.possibleValues?.map((value, index) => (
                      <div className="home-value" key={index}>
                        <FloatLabel label="Название значения" value={value}>
                          <TextInput
                            type={"text"}
                            name={index}
                            placeholder=""
                            value={value}
                            onChange={(e) =>
                              changeValue(true, index, e.target.value)
                            }
                            className={`form__field`}
                          />
                        </FloatLabel>
                        <button
                          onClick={() => deleteValue(true, index)}
                          className="remove-home-value-button"
                          type="button"
                        >
                          <Close className="close-icon" />
                        </button>
                      </div>
                    ))
                  : newParam.possibleValues.map((value, index) => (
                      <div className="home-value" key={index}>
                        <FloatLabel label="Название значения" value={value}>
                          <TextInput
                            type="text"
                            name={index}
                            placeholder=""
                            value={value}
                            onChange={(e) =>
                              changeValue(false, index, e.target.value)
                            }
                            className={`form__field`}
                          />
                        </FloatLabel>

                        <button
                          onClick={() => deleteValue(false, index)}
                          className="remove-home-value-button"
                          type="button"
                        >
                          <Close className="close-icon" />
                        </button>
                      </div>
                    ))}
                <div className="form__add-button">
                  <AddButton
                    onClick={() => addValue(isEdit)}
                    disabled={isLoading || isUploading}
                  >
                    Добавить значение
                  </AddButton>
                </div>
                <div className="form__buttons margin-top-64">
                  <SubmitButton
                    className="form__button"
                    disabled={isLoading || isUploading}
                  >
                    {isLoading || isUploading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                  <CancelButton className={"form__button"}>Отмена</CancelButton>
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  )
);

export default AddEditHomeParam;
