import React from "react";
import { Link } from "react-router-dom";

const SimpleMenu = ({ data }) => {
  const className = "activity__item-link page-link";

  const getLinkOrButton = (handler, disabled, caption, href, key) => {
    return handler !== undefined ? (
      <button
        onClick={handler}
        key={key}
        className={className}
        disabled={disabled}
      >
        {caption}
      </button>
    ) : (
      <Link key={key} className={className} to={href}>
        {caption}
      </Link>
    );
  };

  const items = data.map((el, index) => {
    return el === null
      ? null
      : getLinkOrButton(el.handler, el.disabled, el.caption, el.href, index);
  });

  return <div className="activity__item-menu">{items}</div>;
};

export default SimpleMenu;
