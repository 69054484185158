import React from "react";

import "./index.scss";

import ExpandSection from "./ExpandSection";

const Expand = ({
  children,
  opened,
  onClick,
  selected,
  noBorder,
  selectedArray,
  handleRemoveFromList,
  withCloserList,
}) => (
  <div className="expand">
    <ExpandSection
      isOpen={opened}
      className={noBorder ? " noBorder" : ""}
      label={children.props.label}
      onClick={onClick}
      selected={selected}
      selectedArray={selectedArray}
      handleRemoveFromList={handleRemoveFromList}
      withCloserList={withCloserList}
    >
      {children.props.children}
    </ExpandSection>
  </div>
);

export default Expand;
