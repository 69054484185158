import { stripCharsInIE } from "helpers/commonMethods";

export const EMPTY_DATE = "0001-01-01T00:00:00";

const formattedDate = (date, isConvert, withoutTime) => {
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  let dateString;

  if (withoutTime) {
    return new Date(date);
  }

  if (isConvert && typeof date === "string") {
    let parts = date.split("-");
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  if (date instanceof Date) {
    dateString = date.toLocaleDateString("ru-RU", options);
  } else {
    dateString = new Date(date).toLocaleDateString("ru-RU", options);
  }

  return stripCharsInIE(dateString);
};

export default formattedDate;

function extractDMY(dateString) {
  const date = dateString.split("T")[0];
  return date.split("-").reverse().map(Number);
}

function extractTime(dateString) {
  const time = dateString.split("T")[1];
  return time.slice(0, 5).split(":");
}

export function prepareDateStringForConstructor(dateString) {
  const [day, month, year] = extractDMY(dateString);
  return [year, month - 1, day];
}

export function dateStringToDDMonthYYYY(dateString) {
  const [day, month, year] = extractDMY(dateString);
  const [hours, minutes] = extractTime(dateString);
  const monthsNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  return `${day} ${monthsNames[month - 1]} ${year} года, ${hours}:${minutes}`;
}

export function parseDate(dateString) {
  const date = dateString.split("-").reverse();
  return date[0] + "." + date[1] + "." + date[2];
}

export function toISOLocalDateString(date) {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const iso = dateLocal.toISOString();
  const isoLocal = iso.slice(0, 19) + ".000Z";
  return isoLocal;
}

export function isTimeValid(time) {
  if (!/^\d\d:\d\d$/.test(time)) {
    return false;
  }
  const [hours, minutes] = time.split(":").map(Number);
  if (hours > 23 || minutes > 59) {
    return false;
  }
  return true;
}

export function formDateToStringDate(formStr) {
  let d = new Date(formStr);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${day}.${month}.${year}`;
}
