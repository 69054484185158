import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";

import NotFound from "../NotFound";

import { CREDENTIALS_ERR } from "helpers/errors";

export const PrivateRoute = inject(
  "ApplicationStore",
  "AuthStore"
)(
  observer(
    ({
      ApplicationStore,
      AuthStore,
      adminComponent: AdminComponent,
      adminKokrComponent: AdminKokrComponent,
      mkuComponent: MkuComponent,
      roles,
      ...rest
    }) => (
      <Route
        {...rest}
        render={(props) => {
          const {
            loggedInUser,
            isAdmin,
            isAdminKokr,
            isAdminRegion,
            isMainSpecMKU,
            isSpecialistMKU,
            isChiefSpecMKU,
            isCoChiefMKU,
            isChiefDepartmentMKU,
            isPassportMKU,
          } = ApplicationStore;
          const { logout } = AuthStore;

          if (!loggedInUser) {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }

          if (isAdmin) {
            return <AdminComponent {...props} />;
          }

          if (isAdminKokr || isAdminRegion) {
            return <AdminKokrComponent {...props} />;
          }

          if (
            isMainSpecMKU ||
            isSpecialistMKU ||
            isChiefSpecMKU ||
            isCoChiefMKU ||
            isChiefDepartmentMKU ||
            isPassportMKU
          ) {
            return <MkuComponent {...props} />;
          }

          return <NotFound message={CREDENTIALS_ERR} logout={logout} />;
        }}
      />
    )
  )
);
