import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import "./index.scss";

import AddButton from "common/components/Buttons/AddButton";

import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

import { parseDate } from "helpers/formattedDate";

const Companies = inject(
	"CompaniesStore",
	"ApplicationStore"
)(
	observer(({ CompaniesStore, ApplicationStore }) => {
		const { companies, fetchCompanies, reset } = CompaniesStore;
		const { isLoading } = ApplicationStore;

		const [isCleared, setCleared] = useState(false);

		useEffect(() => {
			isCleared && fetchCompanies();
			return () => reset();
		}, [isCleared, fetchCompanies, reset]);

		useEffect(() => {
			setCleared(true);
		}, []);

		const classnames = {
			header: "companies-header",
			itemWrapper: "company-item",
		};

		const header = (
			<>
				<div>Наименование</div>
				<div>Дата</div>
				<div>Количество участков</div>
				<div>Действие</div>
			</>
		);

		const mappedItems = companies.map((item) => (
			<>
				<span className="company-item__title">
					<Link className="page-link" to={"/handbooks/editcompany/" + item.electionCompanyId}>
						{item.caption}
					</Link>
				</span>
				<p className="company-item__date table-value">{parseDate(item.date.slice(0, 10))}</p>
				<p className="company-item__stations">{item.pollingStationCount ? item.pollingStationCount : "—"}</p>
				<span className="company-item__action">
					<Link
						className="page-link"
						to={`/handbooks/stations/${item.electionCompanyId}?caption=${item.caption}`}>
						Перейти к участкам
					</Link>
				</span>
			</>
		));

		const list =
			companies.length !== 0 ? (
				<Table header={header} items={mappedItems} classnames={classnames} />
			) : (
				<Empty isSearch={false} />
			);

		return (
			<>
				<Link to="/handbooks/addcompany">
					<AddButton className="handbooks__button">Добавить кампанию</AddButton>
				</Link>
				{isLoading ? <LoaderSpinner /> : isCleared && list}
			</>
		);
	})
);

export default Companies;
