import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import Arrived from "./Arrived";
import Invited from "./Invited";
import NotArrived from "./NotArrived";
import Description from "./Description";

import Breadcrumbs from "components/Breadcrumbs";
import Tabs from "components/Tabs";

import useDocumentTitle from "hooks/useDocumentTitle";

const ActivityItem = inject(
  "ActivitiesStore",
  "ApplicationStore",
  "ItemsStore"
)(
  observer(({ ActivitiesStore, ApplicationStore, match, ItemsStore }) => {
    const { fetchActivity, activity, reset } = ActivitiesStore;
    const { isLoading } = ApplicationStore;
    const {
      setSearchActivation,
      setSearchString,
      setLastSearchStringRequest,
    } = ItemsStore;

    const id = match.params.activityId;

    useEffect(() => {
      fetchActivity(id);
      return () => reset();
    }, [fetchActivity, reset, id]);

    useEffect(() => {
      return () => {
        setSearchActivation(false);
        setSearchString("");
        setLastSearchStringRequest();
      };
    }, [setSearchActivation, setSearchString, setLastSearchStringRequest]);

    useDocumentTitle(
      () => (isLoading ? "Загрузка..." : `Мероприятие ${activity?.title}`),
      [activity]
    );

    const breadcrumbs = [
      { path: "/activities", label: "Мероприятия" },
      {
        path: "/activities/:activityId",
        label: isLoading ? "Загрузка..." : `${activity?.title}`,
      },
    ];

    const archived = activity?.archived;

    const tabs = [
      {
        title: "Описание",
        path: `/activity/description/${id}`,
        content: (
          <Description
            id={id}
            description={activity?.description}
            isLoading={isLoading}
          />
        ),
      },
      {
        title: isLoading
          ? "Приглашенные"
          : `Приглашенные (${activity?.invitedPeopleCount})`,
        path: `/activity/invited/${id}`,
        content: (
          <Invited
            id={id}
            needToLoad={activity?.invitedPeopleCount !== 0}
            archived={archived}
          />
        ),
      },
      {
        title: isLoading
          ? "Пришли"
          : `Пришли (${activity?.arrivedPeopleCount})`,
        path: `/activity/arrived/${id}`,
        content: (
          <Arrived
            id={id}
            needToLoad={activity?.arrivedPeopleCount !== 0}
            archived={archived}
          />
        ),
      },
      {
        title: isLoading
          ? "Не пришли"
          : `Не пришли (${activity?.notArrivedPeopleCount})`,
        path: `/activity/notarrived/${id}`,
        content: (
          <NotArrived
            id={id}
            needToLoad={activity?.notArrivedPeopleCount !== 0}
            archived={archived}
          />
        ),
      },
    ];

    const tabsArray = archived ? tabs : tabs.slice(0, 2);

    return (
      <div className="wrapper__content">
        <Breadcrumbs routes={breadcrumbs} />
        <section className="section">
          <div className="container">
            <h1 className="activity__item-title page-title">
              {isLoading ? "Загрузка..." : activity?.title}
            </h1>
            <p className="activity__item-date">
              {isLoading
                ? "Загрузка..."
                : activity?.date + " — " + activity?.dateOfEnd}
            </p>
            <p className="activity__item-location">{activity?.location}</p>
            <Tabs items={tabsArray} withRouter match={match} />
          </div>
        </section>
      </div>
    );
  })
);

export default ActivityItem;
