import React, { useEffect, forwardRef, createRef, useRef } from "react";
import { inject, observer } from "mobx-react";

import SearchInput from "common/components/Inputs/SearchInput";
import RadioButtons from "common/components/Inputs/RadioButtons";
import RadioButton from "common/components/Inputs/RadioButton";

import { SkeletonLine } from "components/Skeleton";
import ParamElement from "../ParamElement";
import Expand from "components/Expand";
import Empty from "components/Empty";

import { BUILDING_PARAMETERS } from "helpers/requestPaths";
import { highlightTextContentsBySubstring } from "helpers/highlighters";

const SearchField = forwardRef((props, ref) => {
  const { className, placeholder, onChange, onSearch, value } = props;
  return (
    <SearchInput
      reference={ref}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
      value={value}
      hideSearchButton
    />
  );
});

const HomeTab = inject("SearchStore")(
  observer(({ SearchStore, isInForm }) => {
    const searchFieldRefference = createRef();
    const paramsListReference = useRef(null);

    const {
      homesParams,
      fetchParams,
      displayedParams,

      radioOptsOR,
      radioOptsNO,
      conditionOR,
      conditionNO,
      onRadioChange,

      homesFoundParams,
      selectedTitle,
      selectedValue,
      possibleValues,
      isParamChecked,
      isValueChecked,

      searchString,
      onSearchInputChange,
      resetSearch,

      onExpandClick,
      disabled,
      disabledValues,
      homesParamsFetched,
      paramsAreFetching,
    } = SearchStore;

    useEffect(() => {
      resetSearch();
      if (!homesParamsFetched) {
        fetchParams(BUILDING_PARAMETERS);
      }
    }, [fetchParams, resetSearch, homesParamsFetched]);

    useEffect(() => {
      searchFieldRefference.current.disabled = paramsAreFetching;
      if (!paramsAreFetching) {
        searchFieldRefference.current.focus();
      }
    }, [searchFieldRefference, paramsAreFetching]);

    useEffect(() => {
      const labels = paramsListReference.current.querySelectorAll(
        ".expand:nth-child(1) .expand__item label span"
      );
      highlightTextContentsBySubstring(labels, searchString);
    }, [searchString, paramsAreFetching]);

    return (
      <div className="params__form">
        {!isInForm && displayedParams.length !== 0 ? (
          <RadioButtons
            options={radioOptsOR}
            checked={conditionOR}
            onChange={(e) => onRadioChange(e, "or")}
          />
        ) : null}
        <div className="params__search">
          <SearchField
            ref={searchFieldRefference}
            className=""
            placeholder="Поиск по параметрам"
            onChange={(e) => onSearchInputChange(e.target.value, "homes")}
            onSearch={() => onSearchInputChange(searchString, "homes")}
            value={searchString}
          />
        </div>
        <ul
          className={`params__list${
            paramsAreFetching ? " overflow loading" : ""
          }`}
          ref={paramsListReference}
        >
          {paramsAreFetching ? (
            <div className="params__loading">
              <SkeletonLine width={375} height={25} translucent />
              <SkeletonLine width={375} height={25} translucent />
              <SkeletonLine width={375} height={25} translucent />
              <SkeletonLine width={375} height={25} translucent />
              <SkeletonLine width={375} height={25} translucent />
              <SkeletonLine width={375} height={25} translucent />
            </div>
          ) : homesFoundParams.length !== 0 ? (
            <>
              <Expand
                opened={!disabled}
                onClick={() => onExpandClick("titles")}
                selected={selectedTitle}
                noBorder
              >
                <ul className="params__list" label="Выберите параметр дома">
                  {homesFoundParams.map((item, index) => (
                    <ParamElement
                      key={item.title}
                      radioButtonId={item.title}
                      title={item.title}
                      checked={isParamChecked(index)}
                      onChange={onRadioChange}
                      searchType="homes"
                      isFoundParameter
                      disabled={disabled}
                    />
                  ))}
                </ul>
              </Expand>

              {possibleValues && (
                <Expand
                  opened={!disabledValues}
                  onClick={() => onExpandClick("values")}
                  selected={selectedValue}
                >
                  <ul className="params__list" label="Выберите значение">
                    {possibleValues.map((item, index) => (
                      <li key={index} label={item} className="params__item">
                        <RadioButton
                          id={item}
                          value={item}
                          label={item}
                          inputName={item}
                          checked={isValueChecked(item)}
                          onChange={(e) => onRadioChange(e, "value")}
                        />
                      </li>
                    ))}
                  </ul>
                </Expand>
              )}
            </>
          ) : homesParams.length !== 0 ? (
            <>
              <Expand
                opened={!disabled}
                onClick={() => onExpandClick("titles")}
                selected={selectedTitle}
                noBorder
              >
                <ul className="params__list" label="Выберите параметр дома">
                  {homesParams.map((item, index) => (
                    <ParamElement
                      key={item.title}
                      radioButtonId={item.title}
                      title={item.title}
                      checked={isParamChecked(index)}
                      onChange={onRadioChange}
                      searchType="homes"
                      disabled={disabled}
                    />
                  ))}
                </ul>
              </Expand>

              {possibleValues && (
                <Expand
                  opened={!disabledValues}
                  onClick={() => onExpandClick("values")}
                  selected={selectedValue}
                >
                  <ul className="params__list" label="Выберите значение">
                    {possibleValues.map((item, index) => (
                      <li key={index} label={item} className="params__item">
                        <RadioButton
                          id={item}
                          value={item}
                          label={item}
                          inputName={item}
                          checked={isValueChecked(item)}
                          onChange={(e) => onRadioChange(e, "value")}
                        />
                      </li>
                    ))}
                  </ul>
                </Expand>
              )}
            </>
          ) : (
            <Empty isSearch={false} isInModal />
          )}
        </ul>
        {!isInForm && (
          <RadioButtons
            options={radioOptsNO}
            checked={conditionNO}
            onChange={(e) => onRadioChange(e, "no")}
          />
        )}
      </div>
    );
  })
);

export default HomeTab;
