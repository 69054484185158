import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import itemsStore from "../../../stores/common/itemsStore";
import Pagination from "components/Pagination";

import uploadFilesResultsStore from "../../../stores/components/uploadFilesResultsStore";
import { PaginationStore } from "stores/common/paginationStore";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import modalStore from "../../../stores/components/modalStore";

import "./styles.scss";

const paginationStore = new PaginationStore();

const ModalImportResults = inject("ItemsStore")(
  observer(({ header, ItemsStore }) => {
    const {
      currentPage,
      perPage,
      changePerPage,
      paginate,
      resetPagination,
      resetAll,
      startIndex,
    } = paginationStore;

    useEffect(() => {
      uploadFilesResultsStore.getUploadFilesResults(
        ItemsStore.fileInfo.md5,
        startIndex,
        perPage
      );
    }, [startIndex, perPage]);

    useEffect(() => {
      return () => {
        uploadFilesResultsStore.closeResultsModal();
      };
    }, []);

    const handleCLoseClick = () => {
      modalStore.toggleIsImportResultsModal(false);
      uploadFilesResultsStore.closeResultsModal();

      resetPagination();
      resetAll();
    };

    return (
      <div className="modal uploadFilesResults">
        <p className="modal__title">{header}</p>
        <span className="close" onClick={handleCLoseClick}>
          <Close />
        </span>
        <p className="modal__subtitle">
          {itemsStore.selectedSource.name || itemsStore.fileInfo.name}
        </p>
        <span className="subtitle-success">
          Количество успешно импортированных записей -{" "}
          {uploadFilesResultsStore.data?.success}
        </span>
        <span className="subtitle-error">
          Количество записей, которые не удалось обработать –{" "}
          {uploadFilesResultsStore.data?.error}
        </span>

        <div className="results-table">
          <li className="results-table__item header">
            <span className="rownumber">Сегмент</span>
            <span className="reason">Причина</span>
          </li>
          {uploadFilesResultsStore.data?.errorDescriptions.map((item, idx) => {
            return (
              <li className="results-table__item" key={idx}>
                <span className="rownumber">{item.rowNumber}</span>
                <span className="reason">{item.description}</span>
              </li>
            );
          })}
          <Pagination
            perPage={perPage}
            total={uploadFilesResultsStore.data?.count}
            currentPage={currentPage + 1}
            handlePaginate={(page) => paginate(page - 1)}
            isShowPerPage
            handleChangePerPage={(count) => changePerPage(count)}
          />
        </div>
      </div>
    );
  })
);

export default ModalImportResults;
