import React from "react";

import Checkbox from "common/components/Inputs/Checkbox";

const CheckboxWrapper = ({ noFlat, handleChange }) => (
	<div className="address-block__checkboxes-wrapper margin-bottom-25">
		<Checkbox
			id="noFlat"
			labelClassName={`checkbox-label ${noFlat ? "checked" : ""}`}
			onChange={() => handleChange("noFlat", !noFlat)}
			checked={noFlat}>
			Нет квартиры
		</Checkbox>
	</div>
);

export default CheckboxWrapper;
