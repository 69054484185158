import React from "react";

import "./index.scss";

const TextArea = (props) => {
  const { className, value, name, placeholder, autoFocus, onChange } = props;

  return (
    <textarea
      className={`input__textarea ${className || ""}`}
      value={value}
      name={name}
      placeholder={placeholder || ""}
      autoFocus={autoFocus || false}
      onChange={onChange}
    />
  );
};

export default TextArea;
