import React from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";

import "./index.scss";

import MainHeader from "components/Header/Main";
import MemoizedSearchBlock from "components/MemoizedSearchBlock";

import { ReactComponent as People } from "assets/svg/people.svg";
import { ReactComponent as Activities } from "assets/svg/activities.svg";
import { ReactComponent as Analytics } from "assets/svg/analytics.svg";

import useDocumentTitle from "hooks/useDocumentTitle";

const blocks = [
  {
    title: "Люди",
    content:
      "Поиск по категориям, добавление, приглашение людей на мероприятия",
    link: `/people`,
    img: <People />,
  },
  {
    title: "Мероприятия",
    content: "Реестр мероприятий, информация о мероприятиях, добавление",
    link: `/activities`,
    img: <Activities />,
  },
  {
    title: "Аналитика",
    content: "Аналитка по различным категориям, выгрузка отчётов в файл",
    link: `/analytics`,
    img: <Analytics />,
  },
];

const Home = inject("ApplicationStore")(
  observer(({ ApplicationStore }) => {
    const { loggedInUser } = ApplicationStore;

    const accessibleBlocks = blocks.filter((block) =>
      loggedInUser?.modules?.includes(block.title)
    );

    useDocumentTitle("АИС Люди");

    return (
      <>
        <MainHeader />
        <div className="wrapper__content">
          <section className="section">
            <div className="container">
              <MemoizedSearchBlock />
              <h1 className="home__title">Смотрите также</h1>
              <ul className="blocks">
                {accessibleBlocks.map((block, index) => (
                  <li className="block block-item" key={index}>
                    <Link to={block.link}>
                      <div className="block__header">
                        {block.img}
                        <p>{block.title}</p>
                      </div>
                      <p>{block.content}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </>
    );
  })
);

export default Home;
