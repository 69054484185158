import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";
import "../TextInput/index.scss";

import FloatLabel from "../FloatLabel";

import useOnClickOutside from "hooks/useOnClickOutside";

export const AreaAutocomplete = inject("AreasStore")(
  observer(({ AreasStore, toggleFetch = true, ...props }) => {
    const { areaAutocompleteStore } = AreasStore;
    const {
      filteredSuggestions,
      filterSuggestions,
      showSuggestions,
      hideSuggestions,
      currInput,
      onChange,
      onClick,
      onFocus,
      onBlur,
      resetAutocomplete,
      handleChange,
    } = areaAutocompleteStore;

    const ref = useRef();

    useOnClickOutside(ref, () => {
      hideSuggestions();
    });

    useEffect(() => {
      filterSuggestions(props.suggestions, currInput);
    }, [filterSuggestions, props.suggestions, currInput]);

    useEffect(() => () => resetAutocomplete(), [resetAutocomplete]);

    useEffect(
      () => handleChange("toggleFetch", toggleFetch),
      [toggleFetch, handleChange]
    );

    const suggestionsList = () => {
      let suggestionsList;

      if (showSuggestions && props.userInput) {
        if (filteredSuggestions?.length) {
          const visibleOptions = 6;
          suggestionsList = (
            <ul
              className={`autocomplete-select ${
                visibleOptions > filteredSuggestions?.length ? "no-borders" : ""
              }`}
              name="suggestions"
            >
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <li
                    className={"autocomplete-option"}
                    key={index}
                    onClick={() => {
                      onClick(
                        suggestion,
                        props.onSelect,
                        props.noFetchCallback
                      );
                      props.getSelectedSuggestion(suggestion);
                    }}
                  >
                    <span>
                      {suggestion.title
                        ? suggestion.title
                        : props.streetTitle +
                          ", д. " +
                          suggestion.location.buildingNumber}
                    </span>
                    {props.needPeopleCount && suggestion.location && (
                      <span>{`${suggestion.peopleCount} человек`}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          );
        }
      }
      return suggestionsList;
    };

    return (
      <div
        className={`autocomplete ${props.noMargin ? "no-margin" : ""}`}
        ref={ref}
      >
        <FloatLabel label={props.placeholder} value={props.userInput || ""}>
          <input
            disabled={props.disabled || false}
            readOnly={props.readOnly || false}
            className={`input__text ${props.className || ""}`}
            type={"text"}
            name={props.name}
            value={props.userInput || ""}
            placeholder=""
            autoComplete={"off"}
            autoFocus={props.autoFocus || false}
            onChange={(e) =>
              onChange(e, props.onInputChange, props.noFetchCallback)
            }
            onFocus={() => onFocus(props.fetch, toggleFetch)}
            onBlur={onBlur}
          />
        </FloatLabel>
        {suggestionsList()}
      </div>
    );
  })
);

const Autocomplete = inject("AutocompleteStore")(
  observer(({ AutocompleteStore, toggleFetch = true, ...props }) => {
    const {
      filteredSuggestions,
      filterSuggestions,
      showSuggestions,
      hideSuggestions,
      currInput,
      onChange,
      onClick,
      onFocus,
      onBlur,
      resetAutocomplete,
      handleChange,
    } = AutocompleteStore;

    const ref = useRef();

    useOnClickOutside(ref, () => {
      hideSuggestions();
    });

    useEffect(() => {
      filterSuggestions(props.suggestions, currInput);
    }, [filterSuggestions, props.suggestions, currInput]);

    useEffect(() => () => resetAutocomplete(), [resetAutocomplete]);

    useEffect(
      () => handleChange("toggleFetch", toggleFetch),
      [toggleFetch, handleChange]
    );

    const suggestionsList = () => {
      let suggestionsList;

      if (showSuggestions && props.userInput) {
        if (filteredSuggestions?.length) {
          const visibleOptions = 6;
          suggestionsList = (
            <ul
              className={`autocomplete-select ${
                visibleOptions > filteredSuggestions?.length ? "no-borders" : ""
              }`}
              name="suggestions"
            >
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <li
                    className={"autocomplete-option"}
                    key={index}
                    onClick={() => {
                      onClick(
                        suggestion,
                        props.onSelect,
                        props.noFetchCallback
                      );
                      props.getSelectedSuggestion(suggestion);
                    }}
                  >
                    <span>
                      {suggestion.title
                        ? suggestion.title
                        : props.streetTitle +
                          ", д. " +
                          suggestion.location.buildingNumber}
                    </span>
                    {props.needPeopleCount && suggestion.location && (
                      <span>{`${suggestion.peopleCount} человек`}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          );
        }
      }
      return suggestionsList;
    };

    return (
      <div
        className={`autocomplete ${props.noMargin ? "no-margin" : ""}`}
        ref={ref}
      >
        <FloatLabel label={props.placeholder} value={props.userInput || ""}>
          <input
            disabled={props.disabled || false}
            readOnly={props.readOnly || false}
            className={`input__text ${props.className || ""}`}
            type={"text"}
            name={props.name}
            value={props.userInput || ""}
            placeholder=""
            autoComplete={"off"}
            autoFocus={props.autoFocus || false}
            onChange={(e) =>
              onChange(e, props.onInputChange, props.noFetchCallback)
            }
            onFocus={() => onFocus(props.fetch, toggleFetch)}
            onBlur={onBlur}
          />
        </FloatLabel>
        {suggestionsList()}
      </div>
    );
  })
);

export default Autocomplete;
