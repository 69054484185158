import { observer } from "mobx-react";
import React, { useEffect } from "react";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import Expand from "components/Expand";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

import excludeStore from "../../../stores/components/excludeStore";

import "./styles.scss";
import List from "./List";
import FileLoader from "./FileLoader";

const ModalExcludesResult = ({ header }) => {
  useEffect(
    () => () => {
      excludeStore.clearState();
    },
    []
  );

  const handleNameSearchOpened = () => {
    excludeStore.selectNameOpened(true);
  };

  const handleNameSearchClosed = () => {
    excludeStore.selectNameOpened(false);
  };

  const handleStreetOpened = () => {
    excludeStore.selectStreetOpened(true);
  };

  const handleStreetClosed = () => {
    excludeStore.selectStreetOpened(false);
  };

  const handleRemoveFromList = (item) => {
    excludeStore.removeFormList(item);
  };

  const handleRemoveFromStreetList = (item) => {
    excludeStore.removeFormStreetList(item);
  };

  const handleCancelClick = () => {
    excludeStore.clearState();
  };

  const textInputs = [
    {
      value: excludeStore.value,
    },
  ];

  const textInputsStreet = [
    {
      value: excludeStore.streetValue,
    },
  ];

  return (
    <div className="modal modal-excluded">
      <p className="modal__title">{header}</p>
      <Expand
        onClick={
          !excludeStore.nameOpenedAccordeon
            ? handleNameSearchOpened
            : handleNameSearchClosed
        }
        opened={excludeStore.nameOpenedAccordeon}
        selectedArray={excludeStore.nameWords}
        handleRemoveFromList={handleRemoveFromList}
        withCloserList={true}
        noBorder
      >
        <ul label={"По национальности"}>
          {textInputs.map((item, idx) => (
            <li key={idx}>
              <FloatLabel
                label="Введите слова-исключения из ФИО"
                value={excludeStore.value}
              >
                <TextInput
                  value={item.value}
                  className={"people-form__aucomplete-input"}
                  readOnly={false}
                  onChange={excludeStore.changeFIO}
                  onKeyDown={excludeStore.onNameKeyDownEvent}
                />
              </FloatLabel>
            </li>
          ))}
        </ul>
      </Expand>
      <div style={{ borderBottom: "1px solid #cdcfd1" }} />
      <Expand
        onClick={
          !excludeStore.streetOpenedAccordeon
            ? handleStreetOpened
            : handleStreetClosed
        }
        opened={excludeStore.streetOpenedAccordeon}
        selectedArray={excludeStore.streetWords}
        handleRemoveFromList={handleRemoveFromStreetList}
        noBorder
      >
        <ul label={"По адресам"}>
          {textInputsStreet.map((item, idx) => (
            <li key={idx}>
              <FloatLabel label="Найти улицу или дом" value={item.value}>
                <TextInput
                  value={excludeStore.streetValue}
                  className={"people-form__aucomplete-input"}
                  readOnly={false}
                  onChange={excludeStore.changeStreet}
                  onKeyDown={excludeStore.onStreetKeyDownEvent}
                />
              </FloatLabel>
            </li>
          ))}
        </ul>
      </Expand>
      <div style={{ borderBottom: "1px solid #cdcfd1" }} />
      <FileLoader />
      <div style={{ borderBottom: "1px solid #cdcfd1" }} />
      <div className="formButtons">
        <SubmitButton
          className="formButton"
          disabled={
            !excludeStore.nameWords.length &&
            !excludeStore.streetWords.length &&
            !excludeStore.loaderFileStore.fileNames.length
          }
          onClick={() => {
            excludeStore.sendRequest(true);
          }}
        >
          Отфильтровать
        </SubmitButton>
        <CancelButton
          onClick={() => {
            handleCancelClick();
          }}
          className="formButton"
        >
          Отмена
        </CancelButton>
      </div>
      <List />
    </div>
  );
};

export default observer(ModalExcludesResult);
