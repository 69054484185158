const openTag = '<span style="background-color:#FDF7C4">';
const closeTag = "</span>";

const addHighlightingBySubstring = (string, substring) => {
  const escapedSubstring = substring.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
  const searchStringPattern = new RegExp(`(${escapedSubstring.trim()})`, "gi");
  return string.replace(searchStringPattern, `${openTag}$1${closeTag}`);
};

const emptyNode = (node) => {
  while (node.hasChildNodes()) {
    node.removeChild(node.childNodes[0]);
  }
};

export const highlightTextContentsBySubstring = (nodes, substring) => {
  nodes.forEach((node) => {
    const initialText = node.innerText;

    emptyNode(node);
    node.insertAdjacentText("afterbegin", initialText);

    if (substring === "") {
      return;
    }

    const htmlRepresentation = addHighlightingBySubstring(
      initialText,
      substring
    );
    emptyNode(node);
    node.insertAdjacentHTML("afterbegin", htmlRepresentation);
  });
};
