import React from "react";

import Tabs from "components/Tabs";
import Breadcrumbs from "components/Breadcrumbs";

import AddPeople from "../AddPeople";
import PeopleHistory from "../PeopleHistory";

const PersonItem = ({ match }) => {
  const isEdit = match.url.includes("/people/editperson");
  const isHistory = match.url.includes("/people/history");
  const id = match.params.id;

  const breadcrumbs = [
    { path: "/people", label: "Люди" },
    {
      path: "/people/addperson",
      label: "Редактировать человека",
    },
  ];

  const tabs = [
    {
      title: "Редактирование",
      path: `/people/editperson/${id}`,
      content: <AddPeople id={id} isEdit={isEdit} />,
    },
    {
      title: "История изменений",
      path: `/people/history/${id}`,
      content: <PeopleHistory id={id} />,
    },
  ];

  return (
    <div className="wrapper__content">
      <Breadcrumbs routes={breadcrumbs} />
      <section className="section">
        <div className="container">
          <h1 className="page-title">
            {isEdit || isHistory
              ? "Редактировать человека"
              : "Добавить человека"}
          </h1>
          <Tabs items={tabs} withRouter match={match} />
        </div>
      </section>
    </div>
  );
};

export default PersonItem;
