import React from "react";

import { ReactComponent as Alert } from "assets/svg/alert.svg";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

const ModalWarning = ({ onSubmit, onCancel, message }) => {
  return (
    <div className="modal">
      <p className="modal__title static">Внимание</p>
      <Alert className="modal__icon" />
      <p className="modal__helper-text static">{message}</p>
      <div className="modal__button-group static">
        <SubmitButton
          className="modal__button"
          onClick={() => onSubmit(true)}
        >
          ОК
        </SubmitButton>
        <CancelButton
          className="modal__button"
          onClick={() => onCancel(false)}
        >
          Отмена
        </CancelButton>
      </div>
    </div>
  );
};

export default ModalWarning;
