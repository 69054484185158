import { action, observable, decorate, runInAction, set, computed } from "mobx";

import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";

import formattedDate from "helpers/formattedDate";
import { EMPTY_ERR, INVALID_DATE_ERR } from "helpers/errors";

class CompaniesStore {
	companies = [];
	company = null;
	titleCache = "";
	editedDate = "";
	serverErr = "";
	newCompany = {
		date: null,
		caption: "",
	};

	get parsedDate() {
		return formattedDate(this.editedDate, false, true);
	}

	get errors() {
		let error = {};
		const { mode, isSubmitted, hasOnlyWhiteSpaces } = ApplicationStore;

		if (!isSubmitted) {
			return error;
		}

		switch (mode) {
			case "ADD_COMPANY":
				if (!this.newCompany.caption || hasOnlyWhiteSpaces(this.newCompany.caption)) {
					error["caption"] = EMPTY_ERR;
				} else if (this.serverErr) {
					error["caption"] = this.serverErr;
				}
				if (!this.newCompany.date || this.newCompany.date === "Invalid Date") {
					error["date"] = INVALID_DATE_ERR;
				}
				break;
			case "EDIT_COMPANY":
				if (!this.company.caption || hasOnlyWhiteSpaces(this.company.caption)) {
					error["caption"] = EMPTY_ERR;
				} else if (this.serverErr) {
					error["caption"] = this.serverErr;
				}
				if (!this.company.date || this.company.date === "Invalid Date") {
					error["date"] = INVALID_DATE_ERR;
				}
				break;
			default:
				break;
		}
		return error;
	}

	changeCompany = (obj, key, value) => {
		this.resetServerErrors();
		set(obj, key, value);
	};

	changeCompanyDate = (obj, value) => {
    set(obj, "date", value?.toISOString());
    this.editedDate = value?.toISOString();
	};

	resetServerErrors = () => {
		this.serverErr = "";
	};

	reset = () => {
		runInAction(() => {
			this.companies = [];
			this.company = null;
			this.titleCache = "";
			this.editedDate = "";
			this.serverErr = "";
			this.newCompany.date = null;
			this.newCompany.caption = "";
		});
	};

	fetchCompanies = async () => {
		ApplicationStore.setLoading(true);
		await app
			.get("/ElectionCompany/GetCompanies")
			.then((res) => {
				this.companies = res.data;
			})
			.catch((err) => {
				ApplicationStore.setError(err);
			})
			.finally(() => {
				ApplicationStore.setLoading(false);
			});
	};

	fetchCompany = async (id) => {
		ApplicationStore.setLoading(true);
		await app
			.get(`/ElectionCompany/GetCompanyById?guid=${id}`)
			.then((res) => {
				this.company = res.data;
				this.editedDate = this.company.date;
				this.titleCache = this.company.caption;
				ApplicationStore.setLoading(false);
			})
			.catch((err) => {
				if (err.response?.status === 400) {
					routing.push(`/404`);
				}
				ApplicationStore.setError(err);
			})
			.finally(() => {
				ApplicationStore.setLoading(false);
			});
	};

	addCompany = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			ApplicationStore.setUploading(true);
			app.post(`/ElectionCompany/AddElectionCompany`, {
				date: this.newCompany.date,
				caption: this.newCompany.caption.trim(),
				pollingStations: [],
			})
				.then((res) => {
					runInAction(() => {
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
						routing.push(`/handbooks/editcompany/${res.data}`);
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};

	editCompany = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			ApplicationStore.setUploading(true);
			app.put(`/ElectionCompany/Change`, {
				electionCompanyId: this.company.electionCompanyId,
				date: this.company.date,
				caption: this.company.caption.trim(),
			})
				.then(() => {
					runInAction(() => {
						this.titleCache = this.company.caption;
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};
}

decorate(CompaniesStore, {
	companies: observable,
	company: observable,
	titleCache: observable,
	newCompany: observable,
	editedDate: observable,
	serverErr: observable,

	parsedDate: computed,
	errors: computed,

	reset: action,
	resetServerErrors: action,
	changeCompany: action,
	changeCompanyDate: action,
	fetchCompanies: action,
	fetchCompany: action,
	addCompany: action,
	editCompany: action,
});

export default new CompaniesStore();
