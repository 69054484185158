import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./index.scss";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

const HomesList = inject(
  "ItemsStore",
  "PaginationStore",
  "ApplicationStore"
)(
  observer(({ ItemsStore, PaginationStore, ApplicationStore, isSearch }) => {
    const {
      items,
      reset,
      fetchWithParameters,
      count,
      itemsAreLoading,
    } = ItemsStore;
    const {
      currentPage,
      perPage,
      changePerPage,
      paginate,
      resetAll,
      startIndex,
    } = PaginationStore;

    const [isCleared, setCleared] = useState(false);

    useEffect(() => {
      isCleared && fetchWithParameters("homes", startIndex, perPage);
      return () => {
        reset();
      };
    }, [fetchWithParameters, startIndex, perPage, reset, isCleared]);

    useEffect(() => {
      resetAll();
      setCleared(true);
    }, [resetAll, setCleared]);

    const classnames = {
      header: "homeslist-header",
      itemWrapper: "homeslist-item",
    };

    const header = (
      <>
        <div>Адрес</div>
        <div>Количество людей</div>
        <div>Параметры</div>
      </>
    );

    const mappedItems = items?.map((item) => (
      <>
        <span className="homeslist-item__home-value">
          <Link className="page-link" to={`/homes/editbuilding/${item.id}`}>
            {item.street}, {item.number}
          </Link>
        </span>
        <p className="table-value">{item.peopleCount}</p>
        <p className="table-value">
          {item.parameters?.length
            ? item.parameters
                .map(
                  (item) => item.Title + (item.Value ? `: ${item.Value}` : "")
                )
                .join(", ")
            : "'—'"}
        </p>
      </>
    ));

    if (itemsAreLoading) {
      return <LoaderSpinner />;
    }

    if (items?.length === 0) {
      return <Empty isSearch={isSearch} />;
    }

    return (
      <div className="people-wrapper">
        {isCleared && (
          <>
            <Table
              header={header}
              items={mappedItems}
              classnames={classnames}
            />
            <Pagination
              perPage={perPage}
              total={count}
              currentPage={currentPage + 1}
              handlePaginate={(page) => paginate(page - 1)}
              isShowPerPage
              handleChangePerPage={(count) => changePerPage(count)}
            />
          </>
        )}
      </div>
    );
  })
);

export default HomesList;
