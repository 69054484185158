import React from "react";

import "./index.scss";

import { ReactComponent as EmptyImg } from "assets/svg/empty.svg";

const Empty = ({ isSearch, isInModal }) => {
	return (
		<div className={`wrapper__empty${isInModal ? " isInModal" : ""}`}>
			<EmptyImg />
			<h1 className="title__empty">{isSearch ? "Ничего не найдено" : "Список пуст"}</h1>
		</div>
	);
};

export default Empty;
