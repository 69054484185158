import React from "react";

import "./index.scss";

const ParamsButton = (props) => {
  return (
    <button
      className={`button__params ${props.className ? props.className : ""}`}
      type="button"
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      {props.children}
    </button>
  );
};

export default ParamsButton;
