import axios from "axios";

/*

baseURL: "/", — для локальной работы, старый API, ПК Андрея
baseURL: "http://172.16.100.158/", — старый, неизвестно
baseURL: "http://172.16.100.165:5433/", — новый API, комп Романа
baseURL: "https://people.aiwoo.info/api/", — старый API для работы на тест-сервере
baseURL: "http://172.23.199.120/api", — билд для Димы (по его запросу)

*/

const app = axios.create({
  //   baseURL: "https://people.aiwoo.info/api/",
  //   baseURL: "http://localhost:8885",
  //   baseURL: "http://172.16.100.102:8814/api",
  baseURL: "/api",
  // baseURL: "http://172.16.100.158/",
  // baseURL: "http://172.16.100.165:5433/",

  //   baseURL: "http://172.23.199.120/api",
  withCredentials: true,
  headers: {
    Pragma: "no-cache",
  },
});
//
export default app;
