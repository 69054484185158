import React from "react";
import { inject, observer } from "mobx-react";

import formatNumber from "helpers/formatNumber";

const HeaderWithCount = inject("ItemsStore")(
	observer(({ ItemsStore, title, loading }) => {
		const { count } = ItemsStore;
		const headerContent = loading
			? `${title} — Загрузка...`
			: title + (count ? ` — ${formatNumber(count)}` : " — 0");
		return <h1 className="page-title">{headerContent}</h1>;
	})
);

export default HeaderWithCount;
