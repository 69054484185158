import { observable, decorate, action, reaction, computed } from "mobx";
import app from "../../utils/axiosConfig";
import ApplicationStore from "../appStore";
import ItemsStore from "../common/itemsStore";
import PaginationStore from "../common/paginationStore";
import ModalStore from "./modalStore";

import {
  PERSON_PARAMETERS,
  SAVED_SEARCHES,
  TEMPORARY_SEARCHES,
  BUILDING_PARAMETERS,
} from "helpers/requestPaths";

class SearchStore {
  searchIsSaving = false;

  radioOptsOR = [
    {
      id: "and_radio",
      value: 0,
      label: "И",
    },
    {
      id: "or_radio",
      value: 1,
      label: "ИЛИ",
    },
  ];

  radioOptsNO = [
    {
      id: "y_radio",
      value: 0,
      label: "Значение - ДА",
    },
    {
      id: "no_radio",
      value: 1,
      label: "Значение - НЕТ",
    },
  ];

  constructor() {
    this.resetSearchStore();
  }

  resetSearchStore = () => {
    if (this.isRedirected) {
      return;
    }

    this.savedSearches = [];
    this.temporarySearches = [];

    this.count = 0;

    this.isSearchesLoading = false;

    this.homesParams = [];
    this.peopleParams = [];

    this.excluded = [];

    this.searchString = "";
    this.peopleFoundParams = [];
    this.homesFoundParams = [];

    this.displayedParams = [];
    this.displayedParamsId = 0;

    this.peopleDisplayedParams = [];
    this.buildingsDisplayedParams = [];

    this.globalConditionAND = true;

    this.foundHomeParamIndex = null;
    this.possibleValues = [];
    this.selectedTitle = "";
    this.selectedValue = "";
    this.disabled = false;
    this.disabledValues = true;

    this.postParams = {};

    this.isSubmitted = false;
    this.isRedirected = false;

    this.conditionOR = 0;
    this.conditionNO = 0;

    this.recentlySearches = [];
    this.savedSearches = [];

    this.displayedSearches = [];
    this.resetSaved();

    this.peopleParamsFetched = false;
    this.homesParamsFetched = false;

    this.paramsAreFetching = false;
  };

  resetSearch = () => {
    this.searchString = "";
    this.peopleFoundParams = [];
    this.homesFoundParams = [];

    let filteredExcluded = [];

    if (this.peopleDisplayedParams.length !== 0) {
      filteredExcluded = this.excluded.filter((value) =>
        this.peopleDisplayedParams.some((array) =>
          array.find((param) => param.title === value)
        )
      );
    }

    if (this.buildingsDisplayedParams.length !== 0) {
      filteredExcluded = filteredExcluded.concat(
        this.excluded.filter((value) =>
          this.buildingsDisplayedParams.some((array) =>
            array.find((param) => param.value === value)
          )
        )
      );
    }

    if (filteredExcluded.length) {
      this.excluded = filteredExcluded;
    }
  };

  resetFiltration = () => {
    this.displayedParams = [];
    this.displayedParamsId = 0;

    this.globalConditionAND = true;

    this.excluded = [];

    this.foundHomeParamIndex = null;
    this.possibleValues = [];
    this.selectedTitle = "";
    this.selectedValue = "";
    this.disabled = false;
    this.disabledValues = false;

    this.postParams = {};

    this.isSubmitted = false;

    this.conditionOR = 0;
    this.conditionNO = 0;
  };

  resetSaved = () => {
    this.isSaved = false;
  };

  resetChecked = (destination) => {
    switch (destination) {
      case "all":
        this.resetCheckedPeople();
        this.resetCheckedHome();
        break;

      case "people":
        this.resetCheckedPeople();
        break;

      case "homes":
        this.resetCheckedHome();
        break;

      default:
        break;
    }
  };

  resetCheckedPeople = () => {
    this.peopleParams = this.peopleParams.map((item) =>
      item.checked ? { ...item, checked: false, excluded: true } : item
    );
  };

  resetCheckedHome = () => {
    this.homesParams = this.homesParams.map((item) =>
      item.checked ? { ...item, checked: false, excluded: true } : item
    );
    this.possibleValues = [];
    this.selectedTitle = "";
    this.selectedValue = "";

    this.disabled = false;
    this.disabledValues = true;
  };

  resetConditions = () => {
    this.conditionOR = 0;
    this.conditionNO = 0;

    this.selectedTitle = "";
    this.selectedValue = "";

    this.disabled = false;
    this.disabledValues = true;
  };

  resetExcludedOnCancel = () => {
    let displayedTitles = [];

    this.displayedParams.forEach((array) => {
      array.forEach(({ title, value }) => {
        if (typeof value === "string") {
          if (this.excluded.includes(value)) displayedTitles.push(value);
        } else {
          if (this.excluded.includes(title)) displayedTitles.push(title);
        }
      });
    });

    this.excluded = this.excluded.filter((item) =>
      displayedTitles.includes(item)
    );
  };

  fetchParams = (path, doNotExclude = false) => {
    this.paramsAreFetching = true;

    app
      .get(path)
      .then((res) => {
        if (path === PERSON_PARAMETERS) {
          this.peopleParamsFetched = true;
          this.peopleParams = doNotExclude
            ? res.data
            : res.data.filter((item) => !this.excluded.includes(item.title));
        }
        if (path === BUILDING_PARAMETERS) {
          this.homesParamsFetched = true;
          this.homesParams = res.data.filter(
            (item) =>
              !item.possibleValues.every((item) => this.excluded.includes(item))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          this.serverErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.paramsAreFetching = false;
      });
  };

  fetchSavedSearches = (startIndex = 0, itemsCount = 4) => {
    ApplicationStore.setLoading(true);
    this.isSearchesLoading = true;
    this.displayedSearches = [];

    app
      .get(
        `${SAVED_SEARCHES}/?startIndex=${startIndex}&itemsCount=${itemsCount}`
      )
      .then((res) => {
        this.savedSearches = res.data.items;
        this.createDisplayedSearches(res.data.items);
        this.count = res.data.count;
      })
      .catch((err) => {
        if (err.response) {
          this.serverErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
        this.isSearchesLoading = false;
      });
  };

  fetchTemporarySearches = () => {
    ApplicationStore.setLoading(true);
    this.isSearchesLoading = true;
    this.displayedSearches = [];

    app
      .get(TEMPORARY_SEARCHES)
      .then((res) => {
        this.recentlySearches = res.data;
        this.createDisplayedSearches(res.data);
      })
      .catch((err) => {
        if (err.response) {
          this.serverErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
        this.isSearchesLoading = false;
      });
  };

  createDisplayedSearches = (searches) => {
    this.displayedSearches = searches.map((item) =>
      this.createDisplayedSearch(item)
    );
  };

  createDisplayedSearch = (search) => {
    const { buildings, parameters, parametersWithOrFilter } = search;
    let stringsArray = [];

    if (parameters?.length)
      stringsArray.push(this.createStringFromParams(parameters, "people"));

    if (parametersWithOrFilter?.length)
      stringsArray.push(
        parametersWithOrFilter
          .map((item) => this.createStringFromParams(item, "people", true))
          .join(" И ")
      );

    if (buildings?.parameters?.length)
      stringsArray.push(
        this.createStringFromParams(buildings.parameters, "buildings")
      );

    if (buildings?.parametersWithOrFilter?.length)
      stringsArray.push(
        buildings.parametersWithOrFilter
          .map((item) => this.createStringFromParams(item, "buildings", true))
          .join(" И ")
      );

    return { id: search.id, string: stringsArray.join(" И ") };
  };

  createStringFromParams = (params, arrayType, or) => {
    const separator = or ? " ИЛИ " : " И ";

    switch (arrayType) {
      case "buildings":
        return params
          .map(
            (item) =>
              (item.included ? "" : "НЕ ") +
              item.title +
              (item.value ? `: ${item.value}` : "")
          )
          .join(separator);

      case "people":
        return params
          .map((item) => (item.value ? "" : "НЕ ") + item.title)
          .join(separator);

      default:
        break;
    }
  };

  createDisplayedParams = (index, type) => {
    let search;

    if (type === "recent") search = this.recentlySearches[index];
    if (type === "saved") search = this.savedSearches[index];

    const { buildings, parameters, parametersWithOrFilter } = search;
    let paramsArray = [];

    if (parameters.length)
      paramsArray = paramsArray.concat(
        parameters.map((item) => [{ ...item, destination: "people" }])
      );

    if (parametersWithOrFilter.length)
      paramsArray = paramsArray.concat(
        parametersWithOrFilter.map((item) =>
          item.flat().map((item) => ({ ...item, destination: "people" }))
        )
      );

    if (buildings.parameters.length)
      paramsArray = paramsArray.concat(
        buildings.parameters.map((item) => [
          { ...item, destination: "buildings" },
        ])
      );

    if (buildings.parametersWithOrFilter.length)
      paramsArray = paramsArray.concat(
        buildings.parametersWithOrFilter.map((item) =>
          item.flat().map((item) => ({ ...item, destination: "buildings" }))
        )
      );

    this.displayedParams = paramsArray;
  };

  onParameterChange = (searchType, title, checked, isFoundParameter) => {
    switch (searchType) {
      case "people":
        let index = this.peopleParams.findIndex((item) => item.title === title);
        this.peopleParams[index] = { ...this.peopleParams[index], checked };
        this.excluded.push(this.peopleParams[index].title);

        if (isFoundParameter) {
          index = this.peopleFoundParams.findIndex(
            (item) => item.title === title
          );
          this.peopleFoundParams[index] = {
            ...this.peopleFoundParams[index],
            checked,
          };
        }

        break;

      case "homes":
        this.foundHomeParamIndex = this.homesParams.findIndex(
          (item) => item.title === title
        );

        let foundParam = this.homesParams[this.foundHomeParamIndex];

        this.possibleValues = foundParam.possibleValues.filter(
          (item) => !this.excluded.includes(item)
        );

        if (foundParam.title !== this.selectedTitle) {
          const index = this.homesParams.findIndex(
            (item) => item.title === this.selectedTitle
          );

          if (index !== -1) {
            this.homesParams[index].checked = false;
          }
        }

        this.homesParams[this.foundHomeParamIndex] = {
          ...foundParam,
          checked,
        };

        if (isFoundParameter) {
          const index = this.homesFoundParams.findIndex(
            (item) => item.title === title
          );

          foundParam = this.homesFoundParams[index];

          if (foundParam.title !== this.selectedTitle) {
            const index = this.homesParams.findIndex(
              (item) => item.title === this.selectedTitle
            );

            if (index !== -1) {
              this.homesParams[index].checked = false;
            }
          }

          this.homesFoundParams[index] = { ...foundParam, checked };
        }

        this.disabled = checked;

        break;

      default:
        break;
    }
  };

  onHomeValueChange = (valueIndex) => {
    const _selectedValue = this.possibleValues[valueIndex];
    this.homesParams[this.foundHomeParamIndex].checkedValue = _selectedValue;
    this.selectedValue = _selectedValue;
    this.disabled = false;
  };

  isParamChecked = (index) =>
    this.homesParams[index]?.title === this.selectedTitle;

  isValueChecked = (value) => value === this.selectedValue;

  onRadioChange = (e, type, searchType, title, checked, isFoundParameter) => {
    if (type === "or") {
      this.conditionOR = parseInt(e.target.value);
    }

    if (type === "no") {
      this.conditionNO = parseInt(e.target.value);
    }

    if (type === "title") {
      this.onParameterChange(searchType, title, checked, isFoundParameter);
      this.selectedTitle = e.target.value;
      this.selectedValue = "";
      this.disabled = true;
      this.disabledValues = false;
    }

    if (type === "value") {
      this.selectedValue = e.target.value;
      this.homesParams[this.foundHomeParamIndex].checkedValue = e.target.value;
      this.excluded.push(e.target.value);
      this.disabled = true;
      this.disabledValues = true;
    }
  };

  onExpandClick = (type) => {
    if (this.disabled && this.disabledValues) {
      switch (type) {
        case "titles":
          this.disabledValues = false;
          break;
        case "values":
          this.disabled = false;
          break;
        default:
          break;
      }
    }
    this.disabled = !this.disabled;
    this.disabledValues = !this.disabledValues;
  };

  delDisplayedParameter = (key, value) => {
    const filteredById = this.displayedParams.map((array) =>
      array.filter((item) => item[key] !== value)
    );
    const filteredByEmpty = filteredById.filter((array) => array.length !== 0);

    this.displayedParams = filteredByEmpty;

    this.excluded = this.excluded.filter((item) => item !== value);

    if (this.displayedParams.length === 0) {
      this.setSubmitted(false);
      ItemsStore.resetSelected();
    }
  };

  addParameters = (searchType) => {
    switch (searchType) {
      case "all":
        this.addCheckedParams(this.peopleParams, "people");
        this.addCheckedParams(this.homesParams, "buildings");
        this.resetChecked(searchType);
        this.resetConditions();
        break;

      case "people":
        this.addCheckedParams(this.peopleParams, "people");
        this.resetChecked(searchType);
        this.resetConditions();
        break;

      case "homes":
        this.addCheckedParams(this.homesParams, "buildings");
        this.resetChecked(searchType);
        this.resetConditions();
        break;

      case "analytics":
        this.addCheckedParams(this.peopleParams, "people");
        this.resetChecked("people");
        this.resetConditions();
        break;

      default:
        break;
    }
  };

  addCheckedParams = (params, destination) => {
    const checkedParams = params.filter((item) => item.checked);

    checkedParams.length !== 0 &&
      this.addWithConditions(checkedParams, destination);
  };

  addWithConditions = (checkedParams, destination) => {
    if (this.conditionNO) {
      let paramsArr = checkedParams.map((item) => {
        let newParam = {
          title: item.title,
          id: this.displayedParamsId++,
          destination,
        };

        if (destination === "buildings") {
          newParam.value = item.checkedValue;
          newParam.included = false;
        } else {
          newParam.value = false;
        }

        return newParam;
      });

      this.addWithORCondition(paramsArr);
    } else {
      let paramsArr = checkedParams.map((item) => {
        let newParam = {
          title: item.title,
          id: this.displayedParamsId++,
          destination,
        };

        if (destination === "buildings") {
          newParam.value = item.checkedValue;
          newParam.included = true;
        } else {
          newParam.value = true;
        }

        return newParam;
      });

      this.addWithORCondition(paramsArr);
    }
  };

  addWithORCondition = (paramsArr) => {
    if (this.conditionOR) {
      if (this.displayedParams.length !== 0) {
        paramsArr.unshift(...this.displayedParams.pop());
      }

      this.displayedParams.push(paramsArr);
    } else {
      paramsArr.forEach((item) => this.displayedParams.push([item]));
    }
  };

  displayedParamsReaction = reaction(
    () => this.displayedParams?.map((array) => array.length),
    () => this.separateDisplayedParams()
  );

  separateDisplayedParams = () => {
    this.peopleDisplayedParams = this.displayedParams
      .map((array) => array.filter((param) => param.destination === "people"))
      .filter((array) => array?.length !== 0);

    this.buildingsDisplayedParams = this.displayedParams
      .map((array) =>
        array.filter((param) => param.destination === "buildings")
      )
      .filter((array) => array?.length !== 0);

    const firstArrayWithBuilding = this.displayedParams.find((array) =>
      array.some((param) => param.destination === "buildings")
    );

    let isParamBeforeBuilding = false;
    if (firstArrayWithBuilding) {
      const buildingParamIndex = firstArrayWithBuilding.findIndex(
        (param) => param.destination === "buildings"
      );
      if (buildingParamIndex !== -1 && buildingParamIndex !== 0)
        isParamBeforeBuilding = true;
    }

    this.globalConditionAND =
      !isParamBeforeBuilding && this.peopleDisplayedParams.length !== 0;
  };

  buildPostParams = () => {
    this.postParams = {
      ...this.filterParamsByDestination("people"),
      buildings: {
        ...this.filterParamsByDestination("buildings"),
      },
      globalConditionAND: this.globalConditionAND,
    };
  };

  filterParamsByDestination = (destination) => {
    const parameters = this.displayedParams
      .filter(
        (array) => array.length === 1 && array[0].destination === destination
      )
      .map(([item]) => item);

    const parametersWithOrFilter = this.displayedParams
      .filter((array) => array.length > 1)
      .map((array) => {
        const filtered = array.filter(
          (item) => item.destination === destination
        );
        return filtered.length !== 0 ? filtered : null;
      })
      .filter((item) => item);

    return { parameters, parametersWithOrFilter };
  };

  setSubmitted = (submitted) => (this.isSubmitted = submitted);

  setRedirected = (redirected) => (this.isRedirected = redirected);

  addSavedSearch = async () => {
    this.searchIsSaving = true;
    await app
      .post("/PersonParameters/SearchRequests", this.postParams)
      .then((res) => {
        if (res.status === 200) {
          this.isSaved = true;
        }
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.searchIsSaving = false;
      });
  };

  addTemporarySearch = async () => {
    if (Object.keys(this.postParams).length === 0) {
      return;
    }

    ApplicationStore.setLoading(true);
    await app
      .post("/PersonParameters/SearchRequests/temporary", this.postParams)
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  delSavedSearch = (id, startIndex, perPage) => {
    app
      .delete(`/PersonParameters/SearchRequests/${id}`)
      .then(() => {
        this.fetchSavedSearches(startIndex, perPage);
        ModalStore.resetModal();
        PaginationStore.resetPagination();
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  resetFetchedParamsFlags = () => {
    this.peopleParamsFetched = false;
    this.homesParamsFetched = false;
  };

  onSearchInputChange = (_searchString, searchType) => {
    this.searchString = _searchString;
    const userInput = this.searchString.trim().toLowerCase();

    this[`${searchType}FoundParams`] = this[
      `${searchType}Params`
    ].filter((item) => item.title.toLowerCase().includes(userInput));
  };

  get noParametersChecked() {
    return [
      ...this.homesParams,
      ...this.peopleParams,
      ...this.peopleFoundParams,
      ...this.homesFoundParams,
    ].every((parameter) => !parameter.checked);
  }
}

decorate(SearchStore, {
  recentlySearches: observable,
  savedSearches: observable,
  isSaved: observable,

  displayedSearches: observable,

  homesParams: observable,
  peopleParams: observable,
  excluded: observable,

  searchString: observable,
  peopleFoundParams: observable,
  homesFoundParams: observable,
  foundHomeParamIndex: observable,
  possibleValues: observable,
  selectedTitle: observable,
  selectedValue: observable,
  disabled: observable,
  disabledValues: observable,

  displayedParams: observable,

  buildingsDisplayedParams: observable,
  peopleDisplayedParams: observable,
  globalConditionAND: observable,

  isSubmitted: observable,
  isRedirected: observable,

  conditionOR: observable,
  conditionNO: observable,

  resetFetchedParamsFlags: observable,

  peopleParamsFetched: observable,
  homesParamsFetched: observable,
  paramsAreFetching: observable,

  searchIsSaving: observable,

  noParametersChecked: computed,

  isParamChecked: action,
  isValueChecked: action,

  fetchParams: action,
  fetchSavedSearches: action,
  fetchTemporarySearches: action,
  addSavedSearch: action,
  addTemporarySearch: action,
  delSavedSearch: action,

  onExpandClick: action,

  onSearchInputChange: action,

  delDisplayedParameter: action,
  addParameters: action,
  onRadioChange: action,
  addPeopleParameter: action,
  addHomeParameter: action,
  onParameterChange: action,
  onHomeValueChange: action,
  buildPostParams: action,
  setSubmitted: action,
  setRedirected: action,
  createDisplayedParams: action,

  separateDisplayedParams: action,

  resetChecked: action,
  resetSaved: action,
  resetFiltration: action,
  resetExcludedOnCancel: action,
});

export default new SearchStore();
