import React, { useEffect, forwardRef, createRef, useRef } from "react";
import { inject, observer } from "mobx-react";

import SearchInput from "common/components/Inputs/SearchInput";
import RadioButtons from "common/components/Inputs/RadioButtons";

import Empty from "components/Empty";
import ParamElement from "../ParamElement";
import { SkeletonLine } from "components/Skeleton";

import { PERSON_PARAMETERS } from "helpers/requestPaths";
import { highlightTextContentsBySubstring } from "helpers/highlighters";

const SearchField = forwardRef((props, ref) => {
	const { className, placeholder, onChange, onSearch, value } = props;
	return (
		<SearchInput
			reference={ref}
			className={className}
			placeholder={placeholder}
			onChange={onChange}
			onSearch={onSearch}
			value={value}
			hideSearchButton
		/>
	);
});

const PeopleTab = inject(
	"SearchStore",
	"ApplicationStore"
)(
	observer(({ SearchStore, isInForm }) => {
		const searchFieldRefference = createRef();
		const paramsListReference = useRef(null);

		const {
			peopleParams,
			fetchParams,
			radioOptsOR,
			radioOptsNO,
			conditionOR,
			conditionNO,
			onRadioChange,
			peopleFoundParams,
			searchString,
			onSearchInputChange,
			onParameterChange,
			resetSearch,
			peopleParamsFetched,
			paramsAreFetching,
		} = SearchStore;

		useEffect(() => {
			searchFieldRefference.current.disabled = paramsAreFetching;
			if (!paramsAreFetching) {
				searchFieldRefference.current.focus();
			}
		}, [searchFieldRefference, paramsAreFetching]);

		useEffect(() => {
			resetSearch();
			if (!peopleParamsFetched) {
				fetchParams(PERSON_PARAMETERS);
			}
		}, [fetchParams, peopleParamsFetched, resetSearch]);

		useEffect(() => {
			const labels = paramsListReference.current.querySelectorAll("label");
			highlightTextContentsBySubstring(labels, searchString);
		}, [searchString]);

		const noParamsChecked = SearchStore.peopleParams.every((param) => !param.checked);

		return (
			<div className="params__form">
				{!isInForm && !noParamsChecked && (
					<RadioButtons
						options={radioOptsOR}
						checked={conditionOR}
						onChange={(e) => onRadioChange(e, "or")}
					/>
				)}
				<div className="params__search">
					<SearchField
						ref={searchFieldRefference}
						className=""
						placeholder="Поиск по параметрам"
						onChange={(e) => {
							onSearchInputChange(e.target.value, "people");
						}}
						onSearch={() => {
							onSearchInputChange(searchString, "people");
						}}
						value={searchString}
					/>
				</div>
				<ul className={`params__list overflow${paramsAreFetching ? " loading" : ""}`} ref={paramsListReference}>
					{paramsAreFetching ? (
						<div className="params__loading">
							<SkeletonLine width={375} height={25} translucent />
							<SkeletonLine width={375} height={25} translucent />
							<SkeletonLine width={375} height={25} translucent />
							<SkeletonLine width={375} height={25} translucent />
							<SkeletonLine width={375} height={25} translucent />
							<SkeletonLine width={375} height={25} translucent />
						</div>
					) : peopleFoundParams.length !== 0 ? (
						peopleFoundParams.map((item) => (
							<ParamElement
								key={item.title}
								checkBoxId={item.title}
								title={item.title}
								checked={item.checked}
								onChange={onParameterChange}
								searchType="people"
								isFoundParameter
							/>
						))
					) : peopleParams.length !== 0 ? (
						peopleParams.map((item) => (
							<ParamElement
								key={item.title}
								checkBoxId={item.title}
								title={item.title}
								checked={item.checked}
								onChange={onParameterChange}
								searchType="people"
							/>
						))
					) : (
						<Empty isSearch={false} isInModal />
					)}
				</ul>
				{!isInForm && (
					<RadioButtons
						options={radioOptsNO}
						checked={conditionNO}
						onChange={(e) => onRadioChange(e, "no")}
					/>
				)}
			</div>
		);
	})
);

export default PeopleTab;
