import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";

export const PublicRoute = inject("ApplicationStore")(
  observer(({ ApplicationStore, ...props }) => {
    const { loggedInUser } = ApplicationStore;
    console.log("loggedInUser", loggedInUser);
    return loggedInUser ? <Redirect to="/" /> : <Route {...props} />;
  })
);
