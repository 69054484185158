import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

import PesonHistoryView from "../PersonHistoryView";

import { parseDate } from "helpers/formattedDate";

const PeopleHistory = inject(
  "PaginationStore",
  "PeopleFormStore",
  "ApplicationStore"
)(
  observer(({ PeopleFormStore, PaginationStore, ApplicationStore, id }) => {
    const {
      reset,
      personHistory,
      historyCount,
      fetchPersonHistory,
    } = PeopleFormStore;
    const {
      startIndex,
      perPage,
      resetAll,
      paginate,
      changePerPage,
      currentPage,
      resetPagination,
    } = PaginationStore;
    const { isLoading } = ApplicationStore;

    const [isCleared, setCleared] = useState(false);
    const [historyItem, setHistoryItem] = useState(null);
    const [isHistoryView, setIsHistoryView] = useState(false);

    useEffect(() => {
      isCleared && fetchPersonHistory({ startIndex, perPage, id });
      return () => {
        reset();
      };
    }, [fetchPersonHistory, id, isCleared, perPage, reset, startIndex]);

    useEffect(() => {
      resetPagination();
    }, [resetPagination, perPage]);

    useEffect(() => {
      resetAll();
      setCleared(true);
    }, [resetAll, setCleared]);

    const classnames = {
      header: "history-header",
      itemWrapper: "history-item",
    };

    const header = (
      <>
        <div>Дата</div>
        <div>Изменил(-а)</div>
        <div>Описание</div>
      </>
    );

    const history =
      personHistory &&
      personHistory.map((item) => {
        const { dateTimeOfChange, changes, user } = item;
        return (
          <>
            <p className="table-value">
              {dateTimeOfChange && parseDate(dateTimeOfChange.slice(0, 10))}
            </p>
            <p className="table-value">{user}</p>
            <div
              className="history-item__link"
              onClick={() => {
                setHistoryItem(item);
                setIsHistoryView(true);
              }}
            >
              <span className="page-link">
                {changes ? changes.split(/\s(?=[А-Я])/).join(", ") : "—"}
              </span>
            </div>
          </>
        );
      });

    const list =
      personHistory && personHistory.length !== 0 ? (
        <div className="params-wrapper">
          <Table header={header} items={history} classnames={classnames} />
          <Pagination
            perPage={perPage}
            total={historyCount}
            currentPage={currentPage + 1}
            handlePaginate={(page) => paginate(page - 1)}
            isShowPerPage
            handleChangePerPage={(count) => changePerPage(count)}
          />
        </div>
      ) : (
        <Empty isSearch={false} />
      );

    return (
      <>
        {isLoading ? (
          <LoaderSpinner />
        ) : isHistoryView && historyItem ? (
          <PesonHistoryView
            person={historyItem}
            resetView={() => {
              setHistoryItem(null);
              setIsHistoryView(false);
              fetchPersonHistory({ startIndex, perPage, id });
            }}
          />
        ) : (
          isCleared && list
        )}
      </>
    );
  })
);

export default PeopleHistory;
