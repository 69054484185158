import { observable, decorate, action, computed, toJS } from "mobx";
import app from "../../utils/axiosConfig";
import itemsStore from "../common/itemsStore";

class UploadFilesResultsStore {
  constructor() {
    this.data = null;
  }

  getUploadFilesResults = async (md5, startIndex, itemsCount) => {
    app
      .get(
        `/People/importresult?md5=${md5}&startIndex=${startIndex}&itemsCount=${itemsCount}`
      )
      .then((res) => {
        const { data } = res;
        this.data = data;
      });
  };

  closeResultsModal = () => {
    itemsStore.setPeopleImportFileIsLoading(false);
    itemsStore.setCurrentUploadingFilename("");
    itemsStore.setSource(null);
    itemsStore.setFile(null);
    itemsStore.setMD5("");
    itemsStore.setPeopleImportFileProgress(0);
    itemsStore.savePeopleFileImport();
    itemsStore.isFileUploaded = false;
    itemsStore.fileInfo = "";
  };
}

decorate(UploadFilesResultsStore, {
  data: observable,

  getUploadFilesResults: action,
  closeResultsModal: action,
});

export default new UploadFilesResultsStore();
