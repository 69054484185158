import React from "react";
import { observer, inject } from "mobx-react";

import "./index.scss";

import { ReactComponent as Arrow } from "assets/svg/memoized-search-arrow.svg";

import routing from "stores/routing";

import { SkeletonLine } from "../Skeleton";

const trimStringToLength = (string, targetLength) => {
	return string.length >= targetLength ? string.substr(0, targetLength) + "..." : string;
};

const RecentlySearchBlocks = inject(
	"SearchStore",
	"PaginationStore",
	"ItemsStore",
	"ApplicationStore"
)(
	observer(({ SearchStore, PaginationStore, ItemsStore, ApplicationStore, data, category }) => {
		const { setSubmitted, setRedirected, createDisplayedParams, buildPostParams, isSearchesLoading } = SearchStore;
		const { startIndex, perPage, resetPagination } = PaginationStore;
		const { fetchWithParameters } = ItemsStore;
		const { isLoading } = ApplicationStore;

		const recentlySearchBlocksItem = data.map((el, index) => {
			return (
				<div
					className="item"
					key={index}
					onClick={() => {
						resetPagination();
						createDisplayedParams(index, category);
						buildPostParams();
						setSubmitted(true);
						setRedirected(true);
						fetchWithParameters("all", startIndex, perPage);
						routing.push("/people");
					}}>
					<span className="caption">
						<span>{trimStringToLength(el.string, 45)}</span>
						<Arrow className="arrow" />
					</span>
				</div>
			);
		});

		return (
			<>
				{isLoading && isSearchesLoading ? (
					<div className="searches__loading">
						<SkeletonLine width={280} height={95} translucent />
						<SkeletonLine width={280} height={95} translucent />
						<SkeletonLine width={280} height={95} translucent />
						<SkeletonLine width={280} height={95} translucent />
					</div>
				) : (
					<div className="recently-search-wrapper">{recentlySearchBlocksItem}</div>
				)}
			</>
		);
	})
);

export default RecentlySearchBlocks;
