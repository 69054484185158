import React from "react";
import { inject, observer } from "mobx-react";

import AddButton from "common/components/Buttons/AddButton";

import { FEW_PEOPLE_JURY_LIST } from "helpers/errors";

import "./index.scss";

const FormListButton = inject("JuryStore")(
  observer(({ JuryStore, toggleModal, item }) => {
    const {
      currentJuryList,
      setCurrentJuryList,
      trackProgressFormJuryList,
    } = JuryStore;

    const isCurrent = item.id === currentJuryList.id;

    if (item.isFormed && item.isPeopleEnough) {
      return <div>{`Список сформировался ${item.formingDate}`}</div>;
    }

    if (item.isForming) {
      return (
        <AddButton
          className="jury-form-button"
          onClick={() => toggleModal(true)}
        >
          Список формируется...
        </AddButton>
      );
    }

    return (
      <>
        {!item.isPeopleEnough && (
          <p className="jury-form-button__error">{FEW_PEOPLE_JURY_LIST}</p>
        )}
        {isCurrent && currentJuryList.isForming ? (
          <AddButton
            className="jury-form-button"
            onClick={() => {
              if (item.id === currentJuryList.id) {
                toggleModal(true);
              }
            }}
          >
            Список формируется...
          </AddButton>
        ) : (
          <AddButton
            className="jury-form-button"
            onClick={() => {
              if (!currentJuryList.isForming) {
                toggleModal(true);
                setCurrentJuryList(item);
                trackProgressFormJuryList();
              }
              return;
            }}
          >
            {!item.isPeopleEnough ? "Повторить" : "Отобрать в список"}
          </AddButton>
        )}
      </>
    );
  })
);

export default FormListButton;
