import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "ie11-custom-properties";
import "promise-polyfill/src/polyfill";
import "url-polyfill";
import "url-search-params-polyfill";
import "text-encoding-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "assets/scss/main.scss";
import * as serviceWorker from "./serviceWorker";

if (typeof window["TextEncoder"] !== "function") {
  const TextEncodingPolyfill = require("text-encoding-polyfill");
  window["TextEncoder"] = TextEncodingPolyfill.TextEncoder;
  window["TextDecoder"] = TextEncodingPolyfill.TextDecoder;
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
