import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { useHistory } from "react-router";

import "./index.scss";

import { formDateToStringDate } from "helpers/formattedDate";

import Table from "common/components/Table";
import ImportButton from "common/components/Buttons/ImportButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import UnderlinedTabs from "components/UnderlinedTabs";

import SearchHeader from "components/Header/Search";
import Empty from "components/Empty";
import Modal from "components/Modal";

const header = (
  <>
    <div>ФИО</div>
    <div>Дата рождения</div>
    <div>Суд</div>
    <div>Статус</div>
  </>
);

const classnames = {
  header: "jurylist-header",
  itemWrapper: "jurylist-item",
};

const getMappedItems = (mappedItems, JuryListId) => {
  return mappedItems?.map((item) => {
    const {
      personId,
      surname = "",
      name = "",
      patronymic = "",
      birthDate,
      gender,
      isActive,
      exclusionDate,
      district,
      changeDate,
    } = item;

    const formGender = gender === "Male" ? "М" : "Ж";

    const activeText = formGender === "М" ? "Активен" : "Активна";
    const excludeText = formGender === "М" ? "Исключен" : "Исключена";
    const changedText = formGender === "М" ? "Изменен" : "Изменена";

    const fullName = [surname, name, patronymic].join(" ").trim();
    const status =
      isActive && changeDate
        ? `${changedText}, ${formDateToStringDate(changeDate)}`
        : isActive
        ? activeText
        : `${excludeText}, ${formDateToStringDate(exclusionDate)}`;

    return (
      <>
        <div className="jurylist-field" key={personId}>
          <Link
            to={`/jury/${JuryListId}/person/${personId}`}
            className="page-link jury-list__table-link"
          >
            {fullName}
          </Link>
          <p className="table-value">{formDateToStringDate(birthDate)}</p>
          <p className="table-value">{district ? district : "-"}</p>
          <p className="table-value">{status}</p>
        </div>
      </>
    );
  });
};

const getUnderlinedTabsData = (list) => {
  return list.map(({ id, dateFrom, dateTo }) => ({
    id,
    title: `${dateFrom} - ${dateTo}`,
  }));
};

const JuryList = inject(
  "JuryStore",
  "PaginationStore",
  "TabbedSwitchersStore",
  "ItemsStore",
  "ModalStore",
  "ApplicationStore"
)(
  observer(
    ({
      JuryStore,
      PaginationStore,
      TabbedSwitchersStore,
      ModalStore,
      ItemsStore,
      ApplicationStore,
      type,
      juryListId,
    }) => {
      const { selectTabById, selectedTab } = TabbedSwitchersStore;
      const {
        fetchJuryLists,
        juryLists,
        exportJuryList,
        handleCurrentListSearchString,
      } = JuryStore;

      const {
        currentPage,
        perPage,
        changePerPage,
        paginate,
        resetAll,
        startIndex,
      } = PaginationStore;

      const { isLoading } = ApplicationStore;

      const history = useHistory();

      const { isExportJuryModalOpen, toggleExportJuryListModal } = ModalStore;

      const actualJuryLists = juryLists.filter((list) => list.current);

      const {
        items,
        reset,
        fetchItems,
        count,
        itemsAreLoading,
        setSearchActivation,
        setLastSearchStringRequest,
        setSearchString,
      } = ItemsStore;

      const loaderOrEmpty = itemsAreLoading ? <LoaderSpinner /> : <Empty />;
      const loaderOrEmptyLists = isLoading ? <LoaderSpinner /> : <Empty />;

      useEffect(() => {
        fetchJuryLists();
        if (
          juryListId === "undefined" &&
          actualJuryLists &&
          actualJuryLists[0]
        ) {
          selectTabById(actualJuryLists[0]?.id);
        } else {
          selectTabById(juryListId);
        }

        return () => {
          reset();
          setSearchActivation(false);
          setLastSearchStringRequest();
          selectTabById("");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        console.log("here6");
        history.push(`/jury/${type}/${selectedTab}`);
      }, [history, selectedTab, type]);

      useEffect(() => {
        console.log("here7");
        if (selectedTab) {
          fetchItems(
            `/JuryLists/${selectedTab}/${type}`,
            "get",
            startIndex,
            perPage
          );
        }
      }, [startIndex, perPage, reset, selectedTab, fetchItems, type]);

      useEffect(() => {
        console.log("here8");
        setSearchActivation(false);
        setLastSearchStringRequest();
        setSearchString("");
        handleCurrentListSearchString(type, selectedTab);
        resetAll();
        changePerPage(50);
        if (selectedTab) {
          fetchItems(`/JuryLists/${selectedTab}/${type}`, "get", 0, 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [resetAll, selectedTab, type]);

      return (
        <div className="people-wrapper">
          <>
            {actualJuryLists && actualJuryLists.length !== 0 ? (
              <>
                <div className="jury-list__search-export">
                  <div
                    onClick={() => toggleExportJuryListModal(true)}
                    className="jury-list__export-btn-wrapper"
                  >
                    <ImportButton className="jury-list__export-btn">
                      <span>Экспорт в файл</span>
                    </ImportButton>
                  </div>
                  <SearchHeader showSearches={false} searchType="jury" />
                </div>

                <UnderlinedTabs
                  data={getUnderlinedTabsData(actualJuryLists)}
                  selectedTab={selectedTab}
                  onSelectTab={selectTabById}
                />
                {items && items.length !== 0 && !itemsAreLoading ? (
                  <>
                    <Table
                      header={header}
                      items={getMappedItems(items, selectedTab)}
                      classnames={classnames}
                    />
                    <Pagination
                      perPage={perPage}
                      total={count}
                      currentPage={currentPage + 1}
                      handlePaginate={(page) => paginate(page - 1)}
                      isShowPerPage
                      handleChangePerPage={(count) => changePerPage(count)}
                    />
                  </>
                ) : (
                  <div>{loaderOrEmpty}</div>
                )}
              </>
            ) : (
              <div className="jury-list__empty">{loaderOrEmptyLists}</div>
            )}
          </>
          {isExportJuryModalOpen && (
            <Modal
              callback={exportJuryList.bind(null, type, selectedTab)}
              type="exportJuryList"
            />
          )}
        </div>
      );
    }
  )
);
export default JuryList;
