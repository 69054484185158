import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

import AssignParameters from "components/Modal/ParametersModalContent/AssignParameter";

const ModalAssignParameter = inject("SearchStore")(
  observer(({ onCancel, onAssign, peopleCount, SearchStore }) => {
    const {
      resetSearch,
      resetChecked,
      resetFetchedParamsFlags,
      noParametersChecked,
    } = SearchStore;

    useEffect(() => {
      resetSearch();
      resetChecked("people");
    }, [resetSearch, resetChecked]);

    useEffect(() => () => resetFetchedParamsFlags(), [resetFetchedParamsFlags]);

    const title = `Назначить параметр ${peopleCount}`;

    return (
      <div className="modal">
        <p className="modal__title">{title}</p>
        <AssignParameters />
        <div className="modal__button-group">
          <SubmitButton
            className="modal__button"
            disabled={noParametersChecked}
            onClick={() => {
              onAssign();
              onCancel(false);
              SearchStore.resetExcludedOnCancel();
            }}
          >
            Назначить
          </SubmitButton>
          <CancelButton
            className="modal__button"
            onClick={() => {
              onCancel(false);
              SearchStore.resetExcludedOnCancel();
            }}
          >
            Отмена
          </CancelButton>
        </div>
      </div>
    );
  })
);

export default ModalAssignParameter;
