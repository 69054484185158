import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { genders } from "helpers/dictionaries";

import "./index.scss";

import DeleteButton from "common/components/Buttons/DeleteButton";
import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import Datepicker from "common/components/Inputs/Datepicker";
import ParamsButton from "common/components/Buttons/ParamsButton";
import BlueImportButton from "common/components/Buttons/BlueImportButton";

import LoaderSpinner from "components/Loader";
import appStore from "../../../../stores/appStore";

const JuryPersonInformation = inject(
  "PaginationStore",
  "ApplicationStore",
  "ItemsStore",
  "JuryPersonStore",
  "ModalStore"
)(
  observer(
    ({
      JuryPersonStore,
      ApplicationStore,
      ModalStore,
      personId,
      juryListId,
    }) => {
      const { juryPerson, fetchJuryPerson, formJuryExcerpt } = JuryPersonStore;

      const {
        toggleManageJuryPersonModal,
        toggleEditExclusionJuryPersonModal,
      } = ModalStore;

      const { isLoading } = ApplicationStore;

      const region = juryPerson?.region ? juryPerson?.region : "";
      const street = juryPerson?.street ? juryPerson?.street : "";
      const hata = juryPerson?.appartamentNumber
        ? juryPerson?.appartamentNumber
        : "";

      const buildingNumber = juryPerson?.buildingNumber
        ? juryPerson?.buildingNumber
        : "";

      const address = Boolean(region && street && buildingNumber)
        ? `${region} р-н, ул. ${street}, д. ${buildingNumber}, кв. ${hata}`
        : "";

      useEffect(() => {
        fetchJuryPerson(juryListId, personId);
      }, [fetchJuryPerson, juryListId, personId]);

      return (
        <>
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <div>
              {!juryPerson.isActive &&
                juryPerson.reasons &&
                !appStore.isMainSpecMKU &&
                !appStore.isSpecialistMKU &&
                !appStore.isChiefSpecMKU &&
                !appStore.isCoChiefMKU &&
                !appStore.isChiefDepartmentMKU &&
                !appStore.isPassportMKU && (
                  <div className="jury-person__exclude-reasons-wrap">
                    <div>
                      <h3 className="jury-person__exclude-reasons-caption">
                        Исключён по причинам:
                      </h3>
                      <ul className="jury-person__exclude-reasons-list">
                        {juryPerson.reasons &&
                          juryPerson.reasons.map((item) => (
                            <li className="jury-person__exclude-reasons-item">
                              {item.title}
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="jury-person__exclude-reasons-btn">
                      <ParamsButton
                        className="isForm"
                        onClick={() => {
                          toggleEditExclusionJuryPersonModal(true);
                        }}
                      >
                        <p>Редактировать исключение</p>
                      </ParamsButton>
                    </div>
                  </div>
                )}

              {!appStore.isMainSpecMKU &&
                !appStore.isSpecialistMKU &&
                !appStore.isChiefSpecMKU &&
                !appStore.isCoChiefMKU &&
                !appStore.isChiefDepartmentMKU &&
                !appStore.isPassportMKU && (
                  <div
                    onClick={() => {
                      toggleManageJuryPersonModal(true);
                    }}
                  >
                    {juryPerson.isActive ? (
                      <DeleteButton className="page-link">
                        Исключить из списка присяжных
                      </DeleteButton>
                    ) : (
                      <BlueImportButton className="page-link">
                        Включить в список присяжных
                      </BlueImportButton>
                    )}
                  </div>
                )}

              <form className="jury-person__form">
                <div className="jury-person__fields-wrapper">
                  <div className="jury-person__field">
                    <FloatLabel value={juryPerson.courtName} label="Суд">
                      <TextInput
                        type="text"
                        name="court"
                        autoComplete="off"
                        value={juryPerson.courtName}
                        readOnly
                        disabled
                        className="jury-person__input jury-person__input--court"
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel value={juryPerson.surname} label="Фамилия">
                      <TextInput
                        type="text"
                        name="surname"
                        autoComplete="off"
                        value={juryPerson.surname}
                        readOnly
                        disabled
                        className="jury-person__input"
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel value={juryPerson.name} label="Имя">
                      <TextInput
                        type="text"
                        name="name"
                        autoComplete="off"
                        value={juryPerson.name}
                        readOnly
                        disabled
                        className="jury-person__input"
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel value={juryPerson.patronymic} label="Отчество">
                      <TextInput
                        readOnly
                        disabled
                        type="text"
                        name="patronymic"
                        autoComplete="off"
                        value={juryPerson.patronymic}
                        className="jury-person__input"
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel
                      value={juryPerson.birthDate}
                      label="Дата рождения"
                    >
                      <Datepicker
                        disabled
                        name="dateFromNew"
                        date={new Date(juryPerson.birthDate)}
                        className={`form__field datepicker`}
                      />
                    </FloatLabel>
                  </div>
                  <div value={juryPerson.snils} className="jury-person__field">
                    <FloatLabel label="СНИЛС" value={juryPerson.snils}>
                      <TextInput
                        name="snils"
                        value={juryPerson.snils}
                        className={"jury-person__input"}
                        readOnly
                        disabled
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel label="Выберите пол" value={juryPerson.gender}>
                      <TextInput
                        readOnly
                        disabled
                        value={
                          Boolean(juryPerson.gender)
                            ? genders.find(
                                (gender) => gender.id === juryPerson.gender
                              ).name
                            : ""
                        }
                        className="jury-person__input"
                      />
                    </FloatLabel>
                  </div>
                  <div className="jury-person__field">
                    <FloatLabel value={address} label="Укажите адрес">
                      <TextInput
                        readOnly
                        disabled
                        type="text"
                        name="address"
                        autoComplete="off"
                        value={address}
                      />
                    </FloatLabel>
                  </div>
                </div>
              </form>
              <div>
                <Link
                  to={`/people/editperson/${personId}`}
                  className="page-link"
                >
                  <ParamsButton className="isForm jury-person__edit-person-btn">
                    Редактировать персональные данные
                  </ParamsButton>
                </Link>
              </div>
            </div>
          )}
        </>
      );
    }
  )
);
export default JuryPersonInformation;
