import React from "react";

import "./index.scss";
import { useHistory } from "react-router";

const CancelButton = (props) => {
  const history = useHistory();

  return (
    <button
      className={`button__cancel ${props.className || ""}`}
      type="button"
      onClick={() => (props.onClick ? props.onClick() : history.goBack())}
    >
      {props.children}
    </button>
  );
};

export default CancelButton;
