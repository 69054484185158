import { observable, decorate, action, autorun, computed } from "mobx";
import "mobx-react-lite/batchingForReactDom";
import routing from "./routing";

import { roles as hardRoles } from "../helpers/roles";

class ApplicationStore {
  isLoading = false;
  isUploading = false;
  isSuccess = false;
  isSubmitted = false;
  error = null;
  loggedInUser = null;
  mode = "";

  constructor() {
    this.load();
    autorun(this.save);
  }

  save = () =>
    window.localStorage.setItem(
      ApplicationStore.name,
      JSON.stringify({
        loggedInUser: this.loggedInUser,
      })
    );

  load = () => {
    Object.assign(
      this,
      JSON.parse(window.localStorage.getItem(ApplicationStore.name) || "{}")
    );
  };

  hasOnlyWhiteSpaces = (field) => !/\S/.test(field);

  setGlobalFieldValue = (field, value) => {
    this[field] = value;
  };

  setLoading = (loading) => {
    this.isLoading = loading;
  };

  setUploading = (uploading) => {
    this.isUploading = uploading;
  };

  setError = (err) => {
    this.error = err.message;
    if (err.response?.status === 500) {
      routing.push(`/500`);
    }
    if (err.response?.status === 401) {
      this.setGlobalFieldValue("loggedInUser", null);
      localStorage.removeItem(ApplicationStore.loggedInUser);
      routing.push("/login");
    }
  };

  get isAdmin() {
    return this.loggedInUser?.roles?.includes(hardRoles.Admin);
  }

  get isAdminKokr() {
    return this.loggedInUser?.roles?.includes(hardRoles.AdminKOKR);
  }

  get isAdminRegion() {
    return this.loggedInUser?.roles?.includes(hardRoles.AdminRegion);
  }

  get isMainSpecMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.MainSpecMKU);
  }

  get isSpecialistMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.SpecialistMKU);
  }

  get isChiefSpecMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.ChiefSpecMKU);
  }

  get isCoChiefMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.CoChiefMKU);
  }

  get isChiefDepartmentMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.ChiefDepartmentMKU);
  }

  get isPassportMKU() {
    return this.loggedInUser?.roles?.includes(hardRoles.PassportMKU);
  }
}

decorate(ApplicationStore, {
  isLoading: observable,
  isUploading: observable,
  isSuccess: observable,
  isSubmitted: observable,
  error: observable,
  loggedInUser: observable,
  mode: observable,

  isAdmin: computed,
  isAdminKokr: computed,
  isAdminRegion: computed,
  isMainSpecMKU: computed,

  hasOnlyWhiteSpaces: action,
  setGlobalFieldValue: action,
  setError: action,
  setLoading: action,
  setChainLoading: action,
  setUploading: action,
  load: action,
});

export default new ApplicationStore();
