import React, { useEffect } from "react";

import { ReactComponent as Logo } from "assets/svg/authlogo.svg";

import "./index.scss";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";

import { observer, inject } from "mobx-react";

import useDocumentTitle from "hooks/useDocumentTitle";

const SignIn = inject(
  "AuthStore",
  "ApplicationStore"
)(
  observer(({ AuthStore, ApplicationStore }) => {
    const { user, errors, setUser, reset, login } = AuthStore;
    const { isUploading, setGlobalFieldValue } = ApplicationStore;

    useDocumentTitle("Вход");

    useEffect(() => {
      setGlobalFieldValue("mode", "LOG_IN");
      return () => {
        reset();
        setGlobalFieldValue("mode", "");
        setGlobalFieldValue("isSubmitted", false);
      };
    }, [reset, setGlobalFieldValue]);

    const handleSubmit = (e) => {
      e.preventDefault();
      login();
    };

    return (
      <>
        <div className="container__card">
          <form className="card__form" onSubmit={handleSubmit} noValidate>
            <header className="form__header">
              <Logo className="form__logo" />
              <h1 className="form__title page-title">Вход</h1>
            </header>
            <div className="form__fields">
              <FloatLabel label="Логин" value={user.login}>
                <TextInput
                  className={`form__input ${errors.login && "warning"}`}
                  name="email"
                  type="text"
                  autoComplete="usename"
                  value={user.login}
                  onChange={(e) => setUser("login", e.target.value)}
                  required
                />
              </FloatLabel>
              {errors.login && <p className="warning-text">{errors.login}</p>}

              <FloatLabel label="Пароль" value={user.password}>
                <TextInput
                  className={`form__input ${errors.password && "warning"}`}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={user.password}
                  onChange={(e) => setUser("password", e.target.value)}
                  required
                />
              </FloatLabel>
              {errors.password && (
                <p className="warning-text">{errors.password}</p>
              )}

              <SubmitButton className={"form__button"} disabled={isUploading}>
                {isUploading ? "Загрузка..." : "Вход"}
              </SubmitButton>
            </div>
          </form>
        </div>
      </>
    );
  })
);

export default SignIn;
