import React from "react";
import { observer, inject } from "mobx-react";

import Tabs from "components/Tabs";

import JuryList from "pages/Jury/JuryList";
import AddEditJury from "pages/Jury/AddEditJury";
import JuryCommon from "pages/Jury/JuryCommon";

import useDocumentTitle from "hooks/useDocumentTitle";

const Jury = inject(
  "JuryStore",
  "TabbedSwitchersStore"
)(
  observer(({ match }) => {
    console.log({ match });

    const tabs = [
      {
        title: "Общий список присяжных",
        path: `/jury/common`,
        content: <JuryCommon />,
      },
      {
        title: "Основной список присяжных",
        path: `/jury/basic/${match?.params.juryListId}`,
        content: (
          <JuryList juryListId={match?.params.juryListId} type={"basic"} />
        ),
      },
      {
        title: "Запасной список присяжных",
        path: `/jury/sub/${match?.params.juryListId}`,
        content: (
          <JuryList juryListId={match?.params.juryListId} type={"sub"} />
        ),
      },
      {
        title: "Списки присяжных",
        path: "/jury/lists",
        content: <AddEditJury />,
      },
    ];

    useDocumentTitle("Присяжные");

    return (
      <div className="wrapper__content">
        <section className="section">
          <div className="container">
            <h1 className="page-title">Присяжные</h1>
            <Tabs items={tabs} withRouter />
          </div>
        </section>
      </div>
    );
  })
);

export default Jury;
