import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import Alert from "common/components/Alerts/Success";

import useDocumentTitle from "hooks/useDocumentTitle";

const Profile = inject(
  "AlertStore",
  "ApplicationStore",
  "AuthStore"
)(
  observer(({ ApplicationStore, AuthStore, AlertStore }) => {
    const { isUploading, loggedInUser, setGlobalFieldValue } = ApplicationStore;
    const {
      logout,
      password,
      newPassword,
      repeatPassword,
      changePassword,
      setPassword,
      errors,
      reset,
    } = AuthStore;

    const {
      isProfileSuccesAlertOpen,
      toggleProfileSuccesAlertOpen,
    } = AlertStore;

    const [isAlertShow, setAlertShow] = useState(false);

    useEffect(() => {
      if (isProfileSuccesAlertOpen) {
        setAlertShow(true);
      }
    }, [isProfileSuccesAlertOpen]);

    useEffect(() => {
      setGlobalFieldValue("mode", "CHANGE_PASSWORD");
      return () => {
        reset();
        setGlobalFieldValue("mode", "");
        setGlobalFieldValue("isSubmitted", false);
        toggleProfileSuccesAlertOpen(false);
      };
    }, [reset, setGlobalFieldValue]);

    useDocumentTitle("Личный кабинет");

    const handleSubmit = (e) => {
      e.preventDefault();
      changePassword();
    };

    return (
      <div className="wrapper__content">
        <section className="section">
          <div className="container">
            <h1 className="page-title">Личный кабинет</h1>
            {isAlertShow && (
              <Alert
                message={"Данные успешно обновлены"}
                toggle={(open) => {
                  toggleProfileSuccesAlertOpen(open);
                  setAlertShow(open);
                }}
              />
            )}
            <div className="profile">
              <div className="profile__info">
                <label className="label">Данные пользователя</label>
                <p className="profile-text margin-top-32">
                  Фамилия: {loggedInUser?.surName}
                </p>
                <p className="profile-text">Имя: {loggedInUser?.name}</p>
                {loggedInUser?.patronymic && (
                  <p className="profile-text">
                    Отчество: {loggedInUser?.patronymic}
                  </p>
                )}

                <button
                  className="profile-button margin-top-22 button__submit"
                  onClick={logout}
                  disabled={isUploading}
                >
                  Выйти
                </button>
              </div>
              <form className="form" onSubmit={handleSubmit} noValidate>
                <label className="label margin-bottom-32">
                  Изменить пароль
                </label>

                <FloatLabel label="Текущий пароль" value={password}>
                  <TextInput
                    type="password"
                    name={"pass"}
                    value={password}
                    onChange={(e) => setPassword("password", e.target.value)}
                    className={`form__field ${errors.pass ? "warning" : ""}`}
                    disabled={isUploading}
                  />
                </FloatLabel>
                {errors.pass && (
                  <p className="warning-text margin-bottom-12">{errors.pass}</p>
                )}

                <FloatLabel label="Новый пароль" value={newPassword}>
                  <TextInput
                    type="password"
                    name="newpass"
                    value={newPassword}
                    onChange={(e) => setPassword("newPassword", e.target.value)}
                    className={`form__field ${errors.newpass ? "warning" : ""}`}
                    disabled={isUploading}
                  />
                </FloatLabel>
                {errors.newpass && (
                  <p className="warning-text margin-bottom-12">
                    {errors.newpass}
                  </p>
                )}

                <FloatLabel label="Повторите пароль" value={newPassword}>
                  <TextInput
                    type="password"
                    name="repeat"
                    value={repeatPassword}
                    onChange={(e) =>
                      setPassword("repeatPassword", e.target.value)
                    }
                    className={`form__field ${errors.repeat ? "warning" : ""}`}
                    required
                  />
                </FloatLabel>
                {errors.repeat && (
                  <p className="warning-text margin-bottom-12">
                    {errors.repeat}
                  </p>
                )}

                <div className="form__buttons">
                  <SubmitButton
                    className="profile-button"
                    disabled={isUploading}
                  >
                    {isUploading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  })
);

export default Profile;
