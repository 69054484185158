import { action, observable, decorate } from "mobx";

class TabbedSwitchersStore {
  selectedTab = "";

  selectTabById = (tabId) => {
    if (tabId === this.selectedTab) {
      return;
    }
    this.selectedTab = tabId;
  };
}

decorate(TabbedSwitchersStore, {
  selectedTab: observable,
  selectTabById: action,
});

export default new TabbedSwitchersStore();
