import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./index.scss";

import Table from "common/components/Table";
import Checkbox from "common/components/Inputs/Checkbox";

import Empty from "components/Empty";
import Modal from "components/Modal";
import LoaderSpinner from "components/Loader";
import SimpleMenu from "components/SimpleMenu";
import Pagination from "components/Pagination";

import { parseDate } from "helpers/formattedDate";
import { toJS } from "mobx";

const PeopleList = inject(
  "ItemsStore",
  "PaginationStore",
  "ModalStore",
  "ActivitiesStore",
  "PeopleParamsStore",
  "SearchStore",
  "ApplicationStore"
)(
  observer(
    ({
      ItemsStore,
      PaginationStore,
      ModalStore,
      ActivitiesStore,
      isSearch,
      PeopleParamsStore,
      SearchStore,
      ApplicationStore,
    }) => {
      const {
        items,
        reset,
        fetchWithParameters,
        count,
        isChecked,
        checkedAll,
        selectedItems,
        selectItem,
        selectAll,
        exportFoundPeopleToExcel,
        itemsAreLoading,
        foundPeopleAreExporting,
      } = ItemsStore;
      const {
        currentPage,
        perPage,
        changePerPage,
        paginate,
        resetAll,
        startIndex,
      } = PaginationStore;
      const { inviteSelectedPeople } = ActivitiesStore;
      const { assignParameterToSelectedPeople } = PeopleParamsStore;
      const {
        toggle,
        isSubmit,
        toggleInvitePeopleModal,
        isInvitePeopleModalOpen,
        isAssignParameterModalOpen,
        toggleAssignParameterModal,
        resetModal,
        isOpen,
      } = ModalStore;
      const {
        addSavedSearch,
        isSaved,
        resetExcludedOnCancel,
        excluded,
        searchIsSaving,
      } = SearchStore;

      const { isAdminKokr } = ApplicationStore;

      const [isCleared, setCleared] = useState(false);

      useEffect(() => () => resetModal(), [resetModal]);

      useEffect(() => {
        isCleared && fetchWithParameters("people", startIndex, perPage);
        return () => reset();
      }, [fetchWithParameters, startIndex, perPage, reset, isCleared]);

      useEffect(() => {
        resetAll();
        setCleared(true);
      }, [resetAll, setCleared]);

      useEffect(() => {
        if (isSubmit) {
          assignParameterToSelectedPeople(startIndex, perPage);
        }
      }, [isSubmit, assignParameterToSelectedPeople, startIndex, perPage]);

      const classnames = {
        header: !isAdminKokr
          ? "peoplelist-header"
          : "peoplelist-header--withoutParams",
        itemWrapper: !isAdminKokr
          ? "peoplelist-item"
          : "peoplelist-item--withoutParams",
      };

      const header = (
        <>
          <div className="people__checkbox top">
            <Checkbox id="all" checked={checkedAll} onChange={selectAll} />
          </div>
          <div>ФИО</div>
          <div>Дата рождения</div>
          <div>Район</div>
          <div>Адрес регистрации</div>
          {!isAdminKokr && <div>Статус</div>}
        </>
      );

      const mappedItems = items?.map((item) => {
        const {
          id,
          surName = "",
          name = "",
          region,
          patronymic = "",
          birthDate,
          street,
          buildingNumber,
          appartamentNumber,
          Parameters = [],
        } = item;
        const fullName = [surName, name, patronymic].join(" ").trim();
        const address =
          street && street.includes("р-н.") ? street.split("р-н.") : street;
        const onlyStreet =
          address && Array.isArray(address) && address[1].split(", ")[1];
        return (
          <>
            <div className="people__checkbox">
              <Checkbox
                id={id}
                checked={isChecked(item)}
                onChange={() => selectItem(item)}
              />
            </div>
            <span className="peoplelist-item__person-name">
              <Link className="page-link" to={`/people/editperson/${id}`}>
                {fullName}
              </Link>
            </span>
            <p className="table-value">
              {(birthDate && parseDate(birthDate.slice(0, 10))) || ""}
            </p>
            <p className="table-value">{region || "—"}</p>
            <p className="table-value">
              {onlyStreet || (street && street)}
              {` ${buildingNumber || ""}`}
              {appartamentNumber > 0 && `, кв. ${appartamentNumber}`}
              {!street && !buildingNumber && !appartamentNumber && "—"}
            </p>
            {!isAdminKokr && (
              <p className="table-value">
                {item.isActive ? "Активный" : "Деактивирован"}
              </p>
            )}
          </>
        );
      });

      const selectedPeopleCount = selectedItems.length;

      const inviteToActivityButtonCaption = `Пригласить на мероприятие (${selectedPeopleCount})`;
      const inviteToActivityButton =
        selectedPeopleCount > 0
          ? {
              caption: inviteToActivityButtonCaption,
              handler: () => {
                toggleInvitePeopleModal(true);
              },
            }
          : null;

      const assignParameterButtonCaption = `Назначить параметры (${selectedPeopleCount})`;
      const assignParameterButton =
        selectedPeopleCount > 0
          ? {
              caption: assignParameterButtonCaption,
              handler: () => {
                toggleAssignParameterModal(true);
              },
            }
          : null;

      const saveSearchButtonCaption = isSaved
        ? "Поиск сохранён"
        : searchIsSaving
        ? "Сохранение..."
        : "Сохранить поиск";

      const saveSearchButton =
        isSearch && excluded.length > 0
          ? {
              caption: saveSearchButtonCaption,
              handler: () => addSavedSearch(),
              disabled: isSaved || searchIsSaving,
            }
          : null;

      const xlsExportButtonTitle = foundPeopleAreExporting
        ? "Идёт экспорт..."
        : `Экспорт выделенных людей в Excel (${selectedPeopleCount})`;

      const xlsExport =
        selectedPeopleCount > 0
          ? {
              caption: xlsExportButtonTitle,
              disabled: foundPeopleAreExporting,
              handler: () => {
                exportFoundPeopleToExcel();
              },
            }
          : null;

      const simpleMenuData =
        count > 0
          ? [
              saveSearchButton,
              inviteToActivityButton,
              assignParameterButton,
              xlsExport,
            ]
          : [saveSearchButton];

      const parameterAssignitionMessage = `Назначить выбранные параметры для ${selectedPeopleCount} чел.?`;
      const modalWarning = (
        <Modal
          type="warning"
          message={parameterAssignitionMessage}
          onCancel={toggle}
        />
      );

      const modalInvite = (
        <Modal
          onCloseInviteModal={toggleInvitePeopleModal}
          type="inviteToActivity"
          peopleCount={`(${selectedPeopleCount} чел.)`}
          onInvite={inviteSelectedPeople}
        />
      );

      const modalAssign = (
        <Modal
          searchType="assignParameter"
          type="assignParameter"
          peopleCount={`(${selectedPeopleCount} чел.)`}
          onCancel={resetExcludedOnCancel}
          onAssign={() => {
            toggle(true);
          }}
        />
      );

      if (itemsAreLoading) {
        return <LoaderSpinner />;
      }

      const simpleMenu = <SimpleMenu data={simpleMenuData} />;

      if (items && items.length === 0) {
        return (
          <div className="people-wrapper">
            {simpleMenu}
            <Empty isSearch={isSearch} />
          </div>
        );
      }

      return (
        <div className="people-wrapper">
          {isCleared && (
            <>
              {isAssignParameterModalOpen && modalAssign}
              {isOpen && modalWarning}
              {isInvitePeopleModalOpen && modalInvite}
              {simpleMenu}
              <Table
                header={header}
                items={mappedItems}
                classnames={classnames}
              />
              <Pagination
                perPage={perPage}
                total={count}
                currentPage={currentPage + 1}
                handlePaginate={(page) => paginate(page - 1)}
                isShowPerPage
                handleChangePerPage={(count) => changePerPage(count)}
              />
            </>
          )}
        </div>
      );
    }
  )
);

export default PeopleList;
