import { observable, decorate, action, runInAction, set, computed, reaction } from "mobx";
import { EMPTY_ERR, INVALID_SYMBOLS_ERR } from "helpers/errors";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import autocompleteStore, { AutocompleteStore } from "./autocompleteStore";
import CompaniesStore from "./companiesStore";

class AreasStore {
	area = null;
	newArea = {
		caption: "",
		address: "",
		buildings: [],
		houseId: "",
	};
	buildings = [];
	buildingsIds = [];
	newBuildings = [];
	addresses = null;
	streetTitle = "";
	serverErr = "";
	addressesAreLoading = false;

	constructor() {
		this.areaAutocompleteStore = new AutocompleteStore();
	}

	get errors() {
		let error = {};
		if (!ApplicationStore.isSubmitted) {
			return error;
		}
		switch (ApplicationStore.mode) {
			case "ADD_AREA":
				if (!this.newArea.caption) {
					error["caption"] = EMPTY_ERR;
				} else if (this.serverErr) {
					error["caption"] = this.serverErr;
				} else if (!this.isValidAreaId(this.newArea.caption)) {
					error["caption"] = INVALID_SYMBOLS_ERR;
				}
				if (!this.selectedAreaId) {
					error["address"] = EMPTY_ERR;
				}
				break;
			case "EDIT_AREA":
				if (!this.selectedAreaId) {
					error["address"] = EMPTY_ERR;
				}
				break;
			default:
				break;
		}
		return error;
	}

	get peopleCount() {
		const buildings = ApplicationStore.mode === "EDIT_AREA" ? this.area?.buildings : this.newArea?.buildings;
		const filteredBuildings = buildings?.filter(
			(x, index, self) => index === self.findIndex((y) => y.location.buildingId === x.location.buildingId)
		);
		return filteredBuildings?.reduce((prev, curr) => prev + curr.peopleCount, 0);
	}

	get declinePeopleCount() {
		const cases = [2, 0, 1, 1, 1, 2];
		const declines = ["человек", "человека", "человек"];
		return declines[
			this.peopleCount % 100 > 4 && this.peopleCount % 100 < 20
				? 2
				: cases[this.peopleCount % 10 < 5 ? this.peopleCount % 10 : 5]
		];
	}

	noBuildingReaction = reaction(
		() => !this.selectedArea,
		() => (this.selectedAreaId = "")
	);

	isValidAreaId = (id) => {
		const regex = /^\d+$/i;
		return regex.test(id);
	};

	addBuilding = (isEdit) => {
		isEdit ? this.buildings.push("") : this.newBuildings.push("");
	};

	changeBuilding = (isEdit, index, value) => {
		isEdit ? (this.buildings[index] = value) : (this.newBuildings[index] = value);
	};

	onSelectBuilding = (isEdit, index, suggestion) => {
		if (isEdit) {
			if (suggestion.title) {
				this.buildings[index] = suggestion.title;
			} else if (suggestion.location) {
				this.buildings[index] = suggestion.location.street + ", " + suggestion.location.buildingNumber;
			}
		} else {
			if (suggestion.title) {
				this.newBuildings[index] = suggestion.title;
			} else if (suggestion.location) {
				this.newBuildings[index] = suggestion.location.street + ", " + suggestion.location.buildingNumber;
			}
		}
		this.resetAddressess();
	};

	onSelectArea = (suggestion) => {
		if (suggestion.title) {
			this.selectedArea = suggestion.title;
		} else if (suggestion.location) {
			this.selectedArea = this.streetTitleArea + ", " + suggestion.location.buildingNumber;
			this.selectedAreaId = suggestion.location.buildingId;
		}
		this.resetAddressess();
	};

	deleteBuilding = (isEdit, item) => {
		if (isEdit) {
			let index = this.buildings.findIndex((el) => el === item);
			this.buildings.splice(index, 1);
		} else {
			let index = this.newBuildings.findIndex((el) => el === item);
			this.newBuildings.splice(index, 1);
		}
	};

	deleteChosenBuilding = (isEdit, item) => {
		if (isEdit) {
			let index = this.area.buildings.findIndex((el) => el.location.buildingId === item.location.buildingId);
			this.area.buildings.splice(index, 1);
		} else {
			let index = this.newArea.buildings.findIndex((el) => el.location.buildingId === item.location.buildingId);
			this.newArea.buildings.splice(index, 1);
		}

		const buildingIndex = this.buildingsIds.findIndex((el) => el === item.location.buildingId);
		this.buildingsIds.splice(buildingIndex, 1);
	};

	changeArea = (key, field, value) => {
		set(key, field, value);
	};

	changeSelectedArea = (value) => {
		this.selectedArea = value;
	};

	resetServerError = () => {
		this.serverErr = "";
	};

	reset = () => {
		runInAction(() => {
			this.area = null;
			this.newArea.caption = "";
			this.newArea.address = "";
			this.newArea.houseId = "";
			this.newArea.buildings = [];
			this.buildings = [];
			this.buildingsIds = [];
			this.newBuildings = [];
			this.addresses = null;
			this.streetTitle = "";
			this.streetId = null;
			this.serverErr = "";
			this.company = null;

			this.addressesArea = null;
			this.streetTitleArea = "";
			this.streetIdArea = null;
			this.selectedArea = "";
			this.selectedAreaId = null;
		});
	};

	resetAddressess = () => {
		this.addresses = null;
		this.addressesArea = null;
	};

	resetStreet = () => {
		this.streetTitle = "";
		this.streetId = null;
	};

	getSelectedBuildingSuggestion = (isEdit, suggestion) => {
		if (suggestion.location) {
			suggestion.location.street = this.streetTitle;
			if (isEdit) {
				this.area.buildings.push(suggestion);
				this.buildings = [];
			} else {
				this.newArea.buildings.push(suggestion);
				this.newBuildings = [];
			}
			this.buildingsIds.push(suggestion?.location?.buildingId);
		} else {
			autocompleteStore.handleChange("currInput", suggestion.title);
			this.fetchAddresses(suggestion.title);
			autocompleteStore.handleChange("showSuggestions", true);
		}
	};

	getSelectedSuggestionArea = (isEdit, suggestion) => {
		if (suggestion.location) {
			suggestion.location.street = this.streetTitleArea;
			if (isEdit) {
				this.area.address = suggestion.title;
			} else {
				this.newArea.address = suggestion.title;
			}
		} else {
			this.areaAutocompleteStore.handleChange("currInput", suggestion.title);
			this.fetchAddressesArea(suggestion.title);
			this.areaAutocompleteStore.handleChange("showSuggestions", true);
		}
	};

	fetchArea = async (id) => {
		ApplicationStore.setLoading(true);
		await app
			.get(`/pollingstations/${id}`)
			.then((res) => {
				this.area = res.data;
				this.selectedArea = res.data.address;
				this.selectedAreaId = res.data.houseId;
				if (res.data.buildings.length) {
					this.buildingsIds = res.data.buildings.map((item) => item.location.buildingId);
				}
				ApplicationStore.setLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 400) {
					routing.push(`/404`);
				}
				ApplicationStore.setError(err);
			})
			.finally(() => {
				ApplicationStore.setLoading(false);
			});
	};

	fetchAddresses = async (searchString) => {
		searchString = encodeURIComponent(searchString);
		const url = `/buildings/searchaddreswithpeoplecount/searchfulladdress?searchString=${searchString}`;

		this.addressesAreLoading = true;
		await app
			.get(url)
			.then((res) => {
				if (res.data.houses.length && res.data.findedStreet) {
					this.addresses = res.data.houses;
					this.streetTitle = res.data.findedStreet.title;
					this.streetId = res.data.findedStreet.id;
				} else {
					this.addresses = res.data;
				}
			})
			.catch((err) => {
				ApplicationStore.setError(err);
			})
			.finally(() => {
				this.addressesAreLoading = false;
			});
	};

	fetchAddressesArea = async (searchString) => {
		searchString = encodeURIComponent(searchString);
		const url = `/buildings/searchaddreswithpeoplecount/searchfulladdress?searchString=${searchString}`;

		this.addressesAreLoading = true;
		await app
			.get(url)
			.then((res) => {
				if (res.data.houses.length && res.data.findedStreet) {
					this.addressesArea = res.data.houses;
					this.streetTitleArea = res.data.findedStreet.title;
					this.streetIdArea = res.data.findedStreet.id;
				} else {
					this.addressesArea = res.data;
				}
			})
			.catch((err) => {
				ApplicationStore.setError(err);
			})
			.finally(() => {
				this.addressesAreLoading = false;
			});
	};

	fetchAddresses = async (searchString) => {
		searchString = encodeURIComponent(searchString);
		const url = `/buildings/searchaddreswithpeoplecount/searchfulladdress?searchString=${searchString}`;

		this.addressesAreLoading = true;
		await app
			.get(url)
			.then((res) => {
				if (res.data.houses.length && res.data.findedStreet) {
					this.addresses = res.data.houses;
					this.streetTitle = res.data.findedStreet.title;
					this.streetId = res.data.findedStreet.id;
				} else {
					this.addresses = res.data;
				}
			})
			.catch((err) => {
				ApplicationStore.setError(err);
			})
			.finally(() => {
				this.addressesAreLoading = false;
			});
	};

	addArea = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			const filteredBuildings = this.buildingsIds.filter(
				(building, index, self) => building && self.indexOf(building) === index
			);
			ApplicationStore.setUploading(true);
			app.post(`/pollingstations`, {
				caption: this.newArea.caption,
				address: this.selectedArea,
				BuildingsIds: filteredBuildings,
				electionCompanyId: CompaniesStore.company.electionCompanyId,
				electionCompany: CompaniesStore.company,
				houseId: this.selectedAreaId,
			})
				.then((res) => {
					runInAction(() => {
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
						routing.push(`/handbooks/editstation/${res.data}`);
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};

	updateArea = (id) => {
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			const filteredBuildings = this.buildingsIds.filter(
				(building, index, self) => building && self.indexOf(building) === index
			);
			ApplicationStore.setUploading(true);
			app.put(`/pollingstations/${id}`, {
				id,
				caption: this.area.caption,
				address: this.selectedArea,
				BuildingsIds: filteredBuildings,
				electionCompanyId: this.area.electionCompanyId,
				electionCompany: this.area.electionCompanyDTO,
				houseId: this.selectedAreaId,
			})
				.then(() => {
					runInAction(() => {
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
					});
				})
				.catch((err) => {
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};

	deleteArea = (id) => {
		app.delete(`/pollingstations/${id}`)
			.then(() => {
				runInAction(() => {
					routing.push(`/handbooks/stations/${CompaniesStore.company.electionCompanyId}`);
				});
			})
			.catch((err) => ApplicationStore.setError(err));
	};
}

decorate(AreasStore, {
	area: observable,
	newArea: observable,
	buildings: observable,
	buildingsIds: observable,
	newBuildings: observable,
	addresses: observable,
	streetTitle: observable,
	serverErr: observable,
	addressesAreLoading: observable,
	company: observable,

	errors: computed,
	declinePeopleCount: computed,
	peopleCount: computed,

	isValidAreaId: action,
	addBuilding: action,
	changeBuilding: action,
	deleteBuilding: action,
	deleteChosenBuilding: action,
	getSelectedBuildingSuggestion: action,
	reset: action,
	resetAddressess: action,
	resetStreetTitle: action,
	changeArea: action,
	fetchArea: action,
	fetchAddresses: action,
	addArea: action,
	updateArea: action,
	deleteArea: action,
	onSelectBuilding: action,
	resetServerError: action,

	addressesArea: observable,
	streetTitleArea: observable,
	streetIdArea: observable,
	selectedArea: observable,
	selectedAreaId: observable,
});

export default new AreasStore();
