import React, { useRef, useEffect } from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import posed from "react-pose";

import "./index.scss";

import ModalError from "./ModalError";
import ModalDanger from "./ModalDanger";
import ModalWarning from "./ModalWarning";
import ModalContent from "./ModalContent";
import ModalAddParameter from "./ModalAddParameter";
import ModalAssignParameter from "./ModalAssignParameter";
import ModalWithRedirect from "./ModalWithRedirect";
import ModalInviteToActivity from "./ModalInviteToActivity";
import ModalFileUpload from "./ModalFileUpload";
import ModalAddJuryList from "./ModalAddJuryList";
import ModalEditJuryList from "./ModalEditJuryList";
import ModalProgressJuryList from "./ModalProgressJuryList";
import ModalJuryIntersect from "./ModalJuryIntersect";
import ModalExportJuryList from "./ModalExportJuryList";
import ModalManageJuryPerson from "./ModalManageJuryPerson";
import ModalEditExclusionJuryPerson from "./ModalEditExclusionJuryPerson";
import ModalFuckingFIlter from "./ModalFuckingFilter";
import ModalImportResults from "./ModalImportResults";

import useOnClickOutside from "hooks/useOnClickOutside";
import ModalExcludesResult from "./ModalExcludesResult";

const modalBackgroundPoses = {
  open: {
    background: "rgba(255, 255, 255, 0.8)",
    applyAtStart: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1",
    },
  },
  closed: {
    background: "rgba(255, 255, 255, 0)",
    applyAtEnd: {
      display: "none",
    },
  },
};

const ModalBackground = styled(posed.div(modalBackgroundPoses))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const modalPoses = {
  open: {
    opacity: 1,
    transition: {
      opacity: {
        type: "tween",
        duration: 200,
      },
    },
  },
  closed: {
    opacity: 0,
    transition: {
      opacity: {
        type: "tween",
        duration: 200,
      },
    },
  },
};

const ModalWindow = styled(posed.div(modalPoses))`
  background: white;
  border-radius: 4px;
  padding: 37.72px 0 48.5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;

const Modal = inject("ModalStore")(
  observer(
    ({
      ModalStore,
      message,
      type,
      callback,
      searchType,
      peopleCount,
      onInvite,
      onCloseInviteModal,
      onUpload,
      getFiltered,
      onCancel,
      onAssign,
      isInForm,
      header,
      children,
    }) => {
      const {
        isOpen,
        isErrModalOpen,
        isSubmit,
        toggle,
        toggleErrModal,
        isHelperModalOpen,
        isContentModalOpen,
        isInvitePeopleModalOpen,
        isFileUploadModalOpen,
        toggleFileUploadModal,
        toggleInvitePeopleModal,
        isAssignParameterModalOpen,
        isAddJuryListModalOpen,
        isEditJuryListModalOpen,
        isProgressJuryListModalOpen,
        isJuryIntersectModalOpen,
        isExportJuryModalOpen,
        isAddParameterModalOpen,
        isManageJuryPersonOpen,
        isEditExclusionJuryPersonOpen,
        isFilterModalOpen,
        isImportResultsOpen,
        toggleContentModal,
        toggleAddParameterModalOpen,
        toggleAssignParameterModal,
        toggleAddJuryListModal,
        toggleEditJuryListModal,
        toggleProgressJuryListModal,
        toggleJuryIntersectModal,
        toggleExportJuryListModal,
        toggleHelperModal,
        toggleManageJuryPersonModal,
        toggleEditExclusionJuryPersonModal,
        toggleFilterModalOpen,
        toggleModalExcludesOpen,
        isExcludesModalIsOpen,
        submit,
        setModal,
        wrapCallback,
      } = ModalStore;
      const ref = useRef();

      const isSomeModalOpen =
        isOpen ||
        isErrModalOpen ||
        isHelperModalOpen ||
        isInvitePeopleModalOpen ||
        isAssignParameterModalOpen ||
        isAddParameterModalOpen ||
        isContentModalOpen ||
        isAddJuryListModalOpen ||
        isEditJuryListModalOpen ||
        isProgressJuryListModalOpen ||
        isJuryIntersectModalOpen ||
        isExportJuryModalOpen ||
        isManageJuryPersonOpen ||
        isEditExclusionJuryPersonOpen ||
        isFileUploadModalOpen ||
        isFilterModalOpen ||
        isImportResultsOpen ||
        isExcludesModalIsOpen;

      useEffect(() => {
        setModal({ message, type });
      }, [message, setModal, type]);

      useOnClickOutside(ref, () => {
        toggle(false);
        toggleErrModal(false);
        toggleHelperModal(false);
        toggleContentModal(false);
        toggleInvitePeopleModal(false);
        toggleAssignParameterModal(false);
        toggleAddParameterModalOpen(false);
        toggleFileUploadModal(false);
        toggleAddJuryListModal(false);
        toggleEditJuryListModal(false);
        toggleProgressJuryListModal(false);
        toggleJuryIntersectModal(false);
        toggleExportJuryListModal(false);
        toggleManageJuryPersonModal(false);
        toggleEditExclusionJuryPersonModal(false);
        toggleFilterModalOpen(false);
        toggleModalExcludesOpen(false);
        onCancel && onCancel();
      });

      const withRedirect = <ModalWithRedirect onCancel={toggleHelperModal} />;

      const error = <ModalError message={message} onCancel={toggleErrModal} />;

      const danger = (
        <ModalDanger message={message} onSubmit={submit} onCancel={toggle} />
      );

      const warning = (
        <ModalWarning message={message} onSubmit={submit} onCancel={toggle} />
      );

      const addJuryList = (
        <ModalAddJuryList
          wrapCallback={wrapCallback}
          callback={callback}
          onCancel={(isOpen) => {
            onCancel();
            toggleAddJuryListModal(isOpen);
          }}
        />
      );
      const juryIntersect = (
        <ModalJuryIntersect callback={callback} onCancel={onCancel} />
      );
      const editJuryList = (
        <ModalEditJuryList
          onCancel={(isOpen) => {
            toggleEditJuryListModal(isOpen);
          }}
        />
      );

      const progressJuryList = (
        <ModalProgressJuryList
          onCancel={(isOpen) => {
            toggleProgressJuryListModal(isOpen);
          }}
        />
      );

      const exportJuryList = (
        <ModalExportJuryList
          callback={callback}
          onCancel={(isOpen) => {
            toggleExportJuryListModal(isOpen);
          }}
        />
      );

      const manageJuryPerson = (
        <ModalManageJuryPerson
          callback={callback}
          onCancel={(isOpen) => {
            toggleManageJuryPersonModal(isOpen);
          }}
        />
      );

      const editExclusionJuryPerson = (
        <ModalEditExclusionJuryPerson
          callback={callback}
          onCancel={(isOpen) => {
            toggleEditExclusionJuryPersonModal(isOpen);
          }}
        />
      );

      const addParameter = (
        <ModalAddParameter
          searchType={searchType}
          callback={callback}
          wrapCallback={wrapCallback}
          onSubmit={submit}
          onCancel={(isOpen) => {
            onCancel();
            toggleAddParameterModalOpen(isOpen);
          }}
          isInForm={isInForm}
        />
      );

      const assignParameter = (
        <ModalAssignParameter
          searchType="assignParameter"
          onAssign={onAssign}
          onCancel={(isOpen) => {
            onCancel();
            toggleAssignParameterModal(isOpen);
          }}
          peopleCount={peopleCount}
        />
      );

      const inviteToActivity = (
        <ModalInviteToActivity
          onCloseInviteModal={onCloseInviteModal}
          peopleCount={peopleCount}
          onInvite={onInvite}
        />
      );

      const modalContent = (
        <ModalContent
          header={header}
          children={children}
          wrapCallback={wrapCallback}
          callback={callback}
          toggle={toggleContentModal}
        />
      );

      const modalFileUpload = (
        <ModalFileUpload
          header={header}
          children={children}
          onUpload={onUpload}
          onCancel={toggleFileUploadModal}
        />
      );

      const modalFilter = (
        <ModalFuckingFIlter
          header={header}
          children={children}
          onCancel={toggleFilterModalOpen}
          getFiltered={getFiltered}
        />
      );

      const excludeJuryList = (
        <ModalExcludesResult
          wrapCallback={wrapCallback}
          onCancel={toggleModalExcludesOpen}
          header={header}
        />
      );

      const modalResults = <ModalImportResults header={header} />;

      return (
        <ModalBackground
          initialPose="closed"
          pose={isSomeModalOpen && !isSubmit ? "open" : "closed"}
        >
          {type === "warning" && <ModalWindow ref={ref}>{warning}</ModalWindow>}
          {type === "danger" && <ModalWindow ref={ref}>{danger}</ModalWindow>}
          {type === "error" && <ModalWindow ref={ref}>{error}</ModalWindow>}
          {type === "addParameter" && (
            <ModalWindow ref={ref}>{addParameter}</ModalWindow>
          )}
          {type === "redirect" && (
            <ModalWindow ref={ref}>{withRedirect}</ModalWindow>
          )}
          {type === "addJuryList" && (
            <ModalWindow ref={ref}>{addJuryList}</ModalWindow>
          )}
          {type === "editJuryList" && (
            <ModalWindow ref={ref}>{editJuryList}</ModalWindow>
          )}
          {type === "progressJuryList" && (
            <ModalWindow ref={ref}>{progressJuryList}</ModalWindow>
          )}
          {type === "juryIntersect" && (
            <ModalWindow ref={ref}>{juryIntersect}</ModalWindow>
          )}
          {type === "exportJuryList" && (
            <ModalWindow ref={ref}>{exportJuryList}</ModalWindow>
          )}
          {type === "manageJuryPerson" && (
            <ModalWindow ref={ref}>{manageJuryPerson}</ModalWindow>
          )}
          {type === "editExclusionJuryPerson" && (
            <ModalWindow ref={ref}>{editExclusionJuryPerson}</ModalWindow>
          )}
          {type === "inviteToActivity" && (
            <ModalWindow ref={ref}>{inviteToActivity}</ModalWindow>
          )}
          {type === "assignParameter" && (
            <ModalWindow ref={ref}>{assignParameter}</ModalWindow>
          )}
          {type === "modalContent" && (
            <ModalWindow ref={ref}>{modalContent}</ModalWindow>
          )}
          {type === "modalFileUpload" && (
            <ModalWindow ref={ref}>{modalFileUpload}</ModalWindow>
          )}
          {type === "modalShietFilter" && (
            <ModalWindow ref={ref}>{modalFilter}</ModalWindow>
          )}
          {type === "modalExcludesTypes" && (
            <ModalWindow ref={ref}>{excludeJuryList}</ModalWindow>
          )}
          {type === "modalImportResult" && (
            <ModalWindow ref={ref}>{modalResults}</ModalWindow>
          )}
        </ModalBackground>
      );
    }
  )
);

export default Modal;
