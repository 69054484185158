import React from "react";

import "./index.scss";

import { ReactComponent as Import } from "assets/svg/blueImport.svg";

const BlueImportButton = (props) => {
  return (
    <div
      className={`blue-button__import ${
        props.className ? props.className : ""
      }`}
    >
      <Import />
      {props.children}
    </div>
  );
};

export default BlueImportButton;
