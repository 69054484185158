import React from "react";

import Users from "./Users";
import Companies from "./Companies";
import HomeParams from "./HomeParams";
import PeopleParams from "./PeopleParams";

import Tabs from "components/Tabs";

import useDocumentTitle from "hooks/useDocumentTitle";

const tabs = [
  {
    title: "Параметры людей",
    path: "/handbooks/peopleparams",
    content: <PeopleParams />,
  },
  {
    title: "Параметры домов",
    path: "/handbooks/homeparams",
    content: <HomeParams />,
  },
  { title: "Пользователи", path: "/handbooks/users", content: <Users /> },
  { title: "Кампании", path: "/handbooks/companies", content: <Companies /> },
];

const Handbooks = ({ match }) => {
  useDocumentTitle("Справочники");
  return (
    <div className="wrapper__content">
      <section className="section">
        <div className="container">
          <h1 className="page-title">Справочники</h1>
          <Tabs items={tabs} withRouter match={match} />
        </div>
      </section>
    </div>
  );
};

export default Handbooks;
