import React, { useState, forwardRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import InputMask from "react-input-mask";
import "react-day-picker/lib/style.css";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/ru";

import YearMonthSelect from "./YearMonthSelect";
import { MODES } from "helpers/calendar";

const MaskInput = forwardRef(
  (
    {
      value,
      name,
      className,
      style,
      onClick,
      onChange,
      onBlur,
      placeholder,
      disabled,
    },
    ref
  ) => (
    <InputMask
      ref={ref}
      value={value}
      name={name}
      className={className}
      style={style}
      onClick={onClick}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);
      }}
      placeholder={placeholder}
      disabled={disabled}
      autoComplete="off"
      mask="99.99.9999"
    />
  )
);

const Datepicker = ({
  changeDate,
  placeholder,
  className,
  date,
  startDate,
  endDate,
  disabled,
  changeActualMask,
}) => {
  const fromMonth = startDate
    ? startDate
    : new Date(new Date().getFullYear(), 0);
  const toMonth = endDate
    ? endDate
    : new Date(new Date().getFullYear() + 23, 11);
  const [month, setMonth] = useState(fromMonth);
  const [mode, setMode] = useState(MODES[0]);

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  const changeMask = Boolean(changeActualMask) ? changeActualMask : () => {};

  return (
    <DayPickerInput
      component={MaskInput}
      formatDate={formatDate}
      parseDate={parseDate}
      value={date}
      autocomplete="off"
      placeholder={placeholder || ""}
      onDayChange={changeDate}
      inputProps={{
        className: `input__text datepicker ${className || ""}`,
        disabled,
        onChange: (e) => changeMask(e.target.value),
      }}
      dayPickerProps={{
        disabled,
        locale: "ru",
        localeUtils: MomentLocaleUtils,
        canChangeMonth: false,
        month,
        fromMonth,
        toMonth,
        captionElement: ({ date }) => (
          <YearMonthSelect
            date={date}
            onChange={handleYearMonthChange}
            fromMonth={fromMonth}
            toMonth={toMonth}
            isInput
            mode={mode}
            setMode={setMode}
          />
        ),
      }}
    />
  );
};

export default Datepicker;
