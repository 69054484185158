import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "assets/svg/arrow.svg";

import "./index.scss";

const Breadcrumbs = ({ routes }) => {
  return (
    <nav className="container__breadcrumbs">
      <ul className="breadcrumbs">
        {routes &&
          routes.map((item, index) => (
            <li className="breadcrumb__item" key={index}>
              <Link to={item.path}>{item.label}</Link>
              <Arrow />
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
