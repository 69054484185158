import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import "./index.scss";

const TabContent = ({ content }) => (
  <div className="tab__content">
    <div>{content}</div>
  </div>
);

const Tabs = ({ items, withRouter, match }) => {
  const history = useHistory();
  const [active, setActive] = useState(
    withRouter
      ? items.findIndex((item) => item.path === history.location.pathname)
      : items.findIndex((item) => item.title === items[0].title)
  );

  const openTab = (e) => setActive(+e.target.dataset.index);

  useEffect(() => {
    console.log("ma4");
    if (match) {
      let indexOfActiveItemByMatch = items.indexOf(
        items.find((item) => item.path === match.url)
      );
      if (indexOfActiveItemByMatch !== -1) {
        setActive(indexOfActiveItemByMatch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <div className="container__tabs">
      <ul className={"tabs__buttons" + (!withRouter ? " padding" : "")}>
        {items &&
          items.map((item, index) => (
            <li key={index}>
              {withRouter ? (
                <Link to={`${item.path}`} className="tab__link">
                  <button
                    type="button"
                    className={`tab__item ${index === active ? "active" : ""}`}
                    onClick={openTab}
                    data-index={index}
                  >
                    {item.title}
                  </button>
                </Link>
              ) : (
                <button
                  type="button"
                  className={`tab__item ${index === active ? "active" : ""}`}
                  onClick={openTab}
                  data-index={index}
                >
                  {item.title}
                </button>
              )}
            </li>
          ))}
      </ul>
      {items[active] && <TabContent {...items[active]} />}
    </div>
  );
};

export default Tabs;
