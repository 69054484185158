import { observable, decorate, action, computed } from "mobx";

export class PaginationStore {
  constructor() {
    this.resetAll();
  }

  resetPagination = () => {
    this.currentPage = 0;
  };

  resetPerPage = () => {
    this.perPage = 10;
  };

  resetAll = () => {
    this.currentPage = 0;
    this.perPage = 10;
  };

  paginate = (page) => {
    this.currentPage = page;
  };

  changePerPage = (value) => {
    this.perPage = value;
    this.currentPage = 0;
  };

  get startIndex() {
    return !this.currentPage ? 0 : this.currentPage * this.perPage;
  }
}

decorate(PaginationStore, {
  perPage: observable,
  currentPage: observable,
  paginate: action,
  changePerPage: action,
  resetPagination: action,
  resetPerPage: action,
  resetAll: action,
  startIndex: computed,
});

export default new PaginationStore();
