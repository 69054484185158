import { observable, decorate, action, runInAction, set, computed } from "mobx";
import {
  INVALID_DATE_ERR,
  INVALID_START_DATE_ERR,
  INVALID_END_DATE_ERR,
  EMPTY_ERRS,
} from "helpers/errors";
import app from "utils/axiosConfig";
import ApplicationStore from "../appStore";
import {
  toISOLocalDateString,
  formDateToStringDate,
} from "helpers/formattedDate";

import { downloadFile } from "helpers/filesExport";

import ModalStore from "stores/components/modalStore";

class JuryStore {
  constructor() {
    this.reset();
  }

  get errors() {
    let error = {};
    const { isSubmitted, mode } = ApplicationStore;

    if (!isSubmitted) {
      return error;
    }
    switch (mode) {
      case "ADD_JURY":
        if (!this.newJuryList.dateFrom || this.dateFrom === "Invalid Date") {
          error["dateFrom"] = INVALID_DATE_ERR;
        } else if (
          new Date(this.newJuryList.dateTo).getTime() <=
          new Date(this.newJuryList.dateFrom).getTime()
        ) {
          error["dateFrom"] = INVALID_START_DATE_ERR;
        }
        if (
          !this.newJuryList.dateTo ||
          this.newJuryList.dateTo === "Invalid Date"
        ) {
          error["dateTo"] = INVALID_DATE_ERR;
        } else if (
          new Date(this.newJuryList.dateTo).getTime() <=
          new Date(this.newJuryList.dateFrom).getTime()
        ) {
          error["dateTo"] = INVALID_END_DATE_ERR;
        }
        if (this.checkJuryListsCount(this.newJuryList.cityDistricts)) {
          error["lists"] = EMPTY_ERRS;
        } else if (this.checkJuryListsZeroErr(this.newJuryList.cityDistricts)) {
          error["lists"] = "Значение не может быть нулевым";
        }
        if (this.checkJuryListsCount(this.newJuryList.garrisonCityDistricts)) {
          error["garrison_lists"] = EMPTY_ERRS;
        } else if (
          this.checkJuryListsZeroErr(this.newJuryList.garrisonCityDistricts)
        ) {
          error["garrison_lists"] = "Значение не может быть нулевым";
        }
        break;

      case "EDIT_JURY":
        if (
          !this.currentJuryList.dateFrom ||
          this.currentJuryList.dateFrom === "Invalid Date"
        ) {
          error["dateFrom"] = INVALID_DATE_ERR;
        } else if (
          new Date(
            this.stringDateToObjectDate(this.currentJuryList.dateTo)
          ).getTime() <=
          new Date(
            this.stringDateToObjectDate(this.currentJuryList.dateFrom)
          ).getTime()
        ) {
          error["dateFrom"] = INVALID_START_DATE_ERR;
        }
        if (
          !this.currentJuryList.dateTo ||
          this.currentJuryList.dateTo === "Invalid Date"
        ) {
          error["dateTo"] = INVALID_DATE_ERR;
        } else if (
          this.currentJuryList.dateTo &&
          new Date(
            this.stringDateToObjectDate(this.currentJuryList.dateTo)
          ).getTime() <=
            new Date(
              this.stringDateToObjectDate(this.currentJuryList.dateFrom)
            ).getTime()
        ) {
          error["dateTo"] = INVALID_END_DATE_ERR;
        }
        if (
          this.checkJuryListsCount(this.currentJuryList.juryListByDistricts)
        ) {
          error["lists"] = EMPTY_ERRS;
        } else if (
          this.checkJuryListsZeroErr(this.currentJuryList.juryListByDistricts)
        ) {
          error["lists"] = "Значение не может быть нулевым";
        }
        if (
          this.checkJuryListsCount(
            this.currentJuryList.juryListByDistrictsForCourt
          )
        ) {
          error["garrison_lists"] = EMPTY_ERRS;
        } else if (
          this.checkJuryListsZeroErr(
            this.currentJuryList.juryListByDistrictsForCourt
          )
        ) {
          error["garrison_lists"] = "Значение не может быть нулевым";
        }
        break;
      default:
        break;
    }
    return error;
  }

  checkJuryListsCount = (arr) => {
    const { hasOnlyWhiteSpaces } = ApplicationStore;
    return arr?.some(
      (district) =>
        hasOnlyWhiteSpaces(district.basicList) ||
        hasOnlyWhiteSpaces(district.subList)
    );
  };

  checkJuryListsZeroErr = (arr) => {
    return arr?.some(
      (district) => district.basicList === 0 || district.subList === 0
    );
  };

  stringDateToObjectDate = (pDate) => {
    if (Boolean(pDate)) {
      let dd = pDate.split(".")[0].padStart(2, "0");
      let mm = pDate.split(".")[1].padStart(2, "0");
      let yyyy = pDate.split(".")[2].split(" ")[0];

      mm = (parseInt(mm) - 1).toString();
      return new Date(yyyy, mm, dd);
    }
  };

  objectDateToStringDate = (d) => {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}.${month}.${year}`;
  };

  reset = () => {
    runInAction(() => {
      this.juryLists = [];
      this.currentJuryList = {
        isForm: false,
        isFormed: false,
        formedDate: "",
        progressForm: 0,
        dateFrom: "",
        dateTo: "",
        confirmIntersectSave: false,
        serverErr: "",
        progressErr: "",
        juryListByDistrictsForCourt: [],
        juryListByDistricts: [],
      };
      this.newJuryList = {
        dateFrom: "",
        dateTo: "",
        confirmIntersectSave: false,
        serverErr: "",
        cityDistricts: [],
        garrisonCityDistricts: [],
      };

      this.actualMask = {
        dateFromNew: "",
        dateToNew: "",
        dateFromCur: "some",
        dateToCur: "some",
      };
      this.currentListSearchString = {
        juryListType: "",
        juryListId: "",
      };
      this.garrisonCourtId = "";
    });
  };

  setCurrentJuryList = (item) => {
    this.currentJuryList = {
      ...this.currentJuryList,
      ...item,
      juryListByDistricts: item.juryListByDistricts.filter(
        (item) => item.districtId !== this.garrisonCourtId
      ),
      serverErr: "",
      progressForm: 0,
      confirmIntersectSave: false,
    };
  };

  exportJuryList = (type, id, exludedType) => {
    const options = { responseType: "blob" };

    const isBasic = type === "basic";

    let url = `/JuryLists/${id}/export?isBasic=${isBasic}`;

    switch (exludedType) {
      case "notExcluded":
        url += `&isExcluded=false`;
        break;
      case "Exluded":
        url += `&isExcluded=true`;
        break;
      default:
        break;
    }

    app
      .get(url, options)
      .then((res) => {
        downloadFile(res, "excel", "export.xlsx");
        ModalStore.toggleExportJuryListModal(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  exportMainJuryList = (id, type) => {
    const options = { responseType: "blob" };

    let boolType = type === "Exluded" ? true : type === "All" ? "" : false;

    app
      .get(`/JuryLists/${id}/export?isExcluded=${boolType}`, options)
      .then((res) => {
        downloadFile(res, "excel", "export.xlsx");
        ModalStore.toggleExportJuryListModal(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });
  };

  exportCommonJuryList = (id) => {
    const options = { responseType: "blob" };

    app.get(`/JuryLists/${id}/exportlists`, options).then((res) => {
      downloadFile(res, "zip", "export.zip");
    });
  };

  trackProgressFormJuryList = () => {
    this.serverErr = "";
    const id = this.currentJuryList.id;

    ApplicationStore.setGlobalFieldValue("isSubmitted", true);
    this.currentJuryList.isForming = true;
    this.interval = setInterval(() => this.fetchProgressForm(id), 3000);
  };

  fetchCityDistricts = () => {
    this.newJuryList.cityDistricts = [];
    ApplicationStore.setLoading(true);
    app
      .get(`/JuryLists/districts`)
      .then((res) => {
        this.newJuryList.cityDistricts = res.data.map((item) => {
          return {
            ...item,
            basicList: "",
            subList: "",
          };
        });
        this.newJuryList.garrisonCityDistricts = res.data.map((item) => {
          return {
            ...item,
            basicList: "",
            subList: "",
          };
        });
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchProgressForm = async (id) => {
    this.currentJuryList.isForming = true;
    app
      .post(`/JuryLists/fill/${id}`)
      .then((res) => {
        this.currentJuryList.progressForm = res.data.percent;
        if (res.data.formingDate) {
          this.fetchJuryLists();
          this.currentJuryList.isForming = false;
          clearInterval(this.interval);
          ModalStore.toggleProgressJuryListModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          this.currentJuryList.progressErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setGlobalFieldValue("isSubmitted", false);
      });
  };

  resetNewJuryList = () => {
    this.newJuryList = {
      dateFrom: "",
      dateTo: "",
      confirmIntersectSave: false,
      serverErr: "",
      cityDistricts: [],
      garrisonCityDistricts: [],
    };
    this.actualMask = {
      dateFromNew: "",
      dateToNew: "",
      dateFromCur: "some",
      dateToCur: "some",
    };
  };

  resetCurrentJuryList = () => {
    this.currentJuryList = {
      isForm: false,
      isFormed: false,
      formedDate: "",
      progressForm: 0,
      dateFrom: "",
      dateTo: "",
      confirmIntersectSave: false,
      serverErr: "",
      progressErr: "",
    };
  };

  fetchJuryLists = async () => {
    this.juryLists = [];
    ApplicationStore.setLoading(true);
    await app
      .get(`/JuryLists`)
      .then((res) => {
        this.garrisonCourtId = res.data.garrisonCourtId;
        this.juryLists = res.data.juryList.map((item) => {
          const {
            id,
            beginDate,
            endDate,
            isFormed,
            formingDate,
            current,
            peopleNumberErrorMessage,
            juryListByDistricts,
            juryListByDistrictsForCourt,
            isForming,
            isPeopleEnough,
          } = item;

          if (isForming) {
            this.setCurrentJuryList({
              id,
              dateFrom: formDateToStringDate(beginDate),
              dateTo: formDateToStringDate(endDate),
              formingDate: formDateToStringDate(formingDate),
              isFormed,
              current,
              peopleNumberErrorMessage,
              juryListByDistricts,
              isPeopleEnough,
              isForming,
              juryListByDistrictsForCourt,
            });

            if (!this.interval) {
              this.trackProgressFormJuryList();
            }
          }

          return {
            id,
            dateFrom: formDateToStringDate(beginDate),
            dateTo: formDateToStringDate(endDate),
            formingDate: formDateToStringDate(formingDate),
            isFormed,
            current,
            peopleNumberErrorMessage,
            juryListByDistricts,
            juryListByDistrictsForCourt,
            isForming,
            isPeopleEnough,
          };
        });
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  stringDateToFormStringDate = (strDate) => {
    return toISOLocalDateString(new Date(this.stringDateToObjectDate(strDate)));
  };

  editJuryList = () => {
    this.serverErr = "";

    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (Object.keys(this.errors).length) {
      return;
    }
    const id = this.currentJuryList.id;

    let beginDate = this.stringDateToFormStringDate(
      this.currentJuryList.dateFrom
    );
    let endDate = this.stringDateToFormStringDate(this.currentJuryList.dateTo);

    const dataToSend = {
      beginDate,
      endDate,
      juryListByDistricts: this.currentJuryList.juryListByDistricts.map(
        ({ districtId, basicList, subList }) => {
          return {
            districtId,
            basicList: Number(basicList),
            subList: Number(subList),
          };
        }
      ),
      juryListByDistrictsForCourt:
        this.currentJuryList.juryListByDistrictsForCourt.map(
          ({ districtId, basicList, subList }) => {
            return {
              districtId,
              basicList: Number(basicList),
              subList: Number(subList),
            };
          }
        ),
    };

    let IntersectParam = this.currentJuryList.confirmIntersectSave;
    app
      .post(
        `/JuryLists/edit/${id}/?confirmIntersectSave=${IntersectParam}`,
        dataToSend
      )
      .then(() => {
        runInAction(() => {
          this.fetchJuryLists();
          ModalStore.resetModal();
          this.resetCurrentJuryList();
        });
      })
      .catch((err) => {
        if (err.response) {
          this.currentJuryList.serverErr = err.response.data;
        }
        ModalStore.toggleEditJuryListModal(false);
        ModalStore.toggleJuryIntersectModal(true);
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setGlobalFieldValue("isSubmitted", false);
      });
  };

  addJuryList = () => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);
    if (Object.keys(this.errors).length) {
      return;
    }
    let dataToSend = {
      beginDate: toISOLocalDateString(new Date(this.newJuryList.dateFrom)),
      endDate: toISOLocalDateString(new Date(this.newJuryList.dateTo)),
      juryListByDistricts: this.newJuryList.cityDistricts.map(
        ({ id, basicList, subList }) => {
          return {
            districtId: id,
            basicList: Number(basicList),
            subList: Number(subList),
          };
        }
      ),
      juryListByDistrictsForCourt: this.newJuryList.garrisonCityDistricts.map(
        ({ id, basicList, subList }) => {
          return {
            districtId: id,
            basicList: Number(basicList),
            subList: Number(subList),
          };
        }
      ),
    };

    let IntersectParam = this.newJuryList.confirmIntersectSave;
    app
      .post(
        `/JuryLists/create/?confirmIntersectSave=${IntersectParam}`,
        dataToSend
      )
      .then(() => {
        this.resetNewJuryList();
        this.fetchJuryLists();
        ModalStore.resetModal();
        ApplicationStore.setGlobalFieldValue("isSubmitted", false);
      })
      .catch((err) => {
        if (err.response) {
          this.newJuryList.serverErr = err.response.data;
        }
        ModalStore.toggleAddJuryListModal(false);
        ModalStore.toggleJuryIntersectModal(true);
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setGlobalFieldValue("isSubmitted", false);
      });
  };

  delJuryList = () => {
    let id = this.currentJuryList.id;
    app
      .post(`/JuryLists/delete/${id}`)
      .then(() => {
        this.fetchJuryLists();
        ModalStore.resetModal();
        this.resetCurrentJuryList();
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  handleCurrentListSearchString = (type, id) => {
    this.currentListSearchString.juryListId = id;
    this.currentListSearchString.juryListType = type;
  };

  handleCurrentJuryListChange = (field, value) => {
    set(this.currentJuryList, field, value);
  };

  handleNewJuryListChange = (field, value) => {
    set(this.newJuryList, field, value);
  };

  handleChangeActualMask = (type, value) => {
    set(this.actualMask, type, value);
  };

  handleChangeNewJuryListCount = (field, type, value, id) => {
    let newDistricts = this.newJuryList[field].map((district) => {
      if (district.id === id) {
        district[type] = value;
      }
      return {
        ...district,
      };
    });

    set(this.newJuryList, field, newDistricts);
  };

  handleChangeCurrentJuryListCount = (field, type, value, name) => {
    let newJuryListByDistricts = this.currentJuryList[field].map((district) => {
      if (district.district === name) {
        district[type] = value;
      }
      return {
        ...district,
      };
    });

    set(this.currentJuryList, field, newJuryListByDistricts);
  };

  resetServerErrors = () => {
    this.serverErr = "";
  };
}

decorate(JuryStore, {
  newJuryList: observable,
  currentJuryList: observable,
  serverErr: observable,
  juryLists: observable,
  currentListSearchString: observable,
  actualMask: observable,
  cityDistricts: observable,
  garrisonCourtId: observable,

  errors: computed,

  reset: action,
  handleNewJuryListChange: action,
  addJuryList: action,
  editJuryList: action,
  delJuryList: action,
  resetServerErrors: action,
  trackProgressFormJuryList: action,
  handleCurrentJuryListChange: action,
  objectDateToStringDate: action,
  setCurrentJuryListIsForm: action,
  stringDateToObjectDate: action,
  fetchJuryLists: action,
  handleChangeActualMask: action,
  handleCurrentListSearchString: action,
  setCurrentJuryListProgress: action,
  setCurrentJuryList: action,
  exportJuryList: action,
  fetchCityDistricts: action,
  handleChangeNewJuryListCount: action,
  handleChangeCurrentJuryListCount: action,
});

export default new JuryStore();
