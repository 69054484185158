import React from "react";
import { Link } from "react-router-dom";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

const ModalWithRedirect = ({ onCancel }) => {
  return (
    <div className="modal">
      <p className="modal__title">Добавить новых в этот список</p>
      <p className="modal__helper-text redirect no-mt">
        Для добавления новых людей в список приглашенных сначала найдите нужных
        людей в разделе “Люди”
      </p>
      <div className="modal__button-group no-padding">
        <Link to="/people">
          <SubmitButton
            className={"modal__button"}
            onClick={() => onCancel(false)}
          >
            Перейти в раздел Люди
          </SubmitButton>
        </Link>
        <CancelButton
          className={"modal__button"}
          onClick={() => onCancel(false)}
        >
          Отмена
        </CancelButton>
      </div>
    </div>
  );
};

export default ModalWithRedirect;
