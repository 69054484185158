import React from "react";

import "./index.scss";

const FindButton = (props) => {
	return (
		<button
			className={`button__find ${props.className ? props.className : ""}`}
			type="button"
			onClick={props.onClick}
			disabled={props.disabled || false}>
			Найти
		</button>
	);
};

export default FindButton;
