import React from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import Checkbox from "common/components/Inputs/Checkbox";

import "./index.scss";

const ModalManageJuryPerson = inject(
  "JuryPersonStore",
  "ApplicationStore"
)(
  observer(({ JuryPersonStore, onCancel }) => {
    const {
      juryPerson,
      handleManageJuryPerson,
      excludeJuryPerson,
    } = JuryPersonStore;

    const { edittedReasons } = juryPerson;

    const actionText = juryPerson.isActive
      ? "Исключить из списка"
      : "Включить в список";

    const disabledCondition = juryPerson.edittedReasons
      ? juryPerson.edittedReasons.every((item) => !item.selected)
      : true;

    return (
      <div className="modal">
        <h3 className="modal__title">{actionText}</h3>
        <div className="modal-manage-jury-person__body">
          <ul>
            {edittedReasons &&
              edittedReasons.map((item) => (
                <div
                  key={item.id}
                  className="modal-manage-jury-person__list-field"
                >
                  <Checkbox
                    id={item.id}
                    onChange={() => {
                      handleManageJuryPerson(item.id);
                    }}
                    checked={item.selected || false}
                  >
                    {item.title}
                  </Checkbox>
                </div>
              ))}
          </ul>
        </div>
        <div className="modal__button-group static">
          <SubmitButton
            disabled={disabledCondition}
            onClick={() => excludeJuryPerson(false, juryPerson.isActive)}
            className="modal__button"
          >
            {juryPerson.isActive ? "Исключить" : "Включить"}
          </SubmitButton>
          <CancelButton
            className="modal__button"
            onClick={() => onCancel(false)}
          >
            Отмена
          </CancelButton>
        </div>
      </div>
    );
  })
);

export default ModalManageJuryPerson;
