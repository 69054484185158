import React, { useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/ru";

import "./index.scss";

import YearMonthSelect from "common/components/Inputs/Datepicker/YearMonthSelect";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

import formattedDate from "helpers/formattedDate";
import { MODES } from "helpers/calendar";

const Calendar = ({
  getByDate,
  isLoading,
  reset,
  startDate,
  endDate,
  disabledDays,
}) => {
  const fromMonth = startDate
    ? startDate
    : new Date(new Date().getFullYear(), 0);
  const toMonth = endDate
    ? endDate
    : new Date(new Date().getFullYear() + 10, 11);
  const [month, setMonth] = useState(fromMonth);
  const [mode, setMode] = useState(MODES[0]);
  const [selectedDay, setSelectedDay] = useState(undefined);

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }
    if (selected) {
      setSelectedDay(undefined);
      reset();
      return;
    }
    setSelectedDay(selected ? undefined : day);
    getByDate(formattedDate(day, false, true));
  };

  return (
    <div className="calendar">
      <DayPicker
        className="calendar"
        onDayClick={handleDayClick}
        selectedDays={selectedDay}
        locale="ru"
        localeUtils={MomentLocaleUtils}
        month={month}
        canChangeMonth={false}
        disabledDays={
          isLoading
            ? [new Date(), { before: new Date(), after: new Date() }]
            : disabledDays === "beforeToday"
            ? { before: new Date() }
            : disabledDays === "afterToday"
            ? [new Date(), { after: new Date() }]
            : null
        }
        captionElement={({ date }) => (
          <YearMonthSelect
            date={date}
            onChange={handleYearMonthChange}
            fromMonth={fromMonth}
            toMonth={toMonth}
            isInput={false}
            isLoading={isLoading}
            mode={mode}
            setMode={setMode}
          />
        )}
      />
      {selectedDay && (
        <BottomLinedButton
          onClick={() => {
            setSelectedDay(undefined);
            reset();
          }}
        >
          Сбросить поиск
        </BottomLinedButton>
      )}
    </div>
  );
};

export default Calendar;
