import React from "react";

import "./index.scss";

const classNames = require("classnames");

const Pagination = ({
  perPage,
  total,
  currentPage,
  handlePaginate,
  handleChangePerPage,
  isShowPerPage,
}) => {
  const pagesCount = Math.ceil(total / perPage);

  const setPaginationList = (page, count) => {
    if (count === 1) {
      return [];
    }

    if (count < 5) {
      return Array(Math.ceil(total / perPage))
        .fill(1)
        .map((_item, index) => index + 1);
    }

    if (page === 1) {
      return [1, 2, "...", count];
    }

    if (page === 2) {
      return [1, 2, 3, "...", count];
    }

    if (page === 3) {
      return [1, 2, 3, 4, "...", count];
    }

    if (page >= 4 && page < count - 2) {
      return [
        1,
        "...",
        page - 2,
        page - 1,
        page,
        page + 1,
        page + 2,
        "...",
        count,
      ];
    }

    return [1, "...", count - 3, count - 2, count - 1, count];
  };

  const paginationList = setPaginationList(currentPage, pagesCount);

  const perPageValues = [10, 50, 100];

  return (
    <div className="container__pagination">
      {paginationList.length > 0 && (
        <nav className="pagination">
          <ul className="pagination__list">
            {paginationList.map((item, index) => (
              <li key={index} className="pagination__item">
                <button
                  onClick={() => handlePaginate(item)}
                  className={classNames({
                    pagination__button: true,
                    "pagination__button active": currentPage === item,
                    "pagination__button disabled": item === "...",
                  })}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
      {total > 10 && isShowPerPage && (
        <div
          className={`${
            paginationList.length === 0 && "none"
          } pagination__perpage`}
        >
          <p>Количество на странице:</p>
          <ul className="pagination__perpage-buttons">
            {perPageValues.map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => handleChangePerPage(item)}
                  className={classNames({
                    perPage__button: true,
                    "perPage__button active": perPage === item,
                  })}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Pagination;
