import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";

import AddButton from "common/components/Buttons/AddButton";
import Table from "common/components/Table";
import Empty from "components/Empty";
import LoaderSpinner from "components/Loader";
import Modal from "components/Modal";

import FormListButton from "./JuryFormButton";

import "./index.scss";

const header = (
  <>
    <div>Дата</div>
    <div>Суд</div>
    <div>Основной список</div>
    <div>Запасной список</div>
    <div>Редактировать</div>
  </>
);

const classnames = {
  header: "table-jury-header",
};

const getMappedItems = (
  list,
  currentJuryList,
  setCurrent,
  editList,
  toggleModal
) => {
  return list?.map((item) => {
    const { id, dateFrom, dateTo, juryListByDistricts, isFormed } = item;

    return (
      <div key={id} className="table-jury-field">
        <div
          className="table-jury-field__static"
          onClick={() => {
            setCurrent(item);
          }}
        >
          <div
            className="table-jury-field__link table-jury-field__static-item"
            onClick={(e) => {
              if (!currentJuryList.isForming) {
                editList(e);
              } else if (
                currentJuryList.isForming &&
                currentJuryList.id === item.id
              ) {
                editList(e);
              }
              return;
            }}
          >
            {`${dateFrom}  -  ${dateTo}`}
          </div>
          <ul className="table-value  table-jury-field__static-item table-jury-field__districts-list">
            {juryListByDistricts?.map((elemList) => (
              <li>{elemList.district}</li>
            ))}
          </ul>
          <ul className="table-value  table-jury-field__static-item table-jury-field__districts-list">
            {juryListByDistricts?.map((elemList) => (
              <li>
                {isFormed
                  ? `${elemList.basicListPeopleCount} / ${elemList.basicList}`
                  : elemList.basicList}
              </li>
            ))}
          </ul>
          <ul className="table-value  table-jury-field__static-item table-jury-field__districts-list">
            {juryListByDistricts?.map((elemList) => (
              <li>
                {isFormed
                  ? `${elemList.subListPeopleCount} / ${elemList.subList}`
                  : elemList.subList}
              </li>
            ))}
          </ul>
        </div>
        <div className="table-jury-field__action">
          <FormListButton item={item} toggleModal={toggleModal} />
        </div>
      </div>
    );
  });
};

const AddEditJury = inject(
  "ModalStore",
  "JuryStore",
  "ApplicationStore"
)(
  observer(({ ModalStore, JuryStore, ApplicationStore }) => {
    const {
      isSubmit,
      resetModal,
      isOpen,
      toggleAddJuryListModal,
      isAddJuryListModalOpen,
      toggleEditJuryListModal,
      isEditJuryListModalOpen,
      isProgressJuryListModalOpen,
      isJuryIntersectModalOpen,
      toggleProgressJuryListModal,
      toggleJuryIntersectModal,
    } = ModalStore;
    const { isLoading, mode, isAdmin, isAdminKokr } = ApplicationStore;
    const {
      setCurrentJuryList,
      juryLists,
      fetchJuryLists,
      delJuryList,
      currentJuryList,
      editJuryList,
      handleCurrentJuryListChange,
      handleNewJuryListChange,
      newJuryList,
      addJuryList,
      resetNewJuryList,
    } = JuryStore;

    const [lastMode, setLastMode] = useState("");

    useEffect(() => {
      fetchJuryLists();
    }, [fetchJuryLists]);

    useEffect(() => {
      if (mode.trim() !== "") {
        setLastMode(mode);
      }
    }, [mode]);

    const addJuryListSubmitCb = () => {
      handleNewJuryListChange(
        "confirmIntersectSave",
        !newJuryList.confirmIntersectSave
      );
      addJuryList();
    };

    const editJuryListSubmitCb = () => {
      handleCurrentJuryListChange(
        "confirmIntersectSave",
        !currentJuryList.confirmIntersectSave
      );
      editJuryList();
    };

    const addJuryListCancelCb = (isOpen) => {
      toggleJuryIntersectModal(isOpen);
      toggleAddJuryListModal(!isOpen);
    };

    const editJuryListCancelCb = (isOpen) => {
      toggleJuryIntersectModal(isOpen);
      toggleEditJuryListModal(!isOpen);
    };

    const submitModalCallback =
      lastMode === "EDIT_JURY" ? editJuryListSubmitCb : addJuryListSubmitCb;

    const cancelModalCallback =
      lastMode === "EDIT_JURY" ? editJuryListCancelCb : addJuryListCancelCb;

    useEffect(() => () => resetModal(), [resetModal]);

    useEffect(() => {
      if (isSubmit) {
        delJuryList();
      }
    }, [isSubmit, delJuryList]);

    const loaderOrEmpty = isLoading ? <LoaderSpinner /> : <Empty />;
    const renderCondition = isAdmin || isAdminKokr;

    return (
      <>
        {renderCondition && (
          <section className="section">
            <div className="container">
              <AddButton
                onClick={() => {
                  toggleAddJuryListModal(true);
                }}
              >
                Добавить список
              </AddButton>
              {juryLists && juryLists.length !== 0 ? (
                <Table
                  header={header}
                  classnames={classnames}
                  items={getMappedItems(
                    juryLists,
                    currentJuryList,
                    (item) => {
                      if (currentJuryList.id && currentJuryList.isForming) {
                        return;
                      }
                      setCurrentJuryList(item);
                    },
                    (e) => {
                      e.preventDefault();
                      toggleEditJuryListModal(true);
                    },
                    () => {
                      toggleProgressJuryListModal(true);
                    }
                  )}
                />
              ) : (
                <div>{loaderOrEmpty}</div>
              )}
            </div>
          </section>
        )}
        {isAddJuryListModalOpen && (
          <Modal
            callback={() => {
              fetchJuryLists();
            }}
            onCancel={() => resetNewJuryList()}
            type="addJuryList"
          />
        )}

        {isOpen && <Modal type="danger" />}
        {isJuryIntersectModalOpen && (
          <Modal
            callback={submitModalCallback}
            onCancel={() => cancelModalCallback(false)}
            type="juryIntersect"
          />
        )}
        {isEditJuryListModalOpen && (
          <Modal callback={submitModalCallback} type="editJuryList" />
        )}
        {isProgressJuryListModalOpen && <Modal type="progressJuryList" />}
      </>
    );
  })
);

export default AddEditJury;
