import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";

import "../index.scss";

import SearchInput from "common/components/Inputs/SearchInput";
import BottomDottedButton from "common/components/Buttons/BottomDottedButton";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";
import Select from "../../../common/components/Select";
import Modal from "../../Modal";
import CancelButton from "../../../common/components/Buttons/CancelButton";

const SearchHeader = inject(
  "ItemsStore",
  "PaginationStore",
  "ApplicationStore",
  "ModalStore"
)(
  observer(
    ({
      ItemsStore,
      PaginationStore,
      ApplicationStore,
      ModalStore,
      showSearches = true,
      searchType = "people",
      common,
    }) => {
      const {
        fetchWithParameters,
        searchString,
        setSearchString,
        resetSelected,
        setSearchRequestSubmitted,
        itemsAreLoading,
        searchIsImpossible,
        setSearchActivation,
        setLastSearchStringRequest,
        searchIsActivated,
        onSourceTypeChange,
        boolean,
      } = ItemsStore;
      const { perPage, startIndex, resetPagination } = PaginationStore;

      const { isAdmin } = ApplicationStore;

      const { toggleFilterModalOpen, isFilterModalOpen } = ModalStore;

      const wrapperSearchInputRef = useRef(null);

      const getPlaceholder = () => {
        switch (searchType) {
          case "jury":
            return "Поиск по ФИО";
          case "homes":
            return "Поиск по домам";
          case "people":
            return "Поиск по ФИО";
          default:
            return "Поиск по ФИО";
        }
      };

      useEffect(() => {
        return () => {
          setSearchString("");
          setSearchRequestSubmitted(false);
        };
      }, [setSearchString, setSearchRequestSubmitted]);

      useEffect(() => {
        if (!itemsAreLoading) {
          wrapperSearchInputRef.current
            .querySelector('input[type="search"]')
            .focus();
        }
      }, [itemsAreLoading]);

      const handleFilterModalOpen = () => {
        toggleFilterModalOpen(true);
      };

      const onFilterClickHandler = () => {
        fetchWithParameters("people", startIndex, perPage, boolean);
        toggleFilterModalOpen(false);
      };

      const handleExcludedFilterModalOpen = () => {
        toggleFilterModalOpen(true);
      };

      const onExcludedFilterClickHandler = () => {
        fetchWithParameters("common", startIndex, perPage, common);
        toggleFilterModalOpen(false);
      };

      const fileContent = (
        <div className="file-upload">
          <Select
            id="id"
            data={[
              { name: "Исключенные", id: true },
              { name: "Не исключенные", id: false },
            ]}
            value={null}
            className="no-float mb-25"
            placeholder="выберите значение"
            onChange={onSourceTypeChange}
          />
        </div>
      );

      const isExcludedType = (
        <div className="file-upload">
          <Select
            id="id"
            data={[
              { name: "Исключенные", id: true },
              { name: "Не исключенные", id: false },
            ]}
            value={null}
            className="no-float mb-25"
            placeholder="выберите значение"
            onChange={onSourceTypeChange}
          />
        </div>
      );

      const modalFilter = (
        <Modal
          type="modalShietFilter"
          header="Выбрать значение"
          children={fileContent}
          getFiltered={onFilterClickHandler}
        />
      );

      const modalExcludedFilter = (
        <Modal
          type="modalShietFilter"
          header="Выбрать значение"
          children={fileContent}
          getFiltered={onExcludedFilterClickHandler}
        />
      );

      return (
        <div className="wrapper__header-search">
          <header className="header-search">
            <form className="header-search__form">
              <div
                className="wrapper__search-input"
                ref={wrapperSearchInputRef}
              >
                <SearchInput
                  disabled={itemsAreLoading}
                  className="inHeader__input"
                  placeholder={getPlaceholder()}
                  onSearch={() => {
                    wrapperSearchInputRef.current
                      .querySelector('input[type="search"]')
                      .focus();
                    if (searchIsImpossible) {
                      return;
                    }
                    setSearchActivation(!!searchString);
                    setLastSearchStringRequest(searchString);
                    resetPagination();
                    resetSelected();
                    startIndex === 0 &&
                      fetchWithParameters(searchType, startIndex, perPage);
                  }}
                  onChange={(event) => {
                    setSearchString(event.target.value);
                  }}
                  value={searchString}
                />
                {searchType === "people" && (
                  <>
                    {isFilterModalOpen && modalFilter}
                    <CancelButton
                      className="feltroneee"
                      onClick={handleFilterModalOpen}
                    >
                      фильтр
                    </CancelButton>
                  </>
                )}
                {common && (
                  <>
                    {isFilterModalOpen && modalExcludedFilter}
                    <CancelButton
                      className="feltroneee"
                      onClick={handleExcludedFilterModalOpen}
                    >
                      фильтр
                    </CancelButton>
                  </>
                )}
                {(boolean !== undefined || searchIsActivated) && (
                  <BottomLinedButton
                    onClick={() => {
                      setSearchString("");
                      wrapperSearchInputRef.current
                        .querySelector('input[type="search"]')
                        .focus();
                      if (boolean === undefined && !searchIsActivated) {
                        return;
                      }
                      setSearchActivation(false);
                      setLastSearchStringRequest();
                      resetSelected(true);
                      resetPagination();
                      startIndex === 0 &&
                        fetchWithParameters(searchType, startIndex, perPage);
                    }}
                    className="inHeader__button-clear"
                  >
                    Очистить поиск
                  </BottomLinedButton>
                )}
              </div>
              {showSearches && isAdmin && (
                <BottomDottedButton path="/searches">
                  Сохраненные поиски
                </BottomDottedButton>
              )}
            </form>
          </header>
        </div>
      );
    }
  )
);

export default SearchHeader;
