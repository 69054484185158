import React from "react";
import { inject, observer } from "mobx-react";

import CancelButton from "common/components/Buttons/CancelButton";

const ModalFileUpload = inject(
  "ItemsStore",
  "ApplicationStore"
)(
  observer(({ header, children, onCancel, getFiltered }) => {
    return (
      <div className="modal filter">
        <p className="modal__title">{header}</p>
        <div className="modal__content full no-mt">{children}</div>
        <div className="modal__button-group static">
          <CancelButton className="feltroneee" onClick={getFiltered}>
            Применить
          </CancelButton>
          <CancelButton
            className="modal__button"
            onClick={() => {
              onCancel(false);
            }}
          >
            Отмена
          </CancelButton>
        </div>
      </div>
    );
  })
);

export default ModalFileUpload;
