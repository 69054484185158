import React, { useState } from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import Select from "common/components/Select";

import { JuryTypes } from "helpers/dictionaries";

import "./index.scss";

const ModalExportJuryList = inject(
  "ApplicationStore",
  "JuryStore",
  "ModalStore"
)(
  observer(({ onCancel, callback }) => {
    let defaultValue = "notExcluded";
    const [exludedType, setExludedType] = useState(defaultValue);

    return (
      <div className="modal">
        <h2 className="modal__title">Экспорт в файл</h2>
        <div className="modal-export-jury__body">
          <Select
            id="id"
            data={JuryTypes}
            value={defaultValue}
            placeholder="Тип экспорта"
            onChange={(type) => {
              setExludedType(type);
            }}
            className="select form__field input__text no-float no-float mb-25"
          />
        </div>

        <div className="modal__button-group static">
          <SubmitButton
            disabled={!exludedType}
            className="modal__button"
            onClick={callback.bind(null, exludedType)}
          >
            Экспорт данных
          </SubmitButton>
          <CancelButton
            className="modal__button"
            onClick={() => onCancel(false)}
          >
            Отмена
          </CancelButton>
        </div>
      </div>
    );
  })
);

export default ModalExportJuryList;
