import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import "../index.scss";

import ParametersList from "components/ParametersList";
import Modal from "components/Modal";
import ParamsButton from "common/components/Buttons/ParamsButton";
import FindButton from "common/components/Buttons/FindButton";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

const DefaultHeader = inject(
  "ModalStore",
  "SearchStore",
  "SearchStore"
)(
  observer(
    ({
      ModalStore,
      SearchStore,
      title,
      buttonText,
      searchType,
      onSearch,
      onClearSearch,
    }) => {
      const { toggleAddParameterModalOpen, isAddParameterModalOpen } =
        ModalStore;
      const {
        addParameters,
        resetExcludedOnCancel,
        displayedParams,
        resetSearchStore,
        setRedirected,
      } = SearchStore;

      useEffect(
        () => () => {
          setRedirected(false);
          resetSearchStore();
        },
        [resetSearchStore, setRedirected]
      );

      return (
        <div className="wrapper__header">
          <header className="header mini">
            <div className="header__container mini">
              <div className="header__title">
                <h1 className="mini">{title}</h1>
              </div>

              <ParametersList searchType={searchType} />

              {displayedParams.length === 0 && (
                <div className="header__buttons">
                  <ParamsButton
                    onClick={() => toggleAddParameterModalOpen(true)}
                  >
                    {buttonText}
                  </ParamsButton>
                </div>
              )}

              {displayedParams.length !== 0 ? (
                <div className="header__buttons">
                  <FindButton onClick={onSearch} />
                  <ParamsButton
                    onClick={() => toggleAddParameterModalOpen(true)}
                  >
                    Добавить параметр
                  </ParamsButton>
                  <BottomLinedButton transparent onClick={onClearSearch}>
                    Очистить поиск
                  </BottomLinedButton>
                </div>
              ) : null}
            </div>

            {isAddParameterModalOpen && (
              <Modal
                type="addParameter"
                callback={() => addParameters(searchType)}
                searchType={searchType}
                onCancel={resetExcludedOnCancel}
              />
            )}
          </header>
        </div>
      );
    }
  )
);

export default DefaultHeader;
