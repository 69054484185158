import React from "react";

import "./index.scss";

import { ReactComponent as Tag } from "assets/svg/tag.svg";

const Chip = ({ children, handleClick }) => {
  return (
    <div className="chip">
      <div className="chip__container">
        <p>{children}</p>
        <button type="button" onClick={handleClick}>
          <Tag />
        </button>
      </div>
    </div>
  );
};

export default Chip;
