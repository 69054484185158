import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import JuryPersonInformation from "../JuryPerson/JuryInformation";
import JuryPersonHistory from "../JuryPerson/JuryHistory";

import Tabs from "components/Tabs";
import Modal from "components/Modal";
import Breadcrumbs from "components/Breadcrumbs";
import "./index.scss";
import ImportButton from "../../../common/components/Buttons/ImportButton";
import ParamsButton from "../../../common/components/Buttons/ParamsButton";
import appStore from "../../../stores/appStore";

const JuryPerson = inject(
  "JuryPersonStore",
  "PaginationStore",
  "TabbedSwitchersStore",
  "ApplicationStore",
  "ItemsStore",
  "ModalStore"
)(
  observer(({ JuryPersonStore, ModalStore, match }) => {
    const {
      reset,
      juryPerson,
      onReject,
      onCorrectInformation,
      onFreeForm,
      formJuryExcerpt,
    } = JuryPersonStore;

    const { isManageJuryPersonOpen, isEditExclusionJuryPersonOpen } =
      ModalStore;

    const personId = match?.params.personId;
    const juryListId = match?.params.juryListId;

    useEffect(() => {
      return () => {
        reset();
      };
    }, [reset]);

    const isMku =
      appStore.isMainSpecMKU ||
      appStore.isSpecialistMKU ||
      appStore.isChiefSpecMKU ||
      appStore.isCoChiefMKU ||
      appStore.isChiefDepartmentMKU ||
      appStore.isPassportMKU;

    const tabs = [
      {
        title: "Информация",
        path: `/jury/${juryListId}/person/information/${personId}`,
        content: (
          <JuryPersonInformation juryListId={juryListId} personId={personId} />
        ),
      },
      {
        title: "История проверок",
        path: `/jury/${juryListId}/person/history/${personId}`,
        content: (
          <JuryPersonHistory personId={personId} juryListId={juryListId} />
        ),
      },
    ];

    const typeOfBreadcrumbs = juryPerson.isBasic ? "basic" : "sub";

    const breadcrumbs = [
      {
        path:
          appStore.isMainSpecMKU ||
          appStore.isSpecialistMKU ||
          appStore.isChiefSpecMKU ||
          appStore.isCoChiefMKU ||
          appStore.isChiefDepartmentMKU ||
          appStore.isPassportMKU
            ? `/jury/common/${juryListId}`
            : `/jury/${typeOfBreadcrumbs}/${juryListId}`,
        label: "Список присяжных",
      },
      {
        path: `/jury/${juryListId}/person`,
        label: `Присяжный`,
      },
    ];

    return (
      <>
        <div className="wrapper__content jury_page">
          <Breadcrumbs routes={breadcrumbs} />
          <section className="section">
            <div className="container">
              <h1 className="page-title">
                Присяжный из
                {juryPerson?.isBasic ? " основного" : " дополнительного"} списка
              </h1>
              <div className="container">
                <Tabs
                  items={isMku ? tabs.splice(0, 1) : tabs}
                  withRouter
                  match={match}
                />
                <div className={`download-buttons`}>
                  <h2 className="label">Заявления</h2>
                  <label className="people-xls-import">
                    <ImportButton className="jury-docs">
                      <span>На исключение</span>
                      <input
                        type="button"
                        className="people-xls-import__input"
                        onClick={() => {
                          onReject(personId, juryListId);
                        }}
                      />
                    </ImportButton>
                  </label>
                  <label className="people-xls-import">
                    <ImportButton className="jury-docs">
                      <span>На исправление неточностей в сведениях</span>
                      <input
                        type="button"
                        className="people-xls-import__input"
                        onClick={() => {
                          onCorrectInformation(personId, juryListId);
                        }}
                      />
                    </ImportButton>
                  </label>
                  <label className="people-xls-import">
                    <ImportButton className="jury-docs">
                      <span>Свободная форма</span>
                      <input
                        type="button"
                        className="people-xls-import__input"
                        onClick={() => {
                          onFreeForm(personId, juryListId);
                        }}
                      />
                    </ImportButton>
                  </label>
                  <h2 className="label">Выписка</h2>
                  <div className="people-xls-import-bt">
                    <ParamsButton
                      className="jury-docs"
                      onClick={() => {
                        formJuryExcerpt(personId, juryListId);
                      }}
                    >
                      Сформировать выписку
                    </ParamsButton>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isManageJuryPersonOpen && <Modal type="manageJuryPerson" />}
        {isEditExclusionJuryPersonOpen && (
          <Modal type="editExclusionJuryPerson" />
        )}
      </>
    );
  })
);
export default JuryPerson;
