import { observable, decorate, action, runInAction, set, computed } from "mobx";
import {
  EMPTY_ERR,
  INVALID_SYMBOLS_ERR,
  NO_STREET_SELECTED,
} from "helpers/errors";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import SearchStore from "./searchStore";
import AlertStore from "./alertsStore";
class BuildingsStore {
  building = null;
  streets = [];
  newBuilding = {
    location: {
      buildingNumber: "",
      street: "",
      streetId: "",
    },
  };
  parameters = [];
  incrementalId = 0;
  serverErr = "";

  get errors() {
    let error = {};
    const { hasOnlyWhiteSpaces } = ApplicationStore;

    if (!ApplicationStore.isSubmitted) {
      return error;
    }
    switch (ApplicationStore.mode) {
      case "ADD_BUILDING":
        if (!this.newBuilding.location.street) {
          error["street"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.newBuilding.location.street)) {
          error["street"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["street"] = this.serverErr;
        }

        if (!this.newBuilding.location.buildingNumber) {
          error["number"] = EMPTY_ERR;
        } else if (
          hasOnlyWhiteSpaces(this.newBuilding.location.buildingNumber)
        ) {
          error["number"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["number"] = this.serverErr;
        }

        if (!this.newBuilding.location.streetId) {
          error["street"] = NO_STREET_SELECTED;
        }
        break;
      case "EDIT_BUILDING":
        if (!this.building.street) {
          error["street"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.building.street)) {
          error["street"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["street"] = this.serverErr;
        }

        if (!this.building.number) {
          error["number"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.building.number)) {
          error["number"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["number"] = this.serverErr;
        }
        break;
      default:
        break;
    }
    return error;
  }

  reset = () => {
    runInAction(() => {
      this.building = null;
      this.streets = [];
      this.parameters = [];
      this.incrementalId = 0;
      this.serverErr = "";
      this.searchString = "";
      this.newBuilding.location.buildingNumber = "";
      this.newBuilding.location.street = "";
      this.newBuilding.location.streetId = "";
    });
  };

  resetServerErrors = () => {
    this.serverErr = "";
  };

  resetExcludedOnCancel = () => {
    let displayedParams = [];

    this.parameters.forEach((item) => {
      displayedParams.push(item.Value);
    });

    SearchStore.excluded = displayedParams;
  };

  handleChange = (key, value) => {
    this.building[key] = value;
  };

  changeBuilding = (key, field, value) => {
    set(key, field, value);
  };

  onBuildingSelect = (isEdit, value) => {
    isEdit
      ? (this.street = value)
      : set(this.newBuilding.location, "street", value);
  };

  getSelectedSuggestion = (isEdit, suggestion) => {
    if (isEdit) {
      this.building.street = suggestion.title;
      this.building.streetId = suggestion.id;
    } else {
      this.newBuilding.location.street = suggestion.title;
      this.newBuilding.location.streetId = suggestion.id;
    }
    this.streets = [];
  };

  fetchStreets = async (searchString) => {
    ApplicationStore.setLoading(true);
    await app
      .get(
        `/Buildings/SearchAddres/SearchFullAddress?searchString=${encodeURIComponent(
          searchString
        )}`
      )
      .then((res) => {
        this.streets = res.data.streets;
        if (res.data.findedStreet) {
          this.newBuilding.location.streetId = res.data.findedStreet.id;
        }
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchBuilding = async (id) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/buildings/${id}`)
      .then((res) => {
        this.building = res.data;
        this.parameters = res.data.parameters.map((item) => ({
          ...item,
          id: this.incrementalId++,
        }));
        SearchStore.excluded = res.data.parameters.map((item) => item.Value);
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          routing.push(`/404`);
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  addBuilding = () => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      ApplicationStore.setUploading(true);
      app
        .post(`/buildings`, {
          parameters: this.parameters,
          location: {
            streetId: this.newBuilding.location.streetId,
            street: this.newBuilding.location.street,
            buildingNumber: this.newBuilding.location.buildingNumber,
          },
        })
        .then((res) => {
          runInAction(() => {
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
            routing.push(`/homes/editbuilding/${res.data}`);
          });
        })
        .catch((err) => {
          if (err.response) {
            this.serverErr = err.response.data;
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  updateBuilding = (id) => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      ApplicationStore.setUploading(true);
      app
        .put(`/buildings`, {
          buildingId: id,
          parameters: this.parameters,
          location: {
            buildingId: id,
            streetId: this.building.streetId,
            street: this.building.street,
            buildingNumber: this.building.number,
          },
        })
        .then(() => {
          runInAction(() => {
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
            AlertStore.toggleHomesEditBuildingSuccessAlertOpen(true);
          });
        })
        .catch((err) => {
          if (err.response) {
            this.serverErr = err.response.data;
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  deleteBuilding = (id) => {
    app
      .delete(`/buildings/${id}`)
      .then(() => {
        runInAction(() => {
          routing.push("/homes");
        });
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  addIncludedBuildingFormParams = () => {
    this.parameters = this.parameters.concat(
      SearchStore.homesParams
        .filter(
          (item) =>
            item.checkedValue &&
            item.possibleValues.some((item) =>
              SearchStore.excluded.includes(item)
            )
        )
        .map((item) => ({
          Title: item.title,
          Value: item.checkedValue,
          id: this.incrementalId++,
        }))
    );
    SearchStore.resetChecked("homes");
  };

  delBuildingFormParam = (id, value) => {
    const index = this.parameters.findIndex((item) => item.id === id);
    if (index !== -1) {
      const excludedIndex = SearchStore.excluded.findIndex(
        (item) => item === value
      );
      SearchStore.excluded.splice(excludedIndex, 1);
      this.parameters.splice(index, 1);
    }
  };
}

decorate(BuildingsStore, {
  building: observable,
  newBuilding: observable,
  parameters: observable,
  streets: observable,
  serverErr: observable,

  errors: computed,

  reset: action,
  handleChange: action,
  fetchStreets: action,
  fetchBuilding: action,
  deleteBuilding: action,
  changeBuilding: action,
  getSelectedSuggestion: action,
  addBuilding: action,
  updateBuilding: action,

  onBuildingSelect: action,

  addIncludedBuildingFormParams: action,
  addBuildingFormParam: action,
  delBuildingFormParam: action,
  resetServerErrors: action,
});

export default new BuildingsStore();
