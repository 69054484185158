import { action, computed, observable, decorate, runInAction } from "mobx";
import {
  EMPTY_ERR,
  PASSWORDS_ERR,
  INVALID_PASSWORD_ERR,
  WEAK_PASSWORD_ERR,
} from "helpers/errors";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import AlertStore from "./alertsStore";

import { getSurnameWithInitials } from "../../helpers/commonMethods";

class AuthStore {
  user = {
    login: "",
    password: "",
  };
  loginErr = "";
  passwordErr = "";

  password = "";
  newPassword = "";
  repeatPassword = "";

  get errors() {
    let error = {};
    if (!ApplicationStore.isSubmitted) {
      return error;
    }
    switch (ApplicationStore.mode) {
      case "LOG_IN":
        if (!this.user.login) {
          error["login"] = EMPTY_ERR;
        } else if (this.loginErr) {
          error["login"] = this.loginErr;
        }
        if (!this.user.password) {
          error["password"] = EMPTY_ERR;
        } else if (this.passwordErr) {
          error["password"] = this.passwordErr;
        }
        break;
      case "CHANGE_PASSWORD":
        if (!this.password) {
          error["pass"] = EMPTY_ERR;
        } else if (this.passwordErr) {
          error["pass"] = this.passwordErr;
        }
        if (!this.newPassword) {
          error["newpass"] = EMPTY_ERR;
        } else if (this.newPassword.length < 6) {
          error["newpass"] = WEAK_PASSWORD_ERR;
        }
        if (!this.repeatPassword) {
          error["repeat"] = EMPTY_ERR;
        }
        if (this.newPassword !== this.repeatPassword) {
          error["newpass"] = PASSWORDS_ERR;
          error["repeat"] = PASSWORDS_ERR;
        }
        break;
      default:
        break;
    }
    return error;
  }

  setUser = (field, value) => {
    this.user[field] = value;
  };

  setPassword = (field, value) => {
    this[field] = value;
  };

  reset = () => {
    this.user.login = "";
    this.user.password = "";
    this.loginErr = "";
    this.passwordErr = "";
    this.password = "";
    this.newPassword = "";
    this.repeatPassword = "";
  };

  login = async () => {
    this.loginErr = "";
    this.passwordErr = "";

    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      ApplicationStore.setUploading(true);
      await app
        .post(`/accounts/login`, {
          login: this.user.login,
          password: this.user.password,
          rememberUser: true,
        })
        .then(() => {
          this.reset();
          this.getCurrentUser();
          ApplicationStore.setUploading(false);
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
          routing.push("/");
        })
        .catch((err) => {
          if (err.response?.status === 406) {
            this.loginErr = err.response?.data;
          }
          if (err.response?.status === 400) {
            this.passwordErr =
              typeof err.response?.data === "string"
                ? err.response.data
                : "Слишком короткий пароль";
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  changePassword = () => {
    this.passwordErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      ApplicationStore.setUploading(true);
      app
        .put(`/accounts/changepassword`, {
          currentPassword: this.password,
          newPassword: this.newPassword,
          passwordConfirm: this.repeatPassword,
        })
        .then(() => {
          this.reset();
          ApplicationStore.setUploading(false);
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
          AlertStore.toggleProfileSuccesAlertOpen(true);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.passwordErr = INVALID_PASSWORD_ERR;
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  getCurrentUser = async () => {
    await app.get(`/users/currentuser`).then((res) => {
      let data = {
        ...res.data,
        modules: res.data.modules.concat([getSurnameWithInitials(res.data)]),
      };
      ApplicationStore.setGlobalFieldValue("loggedInUser", data);
    });
  };

  logout = () => {
    app.post(`/accounts/logout`).then(() => {
      runInAction(() => {
        ApplicationStore.setGlobalFieldValue("loggedInUser", null);
        localStorage.removeItem(ApplicationStore.loggedInUser);
        routing.push("/login");
      });
    });
  };
}

decorate(AuthStore, {
  user: observable,
  loginErr: observable,
  passwordErr: observable,
  password: observable,
  newPassword: observable,
  repeatPassword: observable,

  errors: computed,

  getCurrentUser: action,
  setPassword: action,
  setUser: action,
  reset: action,
  login: action,
  logout: action,
});

export default new AuthStore();
