import { observable, decorate, action, toJS } from "mobx";

export class LoaderFileStore {
  constructor() {
    this.file = null;
    this.loaderOpenAccordeon = false;
    this.fileNames = [];
    this.files = [];
  }

  setFile = (file) => {
    this.fileNames.push(file.name);
    this.file = null;
    this.files.push(file);
  };

  removeFromList = (name) => {
    const nameIndex = this.fileNames.findIndex((id) => id === name);
    this.fileNames.splice(nameIndex, 1);

    const fileIndex = this.files.findIndex((item) => item.name === name);
    this.files.splice(fileIndex, 1);
  };

  selectLoaderOpened = (type) => {
    this.loaderOpenAccordeon = type;
  };

  clearState = () => {
    this.file = null;
    this.loaderOpenAccordeon = false;
    this.fileNames = [];
    this.files = [];
  };
}

decorate(LoaderFileStore, {
  file: observable,
  loaderOpenAccordeon: observable,
  fileNames: observable,
  files: observable,

  setFile: action,
  selectLoaderOpened: action,
  removeFromList: action,
  clearState: action,
});
