import React from "react";

import "./index.scss";

const BottomLinedButton = ({ onClick, className, children, transparent }) => (
  <button
    type="button"
    onClick={onClick}
    className={`button__bottom-lined ${className || ""} ${
      transparent ? "" : "button-block"
    }`}
  >
    {children}
  </button>
);

export default BottomLinedButton;
