import React, { useEffect, useCallback } from "react";
import { observer, inject } from "mobx-react";

import Pagination from "components/Pagination";

import Calendar from "components/Calendar";
import LoaderSpinner from "components/Loader";
import Empty from "components/Empty";
import ActivityPreview from "components/ActivityPreview";

import { dateStringToDDMonthYYYY } from "helpers/formattedDate";

const toMonth = new Date(new Date().getFullYear(), new Date().getMonth());

const Archive = inject(
	"PaginationStore",
	"ActivitiesStore",
	"ApplicationStore"
)(
	observer(({ PaginationStore, ActivitiesStore, ApplicationStore }) => {
		const {
			fetchActivities,
			activities,
			fetchDatesRange,
			parsedStartDate,
			reset,
			activitiesWereFilteredByDate,
		} = ActivitiesStore;
		const { currentPage, paginate, startIndex, resetPagination } = PaginationStore;
		const { isLoading } = ApplicationStore;

		const getComing = useCallback(() => {
			fetchActivities({ startIndex, archived: true, date: "" });
		}, [startIndex, fetchActivities]);

		const getComingByDate = async (date) => {
			await fetchActivities({ startIndex: 0, archived: true, date });
		};

		useEffect(() => {
			getComing();
			fetchDatesRange();
			return () => {
				reset();
			};
		}, [getComing, fetchDatesRange, reset]);

		useEffect(() => resetPagination(), [resetPagination]);

		const loaderOrEmpty = isLoading ? <LoaderSpinner /> : <Empty isSearch={activitiesWereFilteredByDate} />;

		return (
			<div className="activities__wrapper">
				{activities?.items && activities.items.length !== 0 ? (
					<div className="activities__list">
						<ul className="activities">
							{activities.items.map((activity) => (
								<ActivityPreview
									key={activity.id}
									title={activity.title}
									location={activity.location}
									date={dateStringToDDMonthYYYY(activity.date)}
									dateOfEnd={dateStringToDDMonthYYYY(activity.dateOfEnd)}
									href={`/activity/${activity.id}`}
								/>
							))}
						</ul>
						<div className="wrapper__pagination">
							<Pagination
								perPage={10}
								total={activities.count}
								currentPage={currentPage + 1}
								handlePaginate={(page) => paginate(page - 1)}
							/>
						</div>
					</div>
				) : (
					<div className="activities__list">{loaderOrEmpty}</div>
				)}
				<Calendar
					getByDate={getComingByDate}
					isLoading={isLoading}
					reset={getComing}
					startDate={parsedStartDate}
					endDate={toMonth}
					disabledDays={"afterToday"}
				/>
			</div>
		);
	})
);

export default Archive;
