import React from "react";

import Chip from "components/Chip";
import PeopleForm from "../AddPeople/PeopleForm";

import { genders, registrationTypes, passportTypes } from "helpers/dictionaries";
import { EMPTY_DATE, parseDate } from "helpers/formattedDate";

const PesonHistoryView = ({ person, resetView }) => {
	const parsedDate = (date) => {
		return !date || date === EMPTY_DATE ? null : parseDate(date.slice(0, 10));
	};

	const street = person.street ? person.street : "";
	const number = person.buildingNumber ? ", д. " + person.buildingNumber : "";
	const flat = person.appartamentNumber ? ", кв. " + person.appartamentNumber : "";

	if (person.passport) {
		person.passport.gettingDate = parsedDate(person.passport.gettingDate);
		person.passport.passportType =
			passportTypes.find((item) => item.id === person.passport.passportType)?.name || "";
	}

	person.startOfRegistration = parsedDate(person.startOfRegistration);
	person.endOfRegistration = parsedDate(person.endOfRegistration);
	person.endOfTemporalRegistration = parsedDate(person.endOfTemporalRegistration);

	person.birthDate = parsedDate(person.birthDate);
	person.gender = genders.find((item) => item.id === person.gender)?.name;
	person.registrationType = registrationTypes.find((item) => item.id === person.registrationType)?.name || "";
	person.address = street + number + flat;

	return (
		<>
			<Chip children={"Изменено: " + parsedDate(person.dateTimeOfChange)} handleClick={resetView} />
			<PeopleForm isEdit={false} historyPerson={person} isHistoryCard />
		</>
	);
};

export default PesonHistoryView;
