import { observable, decorate, action } from "mobx";

export class AutocompleteStore {
	constructor() {
		this.resetAutocomplete();
	}

	resetAutocomplete = () => {
		this.filteredSuggestions = [];
		this.showSuggestions = false;
		this.toggleFetch = true;
		this.timer = null;
		this.lastInput = "";
		this.currInput = "";
	};

	hideSuggestions = () => (this.showSuggestions = false);

	filterSuggestions = (suggestions, userInput) => {
		this.filteredSuggestions = suggestions;
	};

	handleChange = (key, value) => {
		this[key] = value;
	};

	onChange = (e, onChangeCallback, noFetchCallback) => {
		this.currInput = e.currentTarget.value;
		this.showSuggestions = true;
		onChangeCallback(e);
		if (!this.toggleFetch && noFetchCallback) {
			noFetchCallback(this.currInput);
		}
	};

	onFocus = (fetchCallback) => {
		this.timer = setInterval(() => {
			if (this.currInput.length > 2 && this.currInput.trim() && this.lastInput !== this.currInput) {
				this.toggleFetch && fetchCallback(this.currInput);
				this.lastInput = this.currInput;
			}
		}, 100);
	};

	onBlur = () => {
		clearInterval(this.timer);
	};

	onClick = (title, onSelectCallback, noFetchCallback) => {
		this.filteredSuggestions = [];
		this.showSuggestions = false;
		onSelectCallback(title);
	};
}

decorate(AutocompleteStore, {
	filteredSuggestions: observable,
	showSuggestions: observable,
	currInput: observable,

	filterSuggestions: action,
	resetAutocomplete: action,
	onChange: action,
	onClick: action,
	onFocus: action,
	onBlur: action,
	hideSuggestions: action,
	handleChange: action,
});

export default new AutocompleteStore();
