import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import Breadcrumbs from "components/Breadcrumbs";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import Datepicker from "common/components/Inputs/Datepicker";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

import useDocumentTitle from "hooks/useDocumentTitle";

const fromMonth = new Date(new Date().getFullYear(), new Date().getMonth());

const AddEditCompany = inject(
  "CompaniesStore",
  "ApplicationStore"
)(
  observer(({ CompaniesStore, ApplicationStore, match }) => {
    const {
      company,
      newCompany,
      changeCompany,
      changeCompanyDate,
      fetchCompany,
      parsedDate,
      titleCache,
      addCompany,
      editCompany,
      editedDate,
      errors,
      reset,
    } = CompaniesStore;
    const { setGlobalFieldValue, isLoading, isUploading } = ApplicationStore;

    const [date, setDate] = useState(undefined);

    const isEdit = match.url.includes("/handbooks/editcompany");
    const id = match.params.id;

    useEffect(() => {
      if (isEdit) {
        setGlobalFieldValue("mode", "EDIT_COMPANY");
        fetchCompany(id);
      } else {
        setGlobalFieldValue("mode", "ADD_COMPANY");
      }
      return () => {
        setGlobalFieldValue("mode", "");
        setGlobalFieldValue("isSubmitted", false);
        reset();
      };
    }, [fetchCompany, isEdit, id, reset, setGlobalFieldValue]);

    useDocumentTitle(
      () => (isEdit ? "Редактировать кампанию" : "Добавить кампанию"),
      [isEdit]
    );

    const breadcrumbs = [
      { path: "/handbooks/companies", label: "Кампании" },
      {
        path: "/handbooks/addpeopleparam",
        label: isLoading
          ? "Загрузка..."
          : isEdit
          ? `${titleCache || ""}`
          : "Добавить кампанию",
      },
    ];

    const handleSubmit = (e) => {
      e.preventDefault();
      isEdit ? editCompany() : addCompany();
    };

    return (
      <div className="wrapper__content">
        <Breadcrumbs routes={breadcrumbs} />
        <section className="section">
          <div className="container">
            <h1 className="page-title">
              {isLoading
                ? "Загрузка..."
                : isEdit
                ? `${titleCache}`
                : "Добавить кампанию"}
            </h1>
            <form className="form" onSubmit={handleSubmit} noValidate>
              <FloatLabel
                label="Наименование"
                value={isEdit && company ? company.caption : newCompany.caption}
              >
                <TextInput
                  disabled={isLoading}
                  type="text"
                  name="caption"
                  value={
                    isEdit && company ? company.caption : newCompany.caption
                  }
                  placeholder=""
                  onChange={(e) => {
                    isEdit
                      ? changeCompany(company, "caption", e.target.value)
                      : changeCompany(newCompany, "caption", e.target.value);
                  }}
                  className={`form__field ${errors.caption ? "warning" : ""}`}
                  autoFocus
                />
              </FloatLabel>
              {errors.caption && (
                <p className="warning-text margin-bottom-12">
                  {errors.caption}
                </p>
              )}

              <FloatLabel
                label="Дата проведения"
                value={isEdit && company ? editedDate : date}
              >
                <Datepicker
                  disabled={isLoading}
                  name="date"
                  date={isEdit && company ? parsedDate : date}
                  changeDate={(day) => {
                    setDate(day);
                    changeCompanyDate(
                      isEdit && company ? company : newCompany,
                      day
                    );
                  }}
                  className={`form__field ${errors.date ? "warning" : ""}`}
                  startDate={fromMonth}
                />
              </FloatLabel>
              {errors.date && (
                <p className="warning-text margin-bottom-12">{errors.date}</p>
              )}

              <div className="form__buttons">
                <SubmitButton
                  className="form__button"
                  disabled={isUploading || isLoading}
                >
                  {isUploading || isLoading ? "Загрузка..." : "Сохранить"}
                </SubmitButton>
                <CancelButton className="form__button">Отмена</CancelButton>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  })
);

export default AddEditCompany;
