import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";

import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import DeleteButton from "common/components/Buttons/DeleteButton";

import useDocumentTitle from "hooks/useDocumentTitle";

const AddEditPersonParam = inject(
	"ModalStore",
	"PeopleParamsStore",
	"ApplicationStore"
)(
	observer(({ PeopleParamsStore, ApplicationStore, ModalStore, match }) => {
		const {
			fetchParameter,
			changeParameter,
			personParam,
			newParam,
			titleCache,
			addParam,
			updateParam,
			deleteParam,
			errors,
			reset,
			resetServerErrors,
		} = PeopleParamsStore;
		const { isUploading, setGlobalFieldValue } = ApplicationStore;
		const { toggle, isSubmit, resetModal } = ModalStore;

		const isEdit = match.url.includes("/handbooks/editpeopleparam");
		const title = match.params.title;

		useEffect(() => {
			if (isEdit) {
				setGlobalFieldValue("mode", "EDIT_PERSON_PARAM");
				fetchParameter(title);
			} else {
				setGlobalFieldValue("mode", "ADD_PERSON_PARAM");
			}
			return () => {
				setGlobalFieldValue("mode", "");
				setGlobalFieldValue("isSubmitted", false);
				reset();
			};
		}, [fetchParameter, isEdit, title, reset, setGlobalFieldValue]);

		useEffect(() => {
			if (isSubmit) {
				deleteParam();
			}
		}, [isSubmit, deleteParam]);

		useEffect(() => () => resetModal(), [resetModal]);

		useDocumentTitle(() => (isEdit ? "Редактировать параметр" : "Добавить параметр"), [isEdit]);

		const breadcrumbs = [
			{ path: "/handbooks", label: "Параметры людей" },
			{
				path: "/handbooks/addpeopleparam",
				label: isEdit ? `${titleCache || ""}` : "Добавить параметр",
			},
		];

		const handleSubmit = (e) => {
			e.preventDefault();
			isEdit ? updateParam() : addParam();
		};

		const modalDanger = <Modal type="danger" />;
		const pageTitleInEditorMode = titleCache || "";

		return (
			<div className="wrapper__content">
				{modalDanger}
				<Breadcrumbs routes={breadcrumbs} />
				<section className="section">
					<div className="container">
						<h1 className="page-title">{isEdit ? `${pageTitleInEditorMode}` : "Добавить параметр"}</h1>
						{isEdit && (
							<DeleteButton
								className="page-link"
								disabled={isUploading}
								onClick={() => {
									toggle(true);
								}}>
								Удалить параметр
							</DeleteButton>
						)}
						<form className="form" onSubmit={handleSubmit} noValidate>
							<FloatLabel
								label="Параметр"
								value={isEdit && personParam ? personParam.title : newParam.title}>
								<TextInput
									type="text"
									name="title"
									value={isEdit && personParam ? personParam.title : newParam.title}
									placeholder=""
									onChange={(e) => {
										resetServerErrors();
										isEdit
											? changeParameter(personParam, e.target.value)
											: changeParameter(newParam, e.target.value);
									}}
									className={`form__field ${errors.title ? "warning" : ""}`}
									autoFocus
								/>
							</FloatLabel>
							{errors.title && <p className="warning-text margin-bottom-12">{errors.title}</p>}
							<div className="form__buttons">
								<SubmitButton className="form__button" disabled={isUploading}>
									{isUploading ? "Загрузка..." : "Сохранить"}
								</SubmitButton>
								<CancelButton className={"form__button"}>Отмена</CancelButton>
							</div>
						</form>
					</div>
				</section>
			</div>
		);
	})
);

export default AddEditPersonParam;
