import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Checkbox from "../../../../common/components/Inputs/Checkbox";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import Pagination from "components/Pagination";

import excludeStore from "../../../../stores/components/excludeStore";
import { PaginationStore } from "stores/common/paginationStore";
import LoaderSpinner from "../../../Loader";

const paginationStore = new PaginationStore();

const List = () => {
  const {
    currentPage,
    perPage,
    changePerPage,
    paginate,
    resetPagination,
    resetAll,
    startIndex,
  } = paginationStore;

  useEffect(() => {
    excludeStore.list.length !== 0 &&
      excludeStore.sendRequest(false, startIndex, perPage);
  }, [startIndex, perPage]);

  useEffect(() => {
    return () => {
      resetPagination();
      resetAll();
    };
  }, []);

  return (
    <div className="result-list">
      {excludeStore.list.length !== 0 && (
        <span className="result-length">
          Исключено - {excludeStore.ids.length}
        </span>
      )}
      {!excludeStore.loading ? (
        excludeStore.list.map((item, idx) => {
          return (
            <div
              className="result-list__item"
              key={idx}
              onClick={() => {
                excludeStore.listCheck(item.id);
              }}
            >
              <Checkbox
                key={idx}
                checkBoxId={item.name}
                title={item.id}
                checked={item.checked}
              >
                <span className="name">
                  {item.name} {item.patronymic} {item.surname}
                </span>
                <span className="address">
                  {item.street}, {item.buildingNumber}, {item.appartamentNumber}
                </span>
              </Checkbox>
            </div>
          );
        })
      ) : (
        <LoaderSpinner />
      )}
      {excludeStore.list.length !== 0 && (
        <Pagination
          perPage={perPage}
          total={excludeStore.data?.count}
          currentPage={currentPage + 1}
          handlePaginate={(page) => paginate(page - 1)}
          isShowPerPage
          handleChangePerPage={(count) => changePerPage(count)}
        />
      )}
      {excludeStore.list.length !== 0 && (
        <div className="formButtons">
          <SubmitButton
            className="formButton"
            onClick={excludeStore.sendExcludeFromList}
          >
            Подтвердить
          </SubmitButton>
          <CancelButton
            onClick={excludeStore.cleanChoozenState}
            className="formButton"
          >
            Очистить все
          </CancelButton>
        </div>
      )}
    </div>
  );
};

export default observer(List);
