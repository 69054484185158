import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import fileSaver from "file-saver";

import "./index.scss";

import Select from "common/components/Select";
import FileInput from "common/components/Inputs/FileInput";
import AddButton from "common/components/Buttons/AddButton";
import ImportButton from "common/components/Buttons/ImportButton";

import HeaderWithCount from "components/HeaderWithCount";
import DefaultHeader from "components/Header/Default";
import SearchHeader from "components/Header/Search";
import Modal from "components/Modal";

import PeopleList from "pages/People/PeopleList";

import useDocumentTitle from "hooks/useDocumentTitle";

import { importTypes } from "helpers/importTypes";
import { isValidFileType } from "helpers/fileTypes";
import app from "../../utils/axiosConfig";

const People = inject(
  "SearchStore",
  "ItemsStore",
  "ModalStore",
  "PaginationStore"
)(
  observer(({ SearchStore, ItemsStore, ModalStore, PaginationStore }) => {
    const { isSubmitted, resetSaved, setSubmitted, resetFiltration } =
      SearchStore;
    const {
      file,
      setFile,
      selectedSource,
      importItemsFromExcel,
      isSearchSubmitted,
      itemsAreLoading,
      currentUploadingFilename,
      setSearchActivation,
      fetchWithParameters,
      resetSelected,
      setSource,
      searchIsActivated,
      setLastSearchStringRequest,
      setNecessityToSaveRecentSearches,
      trackProgressPeopleImportFile,
      peopleImportFileIsLoading,
      peopleProgressInterval,
      md5File,
    } = ItemsStore;
    const {
      resetModal,
      isFileUploadModalOpen,
      toggleFileUploadModal,
      isImportResultsOpen,
    } = ModalStore;
    const { startIndex, perPage, resetAll } = PaginationStore;

    const inputFile = useRef(null);

    useEffect(() => {
      if (
        selectedSource &&
        currentUploadingFilename &&
        selectedSource?.id &&
        !peopleProgressInterval &&
        md5File
      ) {
        trackProgressPeopleImportFile();
      }
    }, [
      currentUploadingFilename,
      md5File,
      peopleProgressInterval,
      selectedSource,
      trackProgressPeopleImportFile,
    ]);

    useEffect(() => {
      return () => {
        setSearchActivation(false);
        setLastSearchStringRequest();
      };
    }, [setSearchActivation, setLastSearchStringRequest]);

    useEffect(
      () => () => {
        resetModal();
        resetSaved();
      },
      [resetModal, resetSaved]
    );

    useDocumentTitle(
      () =>
        isSubmitted || isSearchSubmitted || searchIsActivated
          ? `Результаты поиска`
          : "Люди",
      [isSubmitted, isSearchSubmitted, searchIsActivated]
    );

    const prepareToXlsImport = () => {
      const file = inputFile.current.files[0];
      const type = file && file.name.split(".")[1];
      const isValid = isValidFileType(type);
      if (!isValid) {
        setFile(null);
        return;
      } else {
        setFile(file);
      }
      inputFile.current.value = "";
    };

    const onSourceTypeChange = (typeId) => {
      const option = importTypes.find((type) => type.id === typeId);
      setSource(option);
    };

    const downloadFIle = async () => {
      await app
        .post(`/People/GetTableWithAllPeople`, {}, { responseType: "blob" })
        .then((res) => {
          const fileName = `Экспорт.xlsx`;
          fileSaver.saveAs(res.data, fileName);
        });
    };

    const fileContent = (
      <div className="file-upload">
        <Select
          id="id"
          data={importTypes}
          value={selectedSource ? selectedSource.id : null}
          className="no-float mb-25"
          placeholder="Выберите источник"
          onChange={onSourceTypeChange}
          disabled={peopleImportFileIsLoading}
        />

        <FileInput
          currentUploadingFilename={currentUploadingFilename}
          ref={inputFile}
          file={file}
          accept=".xlsx, .txt"
          onChange={(e) => prepareToXlsImport(e)}
          disabled={!selectedSource || peopleImportFileIsLoading}
        />
      </div>
    );

    const modalFileUpload = (
      <Modal
        type="modalFileUpload"
        header="Импорт из файла"
        children={fileContent}
        onUpload={importItemsFromExcel}
        name={file && file.name}
      />
    );

    const modalImportResult = (
      <Modal type="modalImportResult" header="Результат импортирования" />
    );

    return (
      <>
        <SearchHeader />
        <DefaultHeader
          title="Расширенный поиск"
          buttonText="Указать параметры для поиска"
          searchType="all"
          onSearch={() => {
            resetAll();
            resetSelected();
            setSubmitted(true);
            setNecessityToSaveRecentSearches(true);
            startIndex === 0 && fetchWithParameters("all", startIndex, perPage);
          }}
          onClearSearch={() => {
            resetFiltration();
            resetSelected();
            resetAll();
            isSubmitted &&
              startIndex === 0 &&
              fetchWithParameters("all", startIndex, perPage);
          }}
        />
        <div className="wrapper__content">
          {isFileUploadModalOpen && modalFileUpload}
          {isImportResultsOpen && modalImportResult}

          <section className="section">
            <div className="container">
              <HeaderWithCount
                loading={itemsAreLoading}
                title={
                  isSubmitted || isSearchSubmitted || searchIsActivated
                    ? "Результаты поиска"
                    : "Люди"
                }
              />
              {!isSubmitted && (
                <>
                  <Link to="/people/addperson">
                    <AddButton>Добавить человека</AddButton>
                  </Link>
                  <label className="people-xls-import">
                    <ImportButton className="people-xls-import__button">
                      <span>Импорт из файла</span>
                      <input
                        type="button"
                        className="people-xls-import__input"
                        onClick={() => {
                          toggleFileUploadModal(true);
                        }}
                      />
                    </ImportButton>
                  </label>
                  <AddButton onClick={downloadFIle} className="export-xcsl">
                    Экспорт
                  </AddButton>
                </>
              )}
              <PeopleList isSearch={isSubmitted || isSearchSubmitted} />
            </div>
          </section>
        </div>
      </>
    );
  })
);

export default People;
