import { decorate, observable, action, toJS } from "mobx";
import app from "../../utils/axiosConfig";
import modalStore from "./modalStore";
import { LoaderFileStore } from "../../components/Modal/ModalExcludesResult/FileLoader/loaderFileStore";
import itemsStore from "../common/itemsStore";

class ExcludeStore {
  constructor() {
    this.loaderFileStore = new LoaderFileStore();
    this.value = "";
    this.streetValue = "";
    this.nameOpenedAccordeon = false;
    this.streetOpenedAccordeon = false;
    this.id = null;
    this.nameWords = [];
    this.streetWords = [];
    this.ids = [];
    this.list = [];
    this.data = null;
    this.loading = false;
  }

  changeFIO = (value) => {
    this.value = value.target.value;
  };

  changeStreet = (value) => {
    this.streetValue = value.target.value;
  };

  changeVisibility = (open, id) => {
    this.id = id;
    modalStore.toggleModalExcludesOpen(open);
  };

  selectNameOpened = (type) => {
    this.nameOpenedAccordeon = type;
  };

  selectStreetOpened = (type) => {
    this.streetOpenedAccordeon = type;
  };

  removeFormList = (item) => {
    const indx = this.nameWords.findIndex((it) => it === item);

    this.nameWords.splice(indx, 1);
  };

  removeFormStreetList = (item) => {
    const indx = this.streetWords.findIndex((it) => it === item);

    this.streetWords.splice(indx, 1);
  };

  sendRequest = async (button, startIndex = 0, itemsCount = 50) => {
    this.loading = true;

    if (button) {
      this.ids = [];
    }

    const formData = new FormData();

    this.nameWords.forEach((item) => {
      formData.append("ByNationality", item);
    });

    this.streetWords.forEach((item) => {
      formData.append("ByAddresses", item);
    });

    this.loaderFileStore.files.forEach((item) => {
      formData.append("FromFile", item);
    });

    app
      .post(
        `/JuryLists/${this.id}/excludebyparams?startIndex=${startIndex}&itemsCount=${itemsCount}`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          const { data } = res;

          this.data = data;
          this.list = data.people.map((item) => {
            const obj = item;
            obj.checked = false;
            const indx = this.ids.findIndex((item) => item.id === obj.id);
            if (indx > -1) {
              obj.checked = true;
            }
            return obj;
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  onNameKeyDownEvent = (e) => {
    if (e.key === "Enter") {
      this.nameWords.push(this.value);
      this.value = "";
    }
  };

  onStreetKeyDownEvent = (e) => {
    if (e.key === "Enter") {
      this.streetWords.push(this.streetValue);
      this.streetValue = "";
    }
  };

  listCheck = (id) => {
    const idx = this.list.findIndex((item) => item.id === id);
    this.list[idx].checked = !this.list[idx].checked;

    const indx = this.ids.findIndex((item) => item.id === id);

    if (indx > -1) {
      this.ids.splice(indx, 1);
    } else {
      this.ids.push({ id, reasonIds: this.list[idx].reasons });
    }
  };

  sendExcludeFromList = () => {
    app
      .post(`JuryLists/${this.id}/excludebyids`, this.ids)
      .then(() => {
        itemsStore.fetchItems(`/JuryLists/${this.id}/common`, "get", 0, 50);
        modalStore.toggleModalExcludesOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  cleanChoozenState = () => {
    this.list.map((item) => {
      return (item.checked = false);
    });
    this.ids = [];
  };

  clearState = () => {
    this.value = "";
    this.streetValue = "";
    this.nameOpenedAccordeon = false;
    this.streetOpenedAccordeon = false;
    this.id = null;
    this.nameWords = [];
    this.streetWords = [];
    this.list = [];
    this.ids = [];
    this.data = null;
    this.loading = false;
    this.loaderFileStore.clearState();
    modalStore.toggleModalExcludesOpen(false);
    modalStore.resetModal();
  };
}

decorate(ExcludeStore, {
  value: observable,
  nameOpenedAccordeon: observable,
  streetOpenedAccordeon: observable,
  streetValue: observable,
  id: observable,
  nameWords: observable,
  streetWords: observable,
  list: observable,
  ids: observable,
  data: observable,
  loading: observable,

  changeFIO: action,
  changeVisibility: action,
  selectNameOpened: action,
  selectStreetOpened: action,
  changeStreet: action,
  sendRequest: action,
  onNameKeyDownEvent: action,
  onStreetKeyDownEvent: action,
  removeFormList: action,
  removeFormStreetList: action,
  listCheck: action,
});

export default new ExcludeStore();
