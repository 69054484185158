import React from "react";
import { inject, observer } from "mobx-react";

import { ReactComponent as Alert } from "assets/svg/alert.svg";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

const ModalIntersect = inject(
  "JuryStore",
  "ApplicationStore"
)(
  observer(
    ({
      JuryStore,
      ApplicationStore,
      onSubmit,
      onCancel,
      message,
      callback,
    }) => {
      return (
        <div className="modal">
          <p className="modal__title static">Внимание</p>
          <Alert className="modal__icon" />
          <p className="modal__helper-text static">
            Возникло пересечение. Вы уверены, что хотите сохранить?
          </p>
          <div className="modal__button-group static">
            <SubmitButton className="modal__button" onClick={callback}>
              ДА
            </SubmitButton>
            <CancelButton
              className="modal__button"
              onClick={() => onCancel(false)}
            >
              НЕТ
            </CancelButton>
          </div>
        </div>
      );
    }
  )
);

export default ModalIntersect;
