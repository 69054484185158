import React from "react";
import InputMask from "react-input-mask";

import "./index.scss";

const Timepicker = ({ name, className, placeholder, value, onChange, disabled, autoFocus }) => {
	return (
		<InputMask
			name={name}
			mask="99:99"
			onChange={onChange}
			value={value}
			className={`input__text time-picker${className || ""}`}
			placeholder={placeholder}
			disabled={disabled || false}
			autoFocus={autoFocus || false}
		/>
	);
};

export default Timepicker;
