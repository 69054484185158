import React from "react";
import "./index.scss";

const RadioButtons = ({ options, checked, onChange, inputName, wrapperClassname, labelClassname, inputClassname }) => {
  return (
    <div className={"radio-wrapper" + (wrapperClassname ? wrapperClassname : "")}>
      {options.map((item) => {
        const isChecked = checked === item.value;

        return (
          <label key={item.id} className={"radio-label" + (labelClassname ? labelClassname : "")}>
            <input
              type="radio"
              value={item.value}
              checked={isChecked}
              name={inputName ? inputName : ""}
              onChange={onChange}
              className={
                "radio-input" + (isChecked ? " active" : " inactive") + (inputClassname ? ` ${inputClassname}` : "")
              }
            />
            <div className={"fake-radio-outer" + (isChecked ? " active" : " inactive")}>
              <div className={"fake-radio-inner" + (isChecked ? " active" : " inactive")} />
            </div>
            {item.label}
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtons;
