import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ItemsStore from "stores/common/itemsStore.js";

import Navbar from "components/Navbar";

import routes from "./index";

export const AuthLayout = () => (
  <>
    {routes.authRoutes.map((route, index) => (
      <Route key={index} {...route} />
    ))}
  </>
);

export const DefaultLayout = () => {
  useEffect(() => {
    window.addEventListener("storage", ItemsStore.loadPeopleFileImport);
    return () => {
      window.removeEventListener("storage", ItemsStore.loadPeopleFileImport);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Switch>
        <Redirect exact from="/activities" to="/activities/coming" />
        <Redirect exact from="/handbooks" to="/handbooks/peopleparams" />
        <Redirect
          exact
          from="/activity/:activityId"
          to="/activity/description/:activityId"
        />
        <Redirect exact from="/jury" to="/jury/lists" />
        <Redirect
          exact
          from="/jury/:juryListId/person/:personId"
          to="/jury/:juryListId/person/information/:personId"
        />
        {routes.defaultRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

export const AdminLayout = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Redirect exact from="/activities" to="/activities/coming" />
        <Redirect exact from="/handbooks" to="/handbooks/peopleparams" />
        <Redirect
          exact
          from="/jury/:juryListId/person/:personId"
          to="/jury/:juryListId/person/information/:personId"
        />
        <Redirect exact from="/jury" to="/jury/lists" />
        <Redirect
          exact
          from="/activity/:activityId"
          to="/activity/description/:activityId"
        />
        {routes.defaultRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        {routes.adminRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

export const AdminKOKRLayout = () => {
  useEffect(() => {
    window.addEventListener("storage", ItemsStore.loadPeopleFileImport);
    return () => {
      window.removeEventListener("storage", ItemsStore.loadPeopleFileImport);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Switch>
        <Redirect exact from="/jury" to="/jury/lists" />
        {routes.adminKokrRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect
          exact
          from="/jury/:juryListId/person/:personId"
          to="/jury/:juryListId/person/information/:personId"
        />
        <Redirect to="/404" />
      </Switch>
    </>
  );
};

export const MkuLayout = () => {
  useEffect(() => {
    window.addEventListener("storage", ItemsStore.loadPeopleFileImport);
    return () => {
      window.removeEventListener("storage", ItemsStore.loadPeopleFileImport);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Switch>
        <Redirect exact from="/jury" to="/jury/common" />
        {routes.mkuRoutes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect
          exact
          from="/jury/:juryListId/person/:personId"
          to="/jury/:juryListId/person/information/:personId"
        />
        <Redirect to="/404" />
      </Switch>
    </>
  );
};
