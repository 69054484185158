import { observable, decorate, action } from "mobx";

class AlertStore {
  constructor() {
    this.resetModal();
  }

  resetModal = () => {
    this.isOpen = false;
    this.isProfileSuccesAlertOpen = false;
    this.isPeopleEditSuccessAlertOpen = false;
    this.isHandbookEditPersonSuccessAlertOpen = false;
    this.isHomesEditBuildingSuccessAlertOpen = false;
    this.isHandbookEditBuildingParamSuccessAlertOpen = false;
  };

  toggle = (open) => {
    this.isOpen = open;
  };

  toggleProfileSuccesAlertOpen = (isOpen) => {
    this.isProfileSuccesAlertOpen = isOpen;
  };

  togglePeopleEditSuccessAlertOpen = (isOpen) => {
    this.isPeopleEditSuccessAlertOpen = isOpen;
  };

  toggleHandbookEditPersonSuccessAlertOpen = (isOpen) => {
    this.isHandbookEditPersonSuccessAlertOpen = isOpen;
  };

  toggleHomesEditBuildingSuccessAlertOpen = (isOpen) => {
    this.isHomesEditBuildingSuccessAlertOpen = isOpen;
  };

  toggleHandbookEditBuildingParamSuccessAlertOpen = (isOpen) => {
    this.isHandbookEditBuildingParamSuccessAlertOpen = isOpen;
  };
}

decorate(AlertStore, {
  isOpen: observable,
  isPeopleEditSuccessAlertOpen: observable,
  isProfileSuccesAlertOpen: observable,
  isHandbookEditPersonSuccessAlertOpen: observable,
  isHomesEditBuildingSuccessAlertOpen: observable,
  isHandbookEditBuildingParamSuccessAlertOpen: observable,

  toggleHandbookEditPersonSuccessAlertOpen: action,
  toggleProfileSuccesAlertOpen: action,
  togglePeopleEditSuccessAlertOpen: action,
  toggleHomesEditBuildingSuccessAlertOpen: action,
  toggleHandbookEditBuildingParamSuccessAlertOpen: action,
});

export default new AlertStore();
