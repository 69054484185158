import defaultRoutes from "./routes/default.routes";
import authRoutes from "./routes/auth.routes";
import adminRoutes from "./routes/admin.routes";
import adminKokrRoutes from "./routes/adminKokr.routes.js";
import mkuRoutes from "./routes/mku.routes";

const routes = {
  defaultRoutes,
  authRoutes,
  adminRoutes,
  adminKokrRoutes,
  mkuRoutes,
};

export default routes;
