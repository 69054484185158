import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

import { ReactComponent as NotFound404 } from "assets/svg/notfound.svg";

import { SERVER_ERR } from "helpers/errors";

import useDocumentTitle from "hooks/useDocumentTitle";

const NotFound = ({ message, logout, match }) => {
  const isServerErr = match?.path === "/500";

  useDocumentTitle("Страница не найдена");

  return (
    <div className="wrapper__not-found">
      <h1 className="title__not-found">
        {isServerErr || logout
          ? "Что-то пошло не так..."
          : "Страница не найдена :("}
        <br />
      </h1>
      <NotFound404 />
      <p className="helper-text__not-found">
        {isServerErr ? SERVER_ERR : message}
      </p>
      {logout ? (
        <p className="link__not-found" onClick={logout}>
          Выйти
        </p>
      ) : (
        <Link className="link__not-found" to="/">
          На главную
        </Link>
      )}
    </div>
  );
};

export default NotFound;
