import React from "react";

import Checkbox from "common/components/Inputs/Checkbox";
import RadioButton from "common/components/Inputs/RadioButton";

const ParamElement = ({
  checkBoxId,
  radioButtonId,
  title,
  checked,
  onChange,
  searchType,
  isFoundParameter,
  isAssign,
  disabled,
}) => {
  if (searchType === "people") {
    return (
      <li className={`params__item people${isAssign ? " assign" : ""}`}>
        <Checkbox
          id={checkBoxId}
          labelClassName={`checkbox-label${checked ? " checked" : ""}`}
          onChange={() =>
            onChange(searchType, title, !checked, isFoundParameter)
          }
          checked={checked || false}
          disabled={disabled}
        >
          {title}
        </Checkbox>
      </li>
    );
  }

  if (searchType === "homes") {
    return (
      <li className="params__item" label={title}>
        <RadioButton
          id={radioButtonId}
          value={title}
          label={title}
          inputName={title}
          checked={checked}
          onChange={(e) =>
            onChange(e, "title", searchType, title, !checked, isFoundParameter)
          }
          disabled={disabled}
        />
      </li>
    );
  }
};

export default ParamElement;
