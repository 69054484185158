import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./index.scss";

import AddButton from "common/components/Buttons/AddButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";
import SearchHeader from "../../../components/Header/Search";

const Users = inject(
  "ItemsStore",
  "PaginationStore",
  "ApplicationStore"
)(
  observer(({ ItemsStore, PaginationStore, ApplicationStore }) => {
    const {
      items,
      reset,
      fetchItems,
      count,
      itemsAreLoading,
      setSearchActivation,
      setLastSearchStringRequest,
      setSearchString,
    } = ItemsStore;
    const { isAdmin } = ApplicationStore;
    const {
      currentPage,
      perPage,
      changePerPage,
      paginate,
      resetPagination,
      resetAll,
      startIndex,
    } = PaginationStore;

    const [isCleared, setCleared] = useState(false);
    useEffect(() => {
      isCleared && fetchItems("/users", "get", startIndex, perPage);
      return () => {
        reset();
      };
    }, [fetchItems, startIndex, perPage, reset, isCleared]);

    useEffect(() => {
      resetPagination();
    }, [resetPagination, perPage]);

    useEffect(() => {
      resetAll();
      setCleared(true);
    }, [resetAll, setCleared]);

    // useEffect(() => {
    //   setSearchActivation(false);
    //   setLastSearchStringRequest();
    //   setSearchString("");
    //   resetAll();
    //   changePerPage(50);
    //   console.log("ми тут папис");
    //   fetchItems(`/Users`, "get", 0, 50);
    // }, [
    //   changePerPage,
    //   fetchItems,
    //   resetAll,
    //   setLastSearchStringRequest,
    //   setSearchActivation,
    //   setSearchString,
    // ]);

    const classnames = {
      header: "users-header",
      itemWrapper: "users-item",
    };

    const header = (
      <>
        <div>ФИО</div>
        <div>Логин</div>
        <div>Роли</div>
      </>
    );

    const mappedItems = items?.map((item) => (
      <>
        {isAdmin ? (
          <span className="users-item__title">
            <Link className="page-link" to={`/handbooks/editperson/${item.id}`}>
              {item.surName} {item.name} {item.patronymic}
            </Link>
          </span>
        ) : (
          <p className="users-item__title page-link">
            {item.surName} {item.name} {item.patronymic}
          </p>
        )}
        <p className="users-item__login table-value">{item.login}</p>
        {item.roles?.length ? (
          <p className="table-value">{item.roles.join(", ")}</p>
        ) : (
          <p className="table-value">—</p>
        )}
      </>
    ));

    const list =
      items?.length !== 0 ? (
        <div className="params-wrapper">
          {
            <Table
              header={header}
              items={mappedItems}
              classnames={classnames}
            />
          }
          <Pagination
            perPage={perPage}
            total={count}
            currentPage={currentPage + 1}
            handlePaginate={(page) => paginate(page - 1)}
            isShowPerPage
            handleChangePerPage={(count) => changePerPage(count)}
          />
        </div>
      ) : (
        <Empty isSearch={false} />
      );

    return (
      <>
        {isAdmin && (
          <Link to="/handbooks/addperson">
            <AddButton className="handbooks__button">
              Добавить пользователя
            </AddButton>
          </Link>
        )}
        <SearchHeader showSearches={false} searchType="users" />
        {itemsAreLoading ? <LoaderSpinner /> : isCleared && list}
      </>
    );
  })
);

export default Users;
