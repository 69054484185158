import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import Datepicker from "common/components/Inputs/Datepicker";
import TextInput from "common/components/Inputs/TextInput";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import FloatLabel from "common/components/Inputs/FloatLabel";

import { SkeletonLine } from "components/Skeleton";

import "./index.scss";

const getSkeletonFields = (height, count) => {
  return (
    <>
      {Array(count)
        .fill(1)
        .map((_) => (
          <div style={{ marginBottom: "20px", height: `${height}px` }}>
            <SkeletonLine height={height} translucent />
          </div>
        ))}
    </>
  );
};

const getNumberFields = (
  districts = [],
  districtTypeName,
  districtType,
  handler
) => {
  return (
    <>
      {districts.map((district) => (
        <div key={district.id} className="modal-add-jury__field">
          <FloatLabel
            label={`${district.name} ${districtTypeName}`}
            value={
              districts.find((region) => region.id === district.id)[
                `${districtType}`
              ]
            }
          >
            <TextInput
              type="number"
              name="number"
              autoComplete="off"
              value={
                districts.find((region) => region.id === district.id)[
                  `${districtType}`
                ]
              }
              onChange={(e) => {
                handler(districtType, e.target.value, district.id);
              }}
            />
          </FloatLabel>
        </div>
      ))}
    </>
  );
};

const ModalAddJuryList = inject(
  "JuryStore",
  "ApplicationStore",
  "ModalStore"
)(
  observer(({ onCancel, JuryStore, ApplicationStore, ModalStore }) => {
    const {
      newJuryList,
      handleNewJuryListChange,
      errors,
      addJuryList,
      handleChangeActualMask,
      actualMask,
      fetchCityDistricts,
      handleChangeNewJuryListCount,
    } = JuryStore;

    const { setGlobalFieldValue, isLoading } = ApplicationStore;

    useEffect(() => {
      if (!(newJuryList.cityDistricts && newJuryList.cityDistricts[0])) {
        fetchCityDistricts();
      }

      setGlobalFieldValue("mode", "ADD_JURY");
      return () => {
        setGlobalFieldValue("mode", "");
        setGlobalFieldValue("isSubmitted", false);
      };
    }, []);

    const handleDateToChange = (val) => {
      handleNewJuryListChange("dateTo", val);
    };

    const handleDateFromChange = (val) => {
      handleNewJuryListChange("dateFrom", val);

      if (val && !newJuryList.dateTo) {
        let curVal = new Date(val);
        handleNewJuryListChange(
          "dateTo",
          new Date(curVal.setFullYear(curVal.getFullYear() + 4))
        );
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      addJuryList();
    };

    return (
      <div className="modal modal-add-jury">
        <h1 className="modal-add-jury__title">Добавить список присяжных</h1>
        <div className="modal-add-jury__body">
          <form
            className="form modal__content-parameters"
            onSubmit={handleSubmit}
          >
            <div className="modal-add-jury__wrap">
              <h3 className="modal-add-jury__wrap__title">
                Даты действия списка
              </h3>

              <div className="modal-add-jury__blocks">
                <div className="modal-add-jury__field">
                  <FloatLabel
                    label="Начало"
                    value={actualMask["dateFromNew"] || newJuryList.dateFrom}
                  >
                    <Datepicker
                      changeActualMask={(val) =>
                        handleChangeActualMask("dateFromNew", val)
                      }
                      name="dateFromNew"
                      date={newJuryList.dateFrom}
                      changeDate={(day, modifiers) => {
                        handleDateFromChange(day);
                      }}
                      className={`form__field  datepicker-not-width ${
                        errors.dateFrom ? "warning" : ""
                      }`}
                    />
                  </FloatLabel>
                  <div className="modal-add-jury__error-block">
                    {errors.dateFrom && (
                      <p className="modal-add-jury__error--small margin-bottom-12">
                        {errors.dateFrom}
                      </p>
                    )}
                  </div>
                </div>
                <div className="modal-add-jury__field">
                  <FloatLabel
                    value={actualMask["dateToNew"] || newJuryList.dateTo}
                    label="Окончание"
                  >
                    <Datepicker
                      changeActualMask={(val) =>
                        handleChangeActualMask("dateToNew", val)
                      }
                      name="dateToNew"
                      date={newJuryList.dateTo}
                      changeDate={(day) => {
                        handleDateToChange(day);
                      }}
                      className={`form__field datepicker-not-width ${
                        errors.dateTo ? "warning" : ""
                      }`}
                    />
                  </FloatLabel>
                  <div className="modal-add-jury__error-block">
                    {errors.dateTo && (
                      <p className="modal-add-jury__error--small margin-bottom-12">
                        {errors.dateTo}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-add-jury__wrap">
              <h3 className="modal-add-jury__wrap__title">
                Выборки списков по районам
              </h3>
              <div className="modal-add-jury__blocks">
                <div className="modal-add-jury__block">
                  <h4 className="modal-add-jury__block__title">
                    Количество людей основного списка
                  </h4>
                  {isLoading ? (
                    <>{getSkeletonFields(46, 7)}</>
                  ) : (
                    <>
                      {getNumberFields(
                        newJuryList.cityDistricts,
                        "Основной",
                        "basicList",
                        handleChangeNewJuryListCount.bind(null, "cityDistricts")
                      )}
                    </>
                  )}
                </div>
                <div className="modal-add-jury__block">
                  <h4 className="modal-add-jury__block__title">
                    Количество людей запасного списка
                  </h4>
                  {isLoading ? (
                    <>{getSkeletonFields(46, 7)}</>
                  ) : (
                    <>
                      {getNumberFields(
                        newJuryList.cityDistricts,
                        "Запасной",
                        "subList",
                        handleChangeNewJuryListCount.bind(null, "cityDistricts")
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="modal-add-jury__errors-block">
                {errors.lists && (
                  <p className="warning-text margin-bottom-12">
                    {errors.lists}
                  </p>
                )}
              </div>
            </div>

            <div className="modal-add-jury__wrap">
              <h3 className="modal-add-jury__wrap__title">
                Выборки списков по районам для Екатеринбургского гарнизонного
                военного суда
              </h3>
              <div className="modal-add-jury__blocks">
                <div className="modal-add-jury__block">
                  <h4 className="modal-add-jury__block__title">
                    Количество людей основного списка
                  </h4>
                  {isLoading ? (
                    <>{getSkeletonFields(46, 7)}</>
                  ) : (
                    <>
                      {getNumberFields(
                        newJuryList.garrisonCityDistricts,
                        "Основной",
                        "basicList",
                        handleChangeNewJuryListCount.bind(
                          null,
                          "garrisonCityDistricts"
                        )
                      )}
                    </>
                  )}
                </div>
                <div className="modal-add-jury__block">
                  <h4 className="modal-add-jury__block__title">
                    Количество людей запасного списка
                  </h4>
                  {isLoading ? (
                    <>{getSkeletonFields(46, 7)}</>
                  ) : (
                    <>
                      {getNumberFields(
                        newJuryList.garrisonCityDistricts,
                        "Запасной",
                        "subList",
                        handleChangeNewJuryListCount.bind(
                          null,
                          "garrisonCityDistricts"
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="modal-add-jury__errors-block">
                {errors.garrison_lists && (
                  <p className="warning-text margin-bottom-12">
                    {errors.garrison_lists}
                  </p>
                )}
              </div>
            </div>

            <div className="modal__button-group modal-add-jury__btn-group">
              <SubmitButton disabled={false} className={"modal__button"}>
                Добавить
              </SubmitButton>
              <CancelButton
                className={"modal__button"}
                onClick={() => {
                  onCancel(false);
                }}
              >
                Отмена
              </CancelButton>
            </div>
          </form>
        </div>
      </div>
    );
  })
);

export default ModalAddJuryList;
