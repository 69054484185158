import { observable, decorate, action, computed, toJS } from "mobx";

import app from "utils/axiosConfig";

import routing from "../routing";

import ApplicationStore from "stores/appStore";
import SearchStore from "stores/components/searchStore";
import ModalStore from "stores/components/modalStore";
import ActivitiesStore from "stores/components/activitiesStore";
import PaginationStore from "stores/common/paginationStore";
import JuryStore from "stores/components/juryStore";

import { downloadFile } from "helpers/filesExport";
import { importTypes } from "helpers/importTypes";

const composeURL = (path, startIndex, perPage, searchString, id) => {
  const searchStringPart = searchString
    ? `&searchString=${encodeURIComponent(searchString)}`
    : "";
  const idPart = id ? `&guid=${encodeURIComponent(id)}` : "";
  return `${path}/?startIndex=${startIndex}&itemsCount=${perPage}${searchStringPart}${idPart}`;
};

class ItemsStore {
  itemsAreLoading = false;
  foundPeopleAreExporting = false;
  searchIsActivated = false;
  lastSearchStringRequest = "";
  searchString = "";
  necessaryToSaveRecentSearches = false;
  isFileUploaded = false;
  fileInfo = "";

  constructor() {
    this.reset();
    this.loadPeopleFileImport();
  }

  setNecessityToSaveRecentSearches = (flag) => {
    this.necessaryToSaveRecentSearches = flag;
  };

  setSearchActivation = (flag) => {
    this.searchIsActivated = flag;
  };

  setLastSearchStringRequest = (value = "") => {
    this.lastSearchStringRequest = value;
  };

  setSource = (source) => {
    this.selectedSource = source;
  };

  setFile = (file) => {
    this.file = file;
  };

  reset = () => {
    this.items = [];
    this.itemsCount = 0;
    this.count = 0;
    this.parameters = [];
    this.displayedSearches = [];
    this.file = null;
    this.md5File = "";
    this.isSearchSubmitted = false;
    this.peopleImportFileProgress = 0;
    this.peopleImportFileIsLoading = false;
    this.peopleProgressInterval = null;
    this.resetSelected();
    this.boolean = undefined;
  };

  resetSelected = (clearing) => {
    this.selectedItems = [];
    this.checkedAll = false;

    if (clearing) this.boolean = undefined;
  };

  setCurrentUploadingFilename = (val) => {
    this.currentUploadingFilename = val;
  };

  setSearchString = (string) => {
    this.searchString = string;
  };

  setPeopleImportFileProgress = (val) => {
    this.peopleImportFileProgress = val;
  };

  setPeopleImportFileIsLoading = (val) => {
    this.peopleImportFileIsLoading = val;
  };

  setMD5 = (val) => {
    this.md5File = val;
  };

  setSearchRequestSubmitted = (isSubmitted) => {
    this.isEmptySearch()
      ? (this.isSearchSubmitted = false)
      : (this.isSearchSubmitted = isSubmitted);
  };

  isEmptySearch = () => !this.searchString;

  isChecked = (item) => {
    return this.selectedItems.includes(item);
  };

  selectItem = (item) => {
    if (this.selectedItems.includes(item)) {
      const index = this.selectedItems.findIndex((el) => el === item);
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
  };

  selectAll = () => {
    const allTheItemsAreSelected =
      this.items.length === this.selectedItems.length;
    this.selectedItems = allTheItemsAreSelected ? [] : [...this.items];
    this.checkedAll = !allTheItemsAreSelected;
  };

  getSavedSearches = (items) => {
    SearchStore.displayedSearches = items.map((item) =>
      SearchStore.createDisplayedSearch(item)
    );
  };

  onSourceTypeChange = (id) => {
    this.boolean = id;
  };

  fetchItems = async (path, method, startIndex, perPage, id) => {
    const data = method === "post" ? { Parameters: this.parameters } : null;

    const searchString = this.lastSearchStringRequest;

    const url = composeURL(path, startIndex, perPage, searchString, id);

    this.itemsAreLoading = true;
    await app[method](url, data)
      .then((res) => {
        this.items = res.data.items;
        this.count = res.data.count;
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.itemsAreLoading = false;
      });
  };

  fetchWithParameters = async (searchType, startIndex, perPage, idSubType) => {
    const {
      displayedParams,
      isSubmitted,
      buildPostParams,
      addTemporarySearch,
    } = SearchStore;
    const { currentListSearchString } = JuryStore;

    const { juryListType, juryListId } = currentListSearchString;

    if (displayedParams.length === 0 || !isSubmitted) {
      let path;
      let method;

      switch (searchType) {
        case "all" || "people":
          path = "people";
          method = "get";
          break;

        case "common":
          path = "common";
          method = "get";
          break;

        case "homes":
          path = "buildings";
          method = "get";
          break;

        case "analytics":
          path = "/personParameters/WithPeopleCount";
          method = "post";
          break;
        case "jury":
          path = `/JuryLists/${juryListId}/${juryListType}`;
          method = "get";
          break;
        case "users":
          path = `/Users`;
          method = "get";
          break;
        default:
          path = "people";
          method = "get";
          break;
      }

      if (searchType === "jury" && this.boolean !== undefined) {
        this.filterIsExcludedJury(null, juryListId, startIndex, perPage);
        return;
      }

      this.boolean !== undefined &&
        path !== "common" &&
        this.kek(path, method, startIndex, perPage);

      this.boolean !== undefined &&
        path === "common" &&
        this.filterIsExcludedJury(null, idSubType, startIndex, perPage);

      this.boolean === undefined &&
        path !== "common" &&
        this.fetchItems(path, method, startIndex, perPage);
    } else {
      let path;
      let data;

      buildPostParams();

      switch (searchType) {
        case "all" || "people":
          path = "people/withParameters";
          data = SearchStore.postParams;
          break;

        case "homes":
          path = "buildings/withParameters";
          data = SearchStore.postParams.buildings;
          break;

        case "analytics":
          path = "/personParameters/WithPeopleCount";
          data = SearchStore.postParams;
          break;

        default:
          path = "people/withParameters";
          data = SearchStore.postParams;
          break;
      }

      this.itemsAreLoading = true;

      const searchString = this.lastSearchStringRequest;
      const url = composeURL(path, startIndex, perPage, searchString);
      await app
        .post(url, data)
        .then((res) => {
          this.items = res.data.items;
          this.count = res.data.count;

          if (this.necessaryToSaveRecentSearches) {
            addTemporarySearch();
            this.necessaryToSaveRecentSearches = false;
          }
        })
        .catch((err) => {
          ApplicationStore.setError(err);
        })
        .finally(() => {
          this.itemsAreLoading = false;
        });
    }
  };

  kek = async (path, method, startIndex, perPage) => {
    this.itemsAreLoading = true;

    await app
      .get(
        `/People/filteredbystatus?startIndex=${startIndex}&itemsCount=${perPage}&isExcluded=${this.boolean}&searchString=${this.lastSearchStringRequest}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.items = res.data.items;
          this.count = res.data.count;
        }
      })
      .finally(() => {
        this.itemsAreLoading = false;
      });
  };

  filterIsExcludedJury = async (path, id, startIndex, perPage) => {
    this.itemsAreLoading = true;

    await app
      .get(
        `/JuryLists/${id}/common?startIndex=${startIndex}&itemsCount=${perPage}&isExcluded=${this.boolean}&searchString=${this.lastSearchStringRequest}`
      )
      .then((res) => {
        if (res.status === 200) {
          this.items = res.data.items;
          this.count = res.data.count;
        }
      })
      .finally(() => {
        this.itemsAreLoading = false;
      });
  };

  switchItemsCount = (count) => {
    this.itemsCount = count;
  };

  deleteSelected = (id, startIndex, perPage) => {
    const ids = this.selectedItems.map((item) => item.id);
    app
      .delete(`/activities/${id}/invitedpeople`, {
        data: ids,
      })
      .then(() => {
        this.selectedItems = [];
        ActivitiesStore.fetchActivity(id);
        this.fetchItems(
          `/activities/${id}/invited`,
          "get",
          startIndex,
          perPage
        );
        ModalStore.resetModal();
        PaginationStore.resetPagination();
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  importItemsFromExcel = async () => {
    const formData = new FormData();
    formData.append("file", this.file);
    this.setPeopleImportFileIsLoading(true);
    const id = this.selectedSource.id;
    const currentFile = this.file;

    await app
      .post(`/People/ImportPeopleFromTable?importFileType=${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        let percent = res.data.percent;
        this.savePeopleFileImport(res.data.md5, id, currentFile.name);
        this.setMD5(res.data.md5);
        this.setPeopleImportFileProgress(percent);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      });

    let currentMD5 = this.md5File;
    if (!this.peopleProgressInterval) {
      this.peopleProgressInterval = setInterval(
        () => this.fetchProgressPeopleImportFile(id, currentFile, currentMD5),
        4000
      );
    }
  };

  trackProgressPeopleImportFile = () => {
    const id = this.selectedSource?.id || "";
    let currentMD5 = this.md5File;
    this.peopleProgressInterval = setInterval(
      () => this.fetchProgressPeopleImportFile(id, null, currentMD5),
      4000
    );
  };

  fetchProgressPeopleImportFile = (id, currentFile, md5Param) => {
    this.setPeopleImportFileIsLoading(true);
    if (!this.file && currentFile) {
      this.file = currentFile;
    }
    const option = importTypes.find((type) => type.id === id);
    this.setSource(option);

    let md5 = encodeURIComponent(md5Param);
    app
      .get(`/People/ImportPeopleFromTable?md5=${md5}`)
      .then((res) => {
        this.setPeopleImportFileProgress(res.data.percent);
        if (res.data.percent === 100) {
          clearInterval(this.peopleProgressInterval);
          this.peopleProgressInterval = null;
          this.fileInfo = currentFile;
          this.fileInfo.md5 = encodeURIComponent(md5Param);
          this.queueClear();
          //   ModalStore.resetModal();
          //   routing.go(0);
          this.isFileUploaded = true;
        }
      })
      .catch((err) => {
        this.setPeopleImportFileIsLoading(false);
        this.setCurrentUploadingFilename("");
        this.setSource(null);
        this.setFile(null);
        this.setMD5("");
        this.setPeopleImportFileProgress(0);
        this.savePeopleFileImport();
        clearInterval(this.peopleProgressInterval);
        this.peopleProgressInterval = null;
        ModalStore.resetModal();
        ApplicationStore.setError(err);
        this.isFileUploaded = false;
        if (err.response.status === 404) {
          routing.go(0);
        }
        window.location.reload();
      });
  };

  queueClear = () => {
    app.post(`/People/removeimportfiletask?md5=${this.fileInfo.md5}`);
  };

  exportFoundPeopleToExcel = () => {
    const options = { responseType: "blob" };
    const selectedPeopleIds = this.selectedItems.map((person) => person.id);

    const dataToSend = { includedPeople: selectedPeopleIds };
    const url = "/People/GetTableWithPeople";

    this.foundPeopleAreExporting = true;
    app
      .post(url, dataToSend, options)
      .then((response) => {
        downloadFile(response, "excel", "export.xlsx");
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.foundPeopleAreExporting = false;
      });
  };

  savePeopleFileImport = (md5 = "", source = "", filename = "") => {
    localStorage.setItem(
      "peopleFileImport",
      JSON.stringify({
        md5,
        source,
        filename,
      })
    );
  };

  loadPeopleFileImport = () => {
    let savedData = JSON.parse(localStorage.getItem("peopleFileImport"));
    if (savedData) {
      const option = importTypes.find((type) => type.id === savedData.source);
      this.setSource(option);
      this.setCurrentUploadingFilename(savedData.filename);
      this.setMD5(savedData.md5);
    }
  };

  get searchIsImpossible() {
    return this.searchString === "" && !this.searchIsActivated;
  }
}

decorate(ItemsStore, {
  boolean: observable,
  file: observable,
  items: observable,
  itemsCount: observable,
  count: observable,
  searchString: observable,
  selectedSource: observable,
  selectedItems: observable,
  checkedAll: observable,
  currentUploadingFilename: observable,
  displayedSearches: observable,
  isSearchSubmitted: observable,
  itemsAreLoading: observable,
  foundPeopleAreExporting: observable,
  searchIsActivated: observable,
  necessaryToSaveRecentSearches: observable,
  peopleImportFileProgress: observable,
  peopleImportFileIsLoading: observable,
  md5File: observable,
  peopleProgressInterval: observable,
  isFileUploaded: observable,
  searchIsImpossible: computed,
  name: observable,

  kek: action,
  setSource: action,
  setSearchActivation: action,
  setNecessityToSaveRecentSearches: action,
  isChecked: action,
  reset: action,
  resetSelected: action,
  fetchItems: action,
  fetchWithParameters: action,
  switchItemsCount: action,
  setSearchString: action,
  setPeopleImportFileProgress: action,
  checkIfDeleted: action,
  selectItem: action,
  selectAll: action,
  setFile: action,
  deleteSelected: action,
  importItemsFromExcel: action,
  getSavedSearches: action,
  exportFoundPeopleToExcel: action,
  setSearchRequestSubmitted: action,
  setLastSearchStringRequest: action,
  setPeopleImportFileIsLoading: action,
  setCurrentUploadingFilename: action,
  loadPeopleFileImport: action,
  savePeopleFileImport: action,
  trackProgressPeopleImportFile: action,
});

export default new ItemsStore();
