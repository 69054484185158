import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import DefaultHeader from "components/Header/Default";

import AnalyticsList from "./AnalyticsList";

import useDocumentTitle from "hooks/useDocumentTitle";

const Analytics = inject(
  "SearchStore",
  "ItemsStore",
  "PaginationStore"
)(
  observer(({ SearchStore, ItemsStore, PaginationStore }) => {
    const { isSubmitted, setSubmitted, resetFiltration } = SearchStore;
    const {
      isSearchSubmitted,
      resetSelected,
      fetchWithParameters,
      setSearchActivation,
      setLastSearchStringRequest,
    } = ItemsStore;
    const { startIndex, perPage, resetAll } = PaginationStore;

    useEffect(() => {
      return () => {
        setSearchActivation(false);
        setLastSearchStringRequest();
      };
    }, [setSearchActivation, setLastSearchStringRequest]);

    useDocumentTitle(() => (isSubmitted ? `Результаты поиска` : "Аналитика"), [
      isSubmitted,
    ]);

    return (
      <>
        <DefaultHeader
          title="Аналитика"
          buttonText="Указать параметры для аналитики"
          searchType="analytics"
          onSearch={() => {
            resetAll();
            resetSelected();
            setSubmitted(true);
            startIndex === 0 &&
              fetchWithParameters("analytics", startIndex, perPage);
          }}
          onClearSearch={() => {
            resetFiltration();
            resetSelected();
            resetAll();
            isSubmitted &&
              fetchWithParameters("analytics", startIndex, perPage);
          }}
        />
        <div className="wrapper__content">
          <section className="section">
            <div className="container">
              <AnalyticsList isSearch={isSubmitted || isSearchSubmitted} />
            </div>
          </section>
        </div>
      </>
    );
  })
);

export default Analytics;
