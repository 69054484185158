export const genders = [
  { id: "Male", name: "Мужской" },
  { id: "Female", name: "Женский" },
];

export const registrationTypes = [
  { id: "none", name: "[Не задано]" },
  { id: "Постоянная", name: "Постоянная" },
  { id: "Временная", name: "Временная" },
];

export const passportTypes = [
  { id: "none", name: "[Не выбрано]", notSpecified: true },
  { id: "RussianCitizen", name: "Паспорт гражданина РФ" },
  { id: "ForeignCitizen", name: "Паспорт иностранного гражданина" },
];

export const getOptionById = (dictionary, id, identifyerName = "id") => {
  return dictionary.find((option) => option[identifyerName] === id);
};

export const JuryTypes = [
  { id: "All", name: "Всех присяжных" },
  { id: "notExcluded", name: "Не исключенных из списка" },
  { id: "Exluded", name: "Исключенных из списка" },
];
