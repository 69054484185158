import React from "react";

import "./index.scss";

import { ReactComponent as Import } from "assets/svg/import.svg";

const ImportButton = (props) => {
  return (
    <div className={`button__import ${props.className ? props.className : ""}`}>
      <Import />
      {props.children}
    </div>
  );
};

export default ImportButton;
