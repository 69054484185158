import { observable, decorate, action, runInAction, set, computed } from "mobx";
import {
  EMPTY_ERR,
  INVALID_DATE_ERR,
  INVALID_TIME_ERR,
  INVALID_SYMBOLS_ERR,
  INVALID_START_DATE_ERR,
  INVALID_END_DATE_ERR,
} from "helpers/errors";
import { stripCharsInIE } from "helpers/commonMethods";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import ItemsStore from "../common/itemsStore";
import formattedDate, {
  EMPTY_DATE,
  toISOLocalDateString,
  isTimeValid,
} from "helpers/formattedDate";

import ModalStore from "stores/components/modalStore";

import { downloadFile } from "helpers/filesExport";

class ActivitiesStore {
  activities = [];
  comingActivitiesForModal = [];
  activity = null;
  newActivity = {
    title: "",
    location: "",
    description: "",
    date: null,
    dateOfEnd: null,
    hasLottery: false,
    anonymousTicketsCount: 0,
  };
  timeOfStart = "";
  timeOfEnd = "";

  editedStartDate = "";
  editedEndDate = "";
  serverErr = "";
  datesRange = [];
  activityPeopleAreExporting = false;
  invitationsAreDownloading = false;
  ticketsAreDownloading = false;
  anonymousTicketsAreDownloading = false;
  activitiesWereFilteredByDate = false;

  get errors() {
    let error = {};
    const { hasOnlyWhiteSpaces, isSubmitted, mode } = ApplicationStore;

    if (!isSubmitted) {
      return error;
    }

    if (!isTimeValid(this.timeOfStart)) {
      error["timeStart"] = INVALID_TIME_ERR;
    }
    if (!isTimeValid(this.timeOfEnd)) {
      error["timeEnd"] = INVALID_TIME_ERR;
    }

    switch (mode) {
      case "ADD_ACTIVITY":
        if (!this.newActivity.title) {
          error["title"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.newActivity.title)) {
          error["title"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["title"] = this.serverErr;
        }
        if (!this.newActivity.location) {
          error["location"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.newActivity.location)) {
          error["location"] = INVALID_SYMBOLS_ERR;
        }
        if (
          !this.newActivity.date ||
          this.newActivity.date === "Invalid Date"
        ) {
          error["dateStart"] = INVALID_DATE_ERR;
        } else if (
          this.parsedNewActivityDateTimeStartToSend >
          this.parsedNewActivityDateTimeEndToSend
        ) {
          error["dateStart"] = INVALID_START_DATE_ERR;
        }
        if (
          !this.newActivity.dateOfEnd ||
          this.newActivity.dateOfEnd === "Invalid Date"
        ) {
          error["dateEnd"] = INVALID_DATE_ERR;
        } else if (
          this.parsedNewActivityDateTimeEndToSend <
          this.parsedNewActivityDateTimeStartToSend
        ) {
          error["dateEnd"] = INVALID_END_DATE_ERR;
        }
        break;
      case "EDIT_ACTIVITY":
        if (!this.activity.title) {
          error["title"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.activity.title)) {
          error["title"] = INVALID_SYMBOLS_ERR;
        } else if (this.serverErr) {
          error["title"] = this.serverErr;
        }
        if (!this.activity.location) {
          error["location"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.activity.location)) {
          error["location"] = INVALID_SYMBOLS_ERR;
        }
        if (!this.activity.date || this.activity.date === "Invalid Date") {
          error["dateStart"] = INVALID_DATE_ERR;
        } else if (
          this.parsedActivityDateTimeStartToSend >
          this.parsedActivityDateTimeEndToSend
        ) {
          error["dateStart"] = INVALID_START_DATE_ERR;
        }
        if (
          !this.activity.dateOfEnd ||
          this.activity.dateOfEnd === "Invalid Date"
        ) {
          error["dateEnd"] = INVALID_DATE_ERR;
        } else if (
          this.parsedActivityDateTimeEndToSend <
          this.parsedActivityDateTimeStartToSend
        ) {
          error["dateEnd"] = INVALID_END_DATE_ERR;
        }
        break;
      default:
        break;
    }
    return error;
  }

  resetServerErrors = () => {
    this.serverErr = "";
  };

  get parsedStartActivityDate() {
    return !this.editedStartDate || this.editedStartDate === EMPTY_DATE
      ? null
      : formattedDate(this.editedStartDate.slice(0, 10), false, true);
  }

  get parsedEndActivityDate() {
    return !this.editedEndDate || this.editedEndDate === EMPTY_DATE
      ? null
      : formattedDate(this.editedEndDate.slice(0, 10), false, true);
  }

  get parsedStartDate() {
    return this.datesRange.length ? new Date(this.datesRange[0]) : "";
  }

  get parsedEndDate() {
    return this.datesRange.length ? new Date(this.datesRange[1]) : "";
  }

  get parsedActivityDateTimeStartToSend() {
    if (
      this.activity.date &&
      this.activity.date !== "Invalid Date" &&
      this.timeOfStart &&
      !this.timeOfStart.includes("_")
    ) {
      return new Date(this.parseDateTime(this.activity.date, this.timeOfStart));
    }
    return null;
  }

  get parsedActivityDateTimeEndToSend() {
    if (
      this.activity.dateOfEnd &&
      this.activity.dateOfEnd !== "Invalid Date" &&
      this.timeOfEnd &&
      !this.timeOfEnd.includes("_")
    ) {
      return new Date(
        this.parseDateTime(this.activity.dateOfEnd, this.timeOfEnd)
      );
    }
    return null;
  }

  get parsedNewActivityDateTimeStartToSend() {
    if (
      this.newActivity.date &&
      this.newActivity.date !== "Invalid Date" &&
      this.timeOfStart &&
      !this.timeOfStart.includes("_")
    ) {
      return new Date(
        this.parseDateTime(this.newActivity.date, this.timeOfStart)
      );
    }
    return null;
  }

  get parsedNewActivityDateTimeEndToSend() {
    if (
      this.newActivity.dateOfEnd &&
      this.newActivity.dateOfEnd !== "Invalid Date" &&
      this.timeOfEnd &&
      !this.timeOfEnd.includes("_")
    ) {
      return new Date(
        this.parseDateTime(this.newActivity.dateOfEnd, this.timeOfEnd)
      );
    }
    return null;
  }

  parseDateTime = (date, time) => {
    const [day, month, year] = date.slice(0, 10).split(/[-.]/).map(Number);
    const [hours, minutes] = time.split(":").map(Number);
    const d = new Date(year, month - 1, day, hours, minutes, 0);
    return toISOLocalDateString(d);
  };

  changeActivity = (key, field, value) => {
    set(key, field, value);
  };

  changeActivityDate = (key, value) => {
    value instanceof Date
      ? set(key, "date", formattedDate(value?.toISOString()))
      : set(key, "date", null);
    this.editedStartDate = value?.toISOString();
  };

  changeActivityDateOfEnd = (key, value) => {
    value instanceof Date
      ? set(key, "dateOfEnd", formattedDate(value?.toISOString()))
      : set(key, "dateOfEnd", null);
    this.editedEndDate = value?.toISOString();
  };

  changeActivityTime = (key, value) => {
    key === "start" ? (this.timeOfStart = value) : (this.timeOfEnd = value);
  };

  reset = () => {
    runInAction(() => {
      this.activity = null;
      this.activities = [];
      this.newActivity.title = "";
      this.newActivity.location = "";
      this.newActivity.description = "";
      this.newActivity.date = null;
      this.newActivity.dateOfEnd = null;
      this.newActivity.hasLottery = false;
      this.newActivity.anonymousTicketsCount = "0";
      this.timeOfStart = "";
      this.timeOfEnd = "";
      this.editedStartDate = "";
      this.editedEndDate = "";
      this.serverErr = "";
      this.datesRange = [];
    });
  };

  fetchDatesRange = async () => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/activities/datesrange`)
      .then((res) => {
        this.datesRange = res.data;
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchActivities = async (params) => {
    let _date = "";
    if (params.date instanceof Date) {
      _date = toISOLocalDateString(params.date);
    }
    this.activities.items = [];
    ApplicationStore.setLoading(true);
    const strippedDate = stripCharsInIE(_date);
    this.activitiesWereFilteredByDate = !!strippedDate;
    await app
      .get(
        `/activities?startIndex=${params.startIndex}&itemsCount=10&archived=${params.archived}&date=${strippedDate}`
      )
      .then((res) => {
        this.activities = res.data;
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchComingActivitiesForModal = async () => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/activities/forthcoming/`)
      .then((res) => {
        this.comingActivitiesForModal = res.data;
        ApplicationStore.setLoading(true);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchActivity = async (id) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/activities/${id}`)
      .then((res) => {
        const [_yearStart, _monthStart, _dayStart] = res.data.date
          .slice(0, 10)
          .split("-");
        const [_yearEnd, _monthEnd, _dayEnd] = res.data.dateOfEnd
          .slice(0, 10)
          .split("-");
        const timeStartToEdit = res.data.date.split("T")[1].slice(0, 5);
        const timeEndToEdit = res.data.dateOfEnd.split("T")[1].slice(0, 5);

        this.activity = {
          ...res.data,
          date:
            _dayStart +
            "." +
            _monthStart +
            "." +
            _yearStart +
            ", " +
            timeStartToEdit,
          dateOfEnd:
            _dayEnd + "." + _monthEnd + "." + _yearEnd + ", " + timeEndToEdit,
          timeStart: timeStartToEdit,
          timeEnd: timeEndToEdit,
          anonymousTicketsCount: res.data.anonymousTicketsCount.toString(),
        };

        this.timeOfStart = timeStartToEdit;
        this.timeOfEnd = timeEndToEdit;
        this.editedStartDate = res.data.date;
        this.editedEndDate = res.data.dateOfEnd;
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          routing.push(`/404`);
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  addActivity = () => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      const ticketsCount = this.newActivity.hasLottery
        ? Number(this.newActivity.anonymousTicketsCount)
        : 0;
      const dataToSend = {
        title: this.newActivity.title.trim(),
        date: this.parseDateTime(this.newActivity.date, this.timeOfStart),
        dateOfEnd: this.parseDateTime(
          this.newActivity.dateOfEnd,
          this.timeOfEnd
        ),
        location: this.newActivity.location.trim(),
        description: this.newActivity.description.trim(),
        hasLottery: this.newActivity.hasLottery,
        anonymousTicketsCount: ticketsCount,
      };

      ApplicationStore.setUploading(true);
      app
        .post(`/activities`, dataToSend)
        .then((res) => {
          runInAction(() => {
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
            routing.push(`/activity/description/${res.data}`);
          });
        })
        .catch((err) => {
          if (err.response) {
            this.serverErr = err.response.data;
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  updateActivity = (id) => {
    this.serverErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);

    if (!Object.keys(this.errors).length) {
      const ticketsCount = this.activity.hasLottery
        ? Number(this.activity.anonymousTicketsCount)
        : 0;
      const dataToSend = {
        id,
        title: this.activity.title.trim(),
        date: this.parseDateTime(this.activity.date, this.timeOfStart),
        dateOfEnd: this.parseDateTime(this.activity.dateOfEnd, this.timeOfEnd),
        location: this.activity.location.trim(),
        description: this.activity.description.trim(),
        hasLottery: this.activity.hasLottery,
        anonymousTicketsCount: ticketsCount,
      };

      ApplicationStore.setUploading(true);
      app
        .put(`/activities`, dataToSend)
        .then(() => {
          runInAction(() => {
            ApplicationStore.setGlobalFieldValue("isSubmitted", false);
            ApplicationStore.setUploading(false);
            routing.push(`/activity/description/${id}`);
          });
        })
        .catch((err) => {
          if (err.response) {
            this.serverErr = err.response.data;
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setUploading(false);
        });
    }
  };

  deleteActivity = (id) => {
    app
      .delete(`/activities/${id}`)
      .then(() => {
        runInAction(() => {
          routing.push("/activities");
        });
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  inviteSelectedPeople = (activityId) => {
    const { selectedItems } = ItemsStore;

    const selectedPeopleIds = selectedItems.map((person) => person.id);
    const url = `/activities/${activityId}/AddPeople/`;
    const data = { includedPeople: selectedPeopleIds };

    ApplicationStore.setLoading(true);
    app
      .put(url, data)
      .then(() => {
        runInAction(() => {
          routing.push(`/activity/invited/${activityId}`);
        });
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
        ModalStore.resetModal();
      });
  };

  exportXLSActivityPeople = (
    activityId,
    category,
    filename = "export.xlsx"
  ) => {
    const categorySearchString = `?peopleCategory=${category}`;
    const path = `/Activities/GetTableWithPeople`;
    const url = `${path}/${activityId}${categorySearchString}`;
    const options = { responseType: "blob" };

    this.activityPeopleAreExporting = true;
    app
      .get(url, options)
      .then((response) => {
        downloadFile(response, "excel", filename);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.activityPeopleAreExporting = false;
      });
  };

  exportPDFInvitation = (activityId, filename = "invitations.pdf") => {
    const path = `/Activities/GetPdfInvitations`;
    const url = `${path}/${activityId}`;
    this.invitationsAreDownloading = true;
    const options = { responseType: "blob" };
    app
      .get(url, options)
      .then((response) => {
        downloadFile(response, "excel", filename);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this.invitationsAreDownloading = false;
      });
  };

  exportPDFTickets = (
    activityId,
    isAnonymous = false,
    filename = "tickets.pdf"
  ) => {
    const path = `/Activities/GetPdfLotteryTickets`;
    const anonymousGetParam = `?isAnonymous=${isAnonymous}`;
    const url = `${path}/${activityId}${anonymousGetParam}`;
    const loaderName = isAnonymous
      ? "anonymousTicketsAreDownloading"
      : "ticketsAreDownloading";
    this[loaderName] = true;
    const options = { responseType: "blob" };
    app
      .get(url, options)
      .then((response) => {
        downloadFile(response, "pdf", filename);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        this[loaderName] = false;
      });
  };
}

decorate(ActivitiesStore, {
  activities: observable,
  activity: observable,
  newActivity: observable,
  editedStartDate: observable,
  editedEndDate: observable,
  serverErr: observable,
  datesRange: observable,
  comingActivitiesForModal: observable,
  activityPeopleAreExporting: observable,
  invitationsAreDownloading: observable,
  ticketsAreDownloading: observable,
  anonymousTicketsAreDownloading: observable,
  activitiesWereFilteredByDate: observable,

  errors: computed,
  parsedStartActivityDate: computed,
  parsedEndActivityDate: computed,
  parsedStartDate: computed,
  parsedEndDate: computed,

  parsedActivityDateTimeStartToSend: computed,
  parsedActivityDateTimeEndToSend: computed,
  parsedNewActivityDateTimeStartToSend: computed,
  parsedNewActivityDateTimeEndToSend: computed,

  timeOfStart: observable,
  timeOfEnd: observable,

  reset: action,
  parseDateTime: action,
  changeActivity: action,
  changeActivityDate: action,
  fetchDatesRange: action,
  fetchActivities: action,
  fetchActivity: action,
  addActivity: action,
  updateActivity: action,
  deleteActivity: action,
  fetchComingActivitiesForModal: action,
  inviteSelectedPeople: action,
  resetServerErrors: action,
  exportXLSActivityPeople: action,
  exportPDFInvitation: action,
  exportPDFTickets: action,
});

export default new ActivitiesStore();
