import React from "react";
import "./index.scss";

const defaultClassnames = {
  header: null,
  contentWrapper: null,
  itemWrapper: null,
};

const Table = ({ header, items, classnames = defaultClassnames }) => {
  const returnClassIfExists = (classname) => (classname ? classname : "");

  return (
    <div className="table_block">
      <div className={`table_header ${returnClassIfExists(classnames.header)}`}>
        {header}
      </div>
      <ul
        className={`table_content ${returnClassIfExists(
          classnames.contentWrapper
        )}`}
      >
        {items &&
          items.map((item, index) => (
            <li
              key={index}
              className={`table_content_item_wrapper ${returnClassIfExists(
                classnames.itemWrapper
              )}`}
            >
              {item}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Table;
