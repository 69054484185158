import React from "react";

import "./index.scss";

const Progressbar = ({
  completed = 0,
  onChange,
  inputName,
  labelClassname,
  inputClassname,
  disabled,
  id,
  bgcolor,
}) => {
  const fillerStyles = {
    width: `${completed}%`,
  };

  return (
    <div className="progressbar__container">
      <div style={fillerStyles} className="progressbar__filler"></div>
      <span className="progressbar__label">{`${completed}%`}</span>
    </div>
  );
};

export default Progressbar;
