import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";

import "./index.scss";

import { ReactComponent as Danger } from "assets/svg/danger.svg";

import AddButton from "common/components/Buttons/AddButton";

import Breadcrumbs from "components/Breadcrumbs";
import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

const Areas = inject(
	"ItemsStore",
	"PaginationStore",
	"CompaniesStore"
)(
	observer(({ ItemsStore, PaginationStore, CompaniesStore, match }) => {
		const { items, reset, fetchItems, count, itemsAreLoading } = ItemsStore;
		const {
			currentPage,
			perPage,
			changePerPage,
			paginate,
			resetPagination,
			resetAll,
			startIndex,
		} = PaginationStore;
		const { company, fetchCompany } = CompaniesStore;

		const [isCleared, setCleared] = useState(false);
		const id = match.params.id;

		const location = useLocation();
		const params = new URLSearchParams(location.search);
		const caption = params.get("caption");

		useEffect(() => {
			isCleared && fetchItems("/pollingstations", "get", startIndex, perPage, id);
			return () => {
				reset();
			};
		}, [fetchItems, id, startIndex, perPage, reset, isCleared]);

		useEffect(() => {
			fetchCompany(id);
		}, [fetchCompany, id]);

		useEffect(() => {
			resetPagination();
		}, [resetPagination, perPage]);

		useEffect(() => {
			resetAll();
			setCleared(true);
		}, [resetAll, setCleared]);

		const classnames = {
			header: "stations-header",
			itemWrapper: "stations-item",
		};

		const header = (
			<>
				<div>Участок</div>
				<div>Кол-во человек</div>
				<div>Адрес</div>
			</>
		);

		const breadcrumbs = [
			{ path: `/handbooks/companies`, label: "Кампании" },
			{
				path: `/handbooks/editcompany/${company?.electionCompanyId}`,
				label: company?.caption || caption,
			},
			{ path: `/handbooks/stations/${company?.electionCompanyId}`, label: "Участки" },
		];

		const mappedItems = items.map((item) => (
			<>
				<span className="stations-item__id">
					<Link
						className="page-link"
						to={`/handbooks/editstation/${item.id}?caption=${caption || company?.caption}`}>
						{item.caption}
					</Link>
				</span>
				<div className="stations-item__count">
					<p className="table-value">{item.peopleCount}</p>
					{item.peopleCount > 3000 && <Danger />}
					<div className="tooltip">
						<p>На участке не может быть больше 3000 человек</p>
					</div>
				</div>
				{item.address ? <p className="table-value">{item.address}</p> : <p className="table-value">—</p>}
			</>
		));

		const list =
			items.length !== 0 ? (
				<div className="params-wrapper">
					<Table header={header} items={mappedItems} classnames={classnames} />
					<Pagination
						perPage={perPage}
						total={count}
						currentPage={currentPage + 1}
						handlePaginate={(page) => paginate(page - 1)}
						isShowPerPage
						handleChangePerPage={(count) => changePerPage(count)}
					/>
				</div>
			) : (
				<Empty isSearch={false} />
			);

		return (
			<div className="wrapper__content">
				<Breadcrumbs routes={breadcrumbs} />
				<section className="section">
					<div className="container">
						<h1 className="page-title">Кампания — {company?.caption || caption}</h1>
						<Link to={`/handbooks/addstation/${id}`}>
							<AddButton className="handbooks__button">Добавить участок</AddButton>
						</Link>
						{itemsAreLoading ? <LoaderSpinner /> : isCleared && list}
					</div>
				</section>
			</div>
		);
	})
);

export default Areas;
