export const downloadFile = async (response, fileType, filename) => {
  const newBlob = new Blob([response.data], {
    type: `application/${fileType}`,
  });

  const nav = window.navigator;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  const url = window.URL.createObjectURL(newBlob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
