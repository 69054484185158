import React, { useState, useEffect } from "react";
import { INVALID_DATE_ERR } from "../../../../helpers/errors";

import "./index.scss";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, error } = props;

  const labelClass = () => {
    if (!value && children.props.readOnly) {
      return "label";
    } else if (focus || value || error === INVALID_DATE_ERR) {
      return "label label-float";
    }
    return "label";
  };

  useEffect(() => {
    if (children.props.disabled) {
      setFocus(false);
    }
  }, [children.props.disabled]);

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass()}>{label}</label>
    </div>
  );
};

export default FloatLabel;
