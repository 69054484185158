import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./index.scss";

import SearchInput from "common/components/Inputs/SearchInput";
import Checkbox from "common/components/Inputs/Checkbox";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";
import SimpleMenu from "components/SimpleMenu";
import Modal from "components/Modal";

import { parseDate } from "helpers/formattedDate";

const Invited = inject(
	"ItemsStore",
	"PaginationStore",
	"ModalStore",
	"ActivitiesStore"
)(
	observer(({ ModalStore, ItemsStore, PaginationStore, id, needToLoad, archived, ActivitiesStore }) => {
		const {
			activity,
			exportXLSActivityPeople,
			activityPeopleAreExporting,
			exportPDFInvitation,
			invitationsAreDownloading,
			ticketsAreDownloading,
			anonymousTicketsAreDownloading,
			exportPDFTickets,
		} = ActivitiesStore;
		const { isOpen, toggle, resetModal, isSubmit, isHelperModalOpen, toggleHelperModal } = ModalStore;
		const {
			items,
			reset,
			fetchItems,
			count,
			selectAll,
			selectedItems,
			selectItem,
			isChecked,
			checkedAll,
			searchString,
			setSearchString,
			deleteSelected,
			resetSelected,
			itemsAreLoading,
			searchIsImpossible,
			searchIsActivated,
			setSearchActivation,
			setLastSearchStringRequest,
		} = ItemsStore;
		const {
			currentPage,
			perPage,
			changePerPage,
			paginate,
			resetPagination,
			resetAll,
			startIndex,
		} = PaginationStore;

		const searchBlockRef = useRef(null);
		const [isCleared, setCleared] = useState(false);

		useEffect(() => {
			if (isCleared && needToLoad) {
				fetchItems(`/activities/${id}/invited`, "get", startIndex, perPage);
			}
			return () => {
				reset();
			};
		}, [id, fetchItems, needToLoad, startIndex, perPage, reset, isCleared]);

		useEffect(() => {
			if (isSubmit) {
				deleteSelected(id, startIndex, perPage);
			}
		}, [id, isSubmit, deleteSelected, startIndex, perPage]);

		useEffect(() => {
			resetPagination();
		}, [resetPagination, perPage]);

		useEffect(() => {
			resetAll();
			setCleared(true);
		}, [resetAll, setCleared]);

		useEffect(() => {
			if (!itemsAreLoading && needToLoad) {
				searchBlockRef.current.querySelector('input[type="search"]').focus();
			}
		}, [itemsAreLoading, needToLoad]);

		useEffect(() => () => resetModal(), [resetModal]);

		const classnames = {
			header: "activities-header",
			itemWrapper: "activity-item",
		};

		const header = (
			<>
				<div className="activity__checkbox top">
					{!archived && <Checkbox id="all" checked={checkedAll} onChange={selectAll} />}
				</div>
				<div>ФИО</div>
				<div>Дата рождения</div>
				<div>Район</div>
				<div>Адрес регистрации</div>
				<div>Параметры</div>
			</>
		);

		const mappedItems = items?.map((item) => {
			const {
				id,
				surName = "",
				name = "",
				patronymic = "",
				region,
				birthDate,
				street,
				buildingNumber,
				appartamentNumber,
				Parameters = [],
			} = item;
			const fullName = [surName, name, patronymic].join(" ").trim();
			const address = street && street.includes("р-н.") ? street.split("р-н.") : street;
			const onlyStreet = address && Array.isArray(address) && address[1].split(", ")[1];
			return (
				<>
					<div className="activity__checkbox">
						{!archived && <Checkbox id={id} checked={isChecked(item)} onChange={() => selectItem(item)} />}
					</div>
					<div className="activity__fio">
						<Link className="page-link" to={`/people/editperson/${id}`}>
							{fullName}
						</Link>
					</div>
					<p className="table-value">{(birthDate && parseDate(birthDate.slice(0, 10))) || ""}</p>
					<p className="table-value">{region || "—"}</p>
					<p className="table-value">
						{onlyStreet || (street && street)}
						{` ${buildingNumber || ""}`}
						{appartamentNumber > 0 && `, кв. ${appartamentNumber}`}
						{!street && !buildingNumber && !appartamentNumber && "—"}
					</p>
					<p className="table-value">{Parameters.length ? Parameters.join(", ") : "—"}</p>
				</>
			);
		});

		const areTherePeople = needToLoad;
		const werePeopleFound = count > 0;

		const xlsExport = areTherePeople
			? {
					caption: activityPeopleAreExporting ? "Идёт экспорт..." : "Экспорт в Excel",
					disabled: activityPeopleAreExporting,
					handler: () => {
						exportXLSActivityPeople(id, "invited");
					},
			  }
			: null;

		const downloadInvitations =
			!archived && areTherePeople
				? {
						caption: invitationsAreDownloading ? "Приглашения скачиваются..." : "Скачать приглашения",
						disabled: invitationsAreDownloading,
						handler: () => {
							exportPDFInvitation(id);
						},
				  }
				: null;

		const downloadTickets =
			!archived && activity?.hasLottery
				? {
						caption: ticketsAreDownloading ? "Билеты скачиваются..." : "Скачать билеты",
						disabled: ticketsAreDownloading,
						handler: () => {
							exportPDFTickets(id);
						},
				  }
				: null;

		const downloadAnonymousTickets =
			!archived && activity?.hasLottery && activity?.anonymousTicketsCount > 0
				? {
						caption: anonymousTicketsAreDownloading ? "Билеты скачиваются..." : "Скачать анонимные билеты",
						disabled: anonymousTicketsAreDownloading,
						handler: () => {
							exportPDFTickets(id, true, "anonymous_tickets.pdf");
						},
				  }
				: null;

		const addNewPeopleToList = !archived
			? {
					caption: "Добавить новых в этот список",
					handler: () => {
						toggleHelperModal(true);
					},
			  }
			: null;

		const deleteSelectedPeople =
			!archived && werePeopleFound
				? {
						caption: selectedItems.length > 0 ? `Удалить выделенных (${selectedItems.length})` : "",
						handler: () => {
							toggle(true);
						},
				  }
				: null;

		const activityMenuData = [
			xlsExport,
			downloadInvitations,
			downloadTickets,
			downloadAnonymousTickets,
			addNewPeopleToList,
			deleteSelectedPeople,
		];

		const searchInput = areTherePeople && (
			<div ref={searchBlockRef} className="activities-search-fields">
				{searchIsActivated && (
					<BottomLinedButton
						onClick={() => {
							setSearchString("");
							searchBlockRef.current.querySelector('input[type="search"]').focus();

							if (!searchIsActivated) {
								return;
							}

							setSearchActivation(false);
							setLastSearchStringRequest();

							resetSelected();
							resetPagination();
							startIndex === 0 && fetchItems(`/activities/${id}/invited`, "get", startIndex, perPage);
						}}
						className="activity__button-clear">
						Очистить поиск
					</BottomLinedButton>
				)}

				<SearchInput
					disabled={itemsAreLoading}
					placeholder="Поиск по ФИО"
					value={searchString}
					onSearch={() => {
						searchBlockRef.current.querySelector('input[type="search"]').focus();

						if (searchIsImpossible) {
							return;
						}

						setSearchActivation(!!searchString);
						setLastSearchStringRequest(searchString);
						resetPagination();
						resetSelected();

						startIndex === 0 && fetchItems(`/activities/${id}/invited`, "get", startIndex, perPage);
					}}
					onChange={(e) => setSearchString(e.target.value)}
					className="activity__item-search-input"
				/>
			</div>
		);

		const modalRedirect = <Modal type="redirect" />;
		const modalDanger = <Modal type="danger" />;

		const displayngContent = (
			<>
				{searchInput}
				{isOpen && modalDanger}
				{isHelperModalOpen && modalRedirect}
				<SimpleMenu data={activityMenuData} />
				<div className="activities-wrapper">
					{itemsAreLoading ? (
						<LoaderSpinner />
					) : werePeopleFound ? (
						isCleared && (
							<>
								<Table header={header} items={mappedItems} classnames={classnames} />
								<Pagination
									perPage={perPage}
									total={count}
									currentPage={currentPage + 1}
									handlePaginate={(page) => paginate(page - 1)}
									isShowPerPage
									handleChangePerPage={(count) => changePerPage(count)}
								/>
							</>
						)
					) : (
						<Empty isSearch={needToLoad} />
					)}
				</div>
			</>
		);

		return <div className="activity__item-content">{displayngContent}</div>;
	})
);

export default Invited;
