import {
  observable,
  decorate,
  action,
  computed,
  runInAction,
  set,
  reaction,
} from "mobx";

import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import SearchStore from "./searchStore";
import AutocompleteStore from "./autocompleteStore";
import AreasStore from "./areasStore";
import AlertStore from "./alertsStore";

import {
  genders,
  registrationTypes,
  getOptionById,
} from "helpers/dictionaries";

import {
  EMPTY_ERR,
  INVALID_DATE_ERR,
  NO_FLAT_ERR,
  NO_HOUSE_ERR,
  INVALID_SYMBOLS_ERR,
} from "helpers/errors";
import formattedDate from "helpers/formattedDate";
import { parseValue } from "helpers/commonMethods";
import {
  INVALID_SNILS_CHECKSUM_ERR,
  SNILS_DUPLICATE_ERR,
} from "../../helpers/errors";

const EMPTY_BUILDING = "00000000-0000-0000-0000-000000000000";

class PeopleFormStore {
  constructor() {
    this.reset();
  }

  get errors() {
    let error = {};
    const { hasOnlyWhiteSpaces, isSubmitted, mode } = ApplicationStore;

    if (!isSubmitted) {
      return error;
    }
    if (!this.flat && !this.noFlat) {
      error["flat"] = NO_FLAT_ERR;
    }
    if (!this.houseNum && !this.noHouse) {
      error["house"] = NO_HOUSE_ERR;
    }

    switch (mode) {
      case "ADD_PERSON":
        if (!this.name) {
          error["name"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.name)) {
          error["name"] = INVALID_SYMBOLS_ERR;
        }
        if (!this.surName) {
          error["surName"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.surName)) {
          error["surName"] = INVALID_SYMBOLS_ERR;
        }
        if (!this.birthDate || this.birthDate === "Invalid Date") {
          error["birthDate"] = INVALID_DATE_ERR;
        }

        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportSeries
        ) {
          error["series"] = EMPTY_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportNumber
        ) {
          error["number"] = EMPTY_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.gettingDate
        ) {
          error["gettingDate"] = INVALID_DATE_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportAuthority
        ) {
          error["authority"] = EMPTY_ERR;
        }
        break;
      case "EDIT_PERSON":
        if (!this.person.name) {
          error["name"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.person.name)) {
          error["name"] = INVALID_SYMBOLS_ERR;
        }
        if (!this.person.surName) {
          error["surName"] = EMPTY_ERR;
        } else if (hasOnlyWhiteSpaces(this.person.surName)) {
          error["surName"] = INVALID_SYMBOLS_ERR;
        }
        if (
          !this.person.birthDate ||
          this.person.birthDate === "Invalid Date"
        ) {
          error["birthDate"] = INVALID_DATE_ERR;
        }

        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportSeries
        ) {
          error["series"] = EMPTY_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportNumber
        ) {
          error["number"] = EMPTY_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.gettingDate
        ) {
          error["gettingDate"] = INVALID_DATE_ERR;
        }
        if (
          this.selectedPassportType &&
          this.selectedPassportType !== "none" &&
          !this.passportAuthority
        ) {
          error["authority"] = EMPTY_ERR;
        }
        break;
      default:
        break;
    }
    return error;
  }

  get parsedDate() {
    return this.editedDate
      ? formattedDate(this.editedDate.slice(0, 10), false, true)
      : null;
  }

  get parsedGettingDate() {
    return this.gettingDate
      ? formattedDate(this.gettingDate.slice(0, 10), false, true)
      : null;
  }

  get parsedStartOfRegistration() {
    return this.editedStartOfRegistration
      ? formattedDate(this.editedStartOfRegistration.slice(0, 10), false, true)
      : null;
  }

  get parsedEndOfRegistration() {
    return this.editedEndOfRegistration
      ? formattedDate(this.editedEndOfRegistration.slice(0, 10), false, true)
      : null;
  }

  get parsedEndOfTemporalRegistration() {
    return this.editedEndOfTemporalRegistration
      ? formattedDate(
          this.editedEndOfTemporalRegistration.slice(0, 10),
          false,
          true
        )
      : null;
  }

  get isBuildingExists() {
    return this.buildingId && this.buildingId !== EMPTY_BUILDING;
  }

  setGender = (source, isEdit) => {
    isEdit ? (this.person.gender = source) : (this.selectedGender = source);
  };

  setRegistrationType = (source, isEdit) => {
    isEdit
      ? (this.person.registrationType = source)
      : (this.selectedRegistrationType = source);
  };

  setPassportType = (source) => {
    this.selectedPassportType = source;
  };

  reset = () => {
    this.person = null;
    this.personHistory = [];
    this.historyCount = 0;

    this.name = "";
    this.surName = "";
    this.patronymic = "";

    this.selectedGender = null;

    this.selectedRegistrationType = null;

    this.selectedPassportType = null;
    this.passportSeries = "";
    this.passportNumber = "";
    this.passportAuthority = "";

    this.citizenship = "";
    this.birthLocation = "";

    this.passport = {
      type: "[Не выбрано]",
      series: "",
      number: "",
      gettingDate: null,
      authority: "",
    };

    this.registationType = "Постоянная";

    this.snils = "";
    this.snilsErr = null;
    this.birthDate = null;
    this.gettingDate = null;
    this.startOfRegistration = null;
    this.endOfRegistration = null;
    this.endOfTemporalRegistration = null;

    this.buildingId = null;

    this.editedDate = "";
    this.editedGettingDate = "";
    this.editedStartOfRegistration = "";
    this.editedEndOfRegistration = "";
    this.editedEndOfTemporalRegistration = "";

    this.parameters = [];

    this.addressFull = "";
    this.recievedAddressString = "";
    this.togglePeopleFormFetch = true;
    this.flatSuggestion = null;
    this.fullAddressSuggestion = null;

    this.noHouse = true;
    this.noFlat = true;

    this.region = "Свердловская область";
    this.city = "Екатеринбург";
    this.district = "";
    this.locality = "";
    this.innerDistrict = "";
    this.street = "";
    this.additional = "";
    this.additionalStreet = "";
    this.houseNum = null;
    this.housePart = null;
    this.houseBuilding = null;
    this.flat = null;
    this.index = null;

    this.buildingId = null;
  };

  resetSnilsErrors = () => {
    this.snilsErr = null;
  };

  resetAddressFields = () => {
    this.region = "Свердловская область";
    this.city = "Екатеринбург";
    this.district = "";
    this.locality = "";
    this.innerDistrict = "";
    this.street = "";
    this.additional = "";
    this.additionalStreet = "";
    this.houseNum = null;
    this.housePart = null;
    this.houseBuilding = null;
    this.index = null;
  };

  resetExcludedOnCancel = () => {
    SearchStore.excluded = SearchStore.excluded.filter((item) =>
      this.parameters.includes(item)
    );
  };

  onFieldChange = (key, value, type) => {
    const parsed = parseValue(value, type);
    this.handleChange(key, parsed);
  };

  onSelect = (suggestion) => {
    const flatPrefix = this.flatSuggestion
      ? this.flatSuggestion[0].title
      : null;
    if (suggestion.title && suggestion.title !== flatPrefix) {
      this.addressFull = suggestion.title;
    } else if (suggestion.location) {
      this.buildingId = suggestion.location.buildingId;
    } else if (
      this.isBuildingExists &&
      this.buildingId &&
      suggestion.title === flatPrefix
    ) {
      this.addressFull = `${this.recievedAddressString}, д. ${this.houseNum}, ${flatPrefix}`;
      AutocompleteStore.handleChange("showSuggestions", false);
    }
  };

  getSelectedSuggestion = (suggestion) => {
    if (suggestion.location) {
      this.buildingId = suggestion.location.buildingId;
      this.fetchByIdType("building");
      this.togglePeopleFormFetch = false;
      this.flatSuggestion = [{ title: "кв. " }];
      AutocompleteStore.handleChange("showSuggestions", true);
    } else if (this.isBuildingExists && this.buildingId) {
      this.togglePeopleFormFetch = false;
      AutocompleteStore.handleChange("currInput", suggestion.title);
    } else {
      AutocompleteStore.handleChange("currInput", suggestion.title);
      AreasStore.fetchAddresses(suggestion.title);
      AutocompleteStore.handleChange("showSuggestions", true);
    }
  };

  noFetchAсtion = (input) => {
    if (this.isBuildingExists) {
      const flatString = parseInt(
        input.replace(
          this.recievedAddressString + `, д. ${this.houseNum}, кв. `,
          ""
        )
      );
      if (!Number.isNaN(flatString) && flatString !== 0) {
        this.flat = flatString;
        this.noFlat = false;
        this.flatSuggestion = [{ title: "кв. " + flatString }];
      } else {
        this.noFlat = true;
        this.flatSuggestion = null;
        this.fullAddressSuggestion = [
          { title: `${this.recievedAddressString}, д. ${this.houseNum}, кв. ` },
        ];
        this.flat = null;
      }
    }
  };

  handleChange = (key, value) => {
    this[key] = value;
  };

  handlePersonChange = (field, value) => {
    set(this.person, field, value);
  };

  handlePassportChange = (field, value) => {
    set(this.person.passport, field, value);
  };

  changePersonDateBirth = (isEdit, date) => {
    isEdit
      ? set(this.person, "birthDate", date?.toISOString())
      : (this.birthDate = date?.toISOString());
    this.editedDate = date?.toISOString();
  };

  changeGettingDate = (date) => {
    this.gettingDate = date?.toISOString();
  };

  changeStartOfRegistration = (isEdit, date) => {
    isEdit
      ? set(this.person, "startOfRegistration", date?.toISOString())
      : (this.startOfRegistration = date?.toISOString());
  };

  changeEndOfRegistration = (isEdit, date) => {
    isEdit
      ? set(this.person, "endOfRegistration", date?.toISOString())
      : (this.endOfRegistration = date?.toISOString());
  };

  changeEndOfTemporalRegistration = (isEdit, date) => {
    isEdit
      ? set(this.person, "endOfTemporalRegistration", date?.toISOString())
      : (this.endOfTemporalRegistration = date?.toISOString());
  };

  fetchByIdType = (type) => {
    switch (type) {
      case "street":
        break;
      case "building":
        this.fetchBuildingById(this.buildingId);
        break;
      default:
        break;
    }
  };

  fetchBuildingById = async () => {
    if (this.isBuildingExists) {
      ApplicationStore.setLoading(true);
      await app
        .get(`/buildings/${this.buildingId}`)
        .then((res) => {
          const { street, number, region, city } = res.data;
          const parsedString = this.parseAddressString(street);
          this.resetAddressFields();
          AreasStore.resetAddressess();

          this.region = region || "";
          this.city = city || "";
          this.innerDistrict = parsedString.innerDistrict || "";
          this.street = parsedString.street || "";
          this.houseNum = number || null;
          this.noHouse = false;
          this.addressFull =
            `${AreasStore.streetTitle || street}, д. ${number}` +
            (this.flat ? `, кв. ${this.flat}` : "");
          this.recievedAddressString = street;

          if (parsedString.locality) {
            this.locality = parsedString.locality;
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            routing.push(`/404`);
          }
          ApplicationStore.setError(err);
        })
        .finally(() => {
          ApplicationStore.setLoading(false);
        });
    }
  };

  parseAddressString = (string) => {
    const splittedAddressString = string.split(", ");

    if (splittedAddressString.length === 1) {
      return {
        street: splittedAddressString[0],
      };
    }
    if (splittedAddressString.length === 2) {
      const splittedDistrict = splittedAddressString[0].split(" ");
      if (splittedDistrict.includes("р-н.")) {
        return {
          innerDistrict: splittedAddressString[0],
          street: splittedAddressString[1],
        };
      } else {
        return {
          locality: splittedAddressString[0],
          street: splittedAddressString[1],
        };
      }
    }
    if (splittedAddressString.length === 3) {
      return {
        innerDistrict: splittedAddressString[0],
        locality: splittedAddressString[1],
        street: splittedAddressString[2],
      };
    }
  };

  fetchPerson = async (id) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/people/${id}`)
      .then((res) => {
        this.editedDate = res.data.birthDate;
        this.buildingId = res.data.buildingId;
        const {
          streetId,
          appartamentNumber,
          buildingNumber,
          Parameters,
          passport,
          snils,
        } = res.data;
        this.person = { ...res.data, passport: passport || {} };

        this.snils = snils;

        if (passport) {
          this.selectedPassportType = passport.passportType;
          this.passportSeries = passport.series;
          this.passportNumber = passport.number;
          this.passportAuthority = passport.authority;
        }

        AreasStore.streetId = streetId || null;
        this.houseNum = buildingNumber;
        this.parameters = Parameters;
        this.flat = appartamentNumber;

        this.gettingDate = this.person.passport.gettingDate;
        this.editedStartOfRegistration = this.person.startOfRegistration;
        this.editedEndOfRegistration = this.person.endOfRegistration;
        this.editedEndOfTemporalRegistration =
          this.person.endOfTemporalRegistration;

        const fetchedGender = this.person.gender;
        const fetchedGenderOption = getOptionById(genders, fetchedGender);

        this.setGender(fetchedGenderOption);

        const fetchedRegistrationType = this.person.registrationType;
        const fetchedRegistrationTypeOption = getOptionById(
          registrationTypes,
          fetchedRegistrationType
        );

        this.setRegistrationType(fetchedRegistrationTypeOption);

        this.parameters.forEach((value) => {
          if (!SearchStore.excluded.includes(value))
            SearchStore.excluded.push(value);
        });

        if (this.flat) {
          this.noFlat = false;
        }

        if (this.houseNum) {
          this.noHouse = false;
        }

        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          routing.push(`/404`);
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchPersonHistory = async (params) => {
    ApplicationStore.setLoading(true);
    await app
      .get(
        `/people/gethistoryofpersonchanges?startIndex=${params.startIndex}&itemsCount=${params.perPage}&id=${params.id}`
      )
      .then((res) => {
        this.personHistory = res.data.historyOfPersonChanges;
        this.historyCount = res.data.count;
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  addPerson = () => {
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);
    if (Object.keys(this.errors).length) {
      return;
    }
    const setPassportToSend = () => {
      if (
        this.selectedPassportType &&
        !(this.selectedPassportType === "none")
      ) {
        return {
          number: this.passportNumber || null,
          series: this.passportSeries || null,
          authority: this.passportAuthority || null,
          gettingDate: this.gettingDate || null,
          passportType: this.selectedPassportType || null,
        };
      } else {
        return null;
      }
    };
    const setRegistrationDataToSend = () => {
      if (!(this.selectedRegistrationType === "none")) {
        const isPermanentRegistration =
          this.selectedRegistrationType === "Постоянная";
        return {
          registrationType: this.selectedRegistrationType || null,
          startOfRegistration: this.startOfRegistration || null,
          endOfRegistration: isPermanentRegistration
            ? this.endOfRegistration || null
            : null,
          endOfTemporalRegistration: !isPermanentRegistration
            ? this.endOfTemporalRegistration || null
            : null,
        };
      } else {
        return {
          registrationType: null,
          startOfRegistration: null,
          endOfRegistration: null,
          endOfTemporalRegistration: null,
        };
      }
    };
    const dataToSend = {
      name: this.name || null,
      surName: this.surName || null,
      patronymic: this.patronymic || null,
      snils: this.snils || null,
      birthDate: this.birthDate || null,
      buildingId: this.buildingId ? this.buildingId : EMPTY_BUILDING,
      buildingNumber: this.noHouse ? null : this.houseNum,
      appartamentNumber: this.noFlat ? null : this.flat.toString(),
      passport: setPassportToSend(),
      ...setRegistrationDataToSend(),
      citizenship: this.citizenship || null,
      gender: this.selectedGender || null,
      addressOfBirth: this.birthLocation || null,
      parameters: this.parameters,
    };

    ApplicationStore.setUploading(true);
    app
      .post(`/people`, dataToSend)
      .then((res) => {
        runInAction(() => {
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
          ApplicationStore.setUploading(false);
          routing.push(`/people/editperson/${res.data}`);
        });
      })
      .catch((err) => {
        if (err.response.data === INVALID_SNILS_CHECKSUM_ERR) {
          this.snilsErr = err.response.data;
        }
        if (err.response.data === SNILS_DUPLICATE_ERR) {
          this.snilsErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setUploading(false);
      });
  };

  updatePerson = (id) => {
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);
    if (Object.keys(this.errors).length) {
      return;
    }
    const setPassportToSend = () => {
      if (
        this.selectedPassportType &&
        !(this.selectedPassportType === "none")
      ) {
        return {
          number: this.passportNumber || null,
          series: this.passportSeries || null,
          authority: this.passportAuthority || null,
          gettingDate: this.gettingDate || null,
          passportType: this.selectedPassportType || null,
        };
      } else {
        return null;
      }
    };
    const setRegistrationDataToSend = () => {
      if (!(this.person.registrationType === "none")) {
        const isPermanentRegistration =
          this.person.registrationType === "Постоянная";

        return {
          registrationType: this.person.registrationType || null,
          startOfRegistration: this.person.startOfRegistration || null,
          endOfRegistration: isPermanentRegistration
            ? this.person.endOfRegistration || null
            : null,
          endOfTemporalRegistration: !isPermanentRegistration
            ? this.person.endOfTemporalRegistration || null
            : null,
        };
      } else {
        return {
          registrationType: null,
          startOfRegistration: null,
          endOfRegistration: null,
          endOfTemporalRegistration: null,
        };
      }
    };
    const dataToSend = {
      id,
      name: this.person.name || null,
      surName: this.person.surName || null,
      patronymic: this.person.patronymic || null,
      snils: this.snils || null,
      birthDate: this.person.birthDate || null,
      buildingId: this.buildingId ? this.buildingId : EMPTY_BUILDING,
      buildingNumber: this.noHouse ? null : this.houseNum,
      appartamentNumber: this.noFlat ? null : this.flat.toString(),
      passport: setPassportToSend(),
      citizenship: this.person.citizenship || null,
      ...setRegistrationDataToSend(),
      gender: this.person.gender || null,
      addressOfBirth: this.person.addressOfBirth || null,
      parameters: this.parameters,
    };
    ApplicationStore.setUploading(true);
    app
      .put(`/people`, dataToSend)
      .then(() => {
        runInAction(() => {
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
          ApplicationStore.setUploading(false);
          AlertStore.togglePeopleEditSuccessAlertOpen(true);
        });
      })
      .catch((err) => {
        if (err.response.data === INVALID_SNILS_CHECKSUM_ERR) {
          this.snilsErr = err.response.data;
        }
        if (err.response.data === SNILS_DUPLICATE_ERR) {
          this.snilsErr = err.response.data;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setUploading(false);
      });
  };

  deletePerson = (id) => {
    console.log(1, id);
    app
      .post(`/people/${id}/deactivate`)
      .then(() => {
        runInAction(() => {
          routing.push("/people");
        });
      })
      .catch((err) => ApplicationStore.setError(err));
  };

  addCheckedFormParams = () => {
    SearchStore.peopleParams
      .filter((param) => param.checked)
      .map((param) => param.title)
      .forEach((value) => {
        if (!this.parameters.includes(value)) this.parameters.push(value);
      });
    this.parameters.forEach((value) => {
      if (!SearchStore.excluded.includes(value))
        SearchStore.excluded.push(value);
    });
  };

  delFormParam = (title) => {
    const index = this.parameters.findIndex((item) => item === title);
    if (index !== -1) {
      const excludedIndex = SearchStore.excluded.findIndex(
        (item) => item === title
      );
      SearchStore.excluded.splice(excludedIndex, 1);
      this.parameters.splice(index, 1);
    }
  };

  noFlatReaction = reaction(
    () => this.noFlat,
    () => {
      const address = AreasStore.streetTitle || this.recievedAddressString;
      const house = `, д. ${this.houseNum}`;
      const flat = `, кв. ${this.flat}`;
      const flatExists = !this.noFlat;
      const houseExists = !this.noHouse;
      const houseAndFlat = (houseExists ? house : "") + (this.flat ? flat : "");
      return (this.addressFull =
        address + (flatExists ? houseAndFlat : houseExists ? house + "" : "") ||
        "");
    }
  );
}

decorate(PeopleFormStore, {
  person: observable,
  personHistory: observable,
  historyCount: observable,

  name: observable,
  surName: observable,
  patronymic: observable,

  snils: observable,
  snilsErr: observable,
  birthDate: observable,
  gettingDate: observable,
  startOfRegistration: observable,
  endOfRegistration: observable,
  endOfTemporalRegistration: observable,

  birthLocation: observable,
  gender: observable,
  citizenship: observable,

  selectedPassportType: observable,
  passportSeries: observable,
  passportNumber: observable,
  passportAuthority: observable,

  passport: observable,

  registationType: observable,

  buildingId: observable,
  parameters: observable,

  editedDate: observable,
  editedGettingDate: observable,
  editedStartOfRegistration: observable,
  editedEndOfRegistration: observable,
  editedEndOfTemporalRegistration: observable,

  noHouse: observable,
  noFlat: observable,

  region: observable,
  city: observable,
  district: observable,
  locality: observable,
  innerDistrict: observable,
  street: observable,
  additional: observable,
  additionalStreet: observable,
  houseNum: observable,
  housePart: observable,
  houseBuilding: observable,
  flat: observable,
  index: observable,

  selectedGender: observable,
  selectedRegistrationType: observable,

  addressFull: observable,
  recievedAddressString: observable,
  togglePeopleFormFetch: observable,
  flatSuggestion: observable,
  fullAddressSuggestion: observable,

  errors: computed,

  parsedDate: computed,
  parsedStartOfRegistration: computed,
  parsedGettingDate: computed,
  parsedEndOfRegistration: computed,
  parsedEndOfTemporalRegistration: computed,

  isBuildingExists: computed,

  setGender: action,
  setRegistrationType: action,
  setPassportType: action,

  reset: action,
  getSelectedSuggestion: action,
  onFieldChange: action,
  handleChange: action,
  handlePersonChange: action,
  handlePassportChange: action,

  changePersonDateBirth: action,
  changeGettingDate: action,
  changeStartOfRegistration: action,
  changeEndOfRegistration: action,
  changeEndOfTemporalRegistration: action,

  fetchPerson: action,
  fetchPersonHistory: action,
  addPerson: action,
  updatePerson: action,
  deletePerson: action,

  addCheckedFormParams: action,
  delFormParam: action,

  onSelect: action,
  noFetchAсtion: action,

  resetExcludedOnCancel: action,
  resetSnilsErrors: action,
});

export default new PeopleFormStore();
