import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { useLocation } from "react-router-dom";

import "./index.scss";

import { ReactComponent as Close } from "assets/svg/close.svg";
import { ReactComponent as Danger } from "assets/svg/danger.svg";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";

import FloatLabel from "common/components/Inputs/FloatLabel";
import Autocomplete from "common/components/Inputs/Autocomplete";
import TextInput from "common/components/Inputs/TextInput";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import DeleteButton from "common/components/Buttons/DeleteButton";
import AddButton from "common/components/Buttons/AddButton";

import useDocumentTitle from "hooks/useDocumentTitle";
import { AreaAutocomplete } from "../../../../common/components/Inputs/Autocomplete";

const AddEditArea = inject(
  "ModalStore",
  "AreasStore",
  "ApplicationStore",
  "CompaniesStore"
)(
  observer(
    ({ AreasStore, ApplicationStore, ModalStore, CompaniesStore, match }) => {
      const {
        area,
        newArea,
        addArea,
        fetchArea,
        updateArea,
        deleteArea,
        changeArea,
        reset,
        errors,
        addresses,
        buildings,
        newBuildings,
        addBuilding,
        deleteBuilding,
        changeBuilding,
        deleteChosenBuilding,
        getSelectedBuildingSuggestion,
        fetchAddresses,
        peopleCount,
        onSelectBuilding,
        streetTitle,
        declinePeopleCount,
        resetServerError,

        getSelectedSuggestionArea,
        onSelectArea,
        addressesArea,
        streetTitleArea,
        selectedArea,
        fetchAddressesArea,
        changeSelectedArea,
      } = AreasStore;
      const { isLoading, isUploading, setGlobalFieldValue } = ApplicationStore;
      const {
        toggle,
        toggleErrModal,
        isSubmit,
        isOpen,
        isErrModalOpen,
        resetModal,
      } = ModalStore;
      const { company, fetchCompany } = CompaniesStore;

      const isEdit = match.url.includes("/handbooks/editstation");
      const id = match.params.id;

      const location = useLocation();
      const params = new URLSearchParams(location.search);
      const caption = params.get("caption");

      useEffect(() => {
        if (isEdit) {
          setGlobalFieldValue("mode", "EDIT_AREA");
          fetchArea(id);
        } else {
          setGlobalFieldValue("mode", "ADD_AREA");
        }
        return () => {
          setGlobalFieldValue("mode", "");
          setGlobalFieldValue("isSubmitted", false);
          reset();
        };
      }, [fetchArea, isEdit, id, reset, setGlobalFieldValue]);

      useEffect(() => {
        isEdit
          ? area && fetchCompany(area.electionCompanyDTO.electionCompanyId)
          : fetchCompany(id);
      }, [fetchCompany, area, isEdit, id]);

      useEffect(() => {
        if (isSubmit) {
          deleteArea(id);
        }
      }, [isSubmit, deleteArea, id]);

      useEffect(() => () => resetModal(), [resetModal]);

      useDocumentTitle(
        () => (isEdit ? "Редактировать участок" : "Добавить участок"),
        [isEdit]
      );

      const checkBeforeAdding = (isEdit) => {
        if (peopleCount >= 3000) {
          toggleErrModal(true);
        } else {
          isEdit
            ? buildings.length === 0
              ? addBuilding(true)
              : toggleErrModal(true)
            : newBuildings.length === 0
            ? addBuilding(false)
            : toggleErrModal(true);
        }
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        if (newBuildings.length !== 0) {
          toggleErrModal(true);
          return;
        }
        isEdit ? updateArea(area?.id) : addArea();
      };

      const lastBreadcrumbLabelInEditorMode = area
        ? `Участок №${area.caption}`
        : "";

      const breadcrumbs = [
        { path: `/handbooks/companies`, label: "Кампании" },
        {
          path: `/handbooks/editcompany/${
            area?.electionCompanyDTO.electionCompanyId ||
            company?.electionCompanyId
          }`,
          label: caption || company?.caption,
        },
        {
          path: `/handbooks/stations/${company?.electionCompanyId}?caption=${
            caption || company?.caption
          }`,
          label: "Участки",
        },
        {
          path: "/handbooks/addpeopleparam",
          label: isLoading
            ? "Загрузка..."
            : isEdit
            ? lastBreadcrumbLabelInEditorMode
            : "Добавить участок",
        },
      ];

      const modalDanger = <Modal type="danger" />;
      const modalError = (
        <Modal
          type="error"
          message={
            peopleCount >= 3000
              ? "На участке не может быть больше 3000 человек. Вы не сможете добавлять новые дома."
              : "Необходимо ввести и выбрать адрес из списка, прежде чем добавлять еще один дом."
          }
        />
      );

      const homes = (
        <ul className="area__homes">
          {isEdit
            ? area?.buildings?.map((area, index) => (
                <li
                  className="area__home"
                  key={`${area.location.buildingId}-${index}`}
                >
                  <TextInput
                    type="text"
                    name={`${index}-location`}
                    value={
                      area.location.street + ", " + area.location.buildingNumber
                    }
                    className="form__field no-float"
                    readOnly
                  />
                  <TextInput
                    type="number"
                    name={`${index}-peopleCount`}
                    value={area.peopleCount}
                    className="form__field no-float"
                    readOnly
                  />
                  <button
                    className="remove-area-button"
                    type="button"
                    onClick={() => deleteChosenBuilding(true, area)}
                  >
                    <Close className="close" />
                  </button>
                </li>
              ))
            : newArea?.buildings?.map((area, index) => (
                <li
                  className="area__home"
                  key={`${area.location.buildingId}-${index}`}
                >
                  <TextInput
                    type="text"
                    name={`${index}-location`}
                    value={
                      area.location.street + ", " + area.location.buildingNumber
                    }
                    className="form__field no-float"
                    readOnly
                  />
                  <TextInput
                    type="number"
                    name={`${index}-peopleCount`}
                    value={area.peopleCount}
                    className="form__field no-float"
                    readOnly
                  />
                  <button
                    className="remove-area-button"
                    type="button"
                    onClick={() => deleteChosenBuilding(false, area)}
                  >
                    <Close className="close" />
                  </button>
                </li>
              ))}
        </ul>
      );

      const targetBuildings = isEdit ? buildings : newBuildings;

      const autocompleteInputs = (
        <ul className="area__autocomplete-inputs">
          {targetBuildings?.map((building, index) => {
            return (
              <li className="area__autocomplete mb30" key={index}>
                <Autocomplete
                  placeholder="Начните вводить адрес дома и выберите из списка"
                  userInput={building}
                  onInputChange={(e) => {
                    changeBuilding(isEdit, index, e.target.value);
                  }}
                  getSelectedSuggestion={(suggestion) =>
                    getSelectedBuildingSuggestion(isEdit, suggestion)
                  }
                  onSelect={(suggestion) =>
                    onSelectBuilding(isEdit, index, suggestion)
                  }
                  fetch={fetchAddresses}
                  suggestions={addresses?.streets || addresses}
                  streetTitle={streetTitle}
                  needPeopleCount
                  autoFocus
                />
                <div>
                  <Close
                    className="close"
                    onClick={() => deleteBuilding(isEdit, building)}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      );

      const pageTitleInEditorMode = area?.id
        ? `Участок №${
            area?.caption || ""
          } — ${peopleCount} ${declinePeopleCount}`
        : "Загрузка...";

      const pageTitle = isEdit ? pageTitleInEditorMode : "Добавить участок";

      return (
        <div className="wrapper__content">
          {isEdit && isOpen && modalDanger}
          {isErrModalOpen && modalError}
          <Breadcrumbs routes={breadcrumbs} />
          <section className="section">
            <div className="container">
              <div className="title">
                <h1 className="page-title">{pageTitle}</h1>
                {peopleCount >= 3000 && <Danger />}
                <div className="tooltip">
                  <p>
                    На участке не может быть больше 3000 человек. Вы не сможете
                    добавлять новые дома
                  </p>
                </div>
              </div>

              {isEdit && (
                <DeleteButton
                  onClick={() => toggle(true)}
                  disabled={isUploading || isLoading}
                  className="page-link"
                >
                  Удалить участок
                </DeleteButton>
              )}

              <form className="form" onSubmit={handleSubmit} noValidate>
                <FloatLabel
                  label="Номер участка"
                  value={isEdit && area ? area.caption : newArea.caption}
                >
                  <TextInput
                    type="text"
                    name="caption"
                    value={isEdit && area ? area.caption : newArea.caption}
                    onChange={(e) => {
                      resetServerError();
                      !isEdit && changeArea(newArea, "caption", e.target.value);
                    }}
                    className={`form__field ${errors.caption ? "warning" : ""}`}
                    autoFocus={!isEdit}
                    readOnly={isEdit}
                  />
                </FloatLabel>
                {errors.caption && (
                  <p className="warning-text margin-bottom-12">
                    {errors.caption}
                  </p>
                )}

                <AreaAutocomplete
                  placeholder="Адрес участка"
                  name="address"
                  userInput={selectedArea}
                  onInputChange={(e) => {
                    changeSelectedArea(e.target.value);
                  }}
                  getSelectedSuggestion={(suggestion) =>
                    getSelectedSuggestionArea(isEdit, suggestion)
                  }
                  onSelect={(suggestion) => onSelectArea(suggestion)}
                  fetch={fetchAddressesArea}
                  suggestions={addressesArea?.streets || addressesArea}
                  streetTitle={streetTitleArea}
                  className={`form__field ${errors.address ? "warning" : ""}`}
                  autoFocus={isEdit}
                />
                {errors.address && (
                  <p className="warning-text margin-bottom-12">
                    {errors.address}
                  </p>
                )}

                <div className="labels">
                  <label className="label">Дома</label>
                  <label className="label">Человек</label>
                </div>

                {homes}
                {autocompleteInputs}

                <div className="form__footer">
                  <AddButton
                    disabled={isUploading || isLoading}
                    onClick={() => checkBeforeAdding(isEdit)}
                  >
                    Добавить дом
                  </AddButton>
                  <p>Итого: {peopleCount}</p>
                </div>

                <div className="form__buttons">
                  <SubmitButton
                    className="form__button"
                    disabled={isUploading || isLoading}
                  >
                    {isUploading || isLoading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                  <CancelButton className="form__button">Отмена</CancelButton>
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  )
);

export default AddEditArea;
