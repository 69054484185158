import React, { useEffect, useState } from "react";
import { MONTHS } from "helpers/calendar";

import "./index.scss";

const YearMonthSelect = ({
  date,
  onChange,
  fromMonth,
  toMonth,
  isInput,
  isLoading,
  mode,
  setMode,
}) => {
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [calendarWidth, setCalendarWidth] = useState(0);

  const [page, setPage] = useState(1);
  const years = [];

  useEffect(() => {
    const calendarWidth = document.querySelector(".DayPicker-Body").clientWidth;
    const calendarHeight = document.querySelector(".DayPicker-Body")
      .clientHeight;
    setCalendarHeight(isInput ? calendarHeight + 23 : calendarHeight + 22);
    setCalendarWidth(isInput ? calendarWidth + 1 : calendarWidth);
  }, [isInput]);

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const perPage = 12;
  const pagesCount = Math.ceil(years.length / perPage);

  const paginateYears = (page) => {
    return years.slice((page - 1) * perPage, page * perPage);
  };

  const handleChangeMonth = (month) => {
    onChange(new Date(date.getFullYear(), month));
    setMode("SET_DATE");
  };

  const handleChangeYear = (year) => {
    onChange(new Date(year, date.getMonth()));
    setMode("SET_DATE");
  };

  return (
    <div className="DayPicker-Caption">
      <header className="yearMonthPicker">
        {mode === "SET_YEAR" ? (
          page === 1 ? (
            <i className="arrow left disabled"></i>
          ) : (
            <i className="arrow left" onClick={() => setPage(page - 1)}></i>
          )
        ) : mode === "SET_MONTH" || isLoading ? (
          <i className="arrow left disabled"></i>
        ) : (
          <i
            className="arrow left"
            onClick={() =>
              onChange(new Date(date.getFullYear(), date.getMonth() - 1))
            }
          ></i>
        )}
        <div className="date">
          <p
            className={isLoading ? "loading" : ""}
            onClick={() => {
              if (!isLoading)
                setMode(mode === "SET_MONTH" ? "SET_DATE" : "SET_MONTH");
            }}
          >
            {MONTHS[date.getMonth()]}
          </p>
          <p
            className={isLoading ? "loading" : ""}
            onClick={() => {
              if (!isLoading)
                setMode(mode === "SET_YEAR" ? "SET_DATE" : "SET_YEAR");
            }}
          >
            {date.getFullYear()}
          </p>
        </div>
        {mode === "SET_YEAR" ? (
          page === pagesCount ? (
            <i className="arrow right disabled"></i>
          ) : (
            <i className="arrow right" onClick={() => setPage(page + 1)}></i>
          )
        ) : mode === "SET_MONTH" || isLoading ? (
          <i className="arrow right disabled"></i>
        ) : (
          <i
            className="arrow right"
            onClick={() =>
              onChange(new Date(date.getFullYear(), date.getMonth() + 1))
            }
          ></i>
        )}
      </header>
      {mode === "SET_MONTH" && (
        <div className="monthPicker-wrapper">
          <div
            className={`monthPicker${isInput ? " input" : ""}`}
            style={{
              height: calendarHeight + "px",
              width: calendarWidth + "px",
            }}
          >
            {MONTHS.map((month, index) => (
              <div
                className={`month${
                  index === date.getMonth() ? " selected" : ""
                }`}
                key={month}
                value={index}
              >
                <p onClick={() => handleChangeMonth(index)}>{month}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {mode === "SET_YEAR" && (
        <div className="yearPicker">
          <div className="yearPicker-wrapper">
            <div
              className={`yearPicker${isInput ? " input" : ""}`}
              style={{
                height: calendarHeight + "px",
                width: calendarWidth + "px",
              }}
            >
              {paginateYears(page).map((year) => (
                <div
                  className={`year${
                    year === date.getFullYear() ? " selected" : ""
                  }`}
                  key={year}
                  value={year}
                >
                  <p onClick={() => handleChangeYear(year)}>{year}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YearMonthSelect;
