import { observable, decorate, action, runInAction, set, computed } from "mobx";

import { EMPTY_ERR, INVALID_SYMBOLS_ERR } from "helpers/errors";

import app from "utils/axiosConfig";

import routing from "../routing";
import ApplicationStore from "../appStore";

import ModalStore from "stores/components/modalStore";
import SearchStore from "stores/components/searchStore";
import ItemsStore from "stores/common/itemsStore";

import { extractItemsIds } from "helpers/commonMethods";

class PeopleParamsStore {
	personParam = { title: "" };
	newParam = { title: "" };
	titleCache = "";
	serverErr = "";

	get errors() {
		let error = {};
		if (!ApplicationStore.isSubmitted) {
			return error;
		}
		switch (ApplicationStore.mode) {
			case "ADD_PERSON_PARAM":
				if (!this.newParam.title) {
					error["title"] = EMPTY_ERR;
				} else if (this.serverErr) {
					error["title"] = this.serverErr;
				} else if (!this.isValidParam(this.newParam.title)) {
					error["title"] = INVALID_SYMBOLS_ERR;
				}
				break;
			case "EDIT_PERSON_PARAM":
				if (!this.personParam.title) {
					error["title"] = EMPTY_ERR;
				} else if (this.serverErr) {
					error["title"] = this.serverErr;
				} else if (!this.isValidParam(this.personParam.title)) {
					error["title"] = INVALID_SYMBOLS_ERR;
				}
				break;
			default:
				break;
		}
		return error;
	}

	isValidParam = (param) => {
		if (!param.replace(/\s/g, "").length) {
			return false;
		} else {
			const regex = /^[а-яА-ЯёЁ0-9_\- ]*$/i;
			return regex.test(param);
		}
	};

	resetServerErrors = () => {
		this.serverErr = "";
	};

	fetchParameter = (value) => {
		set(this.personParam, "title", value);
		this.titleCache = this.personParam.title;
	};

	changeParameter = (key, value) => {
		set(key, "title", value);
	};

	reset = () => {
		runInAction(() => {
			this.personParam.title = "";
			this.newParam.title = "";
			this.titleCache = "";
			this.serverErr = "";
		});
	};

	addParam = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			ApplicationStore.setUploading(true);
			app.post(`/personparameters`, {
				title: this.newParam.title.trim(),
			})
				.then((res) => {
					runInAction(() => {
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
						routing.push(`/handbooks/editpeopleparam/${res.data}`);
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};

	updateParam = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			ApplicationStore.setUploading(true);
			app.put(`/personparameters/${this.titleCache}`, {
				title: this.personParam.title.trim(),
			})
				.then(() => {
					runInAction(() => {
						this.titleCache = this.personParam.title;
						ApplicationStore.setGlobalFieldValue("isSubmitted", false);
						ApplicationStore.setUploading(false);
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
				})
				.finally(() => {
					ApplicationStore.setUploading(false);
				});
		}
	};

	deleteParam = () => {
		this.serverErr = "";
		ApplicationStore.setGlobalFieldValue("isSubmitted", true);

		if (!Object.keys(this.errors).length) {
			app.delete(`/personparameters/${this.titleCache}`)
				.then(() => {
					runInAction(() => {
						routing.push("/handbooks");
					});
				})
				.catch((err) => {
					if (err.response) {
						this.serverErr = err.response.data;
					}
					ApplicationStore.setError(err);
					ModalStore.resetModal();
				});
		}
	};

	assignParameterToSelectedPeople = (startIndex, perPage) => {
		const selectedPeopleIds = extractItemsIds(ItemsStore.selectedItems);
		const peopleParamsList = SearchStore.peopleParams;
		const selectedParameters = peopleParamsList.filter((param) => param.checked).map((param) => param.title);

		const data = {
			parameters: selectedParameters,
			people: {
				includedPeople: selectedPeopleIds,
			},
		};

		ApplicationStore.setLoading(true);
		app.put("/People/AddParametersToPeople", data)
			.then(() => {
				ItemsStore.resetSelected();
				ItemsStore.fetchWithParameters("people", startIndex, perPage);
			})
			.catch((err) => {
				ApplicationStore.setError(err);
			})
			.finally(() => {
				ApplicationStore.setLoading(false);
				ModalStore.resetModal();
			});
	};
}

decorate(PeopleParamsStore, {
	personParam: observable,
	newParam: observable,
	titleCache: observable,
	serverErr: observable,

	errors: computed,

	isValidParam: action,
	fetchParameter: action,
	changeParameter: action,
	reset: action,
	addParam: action,
	updateParam: action,
	deleteParam: action,
	assignParameterToSelectedPeople: action,
	resetServerErrors: action,
});

export default new PeopleParamsStore();
