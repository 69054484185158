import React from "react";

import "./index.scss";

const TextInput = (props) => {
  return (
    <input
      {...props}
      className={`input__text ${props.className || ""}`}
      type={props.type}
      min={props.min || 0}
      value={props.value}
      name={props.name}
      placeholder={props.placeholder || ""}
      autoComplete={props.autoComplete || "off"}
      autoFocus={props.autoFocus || false}
      disabled={props.disabled || false}
      readOnly={props.readOnly || false}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown || false}
    />
  );
};

export default TextInput;
