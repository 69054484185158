import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import Datepicker from "common/components/Inputs/Datepicker";
import TextInput from "common/components/Inputs/TextInput";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import FloatLabel from "common/components/Inputs/FloatLabel";
import DeleteButton from "common/components/Buttons/DeleteButton";

import "./index.scss";

const getNumberEditFields = (
  districts = [],
  districtTypeName,
  districtType,
  handler,
  disabled
) => {
  return (
    <>
      {districts.map((district) => (
        <div key={district.district} className="modal-add-jury__field">
          <FloatLabel
            label={`${district.district} ${districtTypeName}`}
            value={district[`${districtType}`]}
          >
            <TextInput
              disabled={disabled}
              type="number"
              name="number"
              autoComplete="off"
              value={district[`${districtType}`]}
              onChange={(e) => {
                handler(districtType, e.target.value, district.district);
              }}
            />
          </FloatLabel>
        </div>
      ))}
    </>
  );
};

const ModalEditJuryList = inject(
  "ApplicationStore",
  "JuryStore",
  "ModalStore"
)(
  observer(({ onCancel, JuryStore, ApplicationStore, ModalStore }) => {
    const {
      errors,
      currentJuryList,
      editJuryList,
      handleCurrentJuryListChange,
      objectDateToStringDate,
      actualMask,
      handleChangeActualMask,
      handleChangeCurrentJuryListCount,
    } = JuryStore;

    const { setGlobalFieldValue } = ApplicationStore;
    const { toggle, toggleEditJuryListModal } = ModalStore;

    useEffect(() => {
      setGlobalFieldValue("mode", "EDIT_JURY");
      return () => {
        setGlobalFieldValue("mode", "");
        setGlobalFieldValue("isSubmitted", false);
      };
    }, []);

    const handleSubmit = (e) => {
      e.preventDefault();
      editJuryList();
    };

    const handleDateToChange = (val) => {
      if (val instanceof Date) {
        handleCurrentJuryListChange("dateTo", objectDateToStringDate(val));
      } else {
        handleCurrentJuryListChange("dateTo", val);
      }
    };
    const handleDateFromChange = (val) => {
      if (val instanceof Date) {
        handleCurrentJuryListChange("dateFrom", objectDateToStringDate(val));
      } else {
        handleCurrentJuryListChange("dateFrom", val);
      }
    };

    return (
      <div className="modal modal-edit-jury">
        <p className="modal-edit-jury__title">Редактировать список присяжных</p>
        <div className="modal-edit-jury__body">
          <form className="modal-edit-jury__content" onSubmit={handleSubmit}>
            <DeleteButton
              disabled={currentJuryList.isForming}
              className="modal__button page-link modal-edit-jury__btn-delete"
              onClick={() => {
                toggleEditJuryListModal(false);
                toggle(true);
              }}
            >
              Удалить список присяжных
            </DeleteButton>

            <div className="modal-edit-jury__wrap">
              <h3 className="modal-edit-jury__wrap__title">
                Даты действия списка
              </h3>
              <div className="modal-edit-jury__blocks">
                <div className="modal-edit-jury__field">
                  <FloatLabel
                    label="Начало"
                    value={
                      actualMask["dateFromCur"] || currentJuryList.dateFrom
                    }
                  >
                    <Datepicker
                      date={currentJuryList.dateFrom}
                      changeActualMask={(val) =>
                        handleChangeActualMask("dateFromCur", val)
                      }
                      name="dateFromCur"
                      changeDate={(day) => {
                        handleDateFromChange(day);
                      }}
                      className={`form__field datepicker-not-width ${
                        errors.dateFrom ? "warning" : ""
                      }`}
                    />
                  </FloatLabel>
                  {errors.dateFrom && (
                    <p className="modal-edit-jury__error--small margin-bottom-12">
                      {errors.dateFrom}
                    </p>
                  )}
                </div>
                <div className="modal-edit-jury__field">
                  <FloatLabel
                    label="Окончание"
                    value={actualMask["dateToCur"] || currentJuryList.dateTo}
                  >
                    <Datepicker
                      date={currentJuryList.dateTo}
                      changeActualMask={(val) =>
                        handleChangeActualMask("dateToCur", val)
                      }
                      name="dateToCur"
                      changeDate={(day) => {
                        handleDateToChange(day);
                      }}
                      className={`form__field datepicker-not-width ${
                        errors.dateTo ? "warning" : ""
                      }`}
                    />
                  </FloatLabel>
                  {errors.dateTo && (
                    <p className="modal-edit-jury__error--small margin-bottom-12">
                      {errors.dateTo}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-edit-jury__wrap">
              <h3 className="modal-edit-jury__wrap__title">
                Выборки списков по районам
              </h3>
              <div className="modal-edit-jury__blocks">
                <div className="modal-edit-jury__block">
                  <h4 className="modal-edit-jury__block__title">
                    Количество людей основного списка
                  </h4>
                  {getNumberEditFields(
                    currentJuryList.juryListByDistricts,
                    "Основной",
                    "basicList",
                    handleChangeCurrentJuryListCount.bind(
                      null,
                      "juryListByDistricts"
                    ),
                    Boolean(
                      currentJuryList.isForming || currentJuryList.isFormed
                    )
                  )}
                </div>
                <div className="modal-edit-jury__block">
                  <h4 className="modal-edit-jury__block__title">
                    Количество людей запасного списка
                  </h4>
                  {getNumberEditFields(
                    currentJuryList.juryListByDistricts,
                    "Запасной",
                    "subList",
                    handleChangeCurrentJuryListCount.bind(
                      null,
                      "juryListByDistricts"
                    ),
                    Boolean(
                      currentJuryList.isForming || currentJuryList.isFormed
                    )
                  )}
                </div>
              </div>

              <div className="modal-edit-jury__errors-block">
                {errors.lists && (
                  <p className="warning-text margin-bottom-12">
                    {errors.lists}
                  </p>
                )}
              </div>
            </div>

            <div className="modal-edit-jury__wrap">
              <h3 className="modal-edit-jury__wrap__title">
                Выборки списков по районам для Екатеринбургского гарнизонного
                военного суда
              </h3>
              <div className="modal-edit-jury__blocks">
                <div className="modal-edit-jury__block">
                  <h4 className="modal-edit-jury__block__title">
                    Количество людей основного списка
                  </h4>
                  {getNumberEditFields(
                    currentJuryList.juryListByDistrictsForCourt,
                    "Основной",
                    "basicList",
                    handleChangeCurrentJuryListCount.bind(
                      null,
                      "juryListByDistrictsForCourt"
                    ),
                    Boolean(
                      currentJuryList.isForming || currentJuryList.isFormed
                    )
                  )}
                </div>
                <div className="modal-edit-jury__block">
                  <h4 className="modal-edit-jury__block__title">
                    Количество людей запасного списка
                  </h4>
                  {getNumberEditFields(
                    currentJuryList.juryListByDistrictsForCourt,
                    "Запасной",
                    "subList",
                    handleChangeCurrentJuryListCount.bind(
                      null,
                      "juryListByDistrictsForCourt"
                    ),
                    Boolean(
                      currentJuryList.isForming || currentJuryList.isFormed
                    )
                  )}
                </div>
              </div>
              <div className="modal-edit-jury__errors-block">
                {errors.garrison_lists && (
                  <p className="warning-text margin-bottom-12">
                    {errors.garrison_lists}
                  </p>
                )}
              </div>
            </div>

            <div className="modal__button-group modal-edit-jury__btn-group">
              <SubmitButton className={"modal__button"}>Сохранить</SubmitButton>
              <CancelButton
                className={"modal__button"}
                onClick={() => onCancel(false)}
              >
                Отмена
              </CancelButton>
            </div>
          </form>
        </div>
      </div>
    );
  })
);

export default ModalEditJuryList;
