export const MODES = ["SET_DATE", "SET_MONTH", "SET_YEAR"];

export const MONTHS = [
	"Янв",
	"Февр",
	"Март",
	"Апр",
	"Май",
	"Июнь",
	"Июль",
	"Авг",
	"Сент",
	"Окт",
	"Нояб",
	"Дек",
];
