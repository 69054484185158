import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import routing from "stores/routing";

import "../index.scss";

import { ReactComponent as Logo } from "assets/svg/headerlogo.svg";

import ParametersList from "components/ParametersList";
import Modal from "components/Modal";
import ParamsButton from "common/components/Buttons/ParamsButton";
import FindButton from "common/components/Buttons/FindButton";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

const MainHeader = inject(
  "ApplicationStore",
  "ModalStore",
  "SearchStore",
  "ItemsStore",
  "PaginationStore"
)(
  observer(
    ({
      ModalStore,
      SearchStore,
      ItemsStore,
      PaginationStore,
      ApplicationStore,
    }) => {
      const { toggleAddParameterModalOpen, isAddParameterModalOpen } =
        ModalStore;
      const {
        addParameters,
        resetExcludedOnCancel,
        displayedParams,
        resetFiltration,
        resetSearchStore,
        isSubmitted,
        setSubmitted,
        setRedirected,
      } = SearchStore;
      const { startIndex, perPage, resetAll } = PaginationStore;
      const { fetchWithParameters, setNecessityToSaveRecentSearches } =
        ItemsStore;
      const { isAdmin } = ApplicationStore;

      useEffect(() => () => resetSearchStore(), [resetSearchStore]);

      return (
        <div className="wrapper__header">
          <header className="header">
            <div className="header__top">
              {isAdmin && <Link to="/searches">Сохранённые поиски</Link>}
            </div>
            <div className="header__container">
              <div className="header__title">
                <Logo />
                <h1>Люди. Расширенный поиск</h1>
              </div>

              <ParametersList searchType="all" />

              {displayedParams.length === 0 && (
                <div className="header__buttons">
                  <ParamsButton
                    onClick={() => {
                      toggleAddParameterModalOpen(true);
                    }}
                  >
                    Указать параметры для поиска
                  </ParamsButton>
                </div>
              )}

              {displayedParams.length !== 0 ? (
                <div className="header__buttons">
                  <FindButton
                    onClick={() => {
                      resetAll();
                      setSubmitted(true);
                      setRedirected(true);
                      setNecessityToSaveRecentSearches(true);
                      routing.push("/people");
                    }}
                  />
                  <ParamsButton
                    onClick={() => {
                      toggleAddParameterModalOpen(true);
                    }}
                  >
                    Добавить параметр
                  </ParamsButton>
                  <BottomLinedButton
                    transparent
                    onClick={() => {
                      resetFiltration();
                      resetAll();
                      isSubmitted &&
                        fetchWithParameters("all", startIndex, perPage);
                    }}
                  >
                    Очистить поиск
                  </BottomLinedButton>
                </div>
              ) : null}
            </div>

            {isAddParameterModalOpen && (
              <Modal
                type="addParameter"
                callback={() => addParameters("all")}
                searchType="all"
                onCancel={resetExcludedOnCancel}
              />
            )}
          </header>
        </div>
      );
    }
  )
);

export default MainHeader;
