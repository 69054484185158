import {
  observable,
  decorate,
  action,
  runInAction,
  set,
  computed,
  toJS,
} from "mobx";
import {
  EMPTY_ERR,
  PASSWORDS_ERR,
  WEAK_PASSWORD_ERR,
  LOGIN_DUPLICATE_ERR,
  SNILS_DUPLICATE_ERR,
  ADMIN_DELETE_ERR,
  INVALID_SNILS_CHECKSUM_ERR,
} from "helpers/errors";
import app from "utils/axiosConfig";
import routing from "../routing";
import ApplicationStore from "../appStore";
import formattedDate, { EMPTY_DATE } from "helpers/formattedDate";
import ModalStore from "./modalStore";
import AlertStore from "./alertsStore";

class UsersStore {
  user = {
    password: "",
    repeatedPass: "",
  };
  newUser = {
    login: "",
    password: "",
    repeatedPass: "",
    name: "",
    surName: "",
    patronymic: "",
    snils: "",
    birthDate: null,
    organizationId: "",
  };
  editedDate = "";
  loginErr = "";
  passwordErr = "";
  snilsErr = "";
  serverErr = "";
  availableRoles = [];
  userRolesList = [];
  userOrganizations = [];

  get errors() {
    let error = {};
    if (!ApplicationStore.isSubmitted) {
      return error;
    }
    switch (ApplicationStore.mode) {
      case "ADD_USER":
        if (
          !this.newUser.name ||
          ApplicationStore.hasOnlyWhiteSpaces(this.newUser.name)
        ) {
          error["name"] = EMPTY_ERR;
        }
        if (
          !this.newUser.surName ||
          ApplicationStore.hasOnlyWhiteSpaces(this.newUser.surName)
        ) {
          error["surName"] = EMPTY_ERR;
        }
        if (
          !this.newUser.login ||
          ApplicationStore.hasOnlyWhiteSpaces(this.newUser.login)
        ) {
          error["login"] = EMPTY_ERR;
        } else if (this.loginErr) {
          error["login"] = this.loginErr;
        }

        if (!this.newUser.password) {
          error["password"] = EMPTY_ERR;
        } else if (this.passwordErr) {
          error["password"] = this.passwordErr;
        }
        if (!this.newUser.repeatedPass) {
          error["repeatedPass"] = EMPTY_ERR;
        }
        if (this.newUser.password !== this.newUser.repeatedPass) {
          error["password"] = PASSWORDS_ERR;
          error["repeatedPass"] = PASSWORDS_ERR;
        }

        if (!this.newUser.organizationId) {
          error["organization"] = EMPTY_ERR;
        }

        break;
      case "EDIT_USER":
        if (
          !this.user.name ||
          ApplicationStore.hasOnlyWhiteSpaces(this.user.name)
        ) {
          error["name"] = EMPTY_ERR;
        }
        if (
          !this.user.surName ||
          ApplicationStore.hasOnlyWhiteSpaces(this.user.surName)
        ) {
          error["surName"] = EMPTY_ERR;
        }

        if (
          !this.user.login ||
          ApplicationStore.hasOnlyWhiteSpaces(this.user.login)
        ) {
          error["login"] = EMPTY_ERR;
        } else if (this.loginErr) {
          error["login"] = this.loginErr;
        }

        if (this.user.password !== this.user.repeatedPass) {
          error["password"] = PASSWORDS_ERR;
          error["repeatedPass"] = PASSWORDS_ERR;
        }
        if (
          !this.user.organizationId ||
          this.user.organizationId === "00000000-0000-0000-0000-000000000000"
        ) {
          error["organization"] = EMPTY_ERR;
        }
        break;
      default:
        break;
    }
    return error;
  }

  get parsedDate() {
    return this.editedDate === EMPTY_DATE
      ? null
      : formattedDate(this.editedDate, false, true);
  }

  get filteredAvailableRoles() {
    const userRoles = this.userRolesList;
    const availableRoles = this.availableRoles;

    console.log("availableRoles", toJS(availableRoles));

    return availableRoles.filter(
      (role) => !userRoles.find((item) => item.id === role.id)
    );
  }

  changeUser = (key, field, value) => {
    if (field === "password") {
      this.passwordErr = "";
    }

    set(key, field, value);
  };

  changeUserDate = (key, value) => {
    set(key, "birthDate", value?.toISOString());
    this.editedDate = value?.toISOString();
  };

  isValidSnils = (snils) => {
    const regex = /^\d{3} \d{3} \d{3} \d{2}$/i;
    return snils === "" || regex.test(snils);
  };

  addRole = (role) => {
    const userRoles = this.userRolesList;
    if (userRoles.includes(role.id)) {
      return;
    }
    userRoles.push({
      id: role,
      name: role,
    });
  };

  removeRole = (role) => {
    const userRoles = this.userRolesList;
    const index = userRoles.findIndex((el) => el.id === role.id);
    userRoles.splice(index, 1);
  };

  reset = () => {
    runInAction(() => {
      this.user = {
        password: "",
        repeatedPass: "",
        organizationId: "",
      };
      this.newUser = {
        login: "",
        password: "",
        repeatedPass: "",
        name: "",
        surName: "",
        patronymic: "",
        snils: "",
        birthDate: null,
        organizationId: "",
      };
      this.editedDate = "";
      this.loginErr = "";
      this.passwordErr = "";
      this.snilsErr = "";
      this.serverErr = "";
      this.userRolesList = [];
      this.availableRoles = [];
      this.userOrganizations = [];
    });
  };

  fetchAvailableRoles = async () => {
    ApplicationStore.setLoading(true);
    await app
      .get("/roles")
      .then((res) => {
        this.availableRoles = res.data.map((role) => ({
          id: role,
          name: role,
        }));
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  fetchOrganizations = () => {
    ApplicationStore.setLoading(true);
    app
      .get("/organizations")
      .then((res) => {
        this.userOrganizations = res.data;
      })
      .catch((err) => {
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  resetSnilsErrors = () => {
    this.snilsErr = "";
  };

  resetLoginErrors = () => {
    this.loginErr = "";
  };

  fetchUser = async (id) => {
    ApplicationStore.setLoading(true);
    await app
      .get(`/users/${id}`)
      .then((res) => {
        const fetchedUser = res.data;
        fetchedUser.snils = fetchedUser.snils || "";
        fetchedUser.password = "";
        fetchedUser.repeatedPass = "";
        this.user = fetchedUser;
        this.userRolesList = res.data.roles?.map((role) => ({
          id: role,
          name: role,
        }));

        this.editedDate = this.user.birthDate;
        ApplicationStore.setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          routing.push(`/404`);
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setLoading(false);
      });
  };

  saveUser = (id) => {
    const addModeIsActivated = ApplicationStore.mode === "ADD_USER";
    this.loginErr = "";
    this.passwordErr = "";
    ApplicationStore.setGlobalFieldValue("isSubmitted", true);
    if (Object.keys(this.errors).length !== 0) {
      return;
    }
    const method = addModeIsActivated ? "post" : "put";
    const user = addModeIsActivated ? this.newUser : this.user;
    const userData = {
      surName: user.surName.trim(),
      patronymic: user.patronymic?.trim(),
      name: user.name.trim(),
      login: user.login.trim(),
      organizationId: user.organizationId,
      roles: this.userRolesList?.map((role) => role.id),
    };
    if (!addModeIsActivated) {
      userData.id = id;
      if (!user.password) {
        userData.password = null;
        userData.passwordConfirm = null;
      } else {
        userData.password = user.password;
        userData.passwordConfirm = user.repeatedPass;
      }
    } else {
      userData.password = user.password;
      userData.passwordConfirm = user.repeatedPass;
    }

    ApplicationStore.setUploading(true);
    app[method]("/users", userData)
      .then((res) => {
        if (addModeIsActivated) {
          this.reset();
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
          routing.push(`/handbooks/editperson/${res.data}`);
          return;
        }
        runInAction(() => {
          AlertStore.toggleHandbookEditPersonSuccessAlertOpen(true);
          ApplicationStore.setGlobalFieldValue("isSubmitted", false);
        });
      })
      .catch((err) => {
        if (err.response.data === LOGIN_DUPLICATE_ERR) {
          this.loginErr = err.response.data;
        }
        if (err.response.data === INVALID_SNILS_CHECKSUM_ERR) {
          this.snilsErr = err.response.data;
        }
        if (err.response.data === SNILS_DUPLICATE_ERR) {
          this.snilsErr = err.response.data;
        }
        if (err.response.status === 400) {
          this.passwordErr = WEAK_PASSWORD_ERR;
        }
        ApplicationStore.setError(err);
      })
      .finally(() => {
        ApplicationStore.setUploading(false);
      });
  };

  deleteUser = (id) => {
    ModalStore.resetModal();
    app
      .delete(`/users/${id}`)
      .then(() => {
        runInAction(() => {
          routing.push("/handbooks/users");
        });
      })
      .catch((err) => {
        if (err.response.status === 406) {
          this.serverErr = ADMIN_DELETE_ERR;
          ModalStore.toggleErrModal(true);
        }
        ApplicationStore.setError(err);
      });
  };
}

decorate(UsersStore, {
  user: observable,
  newUser: observable,
  editedDate: observable,
  loginErr: observable,
  passwordErr: observable,
  snilsErr: observable,
  availableRoles: observable,
  userRolesList: observable,
  serverErr: observable,
  userOrganizations: observable,

  filteredAvailableRoles: computed,
  parsedDate: computed,
  errors: computed,

  isValidSnils: action,
  changeUser: action,
  changeUserDate: action,
  reset: action,
  resetSnilsErrors: action,
  resetLoginErrors: action,
  fetchUser: action,
  updateUser: action,
  deleteUser: action,
  fetchAvailableRoles: action,
  addRole: action,
  removeRole: action,
  fetchOrganizations: action,
});

export default new UsersStore();
