import React from "react";

import "../index.scss";

import { ReactComponent as ExpandTop } from "assets/svg/expandtop.svg";
import { ReactComponent as ExpandBottom } from "assets/svg/expandbottom.svg";
import { observer } from "mobx-react";

const ExpandSection = ({
  className,
  children,
  isOpen,
  label,
  onClick,
  selected,
  selectedArray,
  handleRemoveFromList,
  withCloserList,
}) => (
  <header className="expand__header">
    <div
      className={`expand__collapser${className || ""}`}
      onClick={() => !withCloserList && onClick(label)}
    >
      <label>{label}</label>
      <div
        className="expand__arrow"
        onClick={() => withCloserList && onClick(label)}
      >
        {isOpen ? <ExpandTop /> : <ExpandBottom />}
      </div>
      {selected && <p className="expand__selected">{selected}</p>}
      <div className="list">
        {selectedArray &&
          selectedArray.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <span className="list-item">{item}</span>
                <span
                  onClick={() => {
                    handleRemoveFromList(item);
                  }}
                  className="list-cross"
                />

                {", "}
              </React.Fragment>
            );
          })}
      </div>
    </div>
    <div className={`expand__content${isOpen ? " opened" : ""}`}>
      {isOpen &&
        children.map((child, item) => (
          <div className="expand__item" key={item}>
            {child}
          </div>
        ))}
    </div>
  </header>
);

export default observer(ExpandSection);
