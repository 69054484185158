import AddEditUser from "pages/Handbooks/Users/AddEditUser";

const adminRoutes = [
  {
    path: ["/handbooks/addperson", "/handbooks/editperson/:userId"],
    exact: true,
    component: AddEditUser,
  },
];

export default adminRoutes;
