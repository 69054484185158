import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { useHistory, useParams } from "react-router";

import "./index.scss";

import { formDateToStringDate } from "helpers/formattedDate";

import Table from "common/components/Table";
import ImportButton from "common/components/Buttons/ImportButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import UnderlinedTabs from "components/UnderlinedTabs";

import SearchHeader from "components/Header/Search";
import Empty from "components/Empty";
import Modal from "components/Modal";
import excludeStore from "../../../stores/components/excludeStore";

const header = (
  <>
    <div style={{ marginLeft: 100 }}>ФИО</div>
    <div style={{ marginLeft: -80 }}>Дата рождения</div>
    <div>Суд</div>
    <div>Статус</div>
    <div style={{ marginLeft: -20 }}>Список</div>
  </>
);

const classnames = {
  header: "jurylist-header",
  itemWrapper: "jurylist-item",
};

const getUnderlinedTabsData = (list) => {
  return list.map(({ id, dateFrom, dateTo }) => ({
    id,
    title: `${dateFrom} - ${dateTo}`,
  }));
};

const JuryCommonList = inject(
  "JuryStore",
  "PaginationStore",
  "TabbedSwitchersStore",
  "ItemsStore",
  "ModalStore",
  "ApplicationStore"
)(
  observer(
    ({
      JuryStore,
      PaginationStore,
      TabbedSwitchersStore,
      ModalStore,
      ItemsStore,
      ApplicationStore,
      type,
      juryListId,
    }) => {
      const { selectTabById, selectedTab } = TabbedSwitchersStore;
      const {
        fetchJuryLists,
        juryLists,
        handleCurrentListSearchString,
        exportMainJuryList,
        exportCommonJuryList,
      } = JuryStore;

      const {
        currentPage,
        perPage,
        changePerPage,
        paginate,
        resetAll,
        startIndex,
      } = PaginationStore;

      const { isLoading } = ApplicationStore;

      const history = useHistory();

      const {
        isExportJuryModalOpen,
        toggleExportJuryListModal,
        isExcludesModalIsOpen,
      } = ModalStore;

      const actualJuryLists = juryLists.filter((list) => list.current);

      const { juryListId: commonId } = useParams();

      const {
        items,
        reset,
        fetchItems,
        count,
        itemsAreLoading,
        setSearchActivation,
        setLastSearchStringRequest,
        setSearchString,
      } = ItemsStore;

      const loaderOrEmpty = itemsAreLoading ? <LoaderSpinner /> : <Empty />;
      const loaderOrEmptyLists = isLoading ? <LoaderSpinner /> : <Empty />;

      const [idNahui, setId] = useState(undefined);
      const [loadingStatus, setLoading] = useState(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const clickOnTableDate = (id) => {
        fetchItems(`/JuryLists/${id}/common`, "get", 0, 50);
        setId(id);
        id && history.push(`/jury/common/${id}`);
      };

      const handleDownloadList = (ex) => {
        exportMainJuryList(idNahui, ex);
      };

      useEffect(() => {
        fetchJuryLists();

        return () => {
          reset();
          setSearchActivation(false);
          setLastSearchStringRequest();
          setId(undefined);
          setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        fetchJuryLists,
        reset,
        setLastSearchStringRequest,
        setSearchActivation,
        juryListId,
        selectTabById,
      ]);

      useEffect(() => {
        if (commonId !== undefined) {
          history.push(`/jury/common/${commonId}`);
        } else {
          history.push(`/jury/common`);
        }
      }, [history, commonId]);

      useEffect(() => {
        if (actualJuryLists && actualJuryLists[0] && !loadingStatus) {
          clickOnTableDate(commonId);
          fetchItems(
            `/JuryLists/${commonId || actualJuryLists[0]?.id}/common`,
            "get",
            0,
            50
          );

          setLoading(true);
          setId(actualJuryLists[0]?.id);
        }
      }, [
        actualJuryLists,
        commonId,
        fetchItems,
        loadingStatus,
        clickOnTableDate,
      ]);

      useEffect(() => {
        if (idNahui) {
          fetchItems(
            `/JuryLists/${idNahui}/common`,
            "get",
            startIndex,
            perPage
          );
        }
      }, [startIndex, perPage, reset, selectedTab, fetchItems, type, idNahui]);

      useEffect(() => {
        setSearchActivation(false);
        setLastSearchStringRequest();
        setSearchString("");
        handleCurrentListSearchString("common", idNahui);
        resetAll();
        changePerPage(50);
        if (idNahui) {
          fetchItems(`/JuryLists/${idNahui}/common`, "get", 0, 50);
        }
      }, [
        changePerPage,
        fetchItems,
        handleCurrentListSearchString,
        resetAll,
        idNahui,
        setLastSearchStringRequest,
        setSearchActivation,
        setSearchString,
        type,
        selectedTab,
      ]);

      const getMappedItems = (mappedItems, JuryListId) => {
        return mappedItems?.map((item) => {
          const {
            personId,
            surname = "",
            name = "",
            patronymic = "",
            birthDate,
            gender,
            isActive,
            exclusionDate,
            district,
            changeDate,
          } = item;

          const formGender = gender === "Male" ? "М" : "Ж";

          const activeText = formGender === "М" ? "Активен" : "Активна";
          const excludeText = formGender === "М" ? "Исключен" : "Исключена";
          const changedText = formGender === "М" ? "Изменен" : "Изменена";

          const fullName = [surname, name, patronymic].join(" ").trim();
          const status =
            isActive && changeDate
              ? `${changedText}, ${formDateToStringDate(changeDate)}`
              : isActive
              ? activeText
              : `${excludeText}, ${formDateToStringDate(exclusionDate)}`;

          return (
            <>
              <div className="jurylist-field" key={personId}>
                <Link
                  to={`/jury/${
                    commonId ? commonId : JuryListId
                  }/person/${personId}`}
                  className="page-link jury-list__table-link"
                >
                  {fullName}
                </Link>
                <p className="table-value">{formDateToStringDate(birthDate)}</p>
                <p className="table-value">{district ? district : "-"}</p>
                <p className="table-value">{status}</p>
                <p className="table-value">{item.fromBasic ? "ОС" : "ЗС"}</p>
              </div>
            </>
          );
        });
      };

      const handleClickOpen = () => {
        excludeStore.changeVisibility(true, idNahui);
      };

      return (
        <div className="wrapper__content">
          <section className="section">
            <div className="container">
              <div className="people-wrapper">
                <>
                  {actualJuryLists && actualJuryLists.length !== 0 ? (
                    <>
                      <div className="jury-list__search-export">
                        <div
                          onClick={() => toggleExportJuryListModal(true)}
                          className="jury-list__export-btn-wrapper"
                        >
                          <ImportButton className="jury-list__export-btn">
                            <span>Экспорт в файл</span>
                          </ImportButton>
                        </div>
                        <SearchHeader
                          showSearches={false}
                          searchType="jury"
                          common={idNahui}
                        />
                      </div>

                      <div className="functional-panel">
                        <UnderlinedTabs
                          data={getUnderlinedTabsData(actualJuryLists)}
                          selectedTab={selectedTab}
                          onSelectTab={clickOnTableDate}
                        />
                        <div className="functional-panel__buttons">
                          <div
                            onClick={() => {
                              handleClickOpen();
                            }}
                            className="jury-list__export-btn-wrapper exclud"
                          >
                            <ImportButton className="jury-list__export-btn">
                              <span>Исключение</span>
                            </ImportButton>
                          </div>
                          <div
                            onClick={() => exportCommonJuryList(idNahui)}
                            className="jury-list__export-btn-wrapper"
                          >
                            <ImportButton className="jury-list__export-btn">
                              <span>Файлы</span>
                            </ImportButton>
                          </div>
                        </div>
                      </div>
                      {items && items.length !== 0 && !itemsAreLoading ? (
                        <>
                          <Table
                            header={header}
                            items={getMappedItems(items, idNahui)}
                            classnames={classnames}
                          />
                          <Pagination
                            perPage={perPage}
                            total={count}
                            currentPage={currentPage + 1}
                            handlePaginate={(page) => paginate(page - 1)}
                            isShowPerPage
                            handleChangePerPage={(count) =>
                              changePerPage(count)
                            }
                          />
                        </>
                      ) : (
                        <div>{loaderOrEmpty}</div>
                      )}
                    </>
                  ) : (
                    <div className="jury-list__empty">{loaderOrEmptyLists}</div>
                  )}
                </>
                {isExportJuryModalOpen && (
                  <Modal callback={handleDownloadList} type="exportJuryList" />
                )}
                {isExcludesModalIsOpen && (
                  <Modal type="modalExcludesTypes" header={"Исключение"} />
                )}
              </div>
            </div>
          </section>
        </div>
      );
    }
  )
);
export default JuryCommonList;
