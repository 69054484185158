import React from "react";

import "./index.scss";

const SubmitButton = (props) => {
  return (
    <button
      className={`button__submit ${props.className || ""}`}
      type="submit"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default SubmitButton;
