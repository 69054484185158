import React, { forwardRef } from "react";

import { ReactComponent as Import } from "assets/svg/import.svg";

import "./index.scss";

const FileInput = forwardRef((props, ref) => (
  <div
    className={`input__file${props.className ? props.className : ""}${
      props.disabled ? " disabled" : ""
    }`}
  >
    <Import />
    <span className="file__msg">
      {props?.file?.name || props.currentUploadingFilename || "Загрузить файл"}
    </span>
    <input
      className="file__loader"
      type="file"
      name="file__loader"
      accept={props.accept}
      onChange={props.onChange}
      disabled={props.disabled || false}
      ref={ref}
    />
  </div>
));

export default FileInput;
