import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";

const ActivityPreview = ({ id, size, title, location, date, dateOfEnd, href, handler }) => {
	const linkOrButton =
		handler !== undefined ? (
			<button
				onClick={() => {
					handler(id);
				}}
				className={`activity-preview__title reset-button-styles`}>
				{title}
			</button>
		) : (
			<Link to={href} className="activity-preview__title">
				{title}
			</Link>
		);

	return (
		<li className={`activity-preview${size === "mini" ? " mini" : ""}`}>
			{linkOrButton}
			<div className="activity-preview__info">
				<p className="date-location">
					{date} — {dateOfEnd}, {location}
				</p>
			</div>
		</li>
	);
};

export default ActivityPreview;
