import React, { useEffect, useState, Fragment } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { inject, observer } from "mobx-react";

import "./index.scss";

import Chip from "components/Chip";
import Modal from "components/Modal";
import AddressForm from "./AddressForm";

import Autocomplete from "common/components/Inputs/Autocomplete";
import TextInput from "common/components/Inputs/TextInput";
import Datepicker from "common/components/Inputs/Datepicker";
import FloatLabel from "common/components/Inputs/FloatLabel";
import ParamsButton from "common/components/Buttons/ParamsButton";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import BottomDottedButton from "common/components/Buttons/BottomDottedButton";
import Select from "common/components/Select";
import Alert from "common/components/Alerts/Success";

import {
  genders,
  registrationTypes,
  passportTypes,
} from "helpers/dictionaries";

const PeopleForm = inject(
  "PeopleFormStore",
  "AreasStore",
  "ApplicationStore",
  "ModalStore",
  "SearchStore",
  "AlertStore"
)(
  observer(
    ({
      PeopleFormStore,
      ApplicationStore,
      AlertStore,
      AreasStore,
      ModalStore,
      SearchStore,
      isHistoryCard,
      isEdit,
      historyPerson,
      id,
    }) => {
      const {
        buildingId,
        fetchBuildingById,

        name,
        surName,
        patronymic,
        birthLocation,
        citizenship,
        snils,
        snilsErr,
        resetSnilsErrors,

        addressFull,

        changePersonDateBirth,
        changeGettingDate,
        changeStartOfRegistration,
        changeEndOfRegistration,
        changeEndOfTemporalRegistration,

        handleChange,
        reset,
        errors,
        person,
        parameters,

        parsedDate,
        parsedGettingDate,
        parsedStartOfRegistration,
        parsedEndOfRegistration,
        parsedEndOfTemporalRegistration,

        handlePersonChange,
        onFieldChange,
        fetchPerson,
        addPerson,
        updatePerson,
        getSelectedSuggestion,
        addCheckedFormParams,
        delFormParam,
        resetExcludedOnCancel,
        onSelect,
        togglePeopleFormFetch,
        noFetchAсtion,
        flatSuggestion,
        fullAddressSuggestion,

        setGender,
        selectedGender,

        setPassportType,
        selectedPassportType,
        passportSeries,
        passportNumber,
        passportAuthority,

        setRegistrationType,
        selectedRegistrationType,
      } = PeopleFormStore;
      const { toggleAddParameterModalOpen, isAddParameterModalOpen } =
        ModalStore;
      const { resetSearchStore } = SearchStore;

      const { isLoading, isUploading, setGlobalFieldValue, isAdminKokr } =
        ApplicationStore;
      const {
        addresses,
        streetTitle,
        resetStreet,
        fetchAddresses,
        resetAddressess,
      } = AreasStore;

      const { isPeopleEditSuccessAlertOpen, togglePeopleEditSuccessAlertOpen } =
        AlertStore;

      const history = useHistory();

      const [showAdditional, setShowAdditional] = useState(false);
      const [date, setDate] = useState(undefined);
      const [, setGettingDateState] = useState(undefined);
      const [startOfRegistrationDate, setStartOfRegistrationDate] =
        useState(undefined);
      const [endOfRegistrationDate, setEndOfRegistrationDate] =
        useState(undefined);
      const [endOfTemporalRegistrationDate, setEndOfTemporalRegistrationDate] =
        useState(undefined);

      const startDate = new Date(new Date().getFullYear() - 80, 0);
      const endDate = new Date(new Date().getFullYear(), 0);
      const endDateRegistration = new Date(new Date().getFullYear() + 50, 0);

      const [isAlertShow, setAlertShow] = useState(false);

      useEffect(() => {
        if (isEdit) {
          setGlobalFieldValue("mode", "EDIT_PERSON");
          fetchPerson(id);
        } else {
          setGlobalFieldValue("mode", "ADD_PERSON");
        }
        return () => {
          setGlobalFieldValue("mode", "");
          setGlobalFieldValue("isSuccess", false);
          setGlobalFieldValue("isSubmitted", false);
          togglePeopleEditSuccessAlertOpen(false);
          reset();
        };
      }, [fetchPerson, id, isEdit, reset, setGlobalFieldValue]);

      useEffect(() => {
        if (buildingId) fetchBuildingById();
      }, [fetchBuildingById, buildingId]);

      useEffect(() => {
        if (!addressFull) {
          resetStreet();
          handleChange("togglePeopleFormFetch", true);
          handleChange("recievedAddressString", "");
          handleChange("buildingId", "");
          handleChange("houseNum", "");
          handleChange("innerDistrict", "");
          handleChange("street", "");
          handleChange("flat", 0);
          handleChange("noHouse", true);
          handleChange("noFlat", true);
          handleChange("locality", "");
          handleChange("flatSuggestion", null);
          handleChange("fullAddressSuggestion", null);
        }
      }, [addressFull, handleChange, resetStreet]);

      useEffect(
        () => () => {
          resetSearchStore();
          handleChange("addressFull", "");
          resetAddressess();
        },
        [resetSearchStore, handleChange, resetAddressess]
      );

      useEffect(() => {
        if (isPeopleEditSuccessAlertOpen) {
          setAlertShow(true);
        }
      }, [isPeopleEditSuccessAlertOpen]);

      const handleSubmit = (e) => {
        e.preventDefault();
        isEdit ? updatePerson(id) : addPerson();
      };

      const textInputs = [
        {
          key: "surName",
          value: isHistoryCard
            ? historyPerson.surname
            : isEdit
            ? person?.surName
            : surName,
          placeholder: "Фамилия",
        },
        {
          key: "name",
          value: isHistoryCard
            ? historyPerson.name
            : isEdit
            ? person?.name
            : name,
          placeholder: "Имя",
        },
        {
          key: "patronymic",
          value: isHistoryCard
            ? historyPerson.patronymic
            : isEdit
            ? person?.patronymic
            : patronymic,
          placeholder: "Отчество",
        },
      ];

      const personParams =
        parameters &&
        parameters.map((param) => {
          return (
            <Chip
              children={param}
              key={param}
              handleClick={() => delFormParam(param)}
            />
          );
        });

      const noFlatOrHouseErrorMsg =
        errors.flat && errors.house
          ? "Не указаны квартира и дом"
          : errors.flat
          ? errors.flat
          : errors.house;

      const registrationTypeIsPernament = isEdit
        ? person?.registrationType === "Постоянная"
        : selectedRegistrationType === "Постоянная";

      const isRussianCitizen = selectedPassportType === "RussianCitizen";

      const seriesFieldMask = isRussianCitizen ? "9999" : "";

      const numberFieldMask = isRussianCitizen ? "999999" : "";

      const onGenderChange = (genderId) => {
        setGender(genderId, isEdit);
      };

      const onRegistrationTypeChange = (registrationTypeId) => {
        setRegistrationType(registrationTypeId, isEdit);
      };

      const onPassportTypeChange = (passportTypeId) => {
        if (passportTypeId && passportTypeId !== "none") {
          handleChange("passportSeries", "");
          handleChange("passportNumber", "");
          setGettingDateState(undefined);
          changeGettingDate(undefined);
          handleChange("passportAuthority", "");
        }
        setPassportType(passportTypeId, isEdit);
      };

      const isPassportTypeSelected =
        selectedPassportType && selectedPassportType !== passportTypes[0].id;

      const isNotRegistrationTypeSelected = isEdit
        ? !person?.registrationType || person?.registrationType === "none"
        : !selectedRegistrationType || selectedRegistrationType === "none";

      return (
        <>
          <div className="people-form__alert">
            {isAlertShow && (
              <Alert
                message={"Данные человека успешно обновлены"}
                toggle={(open) => {
                  togglePeopleEditSuccessAlertOpen(open);
                  setAlertShow(open);
                }}
              />
            )}
          </div>

          <form className="people-form" onSubmit={handleSubmit} noValidate>
            <div className="people-form__fields-wrapper">
              <div className="people-form__personal-data-fields">
                {textInputs.map((item, index) => (
                  <Fragment key={index}>
                    <FloatLabel
                      label={item.placeholder}
                      value={item.value || ""}
                    >
                      <TextInput
                        type="text"
                        name={item.key}
                        value={item.value || ""}
                        autoComplete="off"
                        onChange={(e) =>
                          isEdit
                            ? handlePersonChange(item.key, e.target.value)
                            : onFieldChange(
                                item.key,
                                e.target.value,
                                e.target.type
                              )
                        }
                        className={`people-form__input margin-bottom-25 ${
                          errors[item.key] ? "warning" : ""
                        }`}
                        autoFocus={index === 0}
                        readOnly={isHistoryCard}
                      />
                    </FloatLabel>
                    {errors[item.key] && (
                      <p className="warning-text margin-bottom-12">
                        {errors[item.key]}
                      </p>
                    )}
                  </Fragment>
                ))}
              </div>

              <FloatLabel
                label="Дата рождения"
                value={
                  isHistoryCard
                    ? historyPerson.birthDate || ""
                    : isEdit && person
                    ? parsedDate
                    : date
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    name="birthDate"
                    value={historyPerson.birthDate || ""}
                    className={`people-form__input datepicker-person margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Datepicker
                    name="birthDate"
                    date={isEdit && person ? parsedDate : date}
                    startDate={startDate}
                    endDate={endDate}
                    changeDate={(day) => {
                      setDate(day);
                      changePersonDateBirth(isEdit, day);
                    }}
                    className={`people-form__input datepicker-person margin-bottom-25 ${
                      errors.birthDate ? "warning" : ""
                    }`}
                  />
                )}
              </FloatLabel>
              {errors.birthDate && (
                <p className="warning-text margin-bottom-12">
                  {errors.birthDate}
                </p>
              )}

              <FloatLabel
                label="СНИЛС"
                value={isHistoryCard ? historyPerson.snils : snils || ""}
              >
                {isHistoryCard ? (
                  <TextInput
                    name="snils"
                    value={historyPerson.snils || ""}
                    className={`input__text people-form__input margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <InputMask
                    mask="999-999-999 99"
                    name={"snils"}
                    value={snils || ""}
                    placeholder=""
                    onChange={(e) => {
                      resetSnilsErrors();
                      handleChange("snils", e.target.value);
                    }}
                    className={`input__text people-form__input margin-bottom-25 ${
                      snilsErr ? "warning" : ""
                    }`}
                  />
                )}
              </FloatLabel>
              {snilsErr && (
                <p className="warning-text margin-bottom-12">{snilsErr}</p>
              )}

              <FloatLabel
                label="Выберите пол"
                value={
                  isHistoryCard
                    ? historyPerson.gender
                    : isEdit
                    ? person?.gender
                    : selectedGender
                    ? selectedGender
                    : null
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.gender}
                    className="people-form__input margin-bottom-25"
                    readOnly
                  />
                ) : (
                  <Select
                    id="id"
                    data={genders}
                    value={
                      isEdit
                        ? person?.gender
                        : selectedGender
                        ? selectedGender
                        : null
                    }
                    className="people-form__input margin-bottom-25"
                    onChange={onGenderChange}
                  />
                )}
              </FloatLabel>
              {errors.gender && (
                <p className="warning-text margin-bottom-12">{errors.gender}</p>
              )}

              <FloatLabel
                label="Гражданство"
                value={
                  isHistoryCard
                    ? historyPerson.citizenship || ""
                    : isEdit
                    ? person?.citizenship || ""
                    : citizenship
                }
              >
                <TextInput
                  value={
                    isHistoryCard
                      ? historyPerson.citizenship || ""
                      : isEdit
                      ? person?.citizenship || ""
                      : citizenship
                  }
                  type="text"
                  name="citizenship"
                  autoComplete="off"
                  onChange={(e) =>
                    isEdit
                      ? handlePersonChange("citizenship", e.target.value)
                      : onFieldChange(
                          "citizenship",
                          e.target.value,
                          e.target.type
                        )
                  }
                  className={`input__text people-form__input margin-bottom-25 ${
                    errors.citizenship ? "warning" : ""
                  }`}
                  readOnly={isHistoryCard}
                />
              </FloatLabel>
              {errors.citizenship && (
                <p className="warning-text margin-bottom-12">
                  {errors.citizenship}
                </p>
              )}

              <FloatLabel
                label="Место рождения"
                value={
                  isHistoryCard
                    ? historyPerson.addressOfBirth || ""
                    : isEdit
                    ? person?.addressOfBirth || ""
                    : birthLocation
                }
              >
                <TextInput
                  value={
                    isHistoryCard
                      ? historyPerson.addressOfBirth || ""
                      : isEdit
                      ? person?.addressOfBirth || ""
                      : birthLocation
                  }
                  type="text"
                  name="birthLocation"
                  autoComplete="off"
                  onChange={(e) =>
                    isEdit
                      ? handlePersonChange("addressOfBirth", e.target.value)
                      : onFieldChange(
                          "birthLocation",
                          e.target.value,
                          e.target.type
                        )
                  }
                  className={`input__text people-form__input margin-bottom-25 ${
                    errors.birthLocation ? "warning" : ""
                  }`}
                  readOnly={isHistoryCard}
                />
              </FloatLabel>

              <label className="label margin-bottom-32 margin-top-20">
                Документ
              </label>

              <FloatLabel
                label="Укажите тип паспорта"
                value={
                  isHistoryCard
                    ? historyPerson.passport?.passportType
                    : selectedPassportType
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={
                      isHistoryCard
                        ? historyPerson.passport?.passportType
                        : selectedPassportType
                    }
                    className={`input__text people-form__input margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Select
                    id="id"
                    data={passportTypes}
                    value={selectedPassportType}
                    className={`input__text people-form__input margin-bottom-25`}
                    onChange={onPassportTypeChange}
                  />
                )}
              </FloatLabel>

              <FloatLabel
                label="Серия"
                value={
                  isHistoryCard
                    ? historyPerson.passport?.series || ""
                    : passportSeries
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.passport?.series || ""}
                    className={`input__text people-form__input margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <InputMask
                    value={passportSeries}
                    mask={seriesFieldMask}
                    className={`input__text people-form__input margin-bottom-25 ${
                      errors.series ? "warning" : ""
                    }`}
                    name={"series"}
                    onChange={(e) =>
                      onFieldChange(
                        "passportSeries",
                        e.target.value,
                        e.target.type
                      )
                    }
                    disabled={!isPassportTypeSelected && !isHistoryCard}
                  />
                )}
              </FloatLabel>
              {errors.series && (
                <p className="warning-text margin-bottom-12">{errors.series}</p>
              )}

              <FloatLabel
                label="Номер"
                value={
                  isHistoryCard
                    ? historyPerson.passport?.number || ""
                    : passportNumber
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.passport?.number || ""}
                    className={`input__text people-form__input margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <InputMask
                    value={passportNumber}
                    mask={numberFieldMask}
                    className={`input__text people-form__input margin-bottom-25 ${
                      errors.number ? "warning" : ""
                    }`}
                    name={"number"}
                    onChange={(e) =>
                      onFieldChange(
                        "passportNumber",
                        e.target.value,
                        e.target.type
                      )
                    }
                    disabled={!isPassportTypeSelected && !isHistoryCard}
                  />
                )}
              </FloatLabel>
              {errors.number && (
                <p className="warning-text margin-bottom-12">{errors.number}</p>
              )}

              <FloatLabel
                label="Дата выдачи"
                value={
                  isHistoryCard
                    ? historyPerson.passport?.gettingDate
                    : parsedGettingDate
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.passport?.gettingDate}
                    className={`people-form__input datepicker-person margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Datepicker
                    name="gettingDate"
                    date={parsedGettingDate}
                    startDate={startDate}
                    endDate={endDate}
                    changeDate={(day) => {
                      setGettingDateState(day);
                      changeGettingDate(day);
                    }}
                    className={`people-form__input datepicker-person margin-bottom-25 ${
                      errors.gettingDate ? "warning" : ""
                    }`}
                    disabled={!isPassportTypeSelected && !isHistoryCard}
                  />
                )}
              </FloatLabel>
              {errors.gettingDate && (
                <p className="warning-text margin-bottom-12">
                  {errors.gettingDate}
                </p>
              )}

              <FloatLabel
                label="Кем выдан"
                value={
                  isHistoryCard
                    ? historyPerson.passport?.authority || ""
                    : passportAuthority
                }
              >
                <TextInput
                  type="text"
                  value={
                    isHistoryCard
                      ? historyPerson.passport?.authority || ""
                      : passportAuthority
                  }
                  name="authority"
                  autoComplete="off"
                  onChange={(e) =>
                    onFieldChange(
                      "passportAuthority",
                      e.target.value,
                      e.target.type
                    )
                  }
                  className={`input__text people-form__input margin-bottom-25 ${
                    errors.authority ? "warning" : ""
                  }`}
                  disabled={!isPassportTypeSelected && !isHistoryCard}
                  readOnly={isHistoryCard}
                />
              </FloatLabel>
              {errors.authority && (
                <p className="warning-text margin-bottom-12">
                  {errors.authority}
                </p>
              )}

              <label className="label margin-bottom-32 margin-top-20">
                Адрес
              </label>

              <FloatLabel
                label="Тип регистрации"
                value={
                  isHistoryCard
                    ? historyPerson.registrationType
                    : isEdit
                    ? person?.registrationType
                    : selectedRegistrationType
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={
                      isHistoryCard
                        ? historyPerson.registrationType
                        : isEdit
                        ? person?.registrationType
                        : selectedRegistrationType
                    }
                    className={`input__text people-form__input margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Select
                    id="id"
                    placeholder=""
                    value={
                      isEdit
                        ? person?.registrationType
                        : selectedRegistrationType
                    }
                    data={registrationTypes}
                    className={`input__text people-form__input margin-bottom-25`}
                    onChange={onRegistrationTypeChange}
                  />
                )}
              </FloatLabel>

              <FloatLabel
                label="Дата начала регистрации"
                value={
                  isHistoryCard
                    ? historyPerson.startOfRegistration
                    : isEdit && person
                    ? person.startOfRegistration
                    : startOfRegistrationDate
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.startOfRegistration || ""}
                    className={`people-form__input datepicker-person margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Datepicker
                    name="startOfRegistration"
                    date={
                      isEdit && person
                        ? parsedStartOfRegistration
                        : startOfRegistrationDate
                    }
                    startDate={startDate}
                    endDate={endDate}
                    changeDate={(day) => {
                      setStartOfRegistrationDate(day);
                      changeStartOfRegistration(isEdit, day);
                    }}
                    className={`people-form__input datepicker-person margin-bottom-25 ${
                      errors.startOfRegistration ? "warning" : ""
                    }`}
                    disabled={isNotRegistrationTypeSelected && !isHistoryCard}
                  />
                )}
              </FloatLabel>
              {errors.startOfRegistration && (
                <p className="warning-text margin-bottom-12">
                  {errors.startOfRegistration}
                </p>
              )}

              <FloatLabel
                label="Дата окончания регистрации"
                value={
                  isHistoryCard
                    ? historyPerson.endOfRegistration
                    : isEdit && person
                    ? person.endOfRegistration
                    : endOfRegistrationDate
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.endOfRegistration || ""}
                    className={`people-form__input datepicker-person margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Datepicker
                    name="endOfRegistration"
                    date={
                      isEdit && person
                        ? parsedEndOfRegistration
                        : endOfRegistrationDate
                    }
                    startDate={startDate}
                    endDate={endDateRegistration}
                    changeDate={(day) => {
                      setEndOfRegistrationDate(day);
                      changeEndOfRegistration(isEdit, day);
                    }}
                    className={`people-form__input datepicker-person margin-bottom-25 ${
                      errors.endOfRegistration ? "warning" : ""
                    }`}
                    disabled={
                      (isNotRegistrationTypeSelected ||
                        !registrationTypeIsPernament) &&
                      !isHistoryCard
                    }
                  />
                )}
              </FloatLabel>
              {errors.endOfRegistration && (
                <p className="warning-text margin-bottom-12">
                  {errors.endOfRegistration}
                </p>
              )}

              <FloatLabel
                label="Дата окончания временной регистрации"
                value={
                  isHistoryCard
                    ? historyPerson.endOfTemporalRegistration
                    : isEdit && person
                    ? person.endOfTemporalRegistration
                    : endOfTemporalRegistrationDate
                }
              >
                {isHistoryCard ? (
                  <TextInput
                    value={historyPerson.endOfTemporalRegistration || ""}
                    className={`people-form__input datepicker-person margin-bottom-25`}
                    readOnly
                  />
                ) : (
                  <Datepicker
                    name="endOfTemporalRegistration"
                    date={
                      isEdit && person
                        ? parsedEndOfTemporalRegistration
                        : endOfTemporalRegistrationDate
                    }
                    startDate={startDate}
                    endDate={endDateRegistration}
                    changeDate={(day) => {
                      setEndOfTemporalRegistrationDate(day);
                      changeEndOfTemporalRegistration(isEdit, day);
                    }}
                    className={`people-form__input datepicker-person margin-bottom-25 ${
                      errors.endOfTemporalRegistration ? "warning" : ""
                    }`}
                    disabled={
                      (isNotRegistrationTypeSelected ||
                        registrationTypeIsPernament) &&
                      !isHistoryCard
                    }
                  />
                )}
              </FloatLabel>
              {errors.endOfTemporalRegistration && (
                <p className="warning-text margin-bottom-12">
                  {errors.endOfTemporalRegistration}
                </p>
              )}

              {isHistoryCard ? (
                <FloatLabel
                  label="Полный адрес"
                  value={isHistoryCard ? historyPerson.address : addressFull}
                >
                  <TextInput
                    value={historyPerson.address}
                    className={"people-form__aucomplete-input"}
                    readOnly
                  />
                </FloatLabel>
              ) : (
                <div className="people-form__autocomplete">
                  <Autocomplete
                    className={`people-form__aucomplete-input ${
                      noFlatOrHouseErrorMsg ? "warning" : ""
                    }`}
                    placeholder={"Укажите адрес"}
                    userInput={addressFull}
                    onInputChange={(e) => {
                      onFieldChange(
                        "addressFull",
                        e.target.value,
                        e.target.type
                      );
                    }}
                    onSelect={onSelect}
                    getSelectedSuggestion={(suggestion) =>
                      getSelectedSuggestion(suggestion)
                    }
                    fetch={fetchAddresses}
                    toggleFetch={togglePeopleFormFetch}
                    noFetchCallback={noFetchAсtion}
                    suggestions={
                      addresses?.streets
                        ? addresses.streets
                        : addresses
                        ? addresses
                        : flatSuggestion
                        ? flatSuggestion
                        : fullAddressSuggestion
                    }
                    streetTitle={streetTitle}
                    needPeopleCount={false}
                  />
                  {noFlatOrHouseErrorMsg && (
                    <p className="warning-text margin-bottom-12">
                      {noFlatOrHouseErrorMsg}
                    </p>
                  )}
                </div>
              )}

              {!isHistoryCard && (
                <div className="people-form__button_wrapper">
                  <BottomDottedButton
                    onClick={() => setShowAdditional(!showAdditional)}
                    className="people-form__hide-button"
                  >
                    {showAdditional
                      ? "Скрыть поля адреса"
                      : "Показать поля адреса"}
                  </BottomDottedButton>
                </div>
              )}
              {showAdditional && !isHistoryCard ? (
                <AddressForm isEdit={isEdit} />
              ) : null}

              {!isHistoryCard && !isAdminKokr && (
                <>
                  <label className="label margin-bottom-32 margin-top-20">
                    Параметры
                  </label>
                  {parameters.length > 0 && (
                    <div className="people-form__params">{personParams}</div>
                  )}
                </>
              )}
            </div>

            {!isHistoryCard && (
              <>
                {!isAdminKokr && (
                  <ParamsButton
                    className="isForm"
                    onClick={() => {
                      toggleAddParameterModalOpen(true);
                    }}
                  >
                    Добавить параметр
                  </ParamsButton>
                )}
                <div className="formButtons">
                  <SubmitButton
                    className="formButton"
                    disabled={isLoading || isUploading}
                  >
                    {isLoading || isUploading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                  <CancelButton
                    onClick={() => history.push("/people")}
                    className="formButton"
                  >
                    Отмена
                  </CancelButton>
                </div>
              </>
            )}
          </form>

          {isAddParameterModalOpen && (
            <Modal
              type="addParameter"
              callback={() => addCheckedFormParams()}
              searchType={"people"}
              isInForm={true}
              onCancel={resetExcludedOnCancel}
            />
          )}
        </>
      );
    }
  )
);

export default PeopleForm;
