import React, { useState } from "react";
import { ReactComponent as Loupe } from "assets/svg/search.svg";

import "./index.scss";

const SearchInput = (props) => {
  const [value] = useState("");

  const search = (event) => {
    event.preventDefault();
    props.onSearch(value);
  };

  const startSearchByEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      props.onSearch();
    }
  };

  const buttonOrIcon = props.hideSearchButton ? (
    <span className="icon">
      <Loupe />
    </span>
  ) : (
    <button
      disabled={props.disabled}
      type="button"
      onClick={search}
      className="search-button"
    >
      <Loupe />
    </button>
  );

  return (
    <div className="search-block">
      <input
        ref={props.reference}
        onKeyDown={startSearchByEnterKey}
        className={`input__search ${props.className || ""}`}
        type="search"
        value={props.value}
        name={props.name}
        placeholder={props.placeholder || ""}
        autoComplete={props.autoComplete || "off"}
        autoFocus={props.autoFocus || false}
        onChange={props.onChange}
        disabled={props.disabled || false}
      />
      {buttonOrIcon}
    </div>
  );
};

export default SearchInput;
