import React from "react";

import "./index.scss";

const RadioButton = ({
  value,
  label,
  checked,
  onChange,
  inputName,
  labelClassname,
  inputClassname,
  disabled,
  id,
}) => {
  const checkedFlag = checked ? " active " : " inactive ";

  return (
    <label className={"radio-label" + (labelClassname || "")}>
      <div className={"fake-radio-outer" + checkedFlag}>
        <div className={"fake-radio-inner" + checkedFlag} />
      </div>
      <input
        id={id}
        type="radio"
        value={value}
        checked={checked}
        name={inputName || ""}
        onChange={onChange}
        className={"radio-input" + checkedFlag + (inputClassname || "")}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};

export default RadioButton;
