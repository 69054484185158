import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";

import SearchInput from "common/components/Inputs/SearchInput";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

const AnalyticsList = inject(
	"ItemsStore",
	"PaginationStore",
	"ApplicationStore"
)(
	observer(({ ItemsStore, PaginationStore, ApplicationStore, isSearch }) => {
		const searchInputRef = useRef(null);

		const {
			items,
			reset,
			fetchWithParameters,
			count,
			searchString,
			setSearchString,
			itemsAreLoading,
			searchIsImpossible,
			setSearchActivation,
			searchIsActivated,
			setLastSearchStringRequest,
		} = ItemsStore;
		const {
			currentPage,
			perPage,
			changePerPage,
			paginate,
			resetPagination,
			resetAll,
			startIndex,
		} = PaginationStore;

		const [isCleared, setCleared] = useState(false);

		useEffect(() => {
			isCleared && fetchWithParameters("analytics", startIndex, perPage);

			return () => {
				reset();
			};
		}, [fetchWithParameters, startIndex, perPage, reset, isCleared]);

		useEffect(() => {
			resetPagination();
		}, [resetPagination, perPage]);

		useEffect(() => {
			resetAll();
			setCleared(true);
		}, [resetAll, setCleared]);

		useEffect(() => {
			if (!itemsAreLoading) {
				searchInputRef.current.querySelector('input[type="search"]').focus();
			}
		}, [itemsAreLoading]);

		useEffect(() => () => setSearchString(""), [setSearchString]);

		const classnames = {
			header: "analyticslist-header",
			itemWrapper: "analyticslist-item",
		};

		const header = (
			<>
				<div>Параметры</div>
				<div>Кол-во человек</div>
			</>
		);

		const mappedItems = items.map((item) => (
			<>
				<p className="analyticslist-item__title">{item.title}</p>
				<p className="table-value">{item.peopleCount}</p>
			</>
		));

		const searchInput = (
			<>
				<SearchInput
					disabled={itemsAreLoading}
					onChange={(event) => {
						setSearchString(event.target.value);
					}}
					value={searchString}
					placeholder="Поиск по параметрам"
					onSearch={() => {
						searchInputRef.current.querySelector('input[type="search"]').focus();

						if (searchIsImpossible) {
							return;
						}

						setSearchActivation(!!searchString);
						setLastSearchStringRequest(searchString);
						resetPagination();
						startIndex === 0 && fetchWithParameters("analytics", startIndex, perPage);
					}}
				/>

				{searchIsActivated && (
					<BottomLinedButton
						onClick={() => {
							setSearchString("");
							searchInputRef.current.querySelector('input[type="search"]').focus();

							if (!searchIsActivated) {
								return;
							}

							setSearchActivation(false);
							setLastSearchStringRequest();

							resetPagination();
							startIndex === 0 && fetchWithParameters("analytics", startIndex, perPage);
						}}
						className="activity__button-clear">
						Очистить поиск
					</BottomLinedButton>
				)}
			</>
		);

		const table = isCleared ? (
			<>
				<Table header={header} items={mappedItems} classnames={classnames} />
				<Pagination
					perPage={perPage}
					total={count}
					currentPage={currentPage + 1}
					handlePaginate={(page) => paginate(page - 1)}
					isShowPerPage
					handleChangePerPage={(count) => changePerPage(count)}
				/>
			</>
		) : null;

		const content = itemsAreLoading ? (
			<LoaderSpinner />
		) : items.length !== 0 ? (
			table
		) : (
			<Empty isSearch={isSearch || searchIsActivated} />
		);

		return (
			<div className="analytics-wrapper">
				<div ref={searchInputRef} className="analytics-search">
					{searchInput}
				</div>
				{content}
			</div>
		);
	})
);

export default AnalyticsList;
