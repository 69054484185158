export const EMPTY_ERR = "Заполните это поле";

export const EMPTY_ERRS = "Заполните все поля*";
export const NO_STREET_SELECTED =
  "Вы должны выбрать улицу из предложеного списка.";

export const INVALID_SYMBOLS_ERR = "Недопустимые символы";

export const SNILS_DUPLICATE_ERR = "такой Snils уже зарегистрирован";
export const LOGIN_DUPLICATE_ERR = "такой Login уже зарегистрирован";

export const NO_FLAT_ERR = "Не указан номер квартиры";
export const NO_HOUSE_ERR = "Не указан номер дома";

export const INVALID_SNILS_ERR =
  "Неверный формат СНИЛС. Пример: 123-456-789 01";

export const INVALID_SNILS_CHECKSUM_ERR = "неверный номер снилс";
export const INVALID_DATE_ERR = "Неверный формат даты или пустое поле";
export const INVALID_TIME_ERR = "Неверный формат времени или пустое поле";
export const INVALID_START_DATE_ERR =
  "Дата начала не может быть больше даты окончания";
export const INVALID_END_DATE_ERR =
  "Дата окончания не может быть меньше даты начала";

export const PASSWORDS_ERR = "Пароли не совпадают.";
export const WEAK_PASSWORD_ERR = "Пароль должен содержать не менее 6 символов";
export const INVALID_PASSWORD_ERR = "Неверный пароль";

export const CREDENTIALS_ERR =
  "Похоже, что у вас недостаточно прав. Попробуйте обратиться к администратору.";

export const SERVER_ERR =
  "Это может быть обусловлено ошибкой сервера или отсутствием подключения к сети.";

export const ADMIN_DELETE_ERR = "Невозможно удалить админа";

export const FEW_PEOPLE_JURY_LIST = "Людей меньше чем нужно";
