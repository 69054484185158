import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";

import "./index.scss";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";
import Chip from "components/Chip";

import Alert from "common/components/Alerts/Success";
import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import ParamsButton from "common/components/Buttons/ParamsButton";
import Autocomplete from "common/components/Inputs/Autocomplete";

import useDocumentTitle from "hooks/useDocumentTitle";

const AddEditHome = inject(
  "ModalStore",
  "BuildingsStore",
  "ApplicationStore",
  "SearchStore",
  "AlertStore"
)(
  observer(
    ({ AlertStore, BuildingsStore, ApplicationStore, ModalStore, match }) => {
      const { isUploading, isLoading, setGlobalFieldValue } = ApplicationStore;
      const {
        streets,
        building,
        newBuilding,
        parameters,
        addIncludedBuildingFormParams,
        delBuildingFormParam,
        fetchBuilding,
        addBuilding,
        updateBuilding,
        deleteBuilding,
        changeBuilding,
        handleChange,
        getSelectedSuggestion,
        fetchStreets,
        errors,
        reset,
        resetExcludedOnCancel,
        onBuildingSelect,
        resetServerErrors,
      } = BuildingsStore;
      const {
        isOpen,
        isSubmit,
        resetModal,
        toggleAddParameterModalOpen,
        isAddParameterModalOpen,
      } = ModalStore;

      const {
        isHomesEditBuildingSuccessAlertOpen,
        toggleHomesEditBuildingSuccessAlertOpen,
      } = AlertStore;

      const isEdit = match.url.includes("/homes/editbuilding");
      const id = match.params.id;

      const [isAlertShow, setAlertShow] = useState(false);

      useEffect(() => {
        if (isHomesEditBuildingSuccessAlertOpen) {
          setAlertShow(true);
        }
      }, [isHomesEditBuildingSuccessAlertOpen]);

      useEffect(() => {
        if (isEdit) {
          setGlobalFieldValue("mode", "EDIT_BUILDING");
          fetchBuilding(id);
        } else {
          setGlobalFieldValue("mode", "ADD_BUILDING");
        }
        return () => {
          setGlobalFieldValue("mode", "");
          setGlobalFieldValue("isSubmitted", false);
          reset();
        };
      }, [fetchBuilding, isEdit, id, reset, setGlobalFieldValue]);

      useEffect(() => {
        if (isSubmit) {
          deleteBuilding(id);
        }
      }, [isSubmit, deleteBuilding, id]);

      useEffect(() => {
        return () => {
          resetModal();
          resetExcludedOnCancel();
          toggleHomesEditBuildingSuccessAlertOpen(false);
        };
      }, [resetModal, resetExcludedOnCancel]);

      useDocumentTitle(
        () => (isEdit ? "Редактировать здание" : "Добавить здание"),
        [isEdit]
      );

      const breadcrumbs = [
        { path: "/homes", label: "Дома" },
        {
          path: "/homes/addbuilding",
          label: isEdit ? "Редактировать здание" : "Добавить здание",
        },
      ];

      const modalDanger = <Modal type="danger" />;

      const homesParams = parameters?.map((param) => {
        return (
          <Chip
            children={param.Title + (param.Value ? `: ${param.Value}` : "")}
            key={param.id}
            handleClick={() => delBuildingFormParam(param.id, param.Value)}
          />
        );
      });

      const handleSubmit = (e) => {
        e.preventDefault();
        isEdit ? updateBuilding(id) : addBuilding();
      };

      return (
        <div className="wrapper__content">
          {isOpen && isEdit && modalDanger}
          <Breadcrumbs routes={breadcrumbs} />
          <section className="section">
            <div className="container">
              <h1 className="page-title">
                {isEdit ? "Редактировать здание" : "Добавить здание"}
              </h1>

              <div className="homes-add-edit-buildings__success-alert">
                {isAlertShow && (
                  <Alert
                    message={"Данные успешно обновлены"}
                    toggle={(open) => {
                      toggleHomesEditBuildingSuccessAlertOpen(open);
                      setAlertShow(open);
                    }}
                  />
                )}
              </div>

              <form className="form" onSubmit={handleSubmit} noValidate>
                <div className="homes-form__autocomplete">
                  <Autocomplete
                    readOnly={isEdit}
                    placeholder={
                      isEdit
                        ? "Район и улица"
                        : "Начните вводить улицу и выберите из списка"
                    }
                    userInput={
                      isEdit ? building?.street : newBuilding.location.street
                    }
                    name="street"
                    onInputChange={(e) => {
                      isEdit
                        ? handleChange("street", e.target.value)
                        : changeBuilding(
                            newBuilding.location,
                            "street",
                            e.target.value
                          );
                    }}
                    onSelect={(title) => onBuildingSelect(isEdit, title)}
                    getSelectedSuggestion={(suggestion) =>
                      getSelectedSuggestion(isEdit, suggestion)
                    }
                    noMargin
                    className={`form__field ${errors.street ? "warning" : ""}`}
                    fetch={fetchStreets}
                    suggestions={streets}
                    needPeopleCount={false}
                    autoFocus
                  />
                  {errors.street && (
                    <p className="warning-text margin-bottom-12">
                      {errors.street}
                    </p>
                  )}
                </div>

                <FloatLabel
                  label="Номер дома"
                  value={
                    isEdit && building
                      ? building.number
                      : newBuilding.location.buildingNumber
                  }
                >
                  <TextInput
                    type="text"
                    name="number"
                    value={
                      isEdit && building
                        ? building.number
                        : newBuilding.location.buildingNumber
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      resetServerErrors();
                      isEdit
                        ? handleChange("number", e.target.value)
                        : changeBuilding(
                            newBuilding.location,
                            "buildingNumber",
                            e.target.value
                          );
                    }}
                    className={`form__field ${errors.number ? "warning" : ""}`}
                  />
                </FloatLabel>
                {errors.number && (
                  <p className="warning-text margin-bottom-12">
                    {errors.number}
                  </p>
                )}

                <label className="label margin-bottom-32 margin-top-20">
                  Параметры
                </label>

                {parameters.length > 0 && (
                  <div className="form__params-building">{homesParams}</div>
                )}

                <div>
                  <ParamsButton
                    className="isForm form__button"
                    disabled={isUploading || isLoading}
                    onClick={() => {
                      toggleAddParameterModalOpen(true);
                    }}
                  >
                    Добавить параметр
                  </ParamsButton>
                </div>

                <div className="form__buttons">
                  <SubmitButton
                    className="form__button"
                    disabled={isUploading || isLoading}
                  >
                    {isUploading || isLoading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                  <CancelButton className="form__button">Отмена</CancelButton>
                </div>
              </form>

              {isAddParameterModalOpen && (
                <Modal
                  type="addParameter"
                  callback={() => {
                    addIncludedBuildingFormParams();
                  }}
                  searchType="homes"
                  onCancel={resetExcludedOnCancel}
                  isInForm
                />
              )}
            </div>
          </section>
        </div>
      );
    }
  )
);

export default AddEditHome;
