import React, { useEffect, forwardRef, createRef, useRef } from "react";
import { inject, observer } from "mobx-react";

import SearchInput from "common/components/Inputs/SearchInput";

import Empty from "components/Empty";
import ParamElement from "../ParamElement";
import { SkeletonLine } from "components/Skeleton";

import { PERSON_PARAMETERS } from "helpers/requestPaths";
import { highlightTextContentsBySubstring } from "helpers/highlighters";

const SearchField = forwardRef((props, ref) => {
	const { className, placeholder, onChange, onSearch, value } = props;
	return (
		<SearchInput
			reference={ref}
			className={className}
			placeholder={placeholder}
			onChange={onChange}
			onSearch={onSearch}
			value={value}
			hideSearchButton
		/>
	);
});

const AssignParameter = inject("SearchStore")(
	observer(({ SearchStore }) => {
		const searchFieldRefference = createRef();
		const paramsListReference = useRef(null);

		const {
			peopleParams,
			fetchParams,

			peopleFoundParams,
			searchString,
			onSearchInputChange,
			onParameterChange,
			paramsAreFetching,
		} = SearchStore;

		useEffect(() => {
			fetchParams(PERSON_PARAMETERS, true);
		}, [fetchParams]);

		useEffect(() => {
			const labels = paramsListReference.current.querySelectorAll("label");
			highlightTextContentsBySubstring(labels, searchString);
		}, [searchString]);

		useEffect(() => {
			searchFieldRefference.current.disabled = paramsAreFetching;
			if (!paramsAreFetching) {
				searchFieldRefference.current.focus();
			}
		}, [searchFieldRefference, paramsAreFetching]);

		return (
			<div className="params__form">
				<div className="params__search">
					<SearchField
						ref={searchFieldRefference}
						placeholder="Поиск по параметрам"
						onChange={(e) => onSearchInputChange(e.target.value, "people")}
						onSearch={() => onSearchInputChange(searchString, "people")}
						value={searchString}
					/>
				</div>
				<ul
					ref={paramsListReference}
					className={`params__list overflow${
						paramsAreFetching ? " loading" : peopleParams.length !== 0 ? " ml-49" : ""
					}`}>
					{paramsAreFetching ? (
						<div className="params__loading">
							<SkeletonLine width={305} height={25} translucent />
							<SkeletonLine width={305} height={25} translucent />
							<SkeletonLine width={305} height={25} translucent />
							<SkeletonLine width={305} height={25} translucent />
							<SkeletonLine width={305} height={25} translucent />
							<SkeletonLine width={305} height={25} translucent />
						</div>
					) : peopleFoundParams.length !== 0 ? (
						peopleFoundParams.map((item) => (
							<ParamElement
								key={item.title}
								checkBoxId={item.title}
								title={item.title}
								checked={item.checked}
								onChange={onParameterChange}
								searchType="people"
								isFoundParameter
							/>
						))
					) : peopleParams.length !== 0 ? (
						peopleParams.map((item) => (
							<ParamElement
								key={item.title}
								checkBoxId={item.title}
								title={item.title}
								checked={item.checked}
								onChange={onParameterChange}
								searchType="people"
								isAssign
							/>
						))
					) : (
						<Empty isSearch={false} isInModal />
					)}
				</ul>
			</div>
		);
	})
);

export default AssignParameter;
