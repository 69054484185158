import Activities from "pages/Activities";
import ActivityItem from "pages/Activities/ActivityItem";
import AddEditActivity from "pages/Activities/AddEditActivity";

import AddEditPersonParam from "pages/Handbooks/PeopleParams/AddEditPersonParam";
import AddEditHomeParam from "pages/Handbooks/HomeParams/AddEditHomeParam";
import AddEditCompany from "pages/Handbooks/Companies/AddEditCompany";
import AddEditArea from "pages/Handbooks/Areas/AddEditArea";
import Areas from "pages/Handbooks/Areas";
import Handbooks from "pages/Handbooks";

import JuryPerson from "pages/Jury/JuryPerson";
import AddEditHome from "pages/Homes/AddEditHome";
import AddPeople from "pages/People/AddPeople";
import PersonItem from "pages/People/PersonItem";

import Analytics from "pages/Analytics";
import Searches from "pages/Searches";
import Profile from "pages/Profile";
import People from "pages/People";
import Homes from "pages/Homes";
import Home from "pages/Home";
import Jury from "pages/Jury";

import NotFound from "components/NotFound";

const defaultRoutes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/homes",
    exact: true,
    component: Homes,
  },
  {
    path: "/people",
    exact: true,
    component: People,
  },
  {
    path: ["/people/editperson/:id", "/people/history/:id"],
    component: PersonItem,
  },
  {
    path: "/people/addperson",
    exact: true,
    component: AddPeople,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: ["/activities/coming", "/activities/archive"],
    exact: true,
    component: Activities,
  },
  {
    path: ["/activities/add", "/activities/edit/:activityId"],
    component: AddEditActivity,
  },
  {
    path: ["/homes/addbuilding", "/homes/editbuilding/:id"],
    exact: true,
    component: AddEditHome,
  },
  {
    path: [
      "/activity/description/:activityId",
      "/activity/arrived/:activityId",
      "/activity/notarrived/:activityId",
      "/activity/invited/:activityId",
    ],
    component: ActivityItem,
  },
  {
    path: "/analytics",
    exact: true,
    component: Analytics,
  },
  {
    path: [
      "/handbooks/homeparams",
      "/handbooks/peopleparams",
      "/handbooks/companies",
      "/handbooks/users",
    ],
    exact: true,
    component: Handbooks,
  },
  {
    path: ["/handbooks/addpeopleparam", "/handbooks/editpeopleparam/:title"],
    exact: true,
    component: AddEditPersonParam,
  },
  {
    path: ["/handbooks/addhomeparam", "/handbooks/edithomeparam/:title"],
    exact: true,
    component: AddEditHomeParam,
  },
  {
    path: "/handbooks/stations/:id",
    exact: true,
    component: Areas,
  },
  {
    path: ["/handbooks/addstation/:id", "/handbooks/editstation/:id"],
    exact: true,
    component: AddEditArea,
  },
  {
    path: ["/handbooks/addcompany", "/handbooks/editcompany/:id"],
    exact: true,
    component: AddEditCompany,
  },
  {
    path: "/homes",
    exact: true,
    component: Homes,
  },
  {
    path: [
      "/jury/:juryListId/person/:personId",
      "/jury/:juryListId/person/history/:personId",
      "/jury/:juryListId/person/information/:personId",
    ],
    exact: true,
    component: JuryPerson,
  },
  {
    path: [
      "/jury/lists",
      "/jury/basic/:juryListId?",
      "/jury/sub/:juryListId?",
      "/jury/common",
      "/jury/common/:juryListId?",
    ],
    exact: true,
    component: Jury,
  },
  //   {
  //     path: ["/jury/common", "/jury/common/:juryListId?"],
  //     exact: true,
  //     component: Jury,
  //   },
  {
    path: "/searches",
    exact: true,
    component: Searches,
  },
  {
    path: ["/500", "/404"],
    component: NotFound,
  },
];

export default defaultRoutes;
