import React from "react";

import { ReactComponent as Danger } from "assets/svg/danger.svg";

import SubmitButton from "common/components/Buttons/SubmitButton";

const ModalError = ({ message, onCancel }) => {
  return (
    <div className="modal">
      <p className="modal__title static">Ошибка</p>
      <Danger className="modal__icon" />
      <p className="modal__helper-text static">{message}</p>
      <div className="modal__button-group static">
        <SubmitButton
          className="modal__button"
          onClick={() => onCancel(false)}
        >
          ОК
        </SubmitButton>
      </div>
    </div>
  );
};

export default ModalError;
