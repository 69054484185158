import React from "react";
import { inject, observer } from "mobx-react";

import Progressbar from "common/components/Inputs/Progressbar";

const ModalProgressJuryList = inject("JuryStore")(
  observer(
    ({ callback, wrapCallback, onCancel, searchType, isInForm, JuryStore }) => {
      const { currentJuryList } = JuryStore;

      return (
        <div className="modal">
          <p className="modal__title">Прогресс формирования списка присяжных</p>
          <div className="modal-add-jury__body">
            <Progressbar
              bgcolor="#6a1b9a"
              completed={currentJuryList.progressForm}
            />
          </div>
        </div>
      );
    }
  )
);

export default ModalProgressJuryList;
