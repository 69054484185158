import React, { useState, useEffect, useRef } from "react";
import { toJS } from "mobx";

import "./index.scss";

import { ReactComponent as Arrow } from "assets/svg/expand-arrow.svg";

import useOnClickOutside from "hooks/useOnClickOutside";

const Select = ({
  id,
  name,
  value,
  data,
  placeholder,
  className,
  disabled,
  onChange,
  notSetValue,
  isRightArrow,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);

  const ref = useRef();

  useEffect(() => {
    if (selected) {
      setIsOpen(false);
    }
  }, [selected, value]);

  useEffect(() => {
    if (value) {
      const option = data.find((item) => item[`${id}`] === value);
      if (option) {
        setSelected(option.name);
      }
    }
  }, [data, id, value]);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  const options = data.map((optionValue, index) => {
    return (
      <li
        className={`option ${selected === optionValue.name ? "active" : ""}`}
        value={optionValue.name}
        key={index}
        onClick={() => {
          !notSetValue && setSelected(optionValue.name);
          onChange(optionValue[`${id}`]);
          setIsOpen(!isOpen);
        }}
      >
        {optionValue.name}
      </li>
    );
  });

  return (
    <div className={`select__wrapper`} ref={ref}>
      <button
        type="button"
        disabled={disabled || !data.length}
        name={name}
        className={`select ${className || ""} input__text ${
          value || selected ? " active" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {typeof toJS(selected) === "object"
          ? selected && toJS(selected.name)
          : selected}
        <div
          className={`select__arrow ${
            isRightArrow ? "select__arrow--right" : ""
          }`}
        >
          <Arrow />
        </div>
      </button>
      {isOpen && <ul className="select__options">{options}</ul>}
    </div>
  );
};

export default Select;
