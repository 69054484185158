import React, { useEffect } from "react";

import "./index.scss";

import { ReactComponent as Approved } from "assets/svg/approved.svg";
import { ReactComponent as CloseAlert } from "assets/svg/close.svg";

const Alert = ({ message, toggle }) => {
  useEffect(() => {
    toggle(true);
  }, [toggle]);

  return (
    <div className="alert__container">
      <div className="alert__icon">
        <Approved />
      </div>
      <p className="alert__message">
        {message ? message : "Все сработало как нужно"}
      </p>
      <div className="alert__action">
        <button onClick={() => toggle(false)}>
          <CloseAlert />
        </button>
      </div>
    </div>
  );
};

export default Alert;
