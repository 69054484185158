import JuryPerson from "pages/Jury/JuryPerson";
import AddEditHome from "pages/Homes/AddEditHome";
import AddPeople from "pages/People/AddPeople";
import PersonItem from "pages/People/PersonItem";
import Profile from "pages/Profile";
import People from "pages/People";
import Homes from "pages/Homes";
import Home from "pages/Home";
import Jury from "pages/Jury";

import NotFound from "components/NotFound";

const adminKokrRoutes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/homes",
    exact: true,
    component: Homes,
  },
  {
    path: "/people",
    exact: true,
    component: People,
  },
  {
    path: ["/people/editperson/:id", "/people/history/:id"],
    component: PersonItem,
  },
  {
    path: "/people/addperson",
    exact: true,
    component: AddPeople,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },

  {
    path: ["/homes/addbuilding", "/homes/editbuilding/:id"],
    exact: true,
    component: AddEditHome,
  },

  {
    path: "/homes",
    exact: true,
    component: Homes,
  },
  {
    path: [
      "/jury/:juryListId/person/:personId",
      "/jury/:juryListId/person/history/:personId",
      "/jury/:juryListId/person/information/:personId",
    ],
    exact: true,
    component: JuryPerson,
  },
  {
    path: [
      "/jury/lists",
      "/jury/basic/:juryListId?",
      "/jury/sub/:juryListId?",
      "/jury/common",
      "/jury/common/:juryListId?",
    ],
    exact: true,
    component: Jury,
  },
  {
    path: ["/500", "/404"],
    component: NotFound,
  },
];

export default adminKokrRoutes;
