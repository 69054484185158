import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import "../NotArrived/index.scss";

import SearchInput from "common/components/Inputs/SearchInput";
import BottomLinedButton from "common/components/Buttons/BottomLinedButton";

import Pagination from "components/Pagination";
import LoaderSpinner from "components/Loader";
import Table from "common/components/Table";
import Empty from "components/Empty";
import SimpleMenu from "components/SimpleMenu";

import { parseDate } from "helpers/formattedDate";

const Arrived = inject(
	"ItemsStore",
	"PaginationStore",
	"ActivitiesStore"
)(
	observer(({ ItemsStore, PaginationStore, ActivitiesStore, id, needToLoad }) => {
		const searchBlockRef = useRef(null);
		const { exportXLSActivityPeople, activityPeopleAreExporting } = ActivitiesStore;
		const {
			items,
			reset,
			fetchItems,
			count,
			searchString,
			setSearchString,
			resetSelected,
			itemsAreLoading,
			searchIsImpossible,
			searchIsActivated,
			setSearchActivation,
			setLastSearchStringRequest,
		} = ItemsStore;
		const {
			currentPage,
			perPage,
			changePerPage,
			paginate,
			resetPagination,
			resetAll,
			startIndex,
		} = PaginationStore;

		const [isCleared, setCleared] = useState(false);

		useEffect(() => {
			if (isCleared && needToLoad) {
				fetchItems(`/activities/${id}/arrived`, "get", startIndex, perPage);
			}
			return () => {
				reset();
			};
		}, [id, fetchItems, needToLoad, startIndex, perPage, reset, isCleared]);

		useEffect(() => {
			resetPagination();
		}, [resetPagination, perPage]);

		useEffect(() => {
			resetAll();
			setCleared(true);
		}, [resetAll, setCleared]);

		useEffect(() => {
			if (!itemsAreLoading && needToLoad) {
				searchBlockRef.current.querySelector('input[type="search"]').focus();
			}
		}, [itemsAreLoading, needToLoad]);

		const classnames = {
			header: "activities-table-header",
			itemWrapper: "activity-table-item",
		};

		const header = (
			<>
				<div>ФИО</div>
				<div>Дата рождения</div>
				<div>Район</div>
				<div>Адрес регистрации</div>
				<div>Параметры</div>
			</>
		);

		const mappedItems = items?.map((item) => {
			const {
				id,
				surName = "",
				name = "",
				patronymic = "",
				region,
				birthDate,
				street,
				buildingNumber,
				appartamentNumber,
				Parameters = [],
			} = item;
			const fullName = [surName, name, patronymic].join(" ").trim();
			const address = street && street.includes("р-н.") ? street.split("р-н.") : street;
			const onlyStreet = address && Array.isArray(address) && address[1].split(", ")[1];
			return (
				<>
					<div className="activity__fio">
						<Link className="page-link" to={`/people/editperson/${id}`}>
							{fullName}
						</Link>
					</div>
					<p className="table-value">{(birthDate && parseDate(birthDate.slice(0, 10))) || ""}</p>
					<p className="table-value">{region || "—"}</p>
					<p className="table-value">
						{onlyStreet || (street && street)}
						{` ${buildingNumber || ""}`}
						{appartamentNumber > 0 && `, кв. ${appartamentNumber}`}
						{!street && !buildingNumber && !appartamentNumber && "—"}
					</p>
					<p className="table-value">{Parameters.length ? Parameters.join(", ") : "—"}</p>
				</>
			);
		});

		const areTherePeople = needToLoad;
		const werePeopleFound = count > 0;

		const xlsExport = areTherePeople
			? {
					caption: activityPeopleAreExporting ? "Идёт экспорт..." : "Экспорт в Excel",
					disabled: activityPeopleAreExporting,
					handler: () => {
						exportXLSActivityPeople(id, "arrived");
					},
			  }
			: null;

		const activityMenuData = xlsExport ? [xlsExport] : [];

		const searchInput = areTherePeople && (
			<div ref={searchBlockRef} className="activities-search-fields">
				{searchIsActivated && (
					<BottomLinedButton
						onClick={() => {
							setSearchString("");
							searchBlockRef.current.querySelector('input[type="search"]').focus();

							if (!searchIsActivated) {
								return;
							}

							setSearchActivation(false);
							setLastSearchStringRequest();

							resetSelected();
							resetPagination();
							startIndex === 0 && fetchItems(`/activities/${id}/arrived`, "get", startIndex, perPage);
						}}
						className="activity__button-clear">
						Очистить поиск
					</BottomLinedButton>
				)}

				<SearchInput
					disabled={itemsAreLoading}
					placeholder="Поиск по ФИО"
					value={searchString}
					onSearch={() => {
						searchBlockRef.current.querySelector('input[type="search"]').focus();

						if (searchIsImpossible) {
							return;
						}

						setSearchActivation(!!searchString);
						setLastSearchStringRequest(searchString);
						resetPagination();
						resetSelected();

						startIndex === 0 && fetchItems(`/activities/${id}/arrived`, "get", startIndex, perPage);
					}}
					onChange={(e) => setSearchString(e.target.value)}
					className="activity__item-search-input"
				/>
			</div>
		);

		const displayngContent = (
			<>
				{searchInput}
				<SimpleMenu data={activityMenuData} />
				<div className="activities-wrapper">
					{itemsAreLoading ? (
						<LoaderSpinner />
					) : werePeopleFound ? (
						isCleared && (
							<>
								<Table header={header} items={mappedItems} classnames={classnames} />
								<Pagination
									perPage={perPage}
									total={count}
									currentPage={currentPage + 1}
									handlePaginate={(page) => paginate(page - 1)}
									isShowPerPage
									handleChangePerPage={(count) => changePerPage(count)}
								/>
							</>
						)
					) : (
						<Empty isSearch={needToLoad} />
					)}
				</div>
			</>
		);

		return <div className="activity__item-content">{displayngContent}</div>;
	})
);

export default Arrived;
