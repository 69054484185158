import React from "react";

import "./index.scss";

const Checkbox = (props) => {
  const {
    id,
    className,
    labelClassName,
    checked,
    onChange,
    disabled,
    children,
  } = props;
  return (
    <>
      <input
        className={`input__checkbox ${className || ""}`}
        type="checkbox"
        name={id}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className={labelClassName}>
        {children}
      </label>
    </>
  );
};

export default Checkbox;
