import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import "./index.scss";

import { formDateToStringDate } from "helpers/formattedDate";

import Table from "common/components/Table";
import Empty from "components/Empty";
import LoaderSpinner from "components/Loader";

const header = (
  <>
    <div>Дата</div>
    <div>Изменил(-а)</div>
    <div>Описание</div>
  </>
);

const classnames = {
  header: "jury-history__table-header",
};

const getMappedItems = (mappedItems) => {
  return mappedItems?.map((item) => {
    const { changeDate = "", changeUser = "-", reasons = "-" } = item;

    return (
      <>
        <div className="jury-history__table-field">
          <p className="table-value">{formDateToStringDate(changeDate)}</p>
          <p className="table-value">{changeUser}</p>
          <p className="table-value">{reasons}</p>
        </div>
      </>
    );
  });
};

const JuryPersonHistory = inject(
  "ApplicationStore",
  "JuryPersonStore"
)(
  observer(({ ApplicationStore, JuryPersonStore, personId, juryListId }) => {
    const { fetchJuryPersonHistory, juryPerson } = JuryPersonStore;
    const { isLoading } = ApplicationStore;

    const loaderOrEmpty = isLoading ? <LoaderSpinner /> : <Empty />;

    useEffect(() => {
      fetchJuryPersonHistory(juryListId, personId);
    }, []);

    return (
      <div>
        {juryPerson.history && juryPerson.history[0] ? (
          <Table
            header={header}
            items={getMappedItems(juryPerson.history)}
            classnames={classnames}
          />
        ) : (
          <div className="jury-history__loader-or-empty">{loaderOrEmpty}</div>
        )}
      </div>
    );
  })
);
export default JuryPersonHistory;
