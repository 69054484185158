import React from "react";
import { Provider } from "mobx-react";
import { Router, Switch } from "react-router-dom";

import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";

import { PublicRoute } from "components/PublicRoute";
import { PrivateRoute } from "components/PrivateRoute";

import stores from "./stores/index";
import routing from "./stores/routing";

import {
  AuthLayout,
  AdminLayout,
  AdminKOKRLayout,
  MkuLayout,
} from "./router/layouts";

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routing);

function App() {
  return (
    <Provider {...stores}>
      <Router history={history}>
        <div className="wrapper">
          <Switch>
            <PublicRoute path={"/login"} component={AuthLayout} />
            <PrivateRoute
              adminComponent={AdminLayout}
              adminKokrComponent={AdminKOKRLayout}
              mkuComponent={MkuLayout}
            />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
