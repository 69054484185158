import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";

import "./index.scss";

import Breadcrumbs from "components/Breadcrumbs";
import Chip from "components/Chip";
import Modal from "components/Modal";

import Select from "common/components/Select";
import TextInput from "common/components/Inputs/TextInput";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";
import DeleteButton from "common/components/Buttons/DeleteButton";
import Alert from "common/components/Alerts/Success";

import useDocumentTitle from "hooks/useDocumentTitle";

const AddEditUser = inject(
  "ModalStore",
  "UsersStore",
  "ApplicationStore",
  "AlertStore"
)(
  observer(
    ({ UsersStore, ApplicationStore, ModalStore, AlertStore, match }) => {
      const {
        fetchUser,
        fetchAvailableRoles,
        user,
        serverErr,
        userRolesList,
        newUser,
        changeUser,
        deleteUser,
        saveUser,
        errors,
        reset,
        filteredAvailableRoles,
        resetLoginErrors,
        addRole,
        removeRole,
        fetchOrganizations,
        userOrganizations,
      } = UsersStore;
      const { isLoading, isUploading, setGlobalFieldValue } = ApplicationStore;
      const { toggle, isOpen, isErrModalOpen, isSubmit, resetModal } =
        ModalStore;

      const isEdit = match.url.includes("/handbooks/editperson");
      const id = match.params.userId;

      const {
        isHandbookEditPersonSuccessAlertOpen,
        toggleHandbookEditPersonSuccessAlertOpen,
      } = AlertStore;

      const [isAlertShow, setAlertShow] = useState(false);

      useEffect(() => {
        if (isHandbookEditPersonSuccessAlertOpen) {
          setAlertShow(true);
        }
      }, [isHandbookEditPersonSuccessAlertOpen]);

      useEffect(() => {
        if (isEdit) {
          setGlobalFieldValue("mode", "EDIT_USER");
          fetchUser(id);
        } else {
          setGlobalFieldValue("mode", "ADD_USER");
        }
        return () => {
          setGlobalFieldValue("mode", "");
          setGlobalFieldValue("isSubmitted", false);
          toggleHandbookEditPersonSuccessAlertOpen(false);
          reset();
        };
      }, [
        fetchUser,
        fetchAvailableRoles,
        isEdit,
        id,
        reset,
        setGlobalFieldValue,
        toggleHandbookEditPersonSuccessAlertOpen,
      ]);

      useEffect(() => {
        fetchAvailableRoles();
        fetchOrganizations();
      }, [fetchAvailableRoles, isEdit, fetchOrganizations]);

      useEffect(() => {
        if (isSubmit) {
          deleteUser(id);
        }
      }, [isSubmit, deleteUser, id]);

      useEffect(() => () => resetModal(), [resetModal]);

      useDocumentTitle(
        () => (isEdit ? "Редактировать пользователя" : "Добавить пользователя"),
        [isEdit]
      );

      const breadcrumbs = [
        { path: "/handbooks/users", label: "Пользователи" },
        {
          path: "/handbooks/addperson",
          label: isLoading
            ? "Загрузка..."
            : isEdit
            ? "Редактировать пользователя"
            : "Добавить пользователя",
        },
      ];

      const handleSubmit = (e) => {
        e.preventDefault();
        saveUser(id);
      };

      const userRolesChips = userRolesList.map((role) => {
        return (
          <Chip
            children={role.name}
            key={role.id}
            handleClick={() => removeRole(role)}
          />
        );
      });

      const userRolesChipsBlock =
        userRolesList.length !== 0 ? (
          <div className="user-roles-chips">{userRolesChips}</div>
        ) : null;

      const rolesSelector =
        filteredAvailableRoles.length !== 0 ? (
          <Select
            id="id"
            data={filteredAvailableRoles}
            value={null}
            notSetValue
            className="select form__field input__text no-float no-float mb-25"
            placeholder={isLoading ? "Загрузка..." : "Выберите роли"}
            onChange={(role) => addRole(role)}
            disabled={isLoading || isUploading}
            isRightArrow={true}
          />
        ) : null;

      const modalDanger = <Modal type="danger" />;
      const modalError = <Modal type="error" message={serverErr} />;
      const targetUser = isEdit ? user : newUser;

      return (
        <div className="wrapper__content">
          {isEdit && isOpen && modalDanger}
          {isErrModalOpen && modalError}
          <Breadcrumbs routes={breadcrumbs} />
          <section className="section">
            <div className="container">
              <h1 className="page-title">
                {isLoading
                  ? "Загрузка..."
                  : isEdit
                  ? "Редактировать пользователя"
                  : "Добавить пользователя"}
              </h1>
              {isEdit && (
                <DeleteButton
                  onClick={() => toggle(true)}
                  disabled={isUploading || isLoading}
                  className={"page-link"}
                >
                  Удалить пользователя и все его данные
                </DeleteButton>
              )}

              <div className="handbooks-add-edit-user__success-alert">
                {isAlertShow && (
                  <Alert
                    message={"Данные успешно обновлены"}
                    toggle={(open) => {
                      toggleHandbookEditPersonSuccessAlertOpen(open);
                      setAlertShow(open);
                    }}
                  />
                )}
              </div>

              <form className="form" onSubmit={handleSubmit} noValidate>
                <FloatLabel
                  label="Фамилия"
                  value={isEdit && user ? user.surName : newUser.surName || ""}
                >
                  <TextInput
                    type="text"
                    name="surName"
                    value={
                      isEdit && user ? user.surName : newUser.surName || ""
                    }
                    onChange={(e) =>
                      changeUser(targetUser, "surName", e.target.value)
                    }
                    className={`form__field ${errors.surName ? "warning" : ""}`}
                    autoFocus
                  />
                </FloatLabel>
                {errors.surName && (
                  <p className="warning-text margin-bottom-12">
                    {errors.surName}
                  </p>
                )}

                <FloatLabel
                  label="Имя"
                  value={isEdit && user ? user.name : newUser.name || ""}
                >
                  <TextInput
                    type="text"
                    name="name"
                    value={isEdit && user ? user.name : newUser.name || ""}
                    onChange={(e) =>
                      changeUser(targetUser, "name", e.target.value)
                    }
                    className={`form__field ${errors.name ? "warning" : ""}`}
                  />
                </FloatLabel>
                {errors.name && (
                  <p className="warning-text margin-bottom-12">{errors.name}</p>
                )}

                <FloatLabel
                  label="Отчество"
                  value={
                    isEdit && user?.patronymic
                      ? user.patronymic
                      : newUser.patronymic
                  }
                >
                  <TextInput
                    type="text"
                    name="patronymic"
                    value={
                      isEdit && user?.patronymic
                        ? user.patronymic
                        : newUser.patronymic
                    }
                    onChange={(e) =>
                      changeUser(targetUser, "patronymic", e.target.value)
                    }
                    className={`form__field ${
                      errors.patronymic ? "warning" : ""
                    }`}
                  />
                </FloatLabel>
                {errors.patronymic && (
                  <p className="warning-text margin-bottom-12">
                    {errors.patronymic}
                  </p>
                )}

                <FloatLabel
                  label="Логин"
                  value={isEdit && user ? user.login : newUser.login}
                >
                  <TextInput
                    type="text"
                    name="login"
                    autoComplete="off"
                    value={isEdit && user ? user.login : newUser.login}
                    onChange={(e) => {
                      resetLoginErrors();
                      changeUser(targetUser, "login", e.target.value);
                    }}
                    className={`form__field ${errors.login ? "warning" : ""}`}
                  />
                </FloatLabel>
                {errors.login && (
                  <p className="warning-text margin-bottom-12">
                    {errors.login}
                  </p>
                )}

                <FloatLabel
                  label="Организация пользователя"
                  value={targetUser.organizationId}
                >
                  <Select
                    id="id"
                    data={userOrganizations}
                    value={targetUser.organizationId}
                    className={`select form__field input__text no-float no-float mb-25 ${
                      errors.organization ? "warning" : ""
                    }`}
                    onChange={(org) =>
                      changeUser(targetUser, "organizationId", org)
                    }
                    disabled={isLoading || isUploading}
                    isRightArrow={true}
                  />
                </FloatLabel>
                {errors.organization && (
                  <p className="warning-text margin-bottom-12">
                    {errors.organization}
                  </p>
                )}

                <FloatLabel
                  label={isEdit ? "Новый пароль" : "Пароль"}
                  value={isEdit && user ? user.password : newUser.password}
                >
                  <TextInput
                    type="password"
                    name="password"
                    autoComplete="off"
                    value={isEdit && user ? user.password : newUser.password}
                    onChange={(e) =>
                      changeUser(targetUser, "password", e.target.value)
                    }
                    className={`form__field ${
                      errors.password ? "warning" : ""
                    }`}
                  />
                </FloatLabel>
                {errors.password && (
                  <p className="warning-text margin-bottom-12">
                    {errors.password}
                  </p>
                )}

                <FloatLabel
                  label="Повторите пароль"
                  value={targetUser.repeatedPass}
                >
                  <TextInput
                    type="password"
                    name="repeatedPass"
                    autoComplete="off"
                    value={targetUser.repeatedPass}
                    onChange={(e) =>
                      changeUser(targetUser, "repeatedPass", e.target.value)
                    }
                    className={`form__field ${
                      errors.repeatedPass ? "warning" : ""
                    }`}
                  />
                </FloatLabel>
                {errors.repeatedPass && (
                  <p className="warning-text margin-bottom-12">
                    {errors.repeatedPass}
                  </p>
                )}

                <label className="label margin-bottom-32 margin-top-20">
                  Роли пользователя
                </label>

                {userRolesChipsBlock}
                {rolesSelector}

                <div className="form__buttons">
                  <SubmitButton
                    className="form__button"
                    disabled={isUploading || isLoading}
                  >
                    {isUploading || isLoading ? "Загрузка..." : "Сохранить"}
                  </SubmitButton>
                  <CancelButton className={"form__button"}>Отмена</CancelButton>
                </div>
              </form>
            </div>
          </section>
        </div>
      );
    }
  )
);

export default AddEditUser;
