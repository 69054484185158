import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

import ParametersModalContent from "../ParametersModalContent";

const ModalAddParameter = inject("SearchStore")(
  observer(
    ({
      callback,
      wrapCallback,
      onCancel,
      searchType,
      isInForm,
      SearchStore,
    }) => {
      const {
        resetChecked,
        resetSearch,
        resetFetchedParamsFlags,
        noParametersChecked,
      } = SearchStore;

      useEffect(() => {
        resetSearch();
        resetChecked("all");
      }, [resetSearch, resetChecked]);

      useEffect(() => () => resetFetchedParamsFlags(), [
        resetFetchedParamsFlags,
      ]);

      return (
        <div className="modal">
          <p className="modal__title">Добавить параметр</p>

          <form
            className={`modal__content-parameters` + (isInForm ? " width" : "")}
          >
            <ParametersModalContent
              searchType={searchType}
              isInForm={isInForm}
            />
          </form>

          <div className="modal__button-group">
            <SubmitButton
              disabled={noParametersChecked}
              className={"modal__button"}
              onClick={(e) => wrapCallback(e, callback, () => onCancel(false))}
            >
              {isInForm ? "Добавить" : "Добавить в фильтрацию"}
            </SubmitButton>
            <CancelButton
              className={"modal__button"}
              onClick={() => onCancel(false)}
            >
              Отмена
            </CancelButton>
          </div>
        </div>
      );
    }
  )
);

export default ModalAddParameter;
