import React from "react";
import { Link } from "react-router-dom";

import "../index.scss";

const Description = ({ id, description, isLoading }) => {
	return (
		<div className="activity__item-content">
			<div className="activity__item-menu">
				<Link className="activity__item-link page-link" to={`/activities/edit/${id}`}>
					Редактировать или удалить мероприятие
				</Link>
			</div>

			<p className="activity__item-description">{isLoading ? "Загрузка..." : description}</p>
		</div>
	);
};

export default Description;
