import React from "react";

import "./index.scss";

const UnderlinedTabs = ({ data, selectedTab, onSelectTab }) => {
  const underlinedTabsElements = data.map((el) => {
    const selctedClass = el.id === selectedTab ? "selected" : "";

    return (
      <button
        key={el.id}
        className={`underlined-tab ${selctedClass}`}
        onClick={() => {
          onSelectTab(el.id);
        }}
      >
        {el.title}
      </button>
    );
  });

  return (
    <div className="underlined-tabs-wrapper">{underlinedTabsElements}</div>
  );
};

export default UnderlinedTabs;
