import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";

import Breadcrumbs from "components/Breadcrumbs";
import Modal from "components/Modal";

import TextInput from "common/components/Inputs/TextInput";
import TextArea from "common/components/Inputs/TextArea";
import Timepicker from "common/components/Inputs/Timepicker";
import Datepicker from "common/components/Inputs/Datepicker";
import Checkbox from "common/components/Inputs/Checkbox";
import FloatLabel from "common/components/Inputs/FloatLabel";
import SubmitButton from "common/components/Buttons/SubmitButton";
import CancelButton from "common/components/Buttons/CancelButton";

import DeleteButton from "common/components/Buttons/DeleteButton";

import useDocumentTitle from "hooks/useDocumentTitle";

import "./index.scss";

const fromMonth = new Date(new Date().getFullYear(), new Date().getMonth());

const AddEditActivity = inject(
	"ModalStore",
	"ActivitiesStore",
	"ApplicationStore"
)(
	observer(({ ActivitiesStore, ApplicationStore, ModalStore, match }) => {
		const {
			fetchActivity,
			changeActivity,
			changeActivityDate,
			changeActivityDateOfEnd,
			changeActivityTime,
			timeOfStart,
			timeOfEnd,
			newActivity,
			addActivity,
			updateActivity,
			deleteActivity,
			parsedStartActivityDate,
			parsedEndActivityDate,
			activity,
			errors,
			reset,
			resetServerErrors,
		} = ActivitiesStore;
		const { isLoading, isUploading, setGlobalFieldValue } = ApplicationStore;
		const { toggle, isSubmit, resetModal } = ModalStore;

		const isEdit = match.url.includes("/activities/edit");
		const id = match.params.activityId;

		const [dateStart, setDateStart] = useState(undefined);
		const [dateEnd, setDateEnd] = useState(undefined);

		useEffect(() => {
			if (isEdit) {
				setGlobalFieldValue("mode", "EDIT_ACTIVITY");
				fetchActivity(id);
			} else {
				setGlobalFieldValue("mode", "ADD_ACTIVITY");
			}
			return () => {
				setGlobalFieldValue("mode", "");
				setGlobalFieldValue("isSubmitted", false);
				reset();
			};
		}, [fetchActivity, isEdit, id, reset, setGlobalFieldValue]);

		useEffect(() => {
			if (isSubmit) {
				deleteActivity(id);
			}
		}, [isSubmit, deleteActivity, id]);

		useEffect(() => () => resetModal(), [resetModal]);

		useDocumentTitle(() => (isEdit ? "Редактировать мероприятие" : "Добавить мероприятие"), [isEdit]);

		const breadcrumbs = [
			{ path: "/activities", label: "Мероприятия" },
			{
				path: "/activities/add",
				label: isEdit ? "Редактировать мероприятие" : "Добавить мероприятие",
			},
		];

		const handleSubmit = (e) => {
			e.preventDefault();
			isEdit ? updateActivity(id) : addActivity();
		};

		const modalDanger = <Modal type="danger" />;
		const anonymousTicketsCountField = (
			<>
				<FloatLabel
					label="Количество анонимных лотерейных билетов"
					value={isEdit ? activity?.anonymousTicketsCount : newActivity.anonymousTicketsCount}>
					<TextInput
						value={isEdit ? activity?.anonymousTicketsCount : newActivity.anonymousTicketsCount}
						type="number"
						name="anonymousTicketsCount"
						onChange={(e) => {
							isEdit
								? changeActivity(activity, "anonymousTicketsCount", e.target.value)
								: changeActivity(newActivity, "anonymousTicketsCount", e.target.value);
						}}
						className="form__field"
						onKeyDown={(e) => {
							if ("e+-".includes(e.key)) {
								e.preventDefault();
							}
						}}
					/>
				</FloatLabel>
				<p className="anonymous-tickets-notification">
					Значения, меньшие ранее установленных, будут проигнорированы системой.
				</p>
			</>
		);

		const hasLottery = isEdit ? activity?.hasLottery : newActivity.hasLottery;

		return (
			<div className="wrapper__content">
				{modalDanger}
				<Breadcrumbs routes={breadcrumbs} />
				<section className="section">
					<div className="container">
						<h1 className="page-title">{isEdit ? "Редактировать мероприятие" : "Добавить мероприятие"}</h1>
						{isEdit && (
							<DeleteButton
								onClick={() => toggle(true)}
								className="page-link"
								disabled={isLoading || isUploading}>
								Удалить мероприятие
							</DeleteButton>
						)}

						<form className="form" onSubmit={handleSubmit} noValidate>
							<FloatLabel
								label="Название"
								value={isEdit && activity ? activity.title : newActivity.title}>
								<TextArea
									name="title"
									value={isEdit && activity ? activity.title : newActivity.title}
									onChange={(e) => {
										resetServerErrors();
										isEdit
											? changeActivity(activity, "title", e.target.value)
											: changeActivity(newActivity, "title", e.target.value);
									}}
									className={`form__field float ${errors.title ? "warning" : ""}`}
									autoFocus
								/>
							</FloatLabel>
							{errors.title && <p className="warning-text margin-bottom-12">{errors.title}</p>}

							<FloatLabel
								label="Дата начала"
								value={isEdit && activity ? parsedStartActivityDate : dateStart}>
								<Datepicker
									name="dateStart"
									date={isEdit && activity ? parsedStartActivityDate : dateStart}
									startDate={fromMonth}
									changeDate={(day) => {
										setDateStart(day);
										isEdit
											? changeActivityDate(activity, day)
											: changeActivityDate(newActivity, day);
									}}
									className={`form__field datepicker ${errors.dateStart ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.dateStart && <p className="warning-text margin-bottom-12">{errors.dateStart}</p>}

							<FloatLabel
								label="Дата окончания"
								value={isEdit && activity ? parsedEndActivityDate : dateEnd}>
								<Datepicker
									name="dateEnd"
									date={isEdit && activity ? parsedEndActivityDate : dateEnd}
									startDate={fromMonth}
									changeDate={(day) => {
										setDateEnd(day);
										isEdit
											? changeActivityDateOfEnd(activity, day)
											: changeActivityDateOfEnd(newActivity, day);
									}}
									className={`form__field datepicker ${errors.dateEnd ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.dateEnd && <p className="warning-text margin-bottom-12">{errors.dateEnd}</p>}

							<FloatLabel label="Время начала" value={timeOfStart || ""}>
								<Timepicker
									value={timeOfStart || ""}
									name="timeStart"
									placeholder=""
									onChange={(e) => changeActivityTime("start", e.target.value)}
									className={`input__text form__field ${errors.timeStart ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.timeStart && <p className="warning-text margin-bottom-12">{errors.timeStart}</p>}

							<FloatLabel label="Время окончания" value={timeOfEnd || ""}>
								<Timepicker
									value={timeOfEnd || ""}
									name="timeEnd"
									placeholder=""
									onChange={(e) => changeActivityTime("end", e.target.value)}
									className={`input__text form__field ${errors.timeEnd ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.timeEnd && <p className="warning-text margin-bottom-12">{errors.timeEnd}</p>}

							<FloatLabel
								label="Место проведения"
								value={isEdit && activity ? activity?.location : newActivity.location}>
								<TextInput
									type="text"
									name="location"
									value={isEdit && activity ? activity?.location : newActivity.location}
									onChange={(e) =>
										isEdit
											? changeActivity(activity, "location", e.target.value)
											: changeActivity(newActivity, "location", e.target.value)
									}
									className={`form__field ${errors.location ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.location && <p className="warning-text margin-bottom-12">{errors.location}</p>}

							<FloatLabel
								label="Описание"
								value={isEdit && activity ? activity.description : newActivity.description}>
								<TextArea
									name="description"
									value={isEdit && activity ? activity.description : newActivity.description}
									onChange={(e) =>
										isEdit
											? changeActivity(activity, "description", e.target.value)
											: changeActivity(newActivity, "description", e.target.value)
									}
									className={`form__field float big ${errors.description ? "warning" : ""}`}
								/>
							</FloatLabel>
							{errors.description && (
								<p className="warning-text margin-bottom-12">{errors.description}</p>
							)}

							<div className="mb30">
								<Checkbox
									id="hasLottery"
									labelClassName={`checkbox-label ${hasLottery ? "checked" : ""}`}
									onChange={() => {
										isEdit
											? changeActivity(activity, "hasLottery", !hasLottery)
											: changeActivity(newActivity, "hasLottery", !hasLottery);
									}}
									checked={hasLottery || false}>
									Проведение лотереи
								</Checkbox>
							</div>

							{hasLottery && anonymousTicketsCountField}

							<div className="form__buttons">
								<SubmitButton className="form__button" disabled={isLoading || isUploading}>
									{isLoading || isUploading ? "Загрузка..." : "Сохранить"}
								</SubmitButton>
								<CancelButton className={"form__button"}>Отмена</CancelButton>
							</div>
						</form>
					</div>
				</section>
			</div>
		);
	})
);

export default AddEditActivity;
